import gemmaLogo from '../../assets/icons/gemma-logo.svg'

interface IGemmaQuestion {
  value: string
}

export function GemmaQuestion({ value }: IGemmaQuestion) {
  return (
    <div>
      <div className="flex gap-3 p-3">
        <div className="bg-cerosGrey100 flex h-12 w-12 items-center justify-center rounded-lg p-2">
          <div
            className="h-full w-full bg-contain bg-center bg-no-repeat"
            style={{
              backgroundImage: `url("${gemmaLogo}")`,
            }}
          />
        </div>

        <div className="flex-1">
          <div className="text-cerosGrey600 text-base font-medium">Gemma</div>
          <p className="text-cerosGrey500 mt-3 text-2xl">{value}</p>
        </div>
      </div>
    </div>
  )
}
