import { injected } from 'brandi'

import { DI_TYPE } from '@/di.types.js'
import type { ApiClient } from '@/services/api-client.js'

export interface EditImageSession {
  id: string
  input_url: string
  result_url: string
  edit_count: number
}

export interface EditImageItem {
  id: string
  input_url: string
  result_url: string
  tool: string
}

interface CreateEditSessionBody {
  input_url: string
}

interface CreateImageEdit {
  tool: string
  params: any
}

interface UploadMaskResult {
  id: string
  url: string
}

export class EditImageRepository {
  constructor(private apiClient: ApiClient) {}

  async createEditSession(body: CreateEditSessionBody) {
    try {
      const { data, status } = await this.apiClient.post<
        EditImageSession,
        any,
        CreateEditSessionBody
      >('/images/edit_sessions', body)

      return { data, status }
    } catch (error: any) {
      return { data: undefined, status: 500 }
    }
  }

  async createEdit(session_id: string, body: CreateImageEdit) {
    try {
      const { data, status } = await this.apiClient.post<
        EditImageItem,
        any,
        CreateImageEdit
      >(`/images/edit_sessions/${session_id}/edits`, body)

      return { data, status }
    } catch (error: any) {
      return { data: undefined, status: 500 }
    }
  }

  async readEdit(session_id: string, edit_id: string) {
    try {
      const { data, status } = await this.apiClient.get<EditImageItem>(
        `/images/edit_sessions/${session_id}/edits/${edit_id}`,
      )
      return { data, status }
    } catch (error: any) {
      return { data: undefined, status: 500 }
    }
  }

  async uploadMask(blob: Blob) {
    try {
      let formData = new FormData()
      let file = new File([blob], 'mask')
      formData.append('image', file)

      const { data, status } = await this.apiClient.post<UploadMaskResult>(
        '/images/images/upload',
        formData,
        {
          headers: {
            'Content-Type': `multipart/form-data`,
          },
        },
      )

      return { data, status }
    } catch (error: any) {
      return { data: undefined, status: 500 }
    }
  }
}

injected(EditImageRepository, DI_TYPE.ApiClient)
