export type ActionHandler = (action: Action) => void

type BlockDefinitionWithoutType<T> = Omit<T, 't'>
interface ComponentProps {
  onAction: ActionHandler
}

export type BlockComponent<T> = BlockDefinitionWithoutType<T> & ComponentProps

export enum ActionType {
  REPLY = 'reply',
  SUBMIT = 'submit',
  CHOICE = 'choice',
  SHOW_MODAL = 'show_modal',
}

export interface Action {
  type: ActionType
  data: any
}
