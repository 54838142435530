import { captureException } from '@sentry/react'
import { injected } from 'brandi'
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk'
import { action, makeObservable, observable, runInAction } from 'mobx'
import toast from 'react-hot-toast'

import { DI_TYPE } from '@/di.types'
import type { SpeechRepository } from '@/repositories/speech'
import { BaseViewModel } from '@/view-models/base-view-model'

export class VoiceInputService extends BaseViewModel {
  REFRESH_TOKEN_INTERVAL = 9 * 60 * 1000 // NOTE: setting refresh to 9 min, because expiration interval for Azure speech service is 10min
  CANCELED_ERROR_MSG =
    "Oops, I'm sorry my ears stopped working for a minute there, can you try again, please?"
  GRANT_PERMISSIONS_MSG =
    'To talk to Gemma please grant her permission to your microphone in site settings of\xA0your\xA0browser.'
  @observable speechInitializing = false
  @observable speechRecording = false
  @observable speechRecordingStarted = false
  @observable speechRecordingEnded = false
  @observable recognizingText: string | undefined = undefined
  @observable recognizedText: string | undefined = undefined
  @observable tokenRefreshIntervalId: number | undefined = undefined

  @observable recognizer: SpeechSDK.SpeechRecognizer | null = null

  constructor(private speechRepository: SpeechRepository) {
    super()
    makeObservable(this)
  }

  protected onInit(): void {}

  protected onDispose(): void {
    this.voiceInputStop()
  }

  @action.bound
  onSpeechRecognized(text: string) {
    this.recognizedText = text
  }

  @action.bound
  onSpeechRecognizing(text: string) {
    this.recognizingText = text
  }

  private async createSpeechRecognizer(
    speechToken: string,
    speechRegion: string,
    micId?: string,
  ) {
    const audioConfig = SpeechSDK.AudioConfig.fromMicrophoneInput(micId)
    const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(
      speechToken,
      speechRegion,
    )

    const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig)

    recognizer.recognized = this.onRecognized
    recognizer.recognizing = this.onRecognizing
    recognizer.canceled = this.onCanceled
    recognizer.sessionStarted = this.onSessionStarted
    recognizer.sessionStopped = this.onSessionStopped
    recognizer.speechStartDetected = this.onSpeechStartDetected
    recognizer.speechEndDetected = this.onSpeechEndDetected

    return recognizer
  }

  @action.bound
  private async getToken(): Promise<{ token: string; region: string }> {
    const { data, status } = await this.speechRepository.generateSpeechToken()

    if (status >= 200 && status < 400 && data) {
      return { ...data }
    }

    throw new Error('Generate token failed')
  }

  @action.bound
  private async refreshToken() {
    try {
      const { token } = await this.getToken()
      if (this.recognizer) {
        this.recognizer.authorizationToken = token
      }
    } catch (e) {
      captureException(new Error('Failed to refresh token', { cause: e }))
    }
  }

  @action.bound
  private async startRefreshToken() {
    const id = window.setInterval(
      this.refreshToken,
      this.REFRESH_TOKEN_INTERVAL,
    )

    runInAction(() => {
      this.tokenRefreshIntervalId = id
    })
  }

  @action.bound
  private async stopRefreshToken() {
    if (this.tokenRefreshIntervalId) {
      clearInterval(this.tokenRefreshIntervalId)
      this.tokenRefreshIntervalId = undefined
    }
  }

  @action.bound
  async voiceInputStart() {
    this.speechInitializing = true
    this.speechRecordingStarted = false
    this.speechRecordingEnded = false

    try {
      // ensuring user gives permission to use microphone
      await navigator.mediaDevices.getUserMedia({ audio: true })
    } catch (e) {
      runInAction(() => {
        this.speechInitializing = false
      })
      return toast.error(this.GRANT_PERMISSIONS_MSG, {
        position: 'top-center',
        duration: 5000,
        style: { maxWidth: '400px' },
      })
    }
    const { token, region } = await this.getToken()

    if (!token || !region) {
      toast.error(this.CANCELED_ERROR_MSG)
    }

    let recognizer: SpeechSDK.SpeechRecognizer
    try {
      recognizer = await this.createSpeechRecognizer(token, region)

      this.startRefreshToken()

      recognizer.startContinuousRecognitionAsync()
    } catch (e) {
      this.stopRefreshToken()

      return toast.error(this.CANCELED_ERROR_MSG)
    }

    runInAction(() => {
      this.speechRecording = true
      this.speechInitializing = false
      this.speechRecordingStarted = true
      this.recognizer = recognizer
    })
  }

  @action.bound
  async voiceInputStop() {
    try {
      // wait for speech recognition to stop
      await new Promise<void>((resolve, reject) => {
        if (this.recognizer) {
          this.recognizer.stopContinuousRecognitionAsync(resolve, reject)
          this.recognizer.close()
          this.stopRefreshToken()
        }
      })
    } catch (e) {
      captureException(
        new Error('Failed to stop voice recognition', { cause: e }),
      )
    }

    runInAction(() => {
      this.recognizer = null
      this.speechRecording = false
      this.speechRecordingEnded = true
    })
  }

  @action.bound
  private onRecognizing(sender, recognitionEventArgs) {
    const result = recognitionEventArgs.result
    this.recognizedText = undefined
    this.recognizingText = result.text
  }

  @action.bound
  private onRecognized(sender, recognitionEventArgs) {
    const result = recognitionEventArgs.result
    if (
      result.reason === SpeechSDK.ResultReason.RecognizedSpeech &&
      result.text
    ) {
      this.recognizedText = result.text
    }
  }

  @action.bound
  private onSessionStarted() {}

  @action.bound
  private onSessionStopped() {
    this.recognizedText = undefined
    this.recognizingText = undefined
  }

  @action.bound
  private onSpeechStartDetected() {}

  @action.bound
  private onSpeechEndDetected() {}

  @action.bound
  private onCanceled() {
    toast.error(this.CANCELED_ERROR_MSG)
    this.speechRecording = false
    this.speechRecordingEnded = true
    this.recognizedText = undefined
    this.recognizingText = undefined
  }
}

injected(VoiceInputService, DI_TYPE.SpeechRepository)
