import { z } from 'zod'

const uppercaseRegex = /^(?=.*[A-Z])/
const lowercaseRegex = /^(?=.*[a-z])/
const numberOrSymbolRegex = /^(?=.*[^a-zA-Z\s])/
const noWhitespaceRegex = /^(?=\S+$)/

export const PASSWORD_ERROR = {
  LENGTH: 'LENGTH',
  UPPERCASE: 'UPPERCASE',
  LOWERCASE: 'LOWERCASE',
  NUMBER_OR_SYMBOL: 'NUMBER_OR_SYMBOL',
  NO_WHITESPACE: 'NO_WHITESPACE',
}

export const passwordSchema = z
  .string()
  .min(8, { message: PASSWORD_ERROR.LENGTH })
  .regex(uppercaseRegex, { message: PASSWORD_ERROR.UPPERCASE })
  .regex(lowercaseRegex, { message: PASSWORD_ERROR.LOWERCASE })
  .regex(numberOrSymbolRegex, { message: PASSWORD_ERROR.NUMBER_OR_SYMBOL })
  .regex(noWhitespaceRegex, { message: PASSWORD_ERROR.NO_WHITESPACE })
