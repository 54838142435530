import type {
  BillingUserSettings,
  Gem,
  TaskPromptType,
} from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import mixpanel from 'mixpanel-figma'

import { DI_TYPE } from '@/di.types'
import { env } from '@/env.js'
import type { UserProperties } from '@/models/user'
import type { AppOrigin } from '@/utils/utils.js'
import {
  APP_VERSION,
  getAppOrigin,
  getPathType,
  isProductionBuild,
} from '@/utils/utils.js'
import type { QuestionPage } from '@/view-models/onboarding.js'

import type { EventBusPayload, EventBusService } from './event-bus'
import { RegisteredEvents } from './event-bus'
import { FEATURE_FLAGS, type FeatureFlagService } from './feature-flag'
import type { CreateFeedbackForm } from './support'

export type IntegrationOrigin = 'Sidebar' | 'Chat'
export type ResultOrigin =
  | 'AI Generated'
  | 'Unsplash'
  | 'NounProject'
  | 'Brandfetch'
export type ResultUsedType =
  | 'drag-and-drop'
  | 'click'
  | 'copy-value'
  | 'copy-url'
  | 'download'
  | 'User Attachment' // FIXME: it's deprecated, think about renaming

type MixpanelStatsName =
  | 'App Started'
  | 'Integration Result Used'
  | 'Results Used (AI generated)'

type SharingOrigin = 'Sidebar' | 'Button'
type SharingContentType = 'Chat' | 'Gem'
type SharingProperties = {
  'Share Type': SharingContentType
  'Share Link Origin'?: SharingOrigin
  'Chat ID'?: string
  'Chat Type'?: string
  'Gem ID'?: string
}

type ChatType = 'New' | 'Existing'

type GemProperties = {
  'Gem ID': string
  'Gem Title': string
  'Gem Emoji': string
  'Files Uploaded': number
}

export type DocumentCopiedSource =
  | 'button'
  | 'selection'
  | 'preview'
  | 'keyboard'
type DocumentProperties = {
  'Chat ID': string
  'Document Version Index': number
  'Document Copied Source'?: DocumentCopiedSource
  'Document Draft Length'?: number
}

// We do not change mixpanel events but new events should be in past tense.
// ie. It should be "Integration Opened" instead of "Integration Open"
type MixpanelEventName =
  | 'App session ended'
  | 'App session started'
  | 'Login by email started'
  | 'Social IDP clicked'
  | 'Chat Delete'
  | 'Chat Rename'
  | 'Chat Start'
  | 'Feedback Sent'
  | 'File Uploaded'
  | 'File Upload Failed'
  | 'Unpin Attachment'
  | 'Idea Submitted'
  | 'Integration Open'
  | 'Integration Result Used'
  | 'Integration Search'
  | 'Navigation Opened'
  | 'New Message'
  | 'Message Reused'
  | 'Result Created'
  | 'Result Edit'
  | 'Result Rated'
  | 'Result Reported'
  | 'Result Used'
  | 'Stop Generating Clicked'
  | 'Onboarding Viewed'
  | 'Onboarding Skipped'
  | 'Tool Invoked'
  | 'Billing Shown'
  | 'Billing Button Clicked'
  | keyof MixpanelEventRequiredProperties

type MixpanelEventRequiredProperties = {
  'Feature Update': {
    'Feature Label': string
    'Feature Value': boolean
  }
  'Task Sent': {
    'Chat ID': string
    'Chat Type': 'New'
    'Task Prompt Type': TaskPromptType
    'Task Name'?: string
  }
  'Task Show More Clicked': {}
  'Task Search': {
    'Search Term': string
  }
  'Gem Added': GemProperties
  'Gem Updated': GemProperties
  'Gem Deleted': GemProperties
  'Gem Cloned': { 'Gem ID': string; 'Original Gem ID': string }
  'Chat Cloned': { 'Chat ID': string; 'Original Chat ID': string }
  'Profile Opened': {}
  'Profile Update': {}
  'App Info Opened': {}
  'Feedback Opened': {}
  'Desktop App Promo Clicked': {}
  'SignUp Started': { email: string; status: number }
  'SignUp Check Activation Code': { email: string; status: number }
  'SignUp Resend Code': { email: string; status: number }
  'SignUp Finish': { email: string; status: number }
  'Password Reset Started': { email: string; status: number }
  'Password Reset Check Activation Code': { email: string; status: number }
  'Password Reset Resend Code': { email: string; status: number }
  'Password Reset Finish': { email: string; status: number }
  'Share Opened': SharingProperties
  'Share Link Copied': SharingProperties
  'Share Link Toggled': SharingProperties & { 'Publicly Shared': boolean }
  'Signup Access Requested': { email: string }
  'Document Copied': DocumentProperties
  'Document Draft Submitted': DocumentProperties
  'Document Selection Submitted': DocumentProperties
  'Document Selection Regenerated': DocumentProperties
}

type MixpanelEventsWithoutProperties = {
  [K in keyof MixpanelEventRequiredProperties]: {} extends MixpanelEventRequiredProperties[K]
    ? K
    : never
}[keyof MixpanelEventRequiredProperties]

export type MessageOrigin = 'TEXT' | 'VOICE_INPUT' | 'TASK' | 'BLOCK'
export type ReportType = 'Harmful/unsafe' | 'Not accurate' | 'Not helpful'
export type ChatCreateSourceType =
  | 'New chat Gem'
  | 'Sidebar button'
  | 'Homepage default task'
  | 'Homepage prompt'
  | 'Homepage file upload'
  | 'New chat URL'
  | 'Studio embed'
type ResultChangeType = 'remove_bg' | 'upscale' | 'variation'
type MixpanelTrackPropertiesBase = {
  'Chat ID'?: string
  'Chat Type'?: ChatType
  'Chat Create Source'?: string
  'Original Chat ID'?: string
  'Edit Type'?: string
  'Error Type'?: string
  'Feature Label'?: string
  'Feature Value'?: boolean
  'Feedback Message'?: string
  'Feedback Type'?: string
  'File Format'?: string
  'File Size'?: number
  'Files Uploaded'?: number
  'Gem ID'?: string
  'Gem Title'?: string
  'Gem Prompt'?: string
  'Gem Emoji'?: string
  'Attachment ID'?: string
  'Original Gem ID'?: string
  'Idea message'?: string
  'Integration Origin'?: IntegrationOrigin
  'Integration Type'?: string
  'Message ID'?: string
  'Message IDs'?: string[]
  'Message Content Types'?: string[]
  'Responding To ID'?: string
  'Message Length'?: number
  'Message Origin'?: MessageOrigin
  'Has Draft'?: boolean
  'Has Selection'?: boolean
  'Navigation Type'?: string
  'Plugin Version'?: string
  'Prompt Type'?: string
  'Pin Type'?: string
  'Profile Name'?: string
  'Profile Job Title'?: string
  'Profile Roles'?: string[]
  'Profile Organization'?: string
  'Profile Additional Info'?: string
  'Profile Interests'?: string[]
  'Tool Type'?: string
  'Query Value'?: string
  'Report Message'?: string
  'Report Type'?: ReportType[]
  'Result Change Type'?: ResultChangeType | string
  'Result ID'?: string
  'Result Origin'?: ResultOrigin
  'Result Rating'?: string
  'Result Time to create'?: number
  'Result Type'?: string
  'Result Used Type'?: ResultUsedType
  'Upload Time'?: number
  'Upload Type'?: string
  'Upscale Option'?: 2 | 4 | 8 | undefined
  'Onboarding Step'?: QuestionPage
  'IDP ID'?: string
  'IDP Name'?: string
  'From Voice'?: boolean
}

type MixpanelTrackProperties = MixpanelTrackPropertiesBase &
  Partial<
    MixpanelEventRequiredProperties[keyof MixpanelEventRequiredProperties]
  >
type MixpanelTrackDefaultProperties = {
  'Plugin Version': string
  'Session ID': string
  Environment: 'Development' | 'Production' | 'Test'
  Origin: AppOrigin
  'LogRocket Session URL': string | undefined
}

type LogRocketSession = {
  sessionURL: string
}

type FileUploadProperties = {
  gemId?: string
  chatId?: string
  chatType?: ChatType
  fileFormat: string
  fileSize: number
  uploadTime?: number
  uploadType: string
  errorType?: string
}

type TrackNewMessageProperties = {
  chatId: string
  messageId: string
  messageOrigin: MessageOrigin
  messageLength?: number
  textEditorContext?: { hasDraft?: boolean; hasSelection?: boolean }
}

type ShareChatProperties = {
  chatId: string
  linkOrigin: SharingOrigin
}

type CloneGemProperties = {
  gemId: string
  originalGemId: string
  attachmentsLength: number
}

type TrackDocumentProperties = {
  chatId: string
  documentVersionIndex?: number
}

export type BillingType = 'upgrade' | 'manage'

export type BillingShownProperties = {
  billing_source:
    | 'upgrade-button'
    | 'upgrade-banner'
    | 'plan-settings'
    | 'chat-upsell-button'
    | 'stripe-callback-page'
  billing_type: BillingType
}

export type BillingButtonClickedProperties = {
  billing_button_type: BillingType | 'ceros-suite'
}

export class MixpanelService {
  private sessionId: string = 'no session'
  private logRocketSessionURL: string | undefined

  constructor(
    private eventBusService: EventBusService,
    private featureFlagService: FeatureFlagService,
  ) {
    const debug = false

    if (isProductionBuild) {
      const MIXPANEL_TOKEN: string = env().VITE_APP_MIXPANEL_PROJECT_ID || ''
      mixpanel.init(MIXPANEL_TOKEN, {
        debug,
      })
      console.info('[MixpanelService] Initialized')

      this.eventBusService.subscribe(
        RegisteredEvents.LogRocketGetSessionURL,
        (payload: EventBusPayload<LogRocketSession>) => {
          this.logRocketSessionURL = payload.detail.sessionURL
        },
      )
    }
  }

  applyIdentity(id: string, email: string, sessionId: string) {
    if (isProductionBuild) {
      this.sessionId = sessionId
      console.info(
        `[MixpanelService] Apply identity: ${!!id ? 'user' : 'anonymous'}`,
      )
      mixpanel.identify(id)

      this.trackAppSessionStarted()

      mixpanel.people.set_once({ id, $email: email })

      mixpanel.people.unset(['Email', 'email']) // TODO: Remove After few releases, it's just remove old property from user profile if exists
    }
  }

  applyUserProperties(id: string, properties: UserProperties) {
    if (isProductionBuild) {
      mixpanel.people.set({
        id: id,
        name: properties.name,
        roles: properties.roles,
        interests: properties.interests,
        job_title: properties.job_title,
      })
    }
  }

  applyBillingProperties(properties: BillingUserSettings) {
    if (isProductionBuild) {
      mixpanel.people.set({
        id: properties.user_id,
        plan_type: properties.plan_type,
        messages_remaining: properties.messages_remaining,
      })
    }
  }

  private trackPlugin(
    eventName: MixpanelEventName,
    properties?: MixpanelTrackProperties,
  ) {
    if (this.featureFlagService.isEnabled(FEATURE_FLAGS.MIXPANEL_LOGGING)) {
      console.info('Mixpanel', eventName, properties)
    }
    if (isProductionBuild) {
      const requestProperties: MixpanelTrackProperties &
        MixpanelTrackDefaultProperties = {
        ...properties,
        Origin: getAppOrigin(),
        'Plugin Version': APP_VERSION,
        'Session ID': this.sessionId,
        Environment: env().VITE_APP_ENVIRONMENT,
        'LogRocket Session URL': this.logRocketSessionURL,
      }

      mixpanel.track(eventName, requestProperties)
    }
  }

  /**
   * Track an event. Properties is optional if and only if they are of type `{}`
   *
   * @param k
   * @param properties
   */
  track(k: MixpanelEventsWithoutProperties): void
  track<K extends keyof MixpanelEventRequiredProperties>(
    k: K,
    properties: MixpanelEventRequiredProperties[K],
  ): void
  track(k: keyof MixpanelEventRequiredProperties, properties?: any) {
    this.trackPlugin(k, properties)
  }

  private incrementStats(property: MixpanelStatsName) {
    if (isProductionBuild) {
      mixpanel.people.increment(property)
    }
  }

  trackAppSessionStarted() {
    this.trackPlugin('App session started')
    this.incrementStats('App Started')
  }

  trackAppSessionEnded() {
    this.trackPlugin('App session ended')
  }

  trackChatCreate(
    chatId: string,
    chatCreateData: {
      source: ChatCreateSourceType
      gemId?: string
      gemTitle?: string
      taskPromptType?: string
      taskTitle?: string
      sourceChatId?: string
    },
  ) {
    this.trackPlugin('Chat Start', {
      'Chat ID': chatId,
      'Chat Type': 'New',
      'Chat Create Source': chatCreateData.source,
      'Task Prompt Type': chatCreateData.taskPromptType as TaskPromptType,
      'Task Name': chatCreateData.taskTitle,
      'Gem ID': chatCreateData.gemId,
      'Gem Title': chatCreateData.gemTitle,
      'Original Chat ID': chatCreateData.sourceChatId,
    })
  }

  trackChatStart(chatId: string, chatType: ChatType = 'Existing') {
    this.trackPlugin('Chat Start', {
      'Chat ID': chatId,
      'Chat Type': chatType,
    })
  }

  trackChatCloned(chatId: string, sourceChatId: string) {
    this.trackPlugin('Chat Cloned', {
      'Chat ID': chatId,
      'Original Chat ID': sourceChatId,
    })
  }

  trackLoginByEmailStarted() {
    this.trackPlugin('Login by email started')
  }

  trackSocialIdpClick(idpId: string, idpName?: string) {
    this.trackPlugin('Social IDP clicked', {
      'IDP ID': idpId,
      'IDP Name': idpName,
    })
  }

  trackChatRename(chatId: string) {
    this.trackPlugin('Chat Rename', {
      'Chat ID': chatId,
    })
  }

  trackChatDelete(chatId: string) {
    this.trackPlugin('Chat Delete', {
      'Chat ID': chatId,
    })
  }

  trackGemAdded(gem: Gem) {
    this.trackPlugin('Gem Added', {
      'Gem ID': gem.id,
      'Gem Title': gem.title,
      'Gem Emoji': gem.emoji,
      'Files Uploaded': gem.attachments?.length ?? 0,
    })
  }

  trackGemDeleted(gem: Gem) {
    this.trackPlugin('Gem Deleted', {
      'Gem ID': gem.id,
      'Gem Title': gem.title,
      'Gem Emoji': gem.emoji,
      'Files Uploaded': gem.attachments?.length ?? 0,
    })
  }

  trackGemUpdated(gem: Gem) {
    this.trackPlugin('Gem Updated', {
      'Gem ID': gem.id,
      'Gem Title': gem.title,
      'Gem Emoji': gem.emoji,
      'Files Uploaded': gem.attachments?.length ?? 0,
    })
  }

  trackFeatureUpdate(featureLabel: string, featureValue: boolean) {
    this.trackPlugin('Feature Update', {
      'Feature Label': featureLabel,
      'Feature Value': featureValue,
    })
  }

  trackNewMessage({
    chatId,
    messageId,
    messageOrigin,
    messageLength,
    textEditorContext,
  }: TrackNewMessageProperties) {
    this.trackPlugin('New Message', {
      'Chat ID': chatId,
      'Message ID': messageId,
      'Message Origin': messageOrigin,
      'Message Length': messageLength,
      'Has Draft': textEditorContext?.hasDraft,
      'Has Selection': textEditorContext?.hasSelection,
    })
  }

  trackMessageReused(chatId: string, messageId: string, value: string) {
    this.trackPlugin('Message Reused', {
      'Chat ID': chatId,
      'Message ID': messageId,
      'Message Length': value.length,
    })
  }

  trackResultCreated({
    chatId,
    messageId,
    messageContentTypes,
    respondingToId,
    resultTimeToCreate,
  }: {
    chatId: string
    messageId: string
    messageContentTypes: string[]
    respondingToId?: string
    resultTimeToCreate?: number
  }) {
    this.trackPlugin('Result Created', {
      'Chat ID': chatId,
      'Message ID': messageId,
      'Message Content Types': messageContentTypes,
      'Responding To ID': respondingToId,
      'Result Time to create': resultTimeToCreate,
    })
  }

  trackResultUsed({
    chatId,
    integrationOrigin,
    messageId,
    messageContentTypes,
    queryValue,
    resultChangeType,
    respondingToId,
    resultOrigin,
    resultUsedType,
  }: {
    chatId?: string
    integrationOrigin?: IntegrationOrigin
    messageId?: string
    messageContentTypes?: string[]
    queryValue?: string
    resultChangeType?: string
    respondingToId?: string
    resultOrigin: ResultOrigin
    resultUsedType: ResultUsedType
  }) {
    this.trackPlugin('Result Used', {
      'Chat ID': chatId,
      'Integration Origin': integrationOrigin,
      'Message ID': messageId,
      'Message Content Types': messageContentTypes,
      'Query Value': queryValue,
      'Result Change Type': resultChangeType,
      'Responding To ID': respondingToId,
      'Result Origin': resultOrigin,
      'Result Used Type': resultUsedType,
    })
    if (resultOrigin === 'AI Generated') {
      this.incrementStats('Results Used (AI generated)')
    } else {
      this.incrementStats('Integration Result Used')
    }
  }

  trackProfileUpdate({
    name,
    job_title,
    roles,
    organization,
    additional_info,
    interests,
  }: UserProperties) {
    this.trackPlugin('Profile Update', {
      'Profile Name': name ?? undefined,
      'Profile Job Title': job_title,
      'Profile Roles': roles,
      'Profile Organization': organization,
      'Profile Additional Info': additional_info,
      'Profile Interests': interests,
    })
  }

  trackResultEdit({
    chatId,
    messageId,
    resultId,
    resultType,
    resultOrigin,
    editType,
    upscaleOption,
    resultTimeToCreate,
  }: {
    chatId: string
    messageId: string
    resultId: string
    resultType: string
    resultOrigin?: ResultOrigin
    editType: string
    upscaleOption?: 2 | 4 | 8 | undefined
    resultTimeToCreate: number
  }) {
    this.trackPlugin('Result Edit', {
      'Chat ID': chatId,
      'Message ID': messageId,
      'Result ID': resultId,
      'Result Type': resultType,
      'Result Origin': resultOrigin,
      'Edit Type': editType,
      'Upscale Option': upscaleOption,
      'Result Time to create': resultTimeToCreate,
    })
  }

  trackResultReported({
    chatId,
    gemId,
    messageId,
    messageContentTypes,
    respondingToId,
    reportType,
    reportMessage,
  }: {
    chatId: string
    gemId?: string
    messageId?: string
    messageContentTypes?: string[]
    respondingToId?: string
    reportType?: ReportType[]
    reportMessage: string
  }) {
    this.trackPlugin('Result Reported', {
      'Chat ID': chatId,
      'Gem ID': gemId,
      'Message ID': messageId,
      'Message Content Types': messageContentTypes,
      'Responding To ID': respondingToId,
      'Report Type': reportType,
      'Report Message': reportMessage,
    })
  }

  trackToolInvoked(chatId: string, messageId: string, toolType: string) {
    this.trackPlugin('Tool Invoked', {
      'Chat ID': chatId,
      'Message ID': messageId,
      'Tool Type': toolType,
    })
  }

  trackNavigationOpened(path: string) {
    this.trackPlugin('Navigation Opened', {
      'Navigation Type': getPathType(path),
    })
  }

  trackFeedbackSent(
    chatId: string,
    feedbackType: CreateFeedbackForm['type'],
    feedbackMessage: string,
  ) {
    this.trackPlugin('Feedback Sent', {
      'Chat ID': chatId,
      'Feedback Type': feedbackType,
      'Feedback Message': feedbackMessage,
    })
  }

  // TODO: we can use this method, missing idea message
  trackIdeasSubmitted({ ideaMessage }: { ideaMessage: string }) {
    this.trackPlugin('Idea Submitted', {
      'Idea message': ideaMessage,
    })
  }

  trackIntegrationSearch(
    queryValue: string,
    integrationOrigin: IntegrationOrigin,
    integrationType: string,
  ) {
    this.trackPlugin('Integration Search', {
      'Query Value': queryValue,
      'Integration Origin': integrationOrigin,
      'Integration Type': integrationType,
    })
  }

  trackIntegrationOpened(integrationType: string, origin?: IntegrationOrigin) {
    this.trackPlugin('Integration Open', {
      'Integration Type': integrationType,
      'Integration Origin': origin,
    })
  }

  trackStopGeneratingClicked(chatId: string, messageIds: string[]) {
    this.trackPlugin('Stop Generating Clicked', {
      'Chat ID': chatId,
      'Message IDs': messageIds,
    })
  }

  trackOnboardingViewed(page: QuestionPage) {
    this.trackPlugin('Onboarding Viewed', {
      'Onboarding Step': page,
    })
  }

  trackOnboardingSkipped(page: QuestionPage) {
    this.trackPlugin('Onboarding Skipped', {
      'Onboarding Step': page,
    })
  }

  trackFileUpload({
    chatId,
    chatType,
    gemId,
    fileFormat,
    fileSize,
    uploadTime,
    uploadType,
    errorType,
  }: FileUploadProperties) {
    this.trackPlugin(errorType ? 'File Upload Failed' : 'File Uploaded', {
      'Chat ID': chatId,
      'Chat Type': chatType,
      'Gem ID': gemId,
      'File Format': fileFormat,
      'File Size': fileSize,
      'Upload Time': uploadTime,
      'Upload Type': uploadType,
      'Error Type': errorType,
    })
  }

  trackUnpinAttachment(gemId: string, attachmentId: string) {
    this.trackPlugin('Unpin Attachment', {
      'Gem ID': gemId,
      'Attachment ID': attachmentId,
    })
  }

  trackChatSharingOpened({ chatId, linkOrigin }: ShareChatProperties) {
    this.trackPlugin('Share Opened', {
      'Share Type': 'Chat',
      'Chat ID': chatId,
      'Share Link Origin': linkOrigin,
    })
  }

  trackGemSharingOpened(gemId: string) {
    this.trackPlugin('Share Opened', {
      'Share Type': 'Gem',
      'Gem ID': gemId,
    })
  }

  trackChatSharingToggled({
    chatId,
    linkOrigin,
    shared,
  }: ShareChatProperties & { shared: boolean }) {
    this.trackPlugin('Share Link Toggled', {
      'Share Type': 'Chat',
      'Chat ID': chatId,
      'Share Link Origin': linkOrigin,
      'Publicly Shared': shared,
    })
  }

  trackGemSharingToggled(gemId: string, shared: boolean) {
    this.trackPlugin('Share Link Toggled', {
      'Share Type': 'Gem',
      'Gem ID': gemId,
      'Publicly Shared': shared,
    })
  }

  trackChatSharingLinkCopied({ chatId, linkOrigin }: ShareChatProperties) {
    this.trackPlugin('Share Link Copied', {
      'Share Type': 'Chat',
      'Chat ID': chatId,
      'Share Link Origin': linkOrigin,
    })
  }

  trackGemSharingLinkCopied(gemId: string) {
    this.trackPlugin('Share Link Copied', {
      'Share Type': 'Gem',
      'Gem ID': gemId,
    })
  }

  trackGemCloned({
    gemId,
    originalGemId,
    attachmentsLength,
  }: CloneGemProperties) {
    this.trackPlugin('Gem Cloned', {
      'Gem ID': gemId,
      'Original Gem ID': originalGemId,
      'Files Uploaded': attachmentsLength,
    })
  }

  trackDocumentCopied({
    chatId,
    documentVersionIndex,
    source,
  }: TrackDocumentProperties & { source: DocumentCopiedSource }) {
    this.trackPlugin('Document Copied', {
      'Chat ID': chatId,
      'Document Version Index':
        typeof documentVersionIndex === 'number'
          ? documentVersionIndex + 1
          : undefined,
      'Document Copied Source': source,
    })
  }

  trackDocumentSelectionRegenerated({
    chatId,
    documentVersionIndex,
  }: TrackDocumentProperties) {
    this.trackPlugin('Document Selection Regenerated', {
      'Chat ID': chatId,
      'Document Version Index':
        typeof documentVersionIndex === 'number'
          ? documentVersionIndex + 1
          : undefined,
    })
  }

  trackBillingShown(properties: BillingShownProperties) {
    this.trackPlugin('Billing Shown', properties)
  }

  trackUpgradeButtonClicked(properties: BillingButtonClickedProperties) {
    this.trackPlugin('Billing Button Clicked', properties)
  }
}

injected(MixpanelService, DI_TYPE.EventBusService, DI_TYPE.FeatureFlagService)
