import { observer } from 'mobx-react-lite'
import { createContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import ChevronDown from '@/assets/icons/chevron-down-2.svg?react'
import ShareIcon from '@/assets/icons/share.svg?react'
import { Button } from '@/components/button/button.js'
import { AbortButton } from '@/components/chat/abort-button'
import { PromptField } from '@/components/chat/prompt-field/prompt-field.js'
import { IconButton } from '@/components/icon-button/icon-button.js'
import { LoadingDots } from '@/components/loading-dots/loading-dots.js'
import { Tooltip } from '@/components/tooltip/tooltip'
import { UploadArea } from '@/components/upload-area/upload-area'
import { DI_TYPE } from '@/di.types.js'
import type { Prompt } from '@/stores/prompt'
import { useOnScreen } from '@/utils/use-on-screen.js'
import { cls } from '@/utils/utils.js'
import { useFactoryViewModel, useViewModel } from '@/utils/view.js'
import type { PromptInputViewModel } from '@/view-models/prompt-input'
import { MAX_PROMPT_LENGTH } from '@/view-models/prompt-input'

import { SidePreview } from '../../components/side-previews/side-preview.js'
import { ActiveGem } from './active-gem.js'
import { MessagesView } from './messages-view'

// this is used to be able to access the promptInputVM from the BubbleMenu in the text editor component
// in future we might refactor it if we decide to use some global state management
export const ConversationContext = createContext<{
  promptInputVM: PromptInputViewModel
} | null>(null)

export const ConversationPage = observer(() => {
  const { conversationId } = useParams()
  const messageContainerRef = useRef<HTMLDivElement>(null)
  const bottomLineRef = useRef<HTMLDivElement>(null)
  const promptInputRef = useRef<HTMLTextAreaElement>(null)
  const [isAutoScrollingEnabled, setAutoScrollingEnabled] = useState(false)

  const vm = useFactoryViewModel(DI_TYPE.ConversationViewModel, [
    conversationId,
  ])
  const promptInputVM = useViewModel(DI_TYPE.PromptInputViewModel)

  const prevLoadedHistoryRef = useRef<boolean>(vm.loadedHistory)

  useEffect(() => {
    const scrollbarElement = document.getElementById('pageContainerScroll')

    if (vm.loadedHistory && !prevLoadedHistoryRef.current) {
      vm.scrollToBottom() // NOTE: scroll to bottom on first message history load
    }

    // NOTE: Scroll to bottom when the last message is from user
    if (scrollbarElement && vm.messages.length > 0) {
      if (vm.messages[vm.messages.length - 1].author.type === 'user') {
        vm.scrollToBottom()
      }
    }

    // NOTE: Scroll to bottom when we're on bottom already
    if (isAutoScrollingEnabled) {
      vm.scrollToBottom()
    }

    prevLoadedHistoryRef.current = vm.loadedHistory
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vm.loadedHistory, // check for scroll if the history has loaded
    vm.messages.length, // check for scroll if a new message appears
    vm.lastMessageUpdateAt, // check for scroll if a message is updated
    vm.fileUploadViewModel.fileUploading,
  ])

  useEffect(() => {
    // NOTE: this gives the input focus if the vm changes the input focus cursor
    promptInputRef.current?.focus()
  }, [vm.promptInputFocusCursor, promptInputRef])

  useOnScreen(bottomLineRef, (value) => {
    setAutoScrollingEnabled(value)
  })

  const onSubmit = (prompt: Prompt) => {
    if (!prompt.text || !prompt.origin) {
      return
    }

    vm.sendMessageText(prompt.text, prompt.origin)
  }

  if (vm.initializing) {
    return (
      <div className="bg-cerosWhite absolute inset-0 z-30 flex items-center justify-center">
        <LoadingDots />
      </div>
    )
  }

  return (
    <ConversationContext.Provider value={{ promptInputVM }}>
      {vm.fileUploadViewModel.draggingType && (
        <UploadArea type={vm.fileUploadViewModel.draggingType} />
      )}
      <div
        id="conversation-layout"
        className={cls(
          'relative grid h-screen max-h-screen overflow-hidden',
          'transition-all duration-500 ease-in-out',
          vm.sidepanelContentType === 'copy_and_content_tool'
            ? 'lg:grid-cols-[minmax(500px,_2fr)_minmax(500px,3fr)]'
            : 'lg:grid-cols-[minmax(500px,_2fr)_minmax(0px,0fr)]',
        )}
      >
        <div
          id="conversation-page"
          data-automation={vm.conversation?.id}
          data-task={vm.conversation?.task_prompt_type}
          className={cls(
            'relative h-screen max-h-screen overflow-hidden',
            vm.sidepanelContentType === 'copy_and_content_tool'
              ? 'lg:px-4'
              : '',
          )}
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr auto',
          }}
        >
          {/**
           * this condition prevents the empty state from showing up when the
           * conversation is being loaded
           */}
          {vm.loadingConversation && <div className="flex-grow" />}

          {/* We can't do anything else until we have the conversation in memory, to know if we are read only or not */}
          {vm.conversation && (
            <>
              {!vm.embedded && (
                <div className="absolute right-4 top-6 z-20">
                  <Tooltip
                    text="Share this project"
                    placement="bottom-end"
                    className="whitespace-nowrap"
                  >
                    <Button
                      onClick={() => vm.openSharingModal()}
                      data-automation="share-button"
                    >
                      <ShareIcon /> Share
                    </Button>
                  </Tooltip>
                </div>
              )}

              {vm.gem && vm.conversation && (
                <div className="absolute top-0 z-20 w-full">
                  <ActiveGem gem={vm.gem} conversationId={vm.conversation.id} />
                </div>
              )}

              <div
                className="chat-custom-scrollbar custom-scrollbar overflow-y-auto overflow-x-hidden"
                id="pageContainerScroll"
              >
                <>
                  <div
                    id="top-fade"
                    className="pointer-events-none absolute top-0 z-10 w-full"
                  >
                    <div className="bg-cerosChatBg h-[65px] w-full md:h-[55px]" />
                    <div className="bg-fade-top progressive-blur-top  h-[55px] w-full" />
                  </div>

                  <div
                    id="bottom-fade"
                    className="pointer-events-none absolute bottom-3 z-10 w-full sm:bottom-1"
                  >
                    <div className="bg-fade-bottom progressive-blur-bottom h-[68px] w-full sm:h-[60px]" />
                    <div className="bg-cerosChatBg h-[70px] w-full md:h-[95px]" />
                  </div>
                </>
                <div className="relative flex min-h-full flex-col items-center justify-end">
                  <section
                    ref={messageContainerRef}
                    className="flex w-full min-w-[350px] max-w-[960px] flex-col gap-1 pb-[6px] pl-[6px] pt-[100px]"
                  >
                    {vm.loadingConversation && <LoadingDots />}
                    {!vm.loadingConversation && vm.messages.length > 0 && (
                      <MessagesView vm={vm} />
                    )}
                    <div ref={bottomLineRef} className="h-1 w-full" />
                  </section>
                </div>
              </div>
              <div className="relative z-30 mx-auto w-full max-w-[960px] pb-4 pt-4 md:pb-7">
                {!isAutoScrollingEnabled &&
                  !vm.responding &&
                  vm.messages.length > 1 && (
                    <IconButton
                      className={cls(
                        'text-cerosWhite bg-cerosWhite absolute -top-[30px] right-0 mr-2 h-[32px] w-[32px] rounded-lg p-[6px] sm:mr-4 md:mr-4 lg:mr-0',
                        'border-cerosWhite text-cerosBlack hover:border-cerosGrey300 active:border-cerosGrey400 bg-cerosWhite border-2 border-solid',
                        'shadow-md hover:shadow-none active:shadow-none',
                        'bg-cerosWhite hover:bg-cerosWhite active:bg-cerosGrey100',
                      )}
                      icon={<ChevronDown />}
                      onClick={vm.scrollToBottom}
                    />
                  )}
                {vm.responding && (
                  <AbortButton
                    onClick={vm.stopGenerating}
                    className="absolute bottom-28 right-0 mr-2 rounded-lg text-[16px] sm:mr-4 md:mr-4 lg:mr-0"
                  />
                )}
                <PromptField
                  vm={promptInputVM}
                  autoFocus
                  promptInputRef={promptInputRef}
                  dataAutomation="prompt-input"
                  disabled={vm.responding}
                  key={
                    vm.messages.length > 0
                      ? 'prompt-field'
                      : 'prompt-field-empty'
                  }
                  onFocus={vm.documentViewModel.onPromptInputFocused}
                  onBlur={vm.documentViewModel.onPromptInputBlurred}
                  onUpload={vm.onFileInputChange}
                  maxLength={MAX_PROMPT_LENGTH}
                  stealFocus={vm.stealFocus}
                  onSubmit={onSubmit}
                />
              </div>
            </>
          )}
        </div>
        <SidePreview vm={vm} />
      </div>
    </ConversationContext.Provider>
  )
})

ConversationPage.displayName = 'ConversationPage'
