import type { ClassValue } from 'clsx'
import { clsx } from 'clsx'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import { extendTailwindMerge } from 'tailwind-merge'

import { env } from '@/env.js'

import { version } from '../../package.json'

export const isProductionBuild = import.meta.env.PROD // build type not environment

export const APP_VERSION = version

export function getUnsplashAppName() {
  return env().VITE_UNSPLASH_APP_NAME || ''
}

export function getSupportApiEndpoint() {
  return `${env().VITE_SUPPORT_API_ENDPOINT || ''}/api`
}

export type AppOrigin = 'embedded' | 'web' | 'ceros_studio' | 'ceros_editor'

function getAppOriginInner(): AppOrigin {
  let isEmbeddedApp = false

  try {
    isEmbeddedApp = window.self !== window.top
  } catch (e) {}

  if (isEmbeddedApp) {
    const params = new URLSearchParams(window.location.search)
    const utmSource = params.get('utm_source')

    if (utmSource === 'ceros_studio') {
      return 'ceros_studio'
    }

    if (utmSource === 'ceros_editor') {
      return 'ceros_editor'
    }

    return 'embedded'
  }

  return 'web'
}

export const getAppOrigin = (() => {
  let appOrigin: AppOrigin | undefined
  return () => {
    if (!appOrigin) {
      appOrigin = getAppOriginInner()
    }
    return appOrigin
  }
})()

export function readableAppOrigin(type: AppOrigin) {
  switch (type) {
    case 'ceros_studio':
      return 'Ceros Studio'
    case 'ceros_editor':
      return 'Ceros Editor'
    case 'embedded':
      return 'Embedded App'
    case 'web':
      return 'Web App'
    default:
      return 'Unknown'
  }
}

const twMerge = extendTailwindMerge({
  classGroups: {
    shadow: [{ shadow: ['cerosLow', 'cerosMedium', 'cerosLarge'] }],
  },
})

// Helper function for Tailwind in JS
export function cls(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function downloadFile(file: File) {
  const a = document.createElement('a')
  a.download = file.name
  a.href = URL.createObjectURL(file)
  a.addEventListener('click', () => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000)
  })
  a.click()
}

export function getExtensionFromFileType(fileType: string) {
  switch (fileType) {
    case 'image/jpeg':
      return 'jpg'
    case 'image/png':
      return 'png'
    case 'image/gif':
      return 'gif'
    case 'image/svg+xml':
      return 'svg'
    default:
      return 'jpg'
  }
}

export function createFileFromImageUrl(image: string) {
  return fetch(image, {
    cache: 'no-store', // NOTE: Resolves issue with CORS for SVGs (https://stackoverflow.com/questions/44800431/caching-effect-on-cors-no-access-control-allow-origin-header-is-present-on-th)
  })
    .then((response) => response.blob())
    .then((blob) => {
      const fileName = `gemma-image-${Date.now()}.${getExtensionFromFileType(
        blob.type,
      )}`
      const file = new File([blob], fileName, { type: blob.type })
      return file
    })
}

export function getImageData(url: string) {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
    })
}

// NOTE: Experimental bound decorator for using ViewModels functions in JSX (Pages/Views)
export function bound(
  target: any,
  key: string,
  descriptor: PropertyDescriptor,
) {
  return {
    configurable: true,
    get() {
      const boundFn = descriptor.value.bind(this)
      Object.defineProperty(this, key, {
        value: boundFn,
        configurable: true,
        writable: true,
      })
      return boundFn
    },
  }
}

export const returnReadableDate = (value: string) => {
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const date = new Date(value)
  const today = new Date()

  const formatter = (date: Date) =>
    formatInTimeZone(date, clientTimeZone, 'h:mm aaa')

  if (differenceInCalendarDays(today, date) === 0) {
    return formatter(date)
  }
  if (differenceInCalendarDays(today, date) === 1) {
    return 'yesterday'
  } else {
    return `${formatDistanceToNow(date)} ago`
  }
}

export async function copyToClipboard(value: string): Promise<void> {
  try {
    if (navigator.clipboard) {
      return navigator.clipboard.writeText(value)
      // @ts-ignore
    } else if (window.copy) {
      // @ts-ignore
      return new Promise((resolve) => window.copy(value, resolve))
    } else {
      const area = document.createElement('textarea')
      document.body.appendChild(area)
      area.value = value
      area.focus()
      area.select()
      const result = document.execCommand('copy')
      document.body.removeChild(area)
      if (!result) {
        throw new Error('execCommand failed')
      }
    }
  } catch (e) {
    throw new Error(`Unable to copy the value: ${value}`, { cause: e })
  }
}

export async function copyRichTextToClipboard(
  html: string,
  plainText?: string,
): Promise<void> {
  const richBlob = new Blob([html], { type: 'text/html' })
  let plainTextToCopy = plainText
  if (!plainTextToCopy) {
    const container = document.createElement('div')
    container.innerHTML = html
    plainTextToCopy = container.textContent || ''
  }
  const plainBlob = new Blob([plainTextToCopy], { type: 'text/plain' })
  const clipboardItem = new window.ClipboardItem({
    'text/html': richBlob,
    'text/plain': plainBlob,
  })
  return navigator.clipboard.write([clipboardItem])
}

export function getPathType(path: string) {
  switch (path) {
    case '/':
      return 'Chat history'
    case '/conversation':
      return 'Chat'
    case '/gallery':
      return 'Gallery'
    case '/commands':
      return 'Commands'
    default:
      return 'Unknown'
  }
}

export function throttle(fn: Function, wait: number) {
  let lastTime = 0
  return (...args: any[]) => {
    const now = new Date().getTime()
    if (now - lastTime < wait) {
      return
    }
    lastTime = now
    return fn(...args)
  }
}

export function debounce<T extends Function>(fn: T, wait: number) {
  let timeout: ReturnType<typeof setTimeout>
  return ((...args: any[]) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => fn(...args), wait)
  }) as unknown as T
}

export async function someAsync<T>(
  items: T[],
  predicate: (item: T) => Promise<boolean>,
): Promise<boolean> {
  for (const item of items) {
    if (await predicate(item)) {
      return true
    }
  }

  return false
}

export function findLastIndex<T>(
  array: T[],
  predicate: (element: T) => boolean,
  maxDepth = Infinity,
): number {
  let depth = 0
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i])) {
      return i
    }

    depth++
    if (depth >= maxDepth) {
      break
    }
  }
  return -1 // Element not found
}
