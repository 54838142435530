import { observer } from 'mobx-react-lite'

import BackgroundRemoveIcon from '@/assets/icons/background-remove.svg?react'
import MagicEraserIcon from '@/assets/icons/magic-eraser.svg?react'
import UpscaleIcon from '@/assets/icons/upscale.svg?react'
import { Button } from '@/components/button/button.js'
import { CollapsibleEditSection } from '@/components/edit-image/collapsible-edit-section.js'
import { EditItem } from '@/components/edit-image/edit-item.js'
import { Slider } from '@/components/slider/slider'
import { cls } from '@/utils/utils.js'
import type { EditImageViewModel } from '@/view-models/edit-image.js'
import {
  INPAINTING_CLIPDROP_ACTION,
  REMOVE_BG_ACTION,
  UPSCALE_ACTION,
} from '@/view-models/edit-image.js'

interface IEditImagePanel {
  vm: EditImageViewModel
}

export const EditImagePanel = observer(({ vm }: IEditImagePanel) => {
  return (
    <div className="bg-cerosGrey100 w-[292px] min-w-[292px] max-w-[292px]">
      <CollapsibleEditSection
        title="Magic erase"
        icon={<MagicEraserIcon className="h-5 w-5" />}
        open={vm.magicEraser.enabled}
        onChange={(value) =>
          vm.updateToolStatus(INPAINTING_CLIPDROP_ACTION, value)
        }
        // TODO: Magic eraser implementation
        // disabled
        disabled={
          vm.disabledTools.includes(INPAINTING_CLIPDROP_ACTION) || vm.isBusy
        }
      >
        <div className="flex items-center justify-between text-sm font-medium">
          <span>Brush size</span>
          <span>{vm.magicEraser.value} px</span>
        </div>

        <Slider
          defaultValue={[vm.magicEraser.value]}
          min={1}
          max={100}
          step={1}
          onValueChange={vm.setMagicEraser}
        />

        <Button
          onClick={() => vm.applyTool(INPAINTING_CLIPDROP_ACTION)}
          disabled={!vm.drawingStack.length}
        >
          Apply
        </Button>
      </CollapsibleEditSection>
      <CollapsibleEditSection
        title="Upscale"
        icon={<UpscaleIcon className="h-5 w-5" />}
        open={vm.upscale.enabled}
        onChange={(value) => vm.updateToolStatus(UPSCALE_ACTION, value)}
        disabled={vm.disabledTools.includes(UPSCALE_ACTION) || vm.isBusy}
      >
        <div className="flex justify-between gap-2">
          {vm.upscale.options.map((option) => (
            <div
              key={option}
              className={cls(
                'border-cerosGrey400 hover:border-cerosPrimaryHover hover:text-cerosPrimaryHover flex-1 cursor-pointer rounded-md border p-[6px] text-center text-sm',
                vm.upscale.value === option &&
                  'border-cerosPrimaryActive text-cerosPrimaryActive',
              )}
              onClick={() => vm.setUpscale(option)}
            >
              {`${option}x`}
            </div>
          ))}
        </div>
        <Button
          className="w-full"
          onClick={() => vm.applyTool(UPSCALE_ACTION)}
          disabled={!vm.upscale.value}
        >
          Apply
        </Button>
      </CollapsibleEditSection>
      <EditItem
        title="Remove bg"
        icon={<BackgroundRemoveIcon className="h-5 w-5" />}
        onClick={() => vm.applyTool(REMOVE_BG_ACTION)}
        disabled={vm.disabledTools.includes(REMOVE_BG_ACTION) || vm.isBusy}
      />
    </div>
  )
})

EditImagePanel.displayName = 'EditImagePanel'
