import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import GemmaLogo from '@/assets/icons/gemma-logo.svg?react'
import ArcIcon from '@/assets/images/not-found/arc.svg?react'
import QuadrantIcon from '@/assets/images/not-found/quadrant.svg?react'
import StarIcon from '@/assets/images/not-found/star.svg?react'
import { Button } from '@/components/button/button'
import { useAuthService, useUserService } from '@/utils/view'

export type NotFoundType = 'Conversation' | 'Gem'
export type NotFoundProps = {
  type: NotFoundType
}

export const NotFound = observer(({ type }: NotFoundProps) => {
  const authService = useAuthService()
  const userService = useUserService()

  const firstName = userService.properties?.name?.split(' ')[0]
  const description =
    type === 'Conversation'
      ? 'This project with Gemma is no longer public.'
      : 'This Gem is no longer public.'

  return (
    <div className="w-sceen flex h-screen flex-col items-center justify-center gap-8 overflow-hidden">
      <div className="relative flex gap-8">
        <StarIcon className="md:h-25 h-18 aspect-square" />
        <ArcIcon className="md:h-25 h-18 aspect-auto" />
        <QuadrantIcon className="md:h-25 h-18 aspect-square" />
        <div className="bg-cerosWhite shadow-normal-elevation absolute -bottom-6 left-[154px] flex h-12 w-12 items-center justify-center rounded-full">
          <GemmaLogo className="h-7 w-7" />
        </div>
      </div>
      <h1 className="text-4xl font-semibold">Nothing to see here...</h1>
      <div className="flex flex-col items-center">
        <p className="text-md text-cerosGrey400 font-normal">{description}</p>
        <p className="text-md text-cerosGrey400 font-normal">
          {`Ready for a new creative adventure${
            firstName != null ? `, ${firstName}` : ''
          }? Let's dive in!`}
        </p>
      </div>
      <div>
        <Link to={authService.isAuthenticated ? '/' : '/login'}>
          <Button>Start a new project</Button>
        </Link>
      </div>
    </div>
  )
})
