import { useIsPresent } from 'framer-motion'
import { observer } from 'mobx-react-lite'

import { Button } from '@/components/button/button'
import { GemmaQuestion } from '@/components/onboarding/gemma-question'
import { QuestionaryPanel } from '@/components/onboarding/questionary-panel'
import { UserAnswer } from '@/components/onboarding/user-answer'
import { BaseForm, type BaseFormField } from '@/utils/base-form'
import type { StepData } from '@/view-models/onboarding'

import { ChoicesBind, QuestionaryInputBind } from './bindings'

const formFields: Record<string, BaseFormField> = {
  roles: {
    name: 'roles[]',
    value: [],
  },
  job_title: {
    name: 'job_title',
    value: '',
    placeholder: 'Enter your title',
  },
}

const validationRule = (fields: typeof formFields) => {
  return fields.roles.value.length === 1
}

export const createOnboardingWorkForm = () => {
  return new BaseForm(formFields, validationRule)
}

interface IOnboardingWorkForm {
  username: string
  data: StepData
  onSubmit: (formData: FormData) => void
}

export const OnboardingWorkForm = observer(
  ({ username, data, onSubmit }: IOnboardingWorkForm) => {
    const isPresent = useIsPresent()
    const { form } = data

    const titleAnswer =
      form.fields.job_title.value.trim() !== ''
        ? `My title is ${form.fields.job_title.value}`
        : undefined
    const responsibilitiesAnswer = `My main responsibilities: ${form.fields.roles.value.join(
      ', ',
    )}`

    const answer = [titleAnswer, responsibilitiesAnswer].join('\n\n')

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (form.isValid) {
        onSubmit(form.data)
      }
    }

    const handleOnChange = (e: React.FormEvent<HTMLFormElement>) => {
      const formData = new FormData(e.currentTarget)
      form.validate()
      form.updateFormData(formData)
    }

    return (
      <QuestionaryPanel>
        <form
          autoComplete="off"
          onChange={handleOnChange}
          onSubmit={handleOnSubmit}
        >
          <GemmaQuestion value={data.question} />

          <div className="mt-8 flex justify-center">
            <fieldset className="flex flex-wrap items-center justify-center gap-2 px-3">
              <ChoicesBind
                name={form.fields.roles.name}
                value={form.fields.roles.value}
                onChange={form.onChange}
                choices={data.choices || []}
                multi={false}
                disabled={form.isSubmitting || !isPresent}
              />
            </fieldset>
          </div>

          {form.isSubmitted && (
            <UserAnswer username={username} value={answer} />
          )}

          <div className="mx-3 mt-9">
            <QuestionaryInputBind
              className="h-[60px] w-full"
              name="job_title"
              value={form.fields.job_title.value}
              onChange={form.onChange}
              placeholder={form.fields.job_title.placeholder}
              disabled={form.isSubmitting || !isPresent}
            />
          </div>

          <div className="mt-7 flex justify-center">
            <Button
              size="large"
              disabled={!form.isValid || form.isSubmitting || !isPresent}
            >
              Continue
            </Button>
          </div>
        </form>
      </QuestionaryPanel>
    )
  },
)
