import type {
  ConversationMessageContent,
  ConversationMessageStreamContent,
} from '@ceros/gemma-api-spec'
import { Fragment } from 'react'

import { BlocksContent } from '@/components/chat/blocks-content'
import { DocumentThumbnail } from '@/components/chat/document-thumbnail'
import { EmbeddedContent } from '@/components/chat/embedded-content'
import { ErrorContent } from '@/components/chat/error-content'
import { GeneratedImagesGrid } from '@/components/chat/generated-images-grid'
import { ImageAttachment } from '@/components/chat/image-attachment'
import { ImageResult } from '@/components/chat/image-result'
import { Loader } from '@/components/chat/loader'
import { NounProjectAttachment } from '@/components/chat/noun-project-attachment'
import NounProjectResultLink from '@/components/chat/noun-project-result-link'
import { PresentationContent } from '@/components/chat/presentation-content'
import { TextContent } from '@/components/chat/text-content'
import UnsplashSearchLink from '@/components/chat/unsplash-search-link'
import { DI_TYPE } from '@/di.types'
import type { Action } from '@/models/blocks'
import { ActionType } from '@/models/blocks'
import type {
  ImageAttachmentObject,
  RenderableConversationMessage,
} from '@/models/conversation'
import { resolveImageToolName, resolveImageToolType } from '@/utils/tool'
import { cls, createFileFromImageUrl, getAppOrigin } from '@/utils/utils'
import { type DrugEventValue, useViewModel } from '@/utils/view'
import type { ConversationViewModel } from '@/view-models/conversation'
import type { SubscriptionPlanViewModel } from '@/view-models/subscription-plan'

import { DocumentPreview } from '../../components/chat/document-preview'
import { EditImagePage } from '../modals/edit-image/edit-image-page'

interface IMessageContent {
  message: RenderableConversationMessage
  vm: ConversationViewModel
}

const handleOnTextClick = async (
  text: string,
  message: RenderableConversationMessage,
  vm: ConversationViewModel,
) => {
  const appOrigin = getAppOrigin()

  if (['embedded', 'ceros_studio', 'ceros_editor'].includes(appOrigin)) {
    console.info(`[Gemma] Sending text to ${appOrigin}`)
    window.parent.postMessage(
      {
        type: 'insertText',
        payload: { text: text },
      },
      '*',
    )
  }

  vm.logResultUse(message, 'click')
}

const handleOnTextDragStart = async (
  e: DrugEventValue,
  text: string,
  message: RenderableConversationMessage,
  vm: ConversationViewModel,
) => {
  if (['embedded', 'ceros_studio', 'ceros_editor'].includes(getAppOrigin())) {
    window.parent.postMessage(
      {
        type: 'dragTextStart',
        payload: {
          clientX: e.clientX,
          clientY: e.clientY,
        },
      },
      '*',
    )
  } else if (e.native) {
    e.native.dataTransfer?.setData('text/plain', text)
  }

  vm.logResultUse(message, 'drag-and-drop')
}

const handleOnTextDrag = async (e: DrugEventValue) => {
  if (['embedded', 'ceros_studio', 'ceros_editor'].includes(getAppOrigin())) {
    window.parent.postMessage(
      {
        type: 'dragText',
        payload: {
          clientX: e.clientX,
          clientY: e.clientY,
        },
      },
      '*',
    )
  }
}

const handleOnTextDragEnd = async (e: DrugEventValue, text: string) => {
  if (['embedded', 'ceros_studio', 'ceros_editor'].includes(getAppOrigin())) {
    window.parent.postMessage(
      {
        type: 'dragTextEnd',
        payload: {
          text: text,
          clientX: e.clientX,
          clientY: e.clientY,
        },
      },
      '*',
    )
  }
}

const handleOnImageDragStart = async (
  e: DrugEventValue,
  imageAttachment: ImageAttachmentObject,
  message: RenderableConversationMessage,
  vm: ConversationViewModel,
) => {
  if (['embedded', 'ceros_studio', 'ceros_editor'].includes(getAppOrigin())) {
    window.parent.postMessage(
      {
        type: 'dragImageStart',
        payload: {
          clientX: e.clientX,
          clientY: e.clientY,
        },
      },
      '*',
    )
  }

  vm.logResultUse(message, 'drag-and-drop')
}

const handleOnImageDrag = async (e: DrugEventValue) => {
  if (['embedded', 'ceros_studio', 'ceros_editor'].includes(getAppOrigin())) {
    window.parent.postMessage(
      {
        type: 'dragImage',
        payload: {
          clientX: e.clientX,
          clientY: e.clientY,
        },
      },
      '*',
    )
  }
}

const handleOnImageDragEnd = async (
  e: DrugEventValue,
  imageAttachment: ImageAttachmentObject,
) => {
  const image = new Image()
  image.src = imageAttachment.url

  if (['embedded', 'ceros_studio', 'ceros_editor'].includes(getAppOrigin())) {
    const file = await createFileFromImageUrl(image.src)
    window.parent.postMessage(
      {
        type: 'dragImageEnd',
        payload: {
          file,
          clientX: e.clientX,
          clientY: e.clientY,
          dropMetadata: {
            width: image.width,
            height: image.height,
          },
        },
      },
      '*',
    )
  }
}

const handleShowModalAction = (
  action: Action,
  conversationViewModel: ConversationViewModel,
  subscriptionPlanViewModel: SubscriptionPlanViewModel,
) => {
  switch (action.data.modal) {
    case 'upgrade':
      subscriptionPlanViewModel.openPlanModal({ source: 'chat-upsell-button' })
      conversationViewModel.sendMessageText(action.data.value, 'BLOCK')
      break
    default:
      throw new Error('Not set up to handle modal action')
  }
}

const handleOnBlockAction = (
  action: Action,
  converastionViewModel: ConversationViewModel,
  subscriptionPlanViewModel: SubscriptionPlanViewModel,
) => {
  switch (action.type) {
    case ActionType.REPLY:
      converastionViewModel.sendMessageText(action.data.value, 'BLOCK')
      break
    case ActionType.CHOICE:
      // NOTE: this is a 'backwards compatible' (we still do this for now) way of handling button clicks when not wrapped in a form
      converastionViewModel.sendMessageText(action.data.value, 'BLOCK')
      break
    case ActionType.SHOW_MODAL:
      handleShowModalAction(
        action,
        converastionViewModel,
        subscriptionPlanViewModel,
      )
      break
    default:
      console.warn('Not set up to handle block action', action)
  }
}

const insertToExternalTools = async (
  imageAttachment: ImageAttachmentObject,
  message: RenderableConversationMessage,
  vm: ConversationViewModel,
) => {
  const image = new Image()
  image.src = imageAttachment.url

  // if (['web', 'chrome_extension'].includes(APP_ORIGIN)) {
  //   const file = await createFileFromImageUrl(image.result_url)
  //   downloadFile(file)
  // }

  if (['embedded', 'ceros_studio', 'ceros_editor'].includes(getAppOrigin())) {
    const file = await createFileFromImageUrl(image.src)
    console.info(`[Gemma] Sending image file to ${getAppOrigin()}`)
    window.parent.postMessage(
      {
        type: 'insertImage',
        payload: { file },
      },
      '*',
    )
  }

  vm.logResultUse(message, 'click')
}

type HandleOpenEditParams = {
  index?: number
  urls: string[]
  vm: ConversationViewModel
  width?: number
  height?: number
  messageId?: string
  openUrl?: string
}

const handleOpenEdit = ({
  index = 0,
  urls,
  vm,
  width,
  height,
  messageId,
  openUrl,
}: HandleOpenEditParams) => {
  if (openUrl) {
    window.open(openUrl, '_blank')
  } else {
    return vm.modalService.showCustom(
      'edit-chat-image',
      <EditImagePage
        index={index}
        urls={urls}
        dataSource="AI Generated"
        width={width}
        height={height}
        messageId={messageId}
      />,
      {
        custom: true,
        backdrop: true,
      },
    )
  }
}

// Assertion function to ensure all cases are covered, if you're getting a TS error then you need to add a case for a content type
function assertNeverMissingContentType(content: never) {
  return (
    <ErrorContent
      dataAutomation="error-response"
      error={`Unknown message content type '${(content as any).type}'`}
    />
  )
}

const mapContentTypeToComponent = (
  message: RenderableConversationMessage,
  item: ConversationMessageContent | ConversationMessageStreamContent,
  conversationViewModel: ConversationViewModel,
  subscriptionPlanViewModel: SubscriptionPlanViewModel,
) => {
  const content = item.content
  switch (content.type) {
    case 'inline_document':
      const index = conversationViewModel.documents.findIndex(
        (msg) => msg.id === message.id,
      ) // FUTURE: get this from BE to get proper numbering when lazy loading
      return (
        <DocumentPreview
          id={item.id}
          date={new Date(message.created_at ?? message.updated_at)}
          version={index}
          openEditor={() => {
            conversationViewModel.documentViewModel.currentItemId = item.id
            conversationViewModel.documentViewModel.isEditorOpen = true
          }}
          streaming={message.renderMetadata.streaming}
          copyRichText={() => {
            conversationViewModel.copyTextItem(item.id)
            conversationViewModel.documentViewModel.onCopyDocument(
              index,
              'preview',
            )
          }}
        />
      )
    case 'text':
      return (
        <TextContent
          dataAutomation="text-response"
          onDragStart={(event) =>
            handleOnTextDragStart(
              event,
              content.text,
              message,
              conversationViewModel,
            )
          }
          onDrag={(event) => handleOnTextDrag(event)}
          onDragEnd={(event) => handleOnTextDragEnd(event, content.text)}
          text={content.text}
          onClick={(e) => {
            if (e.altKey) {
              handleOnTextClick(
                content.text ?? '',
                message,
                conversationViewModel,
              )
            }
          }}
          onReuse={
            message.author.type === 'user'
              ? () => {
                  conversationViewModel.reusePrompt(content.text, message.id)
                }
              : undefined
          }
        />
      )
    case 'images':
      const toolType = content.tool
        ? resolveImageToolType(content.tool)
        : undefined
      // FIXME: this is temporary solution until we get image available operations from BE
      const isEditBlocked = content.tool === 'noun_project'
      const isOnlyOneResult = content.results.length === 1
      const imageUrls = content.results.map((result) => result.url)

      return (
        <GeneratedImagesGrid
          toolType={toolType}
          toolName={
            content.tool ? resolveImageToolName(content.tool) : undefined
          }
          moreLink={
            toolType === 'search' && content.search_url
              ? content.search_url
              : undefined
          }
        >
          {content.results.map((result, index) => {
            const image: ImageAttachmentObject = {
              id: result.id,
              url: result.url,
              width: result.width,
              height: result.height,
              thumbnail_url: result.thumbnail_url || undefined,
              alt: result.description || undefined,
            }
            return (
              <ImageAttachment
                key={image.id}
                className={cls('sm:w-auto', isOnlyOneResult && 'w-auto')}
                editBlocked={isEditBlocked || !!result.open_url}
                dataAutomation="image-response"
                image={image}
                onTask={conversationViewModel.createImageTask}
                hideMenu={!!result.open_url} // FUTURE: create a new component/schema to prevent this kind of logic
                openEdit={() =>
                  handleOpenEdit({
                    index,
                    urls: imageUrls,
                    vm: conversationViewModel,
                    width: image.width,
                    height: image.height,
                    messageId: message.id,
                    openUrl: result.open_url ? result.open_url : undefined,
                  })
                }
                onDragStart={(event) =>
                  handleOnImageDragStart(
                    event,
                    image,
                    message,
                    conversationViewModel,
                  )
                }
                onDrag={handleOnImageDrag}
                onDragEnd={(event) => handleOnImageDragEnd(event, image)}
                onClick={(e) => {
                  if (e.altKey) {
                    insertToExternalTools(image, message, conversationViewModel)
                  } else if (!isEditBlocked) {
                    handleOpenEdit({
                      index,
                      urls: imageUrls,
                      vm: conversationViewModel,
                      width: image.width,
                      height: image.height,
                      messageId: message.id,
                      openUrl: result.open_url || undefined,
                    })
                  }
                }}
                includeImageData={false}
                onCopyUrl={() =>
                  conversationViewModel.copyImageUrl(message, image)
                }
                onDownload={() =>
                  conversationViewModel.downloadImage(message, image)
                }
              />
            )
          })}
        </GeneratedImagesGrid>
      )
    case 'image_pin':
      return (
        <ImageResult description="Uploaded image">
          <ImageAttachment
            className="w-auto"
            dataAutomation="image-response"
            image={{ id: item.id, url: content.url }}
            onTask={conversationViewModel.createImageTask}
            openEdit={() =>
              handleOpenEdit({
                urls: [content.url],
                vm: conversationViewModel,
                messageId: message.id,
              })
            }
            onDragStart={(event) =>
              handleOnImageDragStart(
                event,
                {
                  url: content.url,
                  id: item.id,
                },
                message,
                conversationViewModel,
              )
            }
            onDrag={handleOnImageDrag}
            onDragEnd={(event) =>
              handleOnImageDragEnd(event, {
                url: content.url,
                id: item.id,
              })
            }
            onClick={(e) => {
              if (e.altKey) {
                insertToExternalTools(
                  {
                    url: content.url,
                    id: item.id,
                  },
                  message,
                  conversationViewModel,
                )
              } else {
                handleOpenEdit({
                  urls: [content.url],
                  vm: conversationViewModel,
                  messageId: message.id,
                })
              }
            }}
            includeImageData={false}
            onCopyUrl={() => {
              conversationViewModel.copyImageUrl(message, {
                id: item.id,
                url: content.url,
              })
            }}
            onDownload={() => {
              conversationViewModel.downloadImage(message, {
                id: item.id,
                url: content.url,
              })
            }}
          />
        </ImageResult>
      )
    case 'pdf_pin':
    case 'upload_pin':
      return (
        <DocumentThumbnail
          title={content.filename}
          status={content.status}
          mimeType={content.mime_type}
          previewUrl={content.preview_url}
        />
      )
    case 'unsplash_search':
      // FUTURE: deprecate me when old conversations aren't going to be useful anymore
      return <UnsplashSearchLink query={content.query} messageId={message.id} />
    case 'noun_project_search':
      // FUTURE: deprecate me when old conversations aren't going to be useful anymore
      return (
        <NounProjectResultLink query={content.query} messageId={message.id} />
      )
    case 'noun_project_pin':
      return (
        <NounProjectAttachment
          icon={{
            id: item.id,
            icon_id: content.icon.icon_id,
            url: content.icon.url,
            type: 'noun_project',
          }}
        />
      )
    case 'image_edit_session_pin':
      return (
        <ImageResult description="Edited image">
          <ImageAttachment
            className="w-auto"
            dataAutomation="image-response"
            image={{
              id: content.session.result.id,
              url: content.session.result.url,
            }}
            onTask={conversationViewModel.createImageTask}
            openEdit={() =>
              handleOpenEdit({
                urls: [content.session.result.url],
                vm: conversationViewModel,
                width: content.session.result.width,
                height: content.session.result.height,
                messageId: message.id,
              })
            }
            onDragStart={(event) =>
              handleOnImageDragStart(
                event,
                {
                  url: content.session.result.url,
                  id: content.session.result.id,
                },
                message,
                conversationViewModel,
              )
            }
            onDrag={handleOnImageDrag}
            onDragEnd={(event) =>
              handleOnImageDragEnd(event, {
                url: content.session.result.url,
                id: content.session.result.id,
              })
            }
            onClick={(e) => {
              if (e.altKey) {
                insertToExternalTools(
                  {
                    url: content.session.result.url,
                    id: content.session.result.id,
                  },
                  message,
                  conversationViewModel,
                )
              } else {
                handleOpenEdit({
                  urls: [content.session.result.url],
                  vm: conversationViewModel,
                  width: content.session.result.width,
                  height: content.session.result.height,
                  messageId: message.id,
                })
              }
            }}
            includeImageData={false}
            onCopyUrl={() =>
              conversationViewModel.copyImageUrl(message, {
                id: content.session.result.id,
                url: content.session.result.url,
              })
            }
            onDownload={() =>
              conversationViewModel.downloadImage(message, {
                id: content.session.result.id,
                url: content.session.result.url,
              })
            }
          />
        </ImageResult>
      )
    case 'blocks_json':
      return (
        <BlocksContent
          blocks={content.blocks}
          onAction={(action) =>
            handleOnBlockAction(
              action,
              conversationViewModel,
              subscriptionPlanViewModel,
            )
          }
        />
      )
    case 'embedded_video':
      return (
        <EmbeddedContent source={content.source} html={content.embed_code} />
      )
    case 'images_placeholder':
      // FUTURE: implement once being sent to the client
      return null
    case 'loader':
      return (
        <Loader
          percentage={content.percentage}
          text={content.text}
          is_failed={content.is_failed}
        />
      )

    case 'ai_presentation':
      return <PresentationContent url={content.url} />
    case 'error':
      return (
        <ErrorContent dataAutomation="error-response" error={content.text} />
      )
    default:
      return assertNeverMissingContentType(content)
  }
}

export const MessageContent = (params: IMessageContent) => {
  const subscriptionPlanViewModel = useViewModel(
    DI_TYPE.SubscriptionPlanViewModel,
  )
  return (
    <div data-automation="content-blocks">
      {params.message.items.map((item) => {
        return (
          <Fragment key={item.id}>
            {mapContentTypeToComponent(
              params.message,
              item,
              params.vm,
              subscriptionPlanViewModel,
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
