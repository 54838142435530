import { createContext, useContext } from 'react'

export type Tab = 'left' | 'right'

export const ToggleContext = createContext<{
  activeTab: Tab
  handleClick: (newState: Tab) => void
}>({
  activeTab: 'left',
  handleClick: () => {},
})

export const useToggleContext = () => useContext(ToggleContext)
