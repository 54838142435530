import { injected } from 'brandi'

export class StorageService {
  save(key: string, value: string) {
    localStorage.setItem(key, value)
    return true
  }

  load(key: string) {
    return localStorage.getItem(key)
  }

  remove(key: string) {
    return localStorage.removeItem(key)
  }

  onRemoteChange(
    listener: (event: { key: string; newValue: string | null }) => void,
  ) {
    window.addEventListener('storage', ({ key, newValue }) => {
      if (key) listener({ key, newValue })
    })
  }
}

injected(StorageService)
