import { injected } from 'brandi'

import { DI_TYPE } from '@/di.types'
import type { ApiClient } from '@/services/api-client'

export interface UploadFileS3Url {
  url: string
  read_url: string
  form_data: {
    key: string
    AWSAccessKeyId: string
    policy: string
    signature: string
    'Content-Type': string
  }
}

export class FileUploadRepository {
  constructor(private apiClient: ApiClient) {}

  async getS3UploadUrl(file: File) {
    try {
      const { data, status } = await this.apiClient.post<UploadFileS3Url>(
        '/files/presigned_urls',
        {
          file_name: file.name,
          content_type: file.type,
        },
      )

      return { data, status }
    } catch (error: any) {
      return { data: undefined, status: 500 }
    }
  }

  async uploadFile(file: File) {
    let formData = new FormData()
    const { data, status } = await this.getS3UploadUrl(file)

    if (!data) {
      return { data: undefined, status: 500 }
    }

    for (const name in data.form_data) {
      const value = data.form_data[name]
      if (value) {
        formData.append(name, value)
      }
    }
    // NOTE: file must be appended last according to [AWS docs](https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html)
    formData.append('file', file)

    try {
      const response = await fetch(data.url, {
        method: 'POST',
        body: formData,
      })

      if (response.status !== 204) {
        return { data: undefined, status: 500 }
      }

      return { data, status }
    } catch (error: any) {
      return { data: undefined, status: 500 }
    }
  }
}

injected(FileUploadRepository, DI_TYPE.ApiClient)
