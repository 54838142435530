import { type Env, envSchema } from './env-schema.js'

let cache: Env | null = null

export function env(): Env {
  if (!cache) {
    cache = envSchema.parse(readEnv())
  }
  return cache
}

function readEnv(): Env {
  return {
    VITE_GTM_ID: import.meta.env.VITE_GTM_ID,
    VITE_API_ENDPOINT: import.meta.env.VITE_API_ENDPOINT,
    VITE_SUPPORT_API_ENDPOINT: import.meta.env.VITE_SUPPORT_API_ENDPOINT,
    VITE_API_KEY: import.meta.env.VITE_API_KEY,
    VITE_APP_MIXPANEL_PROJECT_ID: import.meta.env.VITE_APP_MIXPANEL_PROJECT_ID,
    VITE_OKTA_ISSUER: import.meta.env.VITE_OKTA_ISSUER,
    VITE_OKTA_CLIENT_ID: import.meta.env.VITE_OKTA_CLIENT_ID,
    VITE_GOOGLE_IDP_ID: import.meta.env.VITE_GOOGLE_IDP_ID,
    VITE_LOGROCKET_APP_ID: import.meta.env.VITE_LOGROCKET_APP_ID,
    VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
    VITE_APP_ENVIRONMENT: import.meta.env.VITE_APP_ENVIRONMENT,
    VITE_UNSPLASH_APP_NAME: import.meta.env.VITE_UNSPLASH_APP_NAME,
    VITE_ENABLE_LANGTAIL_PLAYGROUNDS: import.meta.env
      .VITE_ENABLE_LANGTAIL_PLAYGROUNDS,
    VITE_REFRESH_TOKEN_RELEASE_DATE: import.meta.env
      .VITE_REFRESH_TOKEN_RELEASE_DATE,
  }
}
