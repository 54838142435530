import type { AttachmentPin } from '@ceros/gemma-api-spec'
import { captureException } from '@sentry/react'
import { injected } from 'brandi'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { match } from 'path-to-regexp'
import { createElement } from 'react'
import { toast } from 'react-hot-toast'

import { DI_TYPE } from '@/di.types.js'
import { UnsavedChangesModal } from '@/pages/modals/unsaved-changes.js'
import type {
  EditImageItem,
  EditImageRepository,
  EditImageSession,
} from '@/repositories/edit-image.js'
import type { Api } from '@/services/api.js'
import type {
  MixpanelService,
  ResultOrigin,
  ResultUsedType,
} from '@/services/mixpanel.js'
import type { ModalService } from '@/services/modal.js'
import type { RouterService } from '@/services/router.js'
import {
  copyToClipboard,
  createFileFromImageUrl,
  downloadFile,
} from '@/utils/utils.js'

import { BaseViewModel } from './base-view-model.js'

export const REMOVE_BG_ACTION = 'remove_bg'
export const UPSCALE_ACTION = 'upscale'
export const INPAINTING_CLIPDROP_ACTION = 'inpainting_clipdrop'

type InitData = {
  index?: number
  inputUrls: string[]
  dataSource: ResultOrigin
  messageId?: string
}

interface EditTool {
  enabled: boolean
}

interface MagicEraserTool extends EditTool {
  value: number
}

interface UpscaleTool extends EditTool {
  value: 2 | 4 | 8 | undefined
  options: [2, 4, 8]
}

type Coord = {
  x: number
  y: number
}
type LineCoords = [Coord, Coord]
type LineSeries = Array<LineCoords>
type DrawingStackItem = {
  lines: LineSeries
  circle: Coord
  brush: number
}
type DrawingStack = Array<DrawingStackItem>

type Dimensions = {
  width: number
  height: number
}

export class EditImageViewModel extends BaseViewModel {
  @observable enabled = false
  @observable isBusy = false
  @observable isDrawing = false
  @observable pervPosition: Coord = { x: 0, y: 0 }

  @observable magicEraser: MagicEraserTool = {
    enabled: false,
    value: 35, // TODO: connect to EditDrawingTool
  }

  @observable upscale: UpscaleTool = {
    enabled: false,
    value: undefined,
    options: [2, 4, 8],
  }

  @observable index: number = 0
  @observable inputUrls: string[] = []
  @observable dataSource?: ResultOrigin = undefined
  @observable session?: EditImageSession = undefined
  @observable edits: EditImageItem[] = []
  @observable messageId?: string = undefined

  // Correct mask for the current image
  // TODO: this duplicates logic from useDrawingTool hook and helper functions, so it makes sense to refactor and remove duplicates from there
  @observable drawingStack: DrawingStack = []
  @observable imageDimensions: Dimensions = {
    width: 0,
    height: 0,
  }

  constructor(
    public api: Api,
    public modalService: ModalService,
    private editImageRepository: EditImageRepository,
    public routerService: RouterService,
    private mixpanelService: MixpanelService,
  ) {
    super()

    makeObservable(this)
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onInit(): void {}

  onDispose(): void {}

  @action.bound
  initData({ index, inputUrls, dataSource, messageId }: InitData) {
    this.index = index ?? 0
    this.inputUrls = inputUrls
    this.dataSource = dataSource
    this.messageId = messageId
  }

  @computed
  get input_url(): string {
    return this.inputUrls[this.index]
  }

  @action.bound
  setIndex(index: number) {
    this.index = index
  }

  @computed
  get conversationId() {
    const fn = match('/conversations/:conversationId{/:integration}?', {
      decode: decodeURIComponent,
    })
    const result = fn(this.routerService.currentPath)
    if (result) {
      const { conversationId } = result.params as { conversationId: string }
      return conversationId
    }
    return undefined
  }

  @computed
  get actualImageUrl() {
    const item = this.edits[this.edits.length - 1]
    if (item) {
      return item.result_url
    } else {
      return this.input_url
    }
  }

  @computed
  get disabledTools() {
    return this.edits
      .map((item) => item.tool)
      .filter((v) => v !== INPAINTING_CLIPDROP_ACTION)
  }

  @computed
  get wasEdited() {
    return this.edits.length > 0
  }

  @action.bound
  setImageDimensions(dimensions: Dimensions) {
    this.imageDimensions = dimensions
  }

  @action.bound
  startDrawing(coord: Coord) {
    this.isDrawing = true
    this.drawingStack.push({
      lines: [],
      circle: coord,
      brush: this.magicEraser.value,
    })
    this.pervPosition = coord
  }

  @action.bound
  draw(prevCoord: Coord, coord: Coord) {
    this.drawingStack[this.drawingStack.length - 1].lines.push([
      prevCoord,
      coord,
    ])
    this.pervPosition = coord
  }

  @action.bound
  stopDrawing() {
    this.isDrawing = false
  }

  @action.bound
  drawCircle(
    { x, y }: Coord,
    context: CanvasRenderingContext2D,
    brush: number,
  ) {
    context.beginPath()
    context.arc(x, y, brush / 2, 0, 2 * Math.PI)
    context.fillStyle = '#FFFFFF'
    context.fill()
    context.closePath()
  }

  @action.bound
  drawCurve(
    { x: x1, y: y1 }: Coord,
    { x: x2, y: y2 }: Coord,
    context: CanvasRenderingContext2D,
    brush: number,
  ) {
    context.beginPath()
    context.moveTo(x1, y1)
    context.lineTo(x2, y2)
    context.lineWidth = brush
    context.lineCap = 'round'
    context.strokeStyle = '#FFFFFF'
    context.stroke()
    context.closePath()
  }

  @action.bound
  resetMask(canvas: HTMLCanvasElement) {
    const context = canvas.getContext('2d')

    if (!context) {
      throw new Error('Cannot get context')
    }

    context.clearRect(0, 0, canvas.width, canvas.height)
    context.fillStyle = '#000000'
    context.fillRect(0, 0, canvas.width, canvas.height)

    this.drawingStack = []
  }

  @action.bound
  reviveDrawing(canvas: HTMLCanvasElement, scale: number) {
    const context = canvas.getContext('2d', { willReadFrequently: true })

    if (!context) {
      captureException(new Error('Could not create 2d drawing context'))
      this.drawingStack = []
      return
    }

    let newDrawingStack: DrawingStack = []

    context.clearRect(0, 0, canvas.width, canvas.height)
    context.fillStyle = '#000000'
    context.fillRect(0, 0, canvas.width, canvas.height)

    for (const drawItem of this.drawingStack) {
      let newDrawingStackItem = {
        circle: {
          x: drawItem.circle.x * scale,
          y: drawItem.circle.y * scale,
        },
        brush: drawItem.brush * scale,
        lines: [] as [Coord, Coord][],
      }

      this.drawCircle(
        {
          x: drawItem.circle.x * scale,
          y: drawItem.circle.y * scale,
        },
        context,
        drawItem.brush * scale,
      )

      for (const [prevCoord, coord] of drawItem.lines) {
        newDrawingStackItem.lines.push([
          {
            x: prevCoord.x * scale,
            y: prevCoord.y * scale,
          },
          {
            x: coord.x * scale,
            y: coord.y * scale,
          },
        ])

        this.drawCurve(
          {
            x: prevCoord.x * scale,
            y: prevCoord.y * scale,
          },
          {
            x: coord.x * scale,
            y: coord.y * scale,
          },
          context,
          drawItem.brush * scale,
        )
      }

      newDrawingStack.push(newDrawingStackItem)
      context.stroke()
    }

    this.drawingStack = newDrawingStack
  }

  @action.bound
  prepareMask(canvas: HTMLCanvasElement): HTMLCanvasElement {
    // FIXME: there's a corner-case when the mask was drawn on large image
    // and then the window/viewport was resized to really small size,
    // then the mask is collapsed and sent as complete black rectangle
    const width = canvas.width
    const height = canvas.height
    const scaleRatio = this.scaleRatio(width, height)
    const hiddenCanvas = document.createElement('canvas')
    const context = hiddenCanvas.getContext('2d')

    if (!context) {
      throw new Error('Cannot get context')
    }

    hiddenCanvas.width = this.imageDimensions.width
    hiddenCanvas.height = this.imageDimensions.height

    context.clearRect(
      0,
      0,
      this.imageDimensions.width,
      this.imageDimensions.height,
    )
    context.fillStyle = '#000000'
    context.fillRect(
      0,
      0,
      this.imageDimensions.width,
      this.imageDimensions.height,
    )

    for (const drawItem of this.drawingStack) {
      this.drawCircle(
        {
          x: drawItem.circle.x * scaleRatio,
          y: drawItem.circle.y * scaleRatio,
        },
        context,
        drawItem.brush * scaleRatio,
      )

      for (const line of drawItem.lines) {
        this.drawCurve(
          {
            x: line[0].x * scaleRatio,
            y: line[0].y * scaleRatio,
          },
          {
            x: line[1].x * scaleRatio,
            y: line[1].y * scaleRatio,
          },
          context,
          drawItem.brush * scaleRatio,
        )
      }
      context.stroke()
    }

    return hiddenCanvas
  }

  scaleRatio(width: number, height: number) {
    const ratio = Math.min(
      this.imageDimensions.width / width,
      this.imageDimensions.height / height,
    )
    return ratio
  }

  @action.bound
  async edit() {
    this.isBusy = true
    const { data, status } = await this.editImageRepository.createEditSession({
      input_url: this.actualImageUrl,
    })

    if (status === 201 && data) {
      runInAction(() => {
        this.isBusy = false
        this.enabled = true
        this.session = data
        this.imageDimensions = {
          width: data.input_width,
          height: data.input_height,
        }
      })
    } else {
      runInAction(() => {
        this.isBusy = false
      })
      toast.error('Failed to edit image')
      captureException(new Error('Failed to edit image'))
      this.close()
    }
  }

  @action.bound
  updateToolStatus(name: string, enabled: boolean) {
    switch (name) {
      case INPAINTING_CLIPDROP_ACTION:
        this.magicEraser.enabled = enabled
        break
      case UPSCALE_ACTION:
        this.upscale.enabled = enabled
        break
      default:
    }
  }

  @action.bound
  openTool(name: string) {
    this.updateToolStatus(name, true)
  }

  @action.bound
  closeTool(name: string) {
    this.updateToolStatus(name, false)
    switch (name) {
      case INPAINTING_CLIPDROP_ACTION:
        this.magicEraser.value = 35
        break
      case UPSCALE_ACTION:
        this.upscale.value = undefined
        break
      default:
    }
  }

  @action.bound
  async applyTool(name: string) {
    if (!this.isBusy) {
      const startTime = performance.now()
      this.isBusy = true

      switch (name) {
        case INPAINTING_CLIPDROP_ACTION:
          await this.executeInpaintingClipdrop()
          break
        case UPSCALE_ACTION:
          await this.executeUpscale()
          break
        case REMOVE_BG_ACTION:
          await this.executeRemoveBackground()
          break
        default:
      }

      runInAction(() => {
        this.isBusy = false
      })
      const endTime = performance.now()
      const timeToCreate = endTime - startTime

      this.trackEdit(name, timeToCreate)
      this.closeTool(name)
    }
  }

  @action.bound
  setMagicEraser(value: number[]) {
    this.magicEraser.value = value[0]
  }

  @action.bound
  setUpscale(value: 2 | 4 | 8) {
    this.upscale.value = value
  }

  // TODO: error handling
  async executeInpaintingClipdrop() {
    const originalCanvas = document.getElementById('edit-canvas') as
      | HTMLCanvasElement
      | undefined

    if (!originalCanvas) {
      throw new Error('Cannot get canvas')
    }

    const canvas = this.prepareMask(originalCanvas!)

    if (canvas) {
      const getCanvasImageBlob = () => {
        return new Promise<Blob | null>((resolve) => {
          if (canvas) {
            canvas.toBlob((blob) => {
              resolve(blob)
            })
          } else {
            resolve(null)
          }
        })
      }

      const blob = await getCanvasImageBlob()
      if (blob) {
        const mask = await this.editImageRepository.uploadMask(blob)

        if (mask.status === 201 && mask.data) {
          const { data, status } = await this.editImageRepository.createEdit(
            this.session!.id,
            {
              tool: INPAINTING_CLIPDROP_ACTION,
              params: {
                mask_url: mask.data.url,
              },
            },
          )

          if (status === 201 && data) {
            runInAction(() => {
              this.edits.push(data)
              this.resetMask(originalCanvas)
            })
          }
        }
      }
    }
  }

  async executeUpscale() {
    if (this.session) {
      const { data, status } = await this.editImageRepository.createEdit(
        this.session.id,
        {
          tool: UPSCALE_ACTION,
          params: {
            scale: this.upscale.value,
          },
        },
      )

      if (status === 201 && data) {
        const item = await this.checkEditItem(data.id)

        if (item) {
          runInAction(() => {
            this.edits.push(item)
          })
        }
      } else {
        toast.error('Failed to remove background')
      }
    }
  }

  async executeRemoveBackground() {
    if (this.session) {
      const { data, status } = await this.editImageRepository.createEdit(
        this.session.id,
        {
          tool: REMOVE_BG_ACTION,
          params: {},
        },
      )

      if (status === 201 && data) {
        runInAction(() => {
          this.edits.push(data)
        })
      } else {
        toast.error('Failed to remove background')
      }
    }
  }

  async checkEditItem(edit_id: string) {
    const MAX_DELAY = 2 * 60 * 1000
    let delay = 0

    while (delay < MAX_DELAY) {
      const { data } = await this.editImageRepository.readEdit(
        this.session!.id,
        edit_id,
      )

      if (data && data.result_url) {
        return data
      }

      await new Promise((r) => setTimeout(r, 1500))
      delay += 1500
    }

    return undefined
  }

  private trackEdit(editType: string, resultTimeToCreate: number) {
    if (this.conversationId) {
      this.mixpanelService.trackResultEdit({
        chatId: this.conversationId,
        messageId: this.messageId ?? '',
        resultId: '', // TODO: Add result id. Now no way to get it
        resultType: 'image',
        resultOrigin: this.dataSource,
        editType,
        upscaleOption:
          editType === UPSCALE_ACTION ? this.upscale.value : undefined,
        resultTimeToCreate,
      })
    }
  }

  private trackIntegrationUsed(
    messageId: string | undefined,
    resultUsedType: ResultUsedType,
  ) {
    if (this.conversationId) {
      this.mixpanelService.trackResultUsed({
        chatId: this.conversationId,
        messageId: messageId ?? '',
        messageContentTypes: ['image'],
        resultOrigin: this.dataSource ?? 'Unsplash',
        resultUsedType,
      })
    }
  }

  @action.bound
  handleDownload = async () => {
    if (this.actualImageUrl) {
      const file = await createFileFromImageUrl(this.actualImageUrl)
      downloadFile(file)
      this.trackIntegrationUsed(this.messageId, 'download')
    }
  }

  @action.bound
  handleCopyToClipboard = async () => {
    if (this.actualImageUrl) {
      try {
        await copyToClipboard(this.actualImageUrl)
        toast('Copied to clipboard')
        this.trackIntegrationUsed(this.messageId, 'copy-url')
      } catch (_) {
        toast.error('Error copying to clipboard')
      }
    }
  }

  @action.bound
  async done() {
    if (!this.isBusy && this.session && this.conversationId && this.wasEdited) {
      const pin: AttachmentPin = {
        type: 'image_edit_session_pin',
        session_id: this.session.id,
      }
      const result = await this.api.conversations.pinAttachment({
        params: { conversation_id: this.conversationId },
        body: {
          pin,
        },
      })

      if (result.status === 201) {
        this.trackIntegrationUsed(result.body.message_id, 'User Attachment')
        this.modalService.closeAll()
      } else {
        captureException(new Error('Failed to send attachment'))
        toast.error('Failed to send attachment')
      }
    }

    this.modalService.closeAll()
  }

  @action.bound
  onKeyDown(e: any) {
    // FIXME: not working...
    if (e.key === 'Escape') {
      this.close()
    }
  }

  @action.bound
  close() {
    if (this.wasEdited) {
      this.modalService.show(
        createElement(UnsavedChangesModal, {
          onDiscard: () => this.modalService.closeAll(),
        }),
        'Unsaved changes',
      )
    } else {
      this.modalService.close()
    }
  }

  @action.bound
  setIsBusy(isBusy: boolean) {
    this.isBusy = isBusy
  }
}

injected(
  EditImageViewModel,
  DI_TYPE.Api,
  DI_TYPE.ModalService,
  DI_TYPE.EditImageRepository,
  DI_TYPE.RouterService,
  DI_TYPE.MixpanelService,
)
