import './emoji-picker.css'

import data from '@emoji-mart/data'
import emojiPicker from '@emoji-mart/react'
import { defaultImport } from 'default-import'
import type { LegacyRef } from 'react'
import { forwardRef } from 'react'

const EmojiPickerComponent = defaultImport(emojiPicker)

interface IEmojiPicker {
  onEmojiSelect: (value: string) => void
  onClickOutside?: () => void
}

export const EmojiPicker = forwardRef(
  ({ onEmojiSelect, onClickOutside }: IEmojiPicker, ref) => {
    const handleOnClick = (e: any) => {
      onEmojiSelect(e.native)
    }

    return (
      <div ref={ref as LegacyRef<HTMLDivElement>}>
        <EmojiPickerComponent
          data={data}
          previewPosition="none"
          navPosition="none"
          skinTonePosition="none"
          theme="light"
          onEmojiSelect={handleOnClick}
          perLine={8}
          emojiButtonSize={28}
          emojiSize={20}
          emojiButtonRadius="4px"
          autoFocus={true}
          onClickOutside={() => onClickOutside?.()}
        />
      </div>
    )
  },
)
