import { useCurrentEditor } from '@tiptap/react'
import React from 'react'

import AbstractShapesArt from '@/assets/images/vector-shapes.svg?react'

import { cls } from '../../utils/utils'
import type { ConversationViewModel } from '../../view-models/conversation'
import { LoadingDots } from '../loading-dots/loading-dots'

export const Placeholder: React.FC<{ vm: ConversationViewModel }> = ({
  vm,
}) => {
  const { editor } = useCurrentEditor()
  const { msg, index } = vm.currentDocument
  const isEditorEmpty = vm.documents.length === 0 && vm.messages.length > 0
  const hidePlaceholder =
    vm.documentViewModel.draft.length > 0 || editor?.isFocused // hide placeholder if user starts drafting
  const showLoading = msg?.renderMetadata.streaming && index > 0
  if (!isEditorEmpty && !showLoading) {
    return null
  }
  return (
    <div
      onClick={() => editor?.commands.focus()}
      className={cls(
        'pointer-events-all absolute flex flex-col items-center justify-center text-center opacity-100 transition-opacity duration-500',
        hidePlaceholder && 'pointer-events-none opacity-0',
        showLoading
          ? 'bg-cerosGrey100/20 pointer-events-none bottom-0 left-0 top-[40px] z-10 w-full backdrop-blur-sm'
          : 'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
      )}
    >
      {isEditorEmpty ? (
        <>
          <AbstractShapesArt />
          <h3 className="text-3xl">Copy & Content</h3>
          <p className="text-cerosGrey500 mt-4 select-none text-base/[1.6rem]">
            Please describe what you'd like help with writing in the chat. You
            can also start writing yourself or copy & paste a draft right here
            in the editor as a starting point.
          </p>
        </>
      ) : (
        <LoadingDots />
      )}
    </div>
  )
}
