export class ExponentialBackoff {
  private interval: number
  private initialInterval: number
  private maxInterval: number
  private maxRetries: number
  private retryCount: number

  constructor(
    initialInterval: number,
    maxInterval: number,
    maxRetries: number,
  ) {
    this.interval = initialInterval
    this.initialInterval = initialInterval
    this.maxInterval = maxInterval
    this.maxRetries = maxRetries
    this.retryCount = 0
  }

  shouldRetry(): boolean {
    return this.retryCount < this.maxRetries
  }

  getNextDelay(): number {
    if (!this.shouldRetry()) {
      throw new Error('Max retries reached')
    }

    const currentDelay = this.interval
    this.interval = Math.min(this.interval * 2, this.maxInterval)
    this.retryCount++

    return currentDelay
  }

  reset() {
    this.retryCount = 0
    this.interval = this.initialInterval // Reset to initial interval
  }
}
