import { Button } from '@/components/button/button.js'
import { useModalService } from '@/utils/view.js'

interface IUnsavedChangesModal {
  onKeep?: () => void
  onDiscard: () => void
}

export function UnsavedChangesModal({
  onKeep,
  onDiscard,
}: IUnsavedChangesModal) {
  const modalService = useModalService()

  const handleDiscard = () => {
    onDiscard()
    modalService.close()
  }

  const handleKeep = () => {
    if (onKeep) {
      onKeep()
    }
    modalService.close()
  }

  return (
    <>
      <div className="mt-4 flex items-center justify-center">
        <p className=" text-cerosGrey400 w-3/4 text-center text-sm font-medium ">
          Would you like to keep editing or return to chat and lose your
          changes?
        </p>
      </div>
      <div className="mt-10 flex flex-col items-center justify-center gap-4 md:flex-row">
        <Button variant="secondary" onClick={handleDiscard}>
          Discard changes
        </Button>
        <Button onClick={handleKeep} tabIndex={1}>
          Keep editing
        </Button>
      </div>
    </>
  )
}
