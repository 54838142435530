import './copy-and-content.css'

import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import CloseIcon from '@/assets/icons/close.svg?react'
import InfoIcon from '@/assets/icons/info.svg?react'

import { getDocumentContent } from '../../utils/document'
import { cls, copyRichTextToClipboard } from '../../utils/utils'
import type { ConversationViewModel } from '../../view-models/conversation'
import { Button } from '../button/button'
import { IconButton } from '../icon-button/icon-button'
import { LoadingDots } from '../loading-dots/loading-dots'
import { TiptapEditor } from '../rich-text-editor/editor'
import { Select } from '../select/select'
import { Tooltip } from '../tooltip/tooltip'

export const CopyAndContentTool = observer(
  ({ vm }: { vm: ConversationViewModel }) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [lastLoaderPosition, setLastLoaderPosition] = useState<DOMRect>()
    const currentLoaderPosition = useMemo(() => {
      const containerRect = containerRef.current?.getBoundingClientRect()
      if (containerRect && lastLoaderPosition) {
        const extraHeight = 40
        const extraWidth = 20
        return {
          width: lastLoaderPosition.width + extraWidth,
          height: lastLoaderPosition.height + extraHeight,
          top: lastLoaderPosition.top - containerRect.top - extraHeight / 2,
          left: lastLoaderPosition.left - containerRect.left - extraWidth / 2,
        }
      }
      return lastLoaderPosition
    }, [lastLoaderPosition])
    const { msg, index } = vm.currentDocument
    const cleanedContent = useMemo(() => {
      return msg ? getDocumentContent(msg) : vm.documentViewModel.draft
    }, [msg, vm.documentViewModel.draft])

    const copyAllContent = () => {
      const richContent = vm.documentViewModel.editor?.getHTML() || ''
      // remove highlights in case user clicks copy while previewing changes
      const richContentClean = richContent.replace(/<mark.*?>|<\/mark>/g, '')
      copyRichTextToClipboard(richContentClean)
        .then(() => {
          toast.success('Copied content to clipboard')
          vm.documentViewModel.onCopyDocument(index, 'button')
        })
        .catch(() => {
          toast.error('Failed to copy to clipboard')
        })
    }

    useEffect(() => {
      if (vm.documentViewModel.loaderPosition) {
        setLastLoaderPosition(vm.documentViewModel.loaderPosition)
      }
    }, [vm.documentViewModel.loaderPosition])

    return (
      <div
        className="relative grid h-full max-h-screen w-full grid-cols-[100%] grid-rows-[50px,_1fr] gap-8 px-4 py-8 pt-4"
        style={{ background: '#D5CCDD' }}
        ref={containerRef}
        data-automation="copy-and-content-home"
      >
        <div className="row flex h-[50px] items-center gap-3" role="toolbar">
          <div className="relative z-30 w-[160px]">
            <Select
              options={
                vm.documents.length === 0
                  ? []
                  : new Array(vm.documents.length)
                      .fill(null)
                      .map((_, i) => `Version ${i + 1}`)
              }
              selectClassName="h-[40px]"
              name="versions[]"
              value={
                index === -1
                  ? 'Draft'
                  : vm.isNewDocumentLoading
                    ? 'Working on it...'
                    : `Version ${index + 1}`
              }
              multiple={false}
              onChange={(c) => {
                if (!c.multiple) {
                  const newIndex =
                    parseInt(c.value.replace('Version', ''), 10) - 1
                  vm.setCurrentDocumentMessage(newIndex)
                }
              }}
              disabled={vm.documents.length === 0 || vm.isNewDocumentLoading}
            />
          </div>
          <Tooltip
            text="Gemma creates a new version everytime she makes changes"
            className="max-w-[200px]"
            placement="right"
          >
            <div className="flex h-[40px] w-[40px] items-center justify-center">
              <InfoIcon className="h-6 w-6" />
            </div>
          </Tooltip>
          <div className="ml-auto flex gap-3">
            <Button
              className="bg-cerosWhite/100 disabled:bg-cerosWhite/80 disabled:text-cerosBlack hover:bg-cerosWhite/80 active:bg-cerosWhite/80 h-[40px] border-none"
              variant="secondary"
              disabled={cleanedContent.length === 0}
              onClick={copyAllContent}
            >
              Copy
            </Button>
            <IconButton
              className="h-[40px] w-[40px]"
              icon={<CloseIcon />}
              onClick={() => {
                vm.documentViewModel.isEditorOpen = false
              }}
            />
          </div>
        </div>
        <TiptapEditor vm={vm} />
        {currentLoaderPosition && (
          <div
            className={cls(
              'bg-cerosGrey100/20 absolute flex items-center justify-center rounded-md backdrop-blur-sm transition-opacity duration-500',
              vm.documentViewModel.loaderPosition
                ? 'opacity-100'
                : 'pointer-events-none opacity-0',
            )}
            style={{
              // techdebt: this takes care of closing/opening sidebar when loader is visible, but it's not the best solution
              // as it doesn't change height of the loader
              width: 'calc(100% - 64px)',
              height: currentLoaderPosition.height,
              top: currentLoaderPosition.top,
              left: currentLoaderPosition.left,
            }}
          >
            <LoadingDots className="h-1/2" />
          </div>
        )}
      </div>
    )
  },
)
