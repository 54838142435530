import { Player } from '@lottiefiles/react-lottie-player'

import gemmaLogo from '../../assets/icons/gemma-logo.svg'
import { Button } from '../button/button'

interface IOnboardingStartScene {
  onStart: () => void
}

export function OnboardingStartScene({ onStart }: IOnboardingStartScene) {
  return (
    <>
      <div className="absolute inset-0 h-full w-full">
        <div className="relative h-full w-full">
          <Player
            autoplay
            loop
            src="/lottie/onboarding-bg-pulse.json"
            className="absolute h-full w-full"
            rendererSettings={{
              progressiveLoad: false,
              preserveAspectRatio: 'xMidYMid slice',
            }}
          />
        </div>
      </div>
      <div className="absolute left-1/2 flex h-full w-full -translate-x-1/2 items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-12">
          <div
            className="h-16 w-16 bg-contain bg-center bg-no-repeat"
            style={{
              backgroundImage: `url("${gemmaLogo}")`,
            }}
          />
          <Button onClick={onStart}>Enter</Button>
        </div>
      </div>
    </>
  )
}
