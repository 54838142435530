import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import { DI_TYPE } from '@/di.types'
import { useFactoryViewModel } from '@/utils/view'

export const ConversationClonePage = observer(() => {
  const { conversationId = '' } = useParams()
  const vm = useFactoryViewModel(DI_TYPE.ConversationCloneViewModel, [
    conversationId,
  ])

  const hasError = vm.hasError

  useEffect(() => {
    if (hasError) {
      toast.error('Failed to clone converstaion')
    }
  }, [hasError])

  return null
})

ConversationClonePage.displayName = 'ConversationClonePage'
