import { Link } from 'react-router-dom'

import GemmaLogo from '@/assets/icons/gemma-logo.svg?react'
import CerosLogo from '@/assets/images/ceros-black.svg?react'
import { cls } from '@/utils/utils'

const UpsellText = ({ className }: { className?: string }) => (
  <div className={cls('flex text-center', className)}>
    <p className="text-sm font-medium">
      Your personal creative AI assistant. Ideate, brainstorm, and create
      on-brand presentations in minutes.{' '}
      <Link
        to="https://educate.ceros.com/gemma-j6azhdor"
        className="text-cerosPrimary whitespace-nowrap text-sm font-medium"
      >
        Learn more about Gemma.
      </Link>
    </p>
  </div>
)

export const ReadonlyFooter = ({ className }: { className?: string }) => {
  return (
    <div
      className={cls(
        'z-[1001] flex w-screen flex-col items-center justify-between gap-4 bg-gradient-to-l from-[#C3CBF7] to-[#F1DEDD] pb-4 pl-4 pr-4 pt-4 sm:pl-8 sm:pr-8',
        className,
      )}
    >
      <UpsellText className="w-full flex-1 md:hidden" />
      <div className="flex w-full items-center justify-between gap-4">
        <div className="flex items-center gap-2 whitespace-nowrap">
          <GemmaLogo className="bg-cerosWhite h-10 w-10 overflow-visible rounded-full p-2" />
          <span className="text-base font-medium">Gemma</span>
        </div>
        <UpsellText className="hidden md:block" />
        <div className="flex gap-1 whitespace-nowrap text-xs font-medium">
          <span>Powered by</span> <CerosLogo className="w-[67px]" />
        </div>
      </div>
    </div>
  )
}
