import { injected } from 'brandi'
import { action, computed, makeObservable, observable, reaction } from 'mobx'

import { DI_TYPE } from '@/di.types'

import type { VoiceInputService } from '../services/voice-input'
import { BaseViewModel } from './base-view-model'

export const MAX_PROMPT_LENGTH = 10_000

export class GemPromptInputViewModel extends BaseViewModel {
  @observable private recognizedPrompt?: string
  @observable private pendingPrompt?: string

  constructor(private readonly voiceInputService: VoiceInputService) {
    super()
    makeObservable(this)
  }

  protected onInit(): void {
    this.voiceInputService._start()

    reaction(
      () => this.voiceInputService.recognizingText,
      this.onSpeechRecognizing,
    )

    reaction(
      () => this.voiceInputService.recognizedText,
      this.onSpeechRecognized,
    )
  }

  protected onDispose(): void {
    this.voiceInputService._stop()
    this.recognizedPrompt = undefined
    this.pendingPrompt = undefined
  }

  @action.bound
  onPromptChange(value?: string) {
    this.recognizedPrompt = value ?? ''
  }

  @action.bound
  onSpeechRecognized(text?: string) {
    if (!text) return
    const isEndOfSentence =
      this.recognizedPrompt?.endsWith('.') ||
      this.recognizedPrompt?.endsWith('?') ||
      this.recognizedPrompt?.endsWith('!')

    if (this.recognizedPrompt) {
      if (isEndOfSentence && !text?.startsWith(' ')) {
        text = ' ' + text
      }
      this.recognizedPrompt += text
    } else {
      this.recognizedPrompt = text
    }

    this.pendingPrompt = undefined
  }

  @action.bound
  onSpeechRecognizing(text?: string) {
    if (!text) return
    this.pendingPrompt = text
  }

  @action.bound
  async voiceInputStart() {
    this.voiceInputService.voiceInputStart()
  }

  @action.bound
  async voiceInputStop() {
    await this.voiceInputService.voiceInputStop()
  }

  @computed
  get promptText() {
    return (this.recognizedPrompt ?? '') + (this.pendingPrompt ?? '')
  }

  @computed
  get isRecording() {
    return this.voiceInputService.speechRecording
  }
}

injected(GemPromptInputViewModel, DI_TYPE.VoiceInputService)
