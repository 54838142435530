import { injected } from 'brandi'

import { DI_TYPE } from '@/di.types.js'
import type { ApiClient } from '@/services/api-client.js'

export interface Task {
  id: number
  emoji: string
  title: string
  sidebar_roles: string[]
  new_conversation_roles: string[]
  keywords: string
}

interface Tasks {
  result: Task[]
}

export class TasksRepository {
  constructor(private apiClient: ApiClient) {}

  async fetchTasks({
    signal,
    place,
    roles,
  }: {
    place: 'sidebar' | 'new_conversation'
    roles?: string[]
    signal?: AbortSignal
  }) {
    try {
      const params = new URLSearchParams({ place })
      if (roles) params.set('roles', roles.join(','))
      const { data, status } = await this.apiClient.get<Tasks>(
        `/chat_tasks/?` + params,
        { signal },
      )
      return { data, status }
    } catch (error: any) {
      return { data: undefined, status: 500 }
    }
  }
}

injected(TasksRepository, DI_TYPE.ApiClient)
