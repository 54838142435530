import { initContract } from '@ts-rest/core';
import * as authentication from './authentication.js';
import * as billing from './billing.js';
import { brandSchema } from './brandfetch.js';
import * as conversations from './conversations.js';
import * as featureFlags from './feature_flags.js';
import * as files from './files.js';
import * as gems from './gems.js';
import * as generativeAI from './generative_ai.js';
import * as messageContents from './message_contents.js';
import * as promptTemplates from './prompt_templates.js';
import * as support from './support.js';
const c = initContract();
export const contract = c.router({
    conversations: conversations.contract,
    messageContents: messageContents.contract,
    brandfetchGetBrand: {
        method: 'GET',
        path: '/proxy/brandfetch/v2/brands/:domain',
        responses: {
            200: brandSchema,
        },
    },
    authentication: authentication.contract,
    featureFlags: featureFlags.contract,
    promptTemplates: promptTemplates.contract,
    files: files.contract,
    support: support.contract,
    gems: gems.contract,
    billing: billing.contract,
    generativeAI: generativeAI.contract,
});
export default contract;
// Reexport all types and schemas
export * from './authentication.js';
export * from './billing.js';
export * from './blocks.js';
export * from './brandfetch.js';
export * from './conversations.js';
export * from './feature_flags.js';
export * from './files.js';
export * from './gems.js';
export * from './generative_ai.js';
export * from './message_contents.js';
export * from './prompt_templates.js';
export * from './support.js';
// Export parts of @ts-rest/core to ensure that the contract is compatible with the types defined in this repo (unique symbol drama)
export { initClient, tsRestFetchApi } from '@ts-rest/core';
