import type { UploadStatus } from '@ceros/gemma-api-spec'
import mime from 'mime'

import FileIcon from '@/assets/icons/file-document.svg?react'
import PdfIcon from '@/assets/icons/pdf.svg?react'
import FileImage from '@/assets/images/attachment/attachment-file.svg?react'
import PreviewImageOver from '@/assets/images/attachment/attachment-over.svg?react'
import PdfImage from '@/assets/images/attachment/attachment-pdf.svg?react'
import AttachmentBackground from '@/assets/images/attachment/attachment-tile.svg?react'
import PreviewImageBackground from '@/assets/images/attachment/attachment-white.svg?react'
import { cls } from '@/utils/utils'

export type DocumentThumbnailProps = {
  title?: string
  status?: UploadStatus
  mimeType?: string
  previewUrl?: string
}

export const DocumentThumbnail = ({
  title,
  status,
  previewUrl,
  mimeType = '',
}: DocumentThumbnailProps) => {
  let statusMessage: string

  const ext = mime.getExtension(mimeType)?.toUpperCase()
  const Icon = ext === 'PDF' ? PdfIcon : FileIcon
  const PlaceholderImage = ext === 'PDF' ? PdfImage : FileImage

  const imagePreview = (
    <>
      <PreviewImageBackground className="absolute z-[1] h-auto w-[160px] sm:w-[232px]" />
      <PreviewImageOver className="absolute z-[3] h-auto w-[160px] sm:w-[232px]" />
      <img
        src={previewUrl}
        alt={title}
        className="z-[2] my-10 h-auto w-[100px] self-start sm:my-14 sm:w-[138px]"
      />
    </>
  )

  switch (status) {
    case 'COMPLETED':
      statusMessage = title ?? ''
      break
    case 'IN_PROGRESS':
    case 'IN_QUEUE':
      statusMessage = `Processing ${ext}...`
      break
    case 'FAILED':
      statusMessage = 'Something went wrong'
      break
    default:
      statusMessage = ''
  }

  return (
    <div
      className={cls(
        'shadow-normal-elevation mt-5 flex h-[202px] w-[220px] flex-col overflow-hidden rounded-2xl opacity-50 sm:h-[264px] sm:w-[300px] md:mt-0',
        status === 'COMPLETED' && 'opacity-100',
      )}
      data-automation="document-thumbnail"
    >
      <div className="border-cerosGrey200 relative flex w-full flex-1 items-end justify-center overflow-hidden rounded-t-2xl border">
        {status === 'COMPLETED' && (
          <AttachmentBackground className="absolute h-auto w-[220px] sm:w-[300px]" />
        )}
        {previewUrl ? (
          imagePreview
        ) : (
          <PlaceholderImage className="z-[1] h-auto w-[160px] sm:w-[232px]" />
        )}
      </div>

      <div className="bg-cerosWhite flex h-[72px] w-full shrink-0 items-center justify-start p-2">
        <div className="flex h-full w-full items-center gap-2 px-2">
          <Icon className="h-[24px] w-[24px] shrink-0" />
          <div className="flex w-[88%] flex-1 grow-0 flex-col">
            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-base font-medium">
              {statusMessage}
            </p>
            {status === 'COMPLETED' && (
              <p className="text-cerosGrey400 whitespace-nowrap text-xs font-normal">
                {ext} Document
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
