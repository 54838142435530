import { injected } from 'brandi'
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { toast } from 'react-hot-toast'

import { DI_TYPE } from '@/di.types'
import type { Choice, Question } from '@/models/user'
import { createOnboardingInterestsForm } from '@/pages/onboarding/interests-form'
import { createOnboardingNameForm } from '@/pages/onboarding/name-form'
import { createOnboardingWorkForm } from '@/pages/onboarding/work-form'
import type { UserRepository } from '@/repositories/user'
import type { EventBusService } from '@/services/event-bus'
import type { MixpanelService } from '@/services/mixpanel'
import type { RouterService } from '@/services/router'
import type { UserService } from '@/services/user'
import type { BaseForm } from '@/utils/base-form'
import { bound } from '@/utils/utils'

import { BaseViewModel } from './base-view-model'

export type QuestionPage = 'name' | 'work' | 'interests'

export interface StepData {
  key: QuestionPage
  question: string
  choices?: Choice[]
  form: BaseForm
}

export class OnboardingViewModel extends BaseViewModel {
  @observable isBusy = false
  @observable initialized = false
  @observable currentStepIndex = 0
  @observable steps: StepData[] = []
  @observable username = 'User'

  constructor(
    public routerService: RouterService,
    private eventBusService: EventBusService,
    private userRepository: UserRepository,
    private mixpanelService: MixpanelService,
    private userService: UserService,
  ) {
    super()
    makeObservable(this)
  }

  onInit() {
    runInAction(() => {
      this.steps = [
        {
          key: 'name',
          question: '',
          choices: [],
          form: createOnboardingNameForm(),
        },
        {
          key: 'work',
          question: '',
          choices: [],
          form: createOnboardingWorkForm(),
        },
        {
          key: 'interests',
          question: '',
          choices: [],
          form: createOnboardingInterestsForm(),
        },
      ]
    })

    this.initOnboardingData()

    reaction(
      () => ({ initialized: this.initialized, step: this.currentStep }),
      ({ initialized, step }) => {
        if (initialized) {
          this.trackOnboardingViewed(step.key)
        }
      },
      { fireImmediately: true },
    )
  }

  onDispose() {
    this.abortController?.abort()
  }

  async startOnboarding() {
    await this.userService.startOnboarding()
  }

  @action.bound
  private async initOnboardingData() {
    if (this.abortController) {
      const data = await this.loadOnboardingQuestion(0)
      runInAction(() => {
        this.initialized = !!data
      })
    }
  }

  @action.bound
  async loadOnboardingQuestion(index: number) {
    this.isBusy = true
    let data: Question | undefined = undefined

    if (index <= this.steps.length - 1) {
      if (this.steps[index].key === 'name') {
        data = await this.loadNameQuestion()
      }

      if (this.steps[index].key === 'work') {
        data = await this.loadRoleQuestion()
      }

      if (this.steps[index].key === 'interests') {
        data = await this.loadInterestsQuestion()
      }

      if (data) {
        this.setQuestionData(data, index)
      }
    }

    runInAction(() => {
      this.isBusy = false
    })

    return data
  }

  private async loadNameQuestion() {
    const { data, status } =
      await this.userRepository.getOnboardingNameQuestion(this.abortController)

    if (status === 500) {
      toast.error('Something went wrong')
    }

    return data
  }

  private async loadRoleQuestion() {
    const { data, status } =
      await this.userRepository.getOnboardingRolesQuestion(this.abortController)

    if (status === 500) {
      toast.error('Something went wrong')
    }

    return data
  }

  private async loadInterestsQuestion() {
    const { data, status } =
      await this.userRepository.getOnboardingInterestQuestion(
        this.abortController,
      )

    if (status === 500) {
      toast.error('Something went wrong')
    }

    return data
  }

  @action.bound
  private setQuestionData(data: Question, stepIndex: number) {
    const step = { ...this.steps[stepIndex] }
    step.question = data.question
    step.choices = data.choices

    this.steps[stepIndex] = step
  }

  @computed
  get currentStep() {
    return this.steps[this.currentStepIndex]
  }

  @computed
  get isComplete() {
    return this.steps.every((step) => step.form.isSubmitted)
  }

  @action.bound
  async changeStep(index: number) {
    if (
      index !== this.currentStepIndex &&
      index >= 0 &&
      index <= this.steps.length - 1 &&
      this.currentStep.form.isValid
    ) {
      if (this.currentStepIndex === this.steps.length - 1) {
        runInAction(() => {
          this.currentStepIndex = index
        })
      } else {
        this.currentStep.form.isSubmitting = true
        const result = await this.updateUserProperties(
          this.currentStep.form.data,
        )

        if (result) {
          // TODO: When UserService will be ready, we need to remove this code and use UserService instead
          if (this.currentStep.key === 'name') {
            runInAction(() => {
              this.username = this.currentStep.form.fields.name.value
            })
          }

          const question = await this.loadOnboardingQuestion(index)
          if (question) {
            runInAction(() => {
              this.currentStep.form.isSubmitted = true
              this.currentStep.form.isSubmitting = false
              this.currentStepIndex = index
            })
          }
        }
      }
    }
  }

  @action.bound
  async submitAnswer(formData: FormData) {
    this.currentStep.form.isSubmitting = true

    try {
      const result = await this.updateUserProperties(formData)
      if (result) {
        // TODO: When UserService will be ready, we need to remove this code and use UserService instead
        if (this.currentStep.key === 'name') {
          runInAction(() => {
            this.username = this.currentStep.form.fields.name.value
          })
        }

        const question = await this.loadOnboardingQuestion(
          this.currentStepIndex + 1,
        )
        runInAction(() => {
          this.currentStep.form.isSubmitting = false
          this.currentStep.form.isSubmitted = true
        })

        if (this.currentStepIndex !== this.steps.length - 1) {
          if (question) {
            this.nextStep()
          } else {
            this.resetFormSubmissionState()
          }
        }
      } else {
        throw new Error()
      }
    } catch (error) {
      this.handleSubmissionError()
    }
  }

  private resetFormSubmissionState() {
    runInAction(() => {
      this.currentStep.form.isSubmitting = false
      this.currentStep.form.isSubmitted = false
    })
  }

  private handleSubmissionError() {
    this.resetFormSubmissionState()
    toast.error('Something went wrong')
  }

  private async updateUserProperties(formData: FormData) {
    const result = await this.userService.updateProperties(formData)

    return result
  }

  trackOnboardingViewed(step: QuestionPage) {
    this.mixpanelService.trackOnboardingViewed(step)
  }

  trackOnboardingSkipped() {
    this.mixpanelService.trackOnboardingSkipped(this.currentStep.key)
  }

  @bound
  async complete() {
    this.routerService.goTo('/')
  }

  @bound
  async skip() {
    this.trackOnboardingSkipped()
    this.routerService.goTo('/')
  }

  @action.bound
  private nextStep() {
    this.currentStepIndex++
  }
}

injected(
  OnboardingViewModel,
  DI_TYPE.RouterService,
  DI_TYPE.EventBusService,
  DI_TYPE.UserRepository,
  DI_TYPE.MixpanelService,
  DI_TYPE.UserService,
)
