import type {
  Conversation,
  ConversationCreate,
  ConversationList,
  ConversationUpdate,
  ConversationWithUserAccess,
  TaskPromptType,
} from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import format from 'date-fns/format'
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { match } from 'path-to-regexp'
import { toast } from 'react-hot-toast'

import { DI_TYPE } from '@/di.types'

import type { Api } from './api'
import type { AuthService } from './auth'
import { BaseService } from './base-service'
import {
  type EventBusPayload,
  type EventBusService,
  RegisteredEvents,
} from './event-bus'
import type { ChatCreateSourceType, MixpanelService } from './mixpanel'
import type { RouterService } from './router'

export enum RENAME_SOURCE {
  USER = 'user',
  AI_SUGGESTION = 'ai_suggestion',
  TASK_SELECT = 'task_select',
}

export type Task = {
  emoji: string
  title: string
  background_color: string
  task_prompt_type: TaskPromptType
  created: number
}

export type ConversationRenamedEventData = {
  id: string
  name: string
  emoji: string
  title?: string
}

export type ConversationUpdatedEventData = {
  id: string
} & ConversationUpdate

export type ConversationItem = ConversationList['result'][number]

export class ConversationsService extends BaseService {
  @observable private conversations: ConversationList = {
    until: '',
    limit: 0,
    count: 0,
    result: [],
  }

  @observable private conversation: ConversationWithUserAccess | null = null

  static CONVERSATIONS_SLUG = 'conversations'

  constructor(
    private api: Api,
    private eventBusService: EventBusService,
    private mixpanelService: MixpanelService,
    private routerService: RouterService,
    private authService: AuthService,
  ) {
    super()
    makeObservable(this)

    this.onInit()
  }

  @action.bound
  async onInit() {
    this.eventBusService.subscribe(
      RegisteredEvents.ConversationRenamed,
      this.receiveNewName,
    )

    this.eventBusService.subscribe<ConversationUpdatedEventData>(
      RegisteredEvents.ConversationUpdated,
      this.receiveUpdatedConversation,
    )

    this.eventBusService.subscribe<ConversationList>(
      RegisteredEvents.ConversationsLoaded,
      (payload) => this.applyConversationListData(payload),
    )

    this.eventBusService.subscribe<ConversationWithUserAccess>(
      RegisteredEvents.ConversationLoaded,
      this.applyCurrentConversation,
    )

    reaction(
      () => {
        return this.authService.auth?.data.user.id
      },
      async (auth) => {
        if (auth) {
          await this.loadConversationList()
        }

        runInAction(() => {
          this.initialized = true
        })
      },
      {
        fireImmediately: true,
      },
    )
  }

  public parseConversationRoute() {
    const fn = match('{/:parentRoute}?{/:conversationId}?{/:integration}?', {
      decode: decodeURIComponent,
    })
    const result = fn(this.routerService.currentPath) as {
      params: {
        parentRoute?: string
        conversationId?: string
        integration?: string
      }
    }
    return result.params
  }

  @computed
  get items(): ConversationItem[] {
    return this.conversations.result ?? []
  }

  @computed
  get currentConversation(): ConversationWithUserAccess | null {
    return this.conversation
  }

  private async loadConversationList() {
    const result = await this.api.conversations.getConversations({
      query: { limit: 1000 }, // FIXME: hotfix until lazy load pagination is implemented
    })

    if (result.status === 200) {
      this.eventBusService.publish(
        RegisteredEvents.ConversationsLoaded,
        result.body,
      )
      return result.body
    } else {
      toast.error('Error loading conversations')
      return undefined
    }
  }

  async fetchConversation(
    conversationId: string,
  ): Promise<ConversationWithUserAccess | null> {
    const result = await this.api.conversations.getConversation({
      params: {
        conversation_id: conversationId,
      },
    })

    if (result.status === 200) {
      this.eventBusService.publish(
        RegisteredEvents.ConversationLoaded,
        result.body,
      )

      return result.body
    }

    return null
  }

  @action
  private applyCurrentConversation(
    payload: EventBusPayload<ConversationWithUserAccess>,
  ) {
    this.conversation = payload.detail
  }

  @action
  private applyConversationListData(
    payload: EventBusPayload<ConversationList>,
  ) {
    this.conversations = payload.detail
  }

  @action.bound
  receiveNewName(payload: EventBusPayload<ConversationRenamedEventData>) {
    const index = this.items.findIndex((item) => item.id === payload.detail.id)
    if (index >= 0) {
      this.items[index] = {
        ...this.items[index],
        name: payload.detail.name,
        emoji: payload.detail.emoji,
      }
    }
  }

  @action.bound
  receiveUpdatedConversation(
    payload: EventBusPayload<ConversationUpdatedEventData>,
  ) {
    const index = this.items.findIndex((item) => item.id === payload.detail.id)
    if (index >= 0) {
      this.items[index] = {
        ...this.items[index],
        ...payload.detail,
      }
    }
  }

  @action.bound
  async receiveTask(conversationId: string, task_prompt_type: TaskPromptType) {
    const result = await this.api.conversations.enableChatTask({
      params: { conversation_id: conversationId },
      body: { task_prompt_type: task_prompt_type },
    })

    if (result.status === 200) {
      return result.body
    } else {
      // FUTURE: move error handling to view model? we may not want a toast every time
      toast.error('Error updating conversation task')
      return undefined
    }
  }

  async createConversation(
    body: Partial<ConversationCreate>,
    meta: {
      createSource: ChatCreateSourceType
      gemTitle?: string
      taskTitle?: string
    },
  ) {
    const name = body?.name ?? `Chat on ${format(new Date(), 'LLLL dd')}`
    const result = await this.api.conversations.createConversation({
      body: {
        ...body,
        name,
      },
    })
    if (result.status === 201) {
      await this.loadConversationList()
      this.eventBusService.publish(
        RegisteredEvents.ConversationCreated,
        result.body,
      )

      // Track creation of chat
      this.mixpanelService.trackChatCreate(result.body.id, {
        source: meta.createSource,
        taskPromptType: body.task_prompt_type,
        taskTitle: meta.taskTitle,
        gemId: body.gem_id,
        gemTitle: meta.gemTitle,
      })

      return result.body
    } else {
      // FUTURE: move error handling to view model? we may not want a toast every time
      toast.error('Error creating conversation')
      return undefined
    }
  }

  async deleteConversation(id: string) {
    const result = await this.api.conversations.deleteConversation({
      params: { conversation_id: id },
    })

    if (result.status === 200) {
      this.mixpanelService.trackChatDelete(id)
      await this.loadConversationList()
      this.eventBusService.publish(
        RegisteredEvents.ConversationDeleted,
        result.body,
      )
      return result.body
    } else {
      // FUTURE: move error handling to view model? we may not want a toast every time
      toast.error('Error deleting conversation')
      return undefined
    }
  }

  async renameConversation(
    id: string,
    emoji: string,
    name: string,
    changeSource: RENAME_SOURCE,
  ) {
    const result = await this.api.conversations.updateConversation({
      params: { conversation_id: id },
      body: { emoji, name },
    })

    if (result.status === 200) {
      this.eventBusService.publish(RegisteredEvents.ConversationRenamed, {
        id,
        name,
        emoji,
      })

      if (changeSource === RENAME_SOURCE.USER) {
        this.mixpanelService.trackChatRename(id)
      }
    } else {
      // FUTURE: move error handling to view model? we may not want a toast every time
      toast.error('Error renaming conversation')
    }
  }

  async updateConversation(id: Conversation['id'], body: ConversationUpdate) {
    const result = await this.api.conversations.updateConversation({
      params: {
        conversation_id: id,
      },
      body: {
        ...body,
      },
    })

    if (result.status !== 200) {
      toast.error('Error updating conversation')
      return undefined
    }

    await this.loadConversationList()
    this.eventBusService.publish(
      RegisteredEvents.ConversationUpdated,
      result.body,
    )
    return result.body
  }

  async cloneConversation(id: Conversation['id']): Promise<Conversation> {
    const result = await this.api.conversations.clone({
      params: { conversation_id: id },
      body: {},
    })

    if (result.status !== 200) {
      throw new Error('Cannot clone converstaion')
    }

    await this.loadConversationList()

    return result.body.conversation
  }

  get tasks(): Task[] {
    return [
      {
        emoji: '💬',
        title: 'Start new chat',
        background_color: '#B6EFCE',
        task_prompt_type: 'task_blank_chat',
        created: 1704067200,
      },
      {
        emoji: '🚀',
        title: 'AI presentations',
        background_color: '#FCEBA5',
        task_prompt_type: 'task_ai_presentations',
        created: 1704067200,
      },
      {
        emoji: '✍️',
        title: 'Copywriting',
        background_color: '#F8C4B9',
        task_prompt_type: 'task_copy_content',
        created: 1714518000,
      },
      {
        emoji: '🧠️',
        title: 'Strategy',
        background_color: '#DAE3E2',
        task_prompt_type: 'task_creative_strategy',
        created: 1704067200,
      },
      {
        emoji: '🤔',
        title: 'Summarize content',
        background_color: '#DEC6E8',
        task_prompt_type: 'task_magic_insights',
        created: 1704067200,
      },
      {
        emoji: '🕹️',
        title: 'Fun with Gemma',
        background_color: '#F0C3A2',
        task_prompt_type: 'task_fun_with_gemma',
        created: 1704067200,
      },
      {
        emoji: '📸',
        title: 'Create images',
        background_color: '#A9DAD1',
        task_prompt_type: 'task_create_images',
        created: 1704067200,
      },
      {
        emoji: '💡',
        title: 'Brainstorming',
        background_color: '#E0E88C',
        task_prompt_type: 'task_brainstorm_ideas',
        created: 1704067200,
      },
    ]
  }
}

injected(
  ConversationsService,
  DI_TYPE.Api,
  DI_TYPE.EventBusService,
  DI_TYPE.MixpanelService,
  DI_TYPE.RouterService,
  DI_TYPE.AuthService,
)
