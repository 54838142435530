import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { errorResponseSchema, unprocessableContentResponseSchema, } from './error.js';
export const userReportFormSchema = z.object({
    isNotSafe: z.boolean(),
    isNotAccurate: z.boolean(),
    isNotHelpful: z.boolean(),
    message: z.string(),
});
export const userReportPayloadSchema = z.object({
    report: userReportFormSchema,
    content_id: z.string().uuid().optional(),
    message_id: z.string().uuid().optional(),
    gem_id: z.string().uuid().optional(),
    conversation_id: z.string().uuid().optional(),
    image_url: z.string().optional(),
});
export const userFeedbackFormSchema = z.object({
    type: z.string(),
    message: z.string(),
    plugin_version: z.string(),
    origin: z.string(),
});
export const userFeedbackPayloadSchema = z.object({
    feedback: userFeedbackFormSchema,
    conversation_id: z.string().uuid().optional(),
});
export const userFeedbackResponseSchema = z.object({
    id: z.string().uuid(),
});
const c = initContract();
export const contract = c.router({
    report: {
        method: 'POST',
        path: '/support/report',
        responses: {
            204: null,
            403: errorResponseSchema,
            404: errorResponseSchema,
            422: unprocessableContentResponseSchema,
        },
        body: userReportPayloadSchema,
        summary: 'Report a message, image or gem',
    },
    feedback: {
        method: 'POST',
        path: '/support/feedback',
        responses: {
            200: userFeedbackResponseSchema,
            403: errorResponseSchema,
            404: errorResponseSchema,
            422: unprocessableContentResponseSchema,
        },
        body: userFeedbackPayloadSchema,
        summary: 'Send feedback',
    },
});
