import mime from 'mime'

import CloseIcon from '@/assets/icons/close.svg?react'
import DocumentIcon from '@/assets/icons/document.svg?react'
import { Spinner } from '@/components/spinner/spinner'
import { cls } from '@/utils/utils'

import { IconButton } from '../icon-button/icon-button'

export type AttachmentProps = {
  name: string
  type: string
  isLoading: boolean
  className?: string
  onClose: (e: any) => void
}

export const Attachment = ({
  name,
  type,
  isLoading,
  className,
  onClose,
}: AttachmentProps) => {
  const loading = <Spinner className="h-6 w-4" type="grey" />

  const ext = mime.getExtension(type)?.toUpperCase().slice(0, 3)

  const documentIcon = (
    <>
      <div className="absolute ml-1 mt-3 text-center text-[5px] font-bold leading-[4px] text-white">
        {ext}
      </div>
      <DocumentIcon className="h-6 w-[18px]" />
    </>
  )

  const closeButton = !isLoading && (
    <IconButton className="h-4 w-5" icon={<CloseIcon />} onClick={onClose} />
  )

  return (
    <div
      className={cls(
        'inline-flex h-10 min-w-20 items-center justify-start gap-2 rounded-lg bg-neutral-100 px-2',
        className,
      )}
    >
      <div className="relative h-6 w-[18px]">
        {isLoading ? loading : documentIcon}
      </div>
      <div
        className={cls(
          'w-full truncate text-sm leading-tight',
          isLoading && 'text-neutral-400',
        )}
      >
        {name}
      </div>
      {closeButton}
    </div>
  )
}
