import { useCallback, useEffect, useRef } from 'react'

// typescriptified version of https://legacy.reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
export function useEventCallback<This, Args extends unknown[], Res>(
  fn: (...args: Args) => Res,
): (...args: Args) => Res {
  const ref = useRef<(...args: Args) => Res>(() => {
    throw new Error('Cannot call an event handler while rendering.')
  })

  useEffect(() => {
    ref.current = fn
  }, [fn])

  return useCallback(function (this: This, ...args: Args) {
    const fn = ref.current
    return fn.apply(this, args)
  }, [])
}
