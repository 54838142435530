import { z } from 'zod';
// the key of keys
// t = type
// l = label
// v = value
export const formSchema = z.object({
    t: z.literal('form'),
    template: z.string(),
    values: z.record(z.string(), z.any()),
    is_completed: z.boolean(),
});
export const buttonSchema = z.object({
    t: z.literal('button'),
    icon: z.string().optional(),
    l: z.string(),
    v: z.string().optional(),
    action: z.string().optional(),
});
export const choicesSchema = z.object({
    t: z.literal('choices'),
    v: z.array(buttonSchema),
});
export const blocksSchema = z.discriminatedUnion('t', [
    buttonSchema,
    choicesSchema,
    formSchema,
]);
