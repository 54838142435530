import { DeleteModal } from '@/pages/modals/delete'

interface IDeleteChatModal {
  onDelete: () => void
}

export function DeleteGemModal({ onDelete }: IDeleteChatModal) {
  return (
    <DeleteModal
      message="Are you sure you want to delete this gem?"
      onDelete={onDelete}
    />
  )
}
