import { injected } from 'brandi'

import { DI_TYPE } from '@/di.types.js'
import type {
  NounProjectRecentResponse,
  NounProjectSearchResponse,
} from '@/models/noun-project'
import type { ApiClient } from '@/services/api-client.js'

export interface SearchBody {
  query: string
  limit_to_public_domain: number
  thumbnail_size: number
  next_page?: string
}

export interface RecentSearchBody {
  limit_to_public_domain: number
  thumbnail_size: number
}

export class NounProjectRepository {
  constructor(private apiClient: ApiClient) {}

  public async getRecentIcons(
    { limit_to_public_domain, thumbnail_size }: RecentSearchBody,
    abortController?: AbortController,
  ): Promise<{
    data: NounProjectRecentResponse | undefined
    status: number
  }> {
    try {
      const { data, status } =
        await this.apiClient.get<NounProjectRecentResponse>(
          `/proxy/noun-project/v1/icons/recent?limit_to_public_domain=${limit_to_public_domain}&thumbnail_size=${thumbnail_size}`,
          { signal: abortController?.signal },
        )
      return { data, status }
    } catch (error: any) {
      if (error.code === 'ERR_CANCELED') {
        return { data: undefined, status: 200 }
      } else {
        return { data: undefined, status: 500 }
      }
    }
  }

  public async searchIcons(
    { query, limit_to_public_domain, thumbnail_size, next_page }: SearchBody,
    abortController?: AbortController,
  ): Promise<{
    data: NounProjectSearchResponse | undefined
    status: number
  }> {
    try {
      const { data, status } =
        await this.apiClient.get<NounProjectSearchResponse>(
          `/proxy/noun-project/v2/icon?query=${query}&limit_to_public_domain=${limit_to_public_domain}&thumbnail_size=${thumbnail_size}${
            next_page ? `&next_page=${next_page}` : ''
          }`,
          { signal: abortController?.signal },
        )
      return { data, status }
    } catch (error: any) {
      if (error.code === 'ERR_CANCELED') {
        return { data: undefined, status: 200 }
      } else {
        return { data: undefined, status: 500 }
      }
    }
  }

  public async trackDownload(id: number): Promise<{
    data: any
    status: number
  }> {
    try {
      const { data, status } = await this.apiClient.get<SearchBody>(
        `/proxy/noun-project/v2/icon/${id}/download`,
      )
      return { data, status }
    } catch (error: any) {
      return { data: null, status: 500 }
    }
  }
}

injected(NounProjectRepository, DI_TYPE.ApiClient)
