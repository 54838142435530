import GoogleIcon from '@/assets/icons/google.svg?react'
import { DI_TYPE } from '@/di.types'
import { env } from '@/env'
import { useViewModel } from '@/utils/view'

import { Button } from '../button/button'

const googleIdpId = env().VITE_GOOGLE_IDP_ID

interface ISocialLogin {
  authState: string
}

export const SocialLogin = ({ authState }: ISocialLogin) => {
  const vm = useViewModel(DI_TYPE.SocialLoginViewModel)

  return (
    <div>
      <Button
        size="large"
        variant="secondary"
        className="border-cerosGrey300 bg-cerosWhite hover:bg-cerosWhite text-cerosGrey600 relative h-[44px] w-full"
        onClick={() => vm.goToIdp(authState, googleIdpId, 'Google')}
      >
        <GoogleIcon
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="absolute left-5"
        />
        <span className="w-full">Continue with Google</span>
      </Button>
    </div>
  )
}
