import { useCallback } from 'react'

import { cls } from '@/utils/utils'

import type { Tab } from './toggle-context'
import { useToggleContext } from './toggle-context'

type ToggleButtonProps = {
  tab: Tab
  dataAutomation: string
  children: string
}

export const ToggleButton = ({
  tab,
  children,
  dataAutomation,
}: ToggleButtonProps) => {
  const { activeTab, handleClick } = useToggleContext()

  const onClick = useCallback(() => handleClick(tab), [tab, handleClick])

  return (
    <button
      className={cls(
        'flex h-full flex-1 cursor-pointer items-center justify-center rounded-lg text-sm font-medium',
        tab === activeTab && 'bg-cerosPrimary text-cerosWhite',
      )}
      onClick={onClick}
      data-automation={dataAutomation}
    >
      {children}
    </button>
  )
}
