import { observer } from 'mobx-react-lite'

import uploadIcon from '@/assets/images/upload-icon.svg'

type UploadAreaProps = {
  type?: 'chat' | 'gem'
}

export const UploadArea = observer(({ type = 'chat' }: UploadAreaProps) => {
  const title = `Drag and drop files to this ${type}`
  return (
    <div className="bg-cerosChatBg fixed left-0 top-0 z-[999] h-full w-full p-2">
      <div className="border-cerosPrimary flex h-full w-full flex-col justify-center rounded-xl border-[5px] border-dashed align-middle">
        <div className="flex w-[343px] flex-col items-center self-center">
          <img
            src={uploadIcon}
            alt="Upload file"
            title="Upload file"
            className="w-[104px] self-center"
          />
          <div className="text-cerosGrey500 mb-[16px] mt-[41px] text-2xl font-medium">
            {title}
          </div>
          <div className="text-cerosGrey400 text-sm font-medium">
            Supports {type === 'chat' ? 'PNG, JPG, ' : ''} PDF or plain text
            files
          </div>
        </div>
      </div>
    </div>
  )
})

UploadArea.displayName = 'UploadArea'
