import { injected } from 'brandi'
import { action, observable, runInAction } from 'mobx'

import { DI_TYPE } from '@/di.types.js'
import type { Auth0StartModel } from '@/models/auth.js'
import type { AuthRepository } from '@/repositories/auth.js'

import { BaseService } from './base-service.js'

export class Auth0Service extends BaseService {
  @observable startData: Auth0StartModel | undefined = undefined

  constructor(private authRepository: AuthRepository) {
    super()
  }

  @action.bound
  async start() {
    const { data, status } = await this.authRepository.startAuth0()

    if (status === 201 && data) {
      runInAction(() => {
        this.startData = data
      })

      return true
    }

    return false
  }

  @action.bound
  async waitForReadKeyDataToBePresent() {
    if (!this.startData) {
      throw new Error(
        'Service must be started before waiting for read key data',
      )
    }

    return this.authRepository.getAuth0AccessToken(this.startData.read_key)
  }
}

injected(Auth0Service, DI_TYPE.AuthRepository)
