import type {
  AttachmentPin,
  Conversation,
  ConversationMessage,
  ConversationMessageStream,
  Gem,
  InvokeToolType,
  TaskPromptType,
} from '@ceros/gemma-api-spec'
import { captureException } from '@sentry/react'
import { injected } from 'brandi'
import type { IReactionDisposer } from 'mobx'
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { match } from 'path-to-regexp'
import { createElement } from 'react'
import { toast } from 'react-hot-toast'

import { DI_TYPE } from '@/di.types.js'
import { env } from '@/env.js'
import type {
  ImageAttachmentObject,
  RenderableConversationMessage,
} from '@/models/conversation.js'
import { ShareModal } from '@/pages/modals/share.js'
import type { Api } from '@/services/api.js'
import { AuthenticationError, FatalError } from '@/services/api-client.js'
import type { AuthService } from '@/services/auth.js'
import type { WithStreaming } from '@/services/conversation-stream.js'
import { type ConversationStreamService } from '@/services/conversation-stream.js'
import type {
  ConversationRenamedEventData,
  ConversationUpdatedEventData,
} from '@/services/conversations.js'
import {
  ConversationsService,
  RENAME_SOURCE,
} from '@/services/conversations.js'
import type { EventBusPayload } from '@/services/event-bus.js'
import { type EventBusService, RegisteredEvents } from '@/services/event-bus.js'
import type { GemsService } from '@/services/gems.js'
import type {
  MessageOrigin,
  MixpanelService,
  ResultUsedType,
} from '@/services/mixpanel.js'
import type { ModalService } from '@/services/modal.js'
import type { RouterService } from '@/services/router.js'
import type { SidebarService } from '@/services/sidebar.js'
import type {
  CreateFeedbackForm,
  CreateReportForm,
  SupportService,
} from '@/services/support.js'
import type { UserService } from '@/services/user.js'
import type { Prompt } from '@/stores/prompt.js'
import { asyncRetry, delayedRejection } from '@/utils/async.js'
import {
  createPendingMessageFromBot,
  createPendingMessageFromUser,
  generatePendingMessageId,
  getMessageContentFirstType,
  getMessageContentTypes,
  PENDING_GENERATION_MESSAGE_ID,
} from '@/utils/message.js'
import {
  bound,
  copyRichTextToClipboard,
  copyToClipboard,
  createFileFromImageUrl,
  downloadFile,
  findLastIndex,
} from '@/utils/utils.js'

import { BaseViewModel } from './base-view-model.js'
import { DocumentViewModel } from './document.js'
import type {
  FileUploadViewModel,
  FileUploadViewModelFactory,
} from './file-upload.js'

export type TaskSelectEvent = {
  emoji: string
  title: string
  task_prompt_type: TaskPromptType
}

export type ConversationRouteState = {
  isExisting: boolean
  prompt: string | null
  expectGemmaResponse?: boolean
}

const STREAM_ERROR_TOAST_ID = 'stream-error'
export const STREAM_CONNECTION_ERROR_TOAST_ID = 'stream-connection-error'
const DEFAULT_HISTORY_CHUNK_SIZE = 100
const RECENT_MESSAGE_SEARCH_DEPTH = 100
const RESPONDING_DELAYED_INTERVAL_MS = 500
const STREAM_CONNECTION_MAX_RETRIES = 3
const CANCELLED_REQUEST_MESSAGE =
  'Error fetching updates, check your firewall settings'
const CANCELLED_REQUEST_TIMEOUT = 3000
// This is time for establishing connection, not completing the request
const CONNECTING_TOAST_DELAY = 2000

export class ConversationViewModel extends BaseViewModel {
  @observable id: string | undefined = undefined

  @observable currentConversation: Conversation | null = null
  @observable loadedHistory = false

  @observable messages: RenderableConversationMessage[] = []

  documentViewModel: DocumentViewModel

  @observable promptInputFocusCursor: Date | undefined = undefined
  @observable lastMessageUpdateAt: Date | undefined = undefined
  streamController: AbortController | undefined = undefined

  @observable fileUploadViewModel: FileUploadViewModel

  @observable respondingMessageIds: Set<string> = new Set()
  silencedMessageIds: Set<string> = new Set()
  toastChunkErrorId: string | undefined = undefined

  reactionDisposers: IReactionDisposer[] = []

  private delayedTimeoutId: ReturnType<typeof setTimeout> | undefined =
    undefined

  constructor(
    private api: Api,
    private streamService: ConversationStreamService,
    private mixpanelService: MixpanelService,
    public modalService: ModalService,
    private authService: AuthService,
    public routerService: RouterService,
    private eventBusService: EventBusService,
    private conversationsService: ConversationsService,
    private fileUploadFactoryVM: FileUploadViewModelFactory,
    private userService: UserService,
    private sidebarService: SidebarService,
    private prompt: Prompt,
    private gemsService: GemsService,
    private supportService: SupportService,
  ) {
    super()
    makeObservable(this)
    this.fileUploadViewModel = this.fileUploadFactoryVM(
      this.fileDropSuccess,
      this.fileDropFailed,
    )
    this.documentViewModel = new DocumentViewModel(
      this.api,
      this.mixpanelService,
    )
  }

  @action.bound
  setFactoryData(id: string | undefined) {
    if (id) {
      this.id = id
    }
  }

  @computed
  get initializing(): boolean {
    return !this.conversationsService.initialized
  }

  @computed
  get loadingConversation(): boolean {
    return !this.conversation || !this.loadedHistory
  }

  @computed
  get gem(): Gem | undefined {
    const gemId = this.currentConversation?.gem_id
    if (!gemId) return undefined
    return this.gemsService.gems?.find((gem) => gem.id === gemId)
  }

  private setCurrentConversation(
    conversation: Conversation | null | undefined,
  ) {
    if (!conversation) {
      this.routerService.replace('/conversations/not-found')
      return
    }
    runInAction(() => {
      this.currentConversation = conversation
    })
  }

  private updateCurrentSession(
    updatedConversation: ConversationUpdatedEventData,
  ) {
    if (!updatedConversation || !this.currentConversation) return

    const newConversation: Conversation = {
      ...this.currentConversation,
      ...updatedConversation,
    }

    this.setCurrentConversation(newConversation)
  }

  @action.bound
  onInit() {
    // TODO: This is a workaround for child (dependency) view model to mimic lifecycle used in hook. We need better way to automatically do this for view models in dependency list. [#503](https://github.com/opendesigndev/gemma-ui/issues/503)
    this.fileUploadViewModel._start()
    this.documentViewModel._start()

    this.reactionDisposers.push(
      reaction(
        () => this.conversationsService.initialized,
        async (conversationServiceInitialized) => {
          if (conversationServiceInitialized) {
            this.eventBusService.subscribe<ConversationRenamedEventData>(
              RegisteredEvents.ConversationRenamed,
              this.receiveNewName,
            )

            this.eventBusService.subscribe<Conversation>(
              RegisteredEvents.ConversationDeleted,
              this.onConversationDeleted,
            )

            this.eventBusService.subscribe<ConversationUpdatedEventData>(
              RegisteredEvents.ConversationUpdated,
              this.onConversationUpdated,
            )

            if (this.id) {
              const session = await this.conversationsService.fetchConversation(
                this.id,
              )

              this.setCurrentConversation(session?.conversation)
            }

            const { expectGemmaResponse } = this.checkState()

            // TODO: Will be refactored in the future. Waiting for the API to be ready (starts)
            // NOTE: When we can load a specific conversation we need a "registry" of conversations to avoid loading the same conversation twice switching pages

            if (
              this.abortController &&
              this.authService.isAuthenticated &&
              this.conversation
            ) {
              // add the message to the UI while we wait for Gemma to respond to a new conversation
              if (expectGemmaResponse) {
                this.addPendingMessage(
                  createPendingMessageFromBot(this.conversation.id, true),
                )
              }

              await asyncRetry(() => {
                return this.openStream()
              }, STREAM_CONNECTION_MAX_RETRIES).catch((err) => {
                toast.error(CANCELLED_REQUEST_MESSAGE, {
                  duration: Infinity,
                  id: STREAM_CONNECTION_ERROR_TOAST_ID,
                })
                captureException(err)
              })

              const loadedHistory = await this.loadMessageHistory()
              if (loadedHistory) {
                runInAction(() => {
                  this.loadedHistory = true
                })

                // If we are expecting a response from Gemma but already have one, we can assume that the conversation has already started.
                // This situation arises when we generate a predefined intro message for an AI presentations task, and the message is streamed back to the client before the conversation is loaded.
                if (
                  expectGemmaResponse &&
                  this.messages.some(
                    (msg) =>
                      msg.author.type === 'bot' &&
                      msg.id !== PENDING_GENERATION_MESSAGE_ID,
                  )
                ) {
                  this.respondingMessageIds.delete(
                    PENDING_GENERATION_MESSAGE_ID,
                  )
                  this.messages = this.messages.filter(
                    (msg) => msg.id !== PENDING_GENERATION_MESSAGE_ID,
                  )
                }

                this.trackChatStart()
                this.checkConversationName()
              }
            }

            // FIX ME: this is a super hacky way of setting the current conversation id on the sidebar, ideally all services could grab this from the router params, but we don't have a way to share that yet (will address post hotfix)
            runInAction(() => {
              this.sidebarService.currentConversationId = this.id
            })
          }
        },
        {
          fireImmediately: true,
        },
      ),
      reaction(
        () => this.responding,
        (responding) => {
          if (responding) {
            this.checkForDelay()
          } else {
            this.clearDelayTimeout()
          }
        },
      ),
    )

    reaction(
      () => this.currentConversation?.id,
      (id) => {
        // techdebt: we shouldn't need to manually sync state between services like this
        this.documentViewModel.conversationId = id
      },
    )

    reaction(
      () => this.loadedHistory,
      (bool) => {
        // techdebt: we shouldn't need to manually sync state between services like this
        this.documentViewModel.loadedHistory = bool
      },
    )

    reaction(
      () => this.documents.length,
      (length) => {
        const lastDocument = this.documents[length - 1]
        this.documentViewModel.onDocumentAdded(lastDocument)
      },
    )
  }

  @action.bound
  onDispose() {
    this.abortController?.abort()
    this.streamController?.abort()

    this.clearDelayTimeout()

    this.eventBusService.unsubscribe<ConversationRenamedEventData>(
      RegisteredEvents.ConversationRenamed,
      this.receiveNewName,
    )

    this.eventBusService.unsubscribe<Conversation>(
      RegisteredEvents.ConversationDeleted,
      this.onConversationDeleted,
    )

    this.eventBusService.unsubscribe<ConversationUpdatedEventData>(
      RegisteredEvents.ConversationUpdated,
      this.onConversationUpdated,
    )

    // dispose to make sure event listeners are cleaned up
    this.fileUploadViewModel._stop()
    this.documentViewModel._stop()

    // dispose of all reactions
    this.reactionDisposers.forEach((disposer) => disposer())
  }

  @action
  private onConversationDeleted = (payload: EventBusPayload<Conversation>) => {
    if (this.id === payload.detail.id) {
      this.routerService.goTo('/')
    }
  }

  private onConversationUpdated = (
    payload: EventBusPayload<ConversationUpdatedEventData>,
  ) => this.updateCurrentSession(payload.detail)

  @action.bound
  private receiveNewName(
    payload: EventBusPayload<ConversationRenamedEventData>,
  ) {
    if (this.conversation && payload.detail.id === this.conversation.id) {
      this.conversation.name = payload.detail.name
      this.conversation.emoji = payload.detail.emoji
    }
  }

  @action.bound
  reusePrompt(text: string, messageId: string) {
    if (!this.conversation) throw new Error('no conversation')

    const sanitizedMessageText = text.trim()
    this.prompt.text = sanitizedMessageText

    this.focusOnPromptInput()
    this.mixpanelService.trackMessageReused(
      this.conversation.id,
      messageId,
      sanitizedMessageText,
    )
  }

  @action.bound
  onEditorTextChange(value: string, msgId?: string, itemId?: string) {
    if (msgId && itemId) {
      this.messages = this.messages.map((msg) => {
        if (msg.id === msgId) {
          msg.items = msg.items.map((item) => {
            if (item.id === itemId && item.content.type === 'inline_document') {
              item.content.text = value
            }
            return item
          })
        }
        return msg
      })
      this.documentViewModel.onDocumentContentChange(value, msgId, itemId)
    } else {
      const cleanedValue = value.trim() === '<p></p>' ? '' : value
      this.documentViewModel.draft = cleanedValue
    }
  }

  copyTextItem = (itemId: string) => {
    let item: RenderableConversationMessage['items'][0] | undefined
    this.messages.some((msg) => {
      item = msg.items.find((it) => it.id === itemId)
      return !!item
    })
    if (
      item?.content.type === 'text' ||
      item?.content.type === 'inline_document'
    ) {
      const isDocument = item.content.type === 'inline_document'
      const copyContent = isDocument
        ? (html: string) => {
            return copyRichTextToClipboard(html)
          }
        : (text: string) => copyToClipboard(text)

      copyContent(item.content.text)
        .then(() => {
          toast.success('Copied to clipboard')
        })
        .catch(() => {
          toast.error('Error copying to clipboard')
        })
    }
  }

  @computed
  get documents() {
    return this.messages.filter((msg) => {
      return msg.items.some((it) => it.content.type === 'inline_document')
    })
  }

  @computed
  get currentDocument() {
    const index = this.documents.findIndex((msg) =>
      msg.items.some((it) => it.id === this.documentViewModel.currentItemId),
    )
    return {
      index,
      msg: this.documents[index] as RenderableConversationMessage | undefined,
    }
  }

  @computed
  get isNewDocumentLoading() {
    const { msg } = this.currentDocument
    return (
      msg?.renderMetadata.streaming ||
      this.documentViewModel.loaderPosition !== false
    )
  }

  @action.bound
  public setCurrentDocumentMessage(index: number) {
    const newItemId = this.documents[index]?.items.find(
      (it) => it.content.type === 'inline_document',
    )?.id
    this.documentViewModel.onDocumentChange(newItemId)
  }

  @action.bound
  private checkState() {
    const currentLocation = this.routerService.currentLocation
    const defaultState: ConversationRouteState = {
      isExisting: true,
      prompt: null,
      expectGemmaResponse: false,
    }

    if (currentLocation) {
      const { isExisting, prompt, expectGemmaResponse } =
        (currentLocation.state as ConversationRouteState) || defaultState

      if (this.conversation) {
        if (prompt !== null) {
          this.prompt.text = prompt
        }
      }

      return { isExisting, expectGemmaResponse, prompt }
    }

    return defaultState
  }

  @computed
  get conversation() {
    return this.currentConversation
  }

  @computed
  get responding() {
    return this.respondingMessageIds.size > 0
  }

  @computed
  get stealFocus() {
    const lastMessage = this.messages[this.messages.length - 1]
    if (!lastMessage) return true

    const isForm = lastMessage.items.some(
      (item) =>
        item.content.type === 'blocks_json' && item.content.blocks.t === 'form',
    )

    return !isForm
  }

  @computed
  get respondingDelayed() {
    const msSinceLastUpdate = this.lastMessageUpdateAt
      ? Date.now() - this.lastMessageUpdateAt.getTime()
      : Infinity

    return this.responding && msSinceLastUpdate > RESPONDING_DELAYED_INTERVAL_MS
  }

  @computed
  get isAuthenticated() {
    return this.authService.isAuthenticated
  }

  @computed
  get sidepanelContentType() {
    // FUTURE: other sidepanel views, like widgets, images maybe too
    if (
      // for beta make sure it's only for this task
      this.currentConversation?.task_prompt_type === 'task_copy_content' &&
      this.documentViewModel.isEditorOpen
    ) {
      return 'copy_and_content_tool'
    } else {
      return false
    }
  }

  get embedded() {
    return Boolean(
      match('/embed/(.*)', { decode: decodeURIComponent })(
        this.routerService.currentPath,
      ),
    )
  }

  @action.bound
  trackChatStart() {
    if (this.conversation) {
      this.mixpanelService.trackChatStart(this.conversation.id)
    }
  }

  @action.bound
  private async loadMessageHistoryChunk(
    conversationId: string,
    page: number,
    size: number,
  ) {
    const result = await this.api.conversations.getMessageHistory({
      params: { conversation_id: conversationId },
      query: { page, size },
    })

    if (result.status === 200) {
      return result.body
    } else {
      this.toastChunkErrorId = toast.error(
        'Failed to load conversation history',
        {
          duration: Infinity,
        },
      )
      return undefined
    }
  }

  @action
  private applyMessageHistoryChunk(data: { items: ConversationMessage[] }) {
    // messages come as date desc, reverse that
    const messagesInDateOrder = data.items.reverse()
    messagesInDateOrder.forEach((message) => {
      // merge the messages in, there could be dupes from the pagination or we may be reloading an existing chunk
      this.upsertMessageInStack(message)
    })
  }

  @action.bound
  async openStream(): Promise<void> {
    if (!this.conversation) return

    const conversationId = this.conversation.id
    const onMessageGenerating = this.streamAbortWrapper(
      this.onStreamMessageGenerating,
      undefined,
    )
    const onMessageFinished = this.streamAbortWrapper(
      this.onStreamMessageFinished,
      undefined,
    )
    const onError = this.streamAbortWrapper(this.onStreamError, false)
    const onPing = this.streamAbortWrapper(this.onStreamPing, undefined)
    const onHeartbeatMissed = this.streamAbortWrapper(
      this.onStreamHeartbeatMissed,
      undefined,
    )

    // Variable to dismiss already launched toasts from different scopes
    let connectingToastId

    const connection = new Promise<void>((resolve, reject) => {
      this.streamController = new AbortController()
      const callbacks = {
        onStart: () => {
          resolve()
        },
        onPing,
        onMessageGenerating,
        onMessageFinished,
        onHeartbeatMissed,
        onError,
      }
      this.streamService
        .connect(
          conversationId,
          callbacks,
          this.streamController,
          CANCELLED_REQUEST_TIMEOUT,
        )
        .catch((err) => reject(err))
    }).finally(() => {
      // If we are slow, but connected (quicker than CANCELLED_REQUEST_TIMEOUT), just remove toast
      if (connectingToastId) toast.dismiss(connectingToastId)
    })

    Promise.race([connection, delayedRejection(CONNECTING_TOAST_DELAY)]).catch(
      () => {
        connectingToastId = toast.loading('Connecting...', { icon: '⏳' })
      },
    )

    return connection
  }

  async reconnectStream() {
    this.streamController?.abort()
    this.openStream()

    // fetch recent history to patch any gaps
    this.loadMessageHistory(DEFAULT_HISTORY_CHUNK_SIZE, 1)
  }

  private streamAbortWrapper<T, A extends any[], R>(
    fn: (...args: A) => T,
    abortReturnValue: R,
  ) {
    return (...args: A) => {
      if (this.streamController) {
        return fn(...args)
      }

      return abortReturnValue
    }
  }

  @action.bound
  onStreamPing(_: string) {
    toast.remove(STREAM_ERROR_TOAST_ID)
  }

  @action.bound
  onStreamMessageGenerating(message: ConversationMessageStream) {
    if (
      this.silencedMessageIds.has(message.id) ||
      (message.responding_to_id &&
        this.silencedMessageIds.has(message.responding_to_id))
    ) {
      return
    }

    // for generating messages, created_at will be null, but we need to render it in the right place
    // FUTURE: backend should change so that everything has a created_date
    if (message.created_at === null) {
      message.created_at = message.updated_at
    }

    // look out for any pending generation messages
    this.patchPendingMessage(PENDING_GENERATION_MESSAGE_ID, message.id)

    // upsert message so we see it in the UI
    this.upsertMessageInStack(message)

    // forces a scroll on any message change
    this.lastMessageUpdateAt = new Date()
  }

  @action.bound
  onStreamMessageFinished(message: ConversationMessage) {
    if (!this.conversation) throw new Error('no conversation')
    // FUTURE: this will stop the message from updating on the screen if it has been silenced but on refresh it will still be there updated, not much we can do until we can abort the backend too
    if (!this.silencedMessageIds.has(message.id)) {
      this.upsertMessageInStack(message)

      // NOTE: forces a scroll on any message change
      this.lastMessageUpdateAt = new Date()
    }

    // stop waiting for a response for related messages
    this.patchPendingMessage(PENDING_GENERATION_MESSAGE_ID, message.id)
    this.respondingMessageIds.delete(message.id)
    message.responding_to_id &&
      this.respondingMessageIds.delete(message.responding_to_id)
    this.silencedMessageIds.delete(message.id)

    this.mixpanelService.trackResultCreated({
      chatId: this.conversation.id,
      messageId: message.id,
      messageContentTypes: getMessageContentTypes(message),
      respondingToId: message.responding_to_id,
    })
  }

  @action.bound
  onStreamHeartbeatMissed() {
    this.onStreamError(new Error('Heartbeat missed'))
    this.reconnectStream()
  }

  @action.bound
  onStreamError(error?: any): boolean {
    const message =
      error instanceof FatalError
        ? 'Error fetching updates, refresh this page to try again'
        : 'Error fetching updates, trying to reconnect'

    if (!(error instanceof AuthenticationError)) {
      // these errors will be retried after refresh so don't show them to user
      toast.error(message, {
        duration: Infinity,
        id: STREAM_ERROR_TOAST_ID,
      })
    }

    // assume we won't get more messages for pending responses
    this.respondingMessageIds.clear()

    return false
  }

  @action.bound
  async loadMessageHistory(
    chunkSize: number = DEFAULT_HISTORY_CHUNK_SIZE,
    maxChunks: number = Infinity,
  ): Promise<boolean> {
    if (!this.conversation) return false

    let chunkNumber = 0
    let moreMessages = true
    let wasAtLeastOneMessage = false

    // FUTURE: only grab the recent X messages, auto load more if the user scrolls up
    do {
      const data = await this.loadMessageHistoryChunk(
        this.conversation.id,
        chunkNumber,
        chunkSize,
      )

      if (!data) {
        return false
      }

      wasAtLeastOneMessage = wasAtLeastOneMessage || data.items.length > 0
      moreMessages = data.items.length >= chunkSize
      chunkNumber++

      if (!this.abortController) return false
      this.applyMessageHistoryChunk(data)
    } while (moreMessages && chunkNumber < maxChunks)

    toast.remove(this.toastChunkErrorId)
    return true
  }

  @action.bound
  upsertMessageInStack(
    message:
      | WithStreaming<ConversationMessage, false>
      | WithStreaming<ConversationMessageStream, true>,
  ) {
    const renderableMessage = {
      ...message,
      renderMetadata: {
        streaming: message.streaming,
        date: new Date(message.created_at ?? message.updated_at),
      },
    }

    let messageIndex = findLastIndex(this.messages, (existingMessage) => {
      return existingMessage.id === message.id
    })

    if (messageIndex > -1) {
      const existingMessage = this.messages[messageIndex]
      if (
        existingMessage.renderMetadata.date.getTime() ===
        renderableMessage.renderMetadata.date.getTime()
      ) {
        // if the message exists and the render date is the same, we don't need to move it in the array
        this.messages[messageIndex] = renderableMessage
        return
      }
    }

    // the date has changed, let's remove the message and reindex it as if it were a new message
    messageIndex > -1 && this.messages.splice(messageIndex, 1)

    // put the message in to the list in the correct place
    const firstOlderMessageIndex = findLastIndex(
      this.messages,
      (existingMessage) => {
        return (
          existingMessage.renderMetadata.date <=
          renderableMessage.renderMetadata.date
        )
      },
    )

    const insertIndex = firstOlderMessageIndex + 1
    this.messages.splice(insertIndex, 0, renderableMessage)
  }

  @computed
  get hasTaskDefined() {
    return typeof this.conversation?.task_prompt_type === 'string'
  }

  @action.bound
  addPendingMessage(message: RenderableConversationMessage) {
    // mark the message as responding so we can disable user input
    this.respondingMessageIds.add(message.id)
    this.upsertMessageInStack(message)
  }

  @action.bound
  patchPendingMessage(pendingMessageId: string, persistedMessageId: string) {
    // replace the pending message ID with the persisted one for tracking responding state
    // note that the persisted message may have already come down for the stream, so account for that by removing the pending message
    let pendingIndex = findLastIndex(
      this.messages,
      (existingMessage) => {
        return existingMessage.id === pendingMessageId
      },
      RECENT_MESSAGE_SEARCH_DEPTH,
    )

    let persistedIndex = findLastIndex(
      this.messages,
      (existingMessage) => {
        return existingMessage.id === persistedMessageId
      },
      RECENT_MESSAGE_SEARCH_DEPTH,
    )

    if (pendingIndex > -1 && persistedIndex === -1) {
      this.messages[pendingIndex].id = persistedMessageId
      this.respondingMessageIds.add(persistedMessageId)
    } else if (pendingIndex > -1 && persistedIndex > -1) {
      this.messages.splice(pendingIndex, 1)
    }

    this.respondingMessageIds.delete(pendingMessageId)
  }

  @action.bound
  errorPendingMessage(pendingMessageId: string) {
    let messageIndex = findLastIndex(this.messages, (existingMessage) => {
      return existingMessage.id === pendingMessageId
    })

    if (messageIndex) {
      this.messages[messageIndex].items.push({
        id: generatePendingMessageId('user'),
        content: {
          type: 'error',
          text: 'Unable to save message',
        },
      })
    }
  }

  @computed
  get chatTitle() {
    if (!this.conversation) {
      return `New Chat ${new Date().toDateString()}`
    }

    return !!this.conversation.emoji.trim()
      ? `${this.conversation.emoji} ${this.conversation.name}`
      : this.conversation.name
  }

  @computed
  get isSendDisabled() {
    return this.responding
  }

  @computed
  get userData() {
    return this.userService.userData
  }

  @action.bound
  async sendMessageText(value: string, origin: MessageOrigin) {
    if (this.isSendDisabled || !this.conversation || !this.authService.auth) {
      return
    }
    const conversationId = this.conversation.id

    // add the message to the UI while we wait for persistence, update the ID when we get it back
    const newMessage = createPendingMessageFromUser(
      conversationId,
      this.authService.auth.data.user,
      [
        {
          id: generatePendingMessageId('user'),
          content: { type: 'text', text: value },
        },
      ],
    )

    this.addPendingMessage(newMessage)
    const context = this.documentViewModel.textEditorContext
      ? {
          text_editor: this.documentViewModel.textEditorContext,
        }
      : undefined
    const result = await this.api.conversations.postTextMessage({
      params: { conversation_id: conversationId },
      body: {
        content: value,
        context,
      },
    })

    if (result.status === 201) {
      this.patchPendingMessage(newMessage.id, result.body.message_id)
      if (origin !== 'TASK') {
        const textEditorContext = context?.text_editor.draft
          ? { hasDraft: true }
          : context?.text_editor.selection
            ? { hasSelection: true }
            : undefined
        // Tasks are tracked as 'Task Sent'
        this.mixpanelService.trackNewMessage({
          chatId: conversationId,
          messageId: result.body.message_id,
          messageLength: value.length,
          messageOrigin: origin,
          textEditorContext,
        })
      }
    } else {
      this.errorPendingMessage(newMessage.id)
    }

    this.checkConversationName()
  }

  checkConversationName() {
    // give the conversation an AI name if it has the default name and has at least 2 messages, including one from the user
    if (
      this.messages.length >= 2 &&
      this.conversation?.name &&
      /^Chat on [A-Za-z]+ ([0-9]){1,2}$/.test(this.conversation.name) &&
      this.messages.some((msg) => msg.author.type === 'user')
    ) {
      this.generateAndUpdateConversationName()
    }
  }

  @action.bound
  async stopGenerating() {
    if (!this.conversation) return
    const conversationId = this.conversation.id
    const idsToStop = Array.from(this.respondingMessageIds.values())

    try {
      const requests = idsToStop.map((respondingMessageId) => {
        return this.api.conversations.stopGenerating({
          params: { conversation_id: conversationId },
          body: { responding_to: respondingMessageId },
        })
      })
      await Promise.all(requests)
    } catch (err) {
      captureException(err)
      toast.error('Failed to stop generating messages')
    }

    this.mixpanelService.trackStopGeneratingClicked(
      this.conversation.id,
      idsToStop,
    )
  }

  private async generateAndUpdateConversationName() {
    if (!this.conversation) throw new Error('no conversation')

    const result = await this.api.conversations.getAiTitleSuggestion({
      params: { conversation_id: this.conversation.id },
      body: {},
    })

    if (result.status === 200) {
      await this.renameConversation(
        result.body.emoji,
        result.body.name,
        RENAME_SOURCE.AI_SUGGESTION,
      )
    }
  }

  @action.bound
  async renameConversation(
    emoji: string,
    name: string,
    changeSource: RENAME_SOURCE,
  ) {
    if (!this.id) {
      return
    }

    this.conversationsService.renameConversation(
      this.id,
      emoji,
      name,
      changeSource,
    )
  }

  @action.bound
  async createImageTask(tool: InvokeToolType) {
    if (!this.conversation || !this.authService.auth) return

    const conversationId = this.conversation.id
    const newMessage = createPendingMessageFromUser(
      conversationId,
      this.authService.auth.data.user,
      [],
    )

    this.addPendingMessage(newMessage)

    const result = await this.api.conversations.invokeTool({
      params: { conversation_id: this.conversation.id },
      body: { tool },
    })

    if (result.status === 201) {
      this.patchPendingMessage(newMessage.id, result.body.message_id)
      this.mixpanelService.trackToolInvoked(
        conversationId,
        result.body.message_id,
        tool.type,
      )
    } else {
      this.errorPendingMessage(newMessage.id)
    }
  }

  @action.bound
  async sendFeedback(type: CreateFeedbackForm['type'], message: string) {
    if (!this.conversation || !this.authService.auth?.data.user) {
      toast.error('You need to log in first')
      return
    }
    this.supportService.sendFeedback({
      conversationId: this.conversation.id,
      type,
      message,
    })
  }

  @action.bound
  async sendReport(
    report: CreateReportForm,
    message: RenderableConversationMessage,
  ) {
    if (!this.conversation || !this.authService.auth?.data.user) {
      toast.error('You need to log in first')
      return
    }
    this.supportService.sendReport(this.conversation.id, report, message)
  }

  @action.bound
  async openPlayground(message: RenderableConversationMessage) {
    window.open(
      `${env().VITE_API_ENDPOINT}/playgrounds/messages/${message.id}`,
      '_blank',
    )
  }

  @action.bound
  logResultUse(
    message: RenderableConversationMessage,
    resultUsedType: ResultUsedType,
  ) {
    const messageId = message.id
    if (this.conversation && this.authService.auth?.data.user) {
      this.mixpanelService.trackResultUsed({
        chatId: this.conversation.id,
        messageId,
        messageContentTypes: getMessageContentTypes(message),
        respondingToId: message.responding_to_id,
        resultChangeType: getMessageContentFirstType(message),
        resultOrigin: 'AI Generated',
        resultUsedType,
        integrationOrigin: 'Chat',
      })
    }
  }

  @action.bound
  async saveAttachment(file: File) {
    const url = await this.fileUploadViewModel.uploadFile(file)

    if (!url || !this.conversation || !this.authService.auth) {
      return
    }

    const conversationId = this.conversation.id

    const user = this.authService.auth.data.user

    const attachmentContent =
      this.fileUploadViewModel.buildAttachmentContentForFile(file, url)

    // add the message to the UI while we wait for persistence, update the ID when we get it back
    const newMessage = createPendingMessageFromUser(conversationId, user, [
      {
        id: generatePendingMessageId('user'),
        content: attachmentContent,
      },
    ])

    this.addPendingMessage(newMessage)

    const pin: AttachmentPin = {
      type: attachmentContent.type,
      url: attachmentContent.url,
    }
    const result = await this.api.conversations.pinAttachment({
      params: { conversation_id: conversationId },
      body: { pin },
    })

    if (result.status === 201) {
      this.patchPendingMessage(newMessage.id, result.body.message_id)
    } else {
      this.errorPendingMessage(newMessage.id)
    }
  }

  @action.bound
  async onFileInputChange(event) {
    if (!this.conversation) return

    if (this.fileUploadViewModel.validateFiles(event.target.files)) {
      const startTime = performance.now()

      await this.saveAttachment(event.target.files[0])

      const endTime = performance.now()
      const timeToCreate = endTime - startTime

      this.mixpanelService.trackFileUpload({
        chatId: this.id!,
        chatType: 'Existing',
        fileSize: event.target.files[0].size,
        fileFormat: event.target.files[0].type,
        uploadTime: timeToCreate,
        uploadType: 'click',
      })
    } else {
      this.mixpanelService.trackFileUpload({
        chatId: this.id!,
        chatType: 'Existing',
        fileSize: event.target.files[0].size,
        fileFormat: event.target.files[0].type,
        uploadTime: 0, // NOTE: Upload attempt was not successful
        uploadType: 'click',
        errorType: this.fileUploadViewModel.fileUploadErrorType,
      })
    }
  }

  @action.bound
  async fileDropSuccess(file: File) {
    const startTime = performance.now()

    await this.saveAttachment(file)

    const endTime = performance.now()
    const timeToCreate = endTime - startTime

    this.mixpanelService.trackFileUpload({
      chatId: this.id!,
      chatType: 'Existing',
      fileSize: file.size,
      fileFormat: file.type,
      uploadTime: timeToCreate,
      uploadType: 'drag-and-drop',
    })
  }

  @action.bound
  async fileDropFailed(file: File, reason?: string) {
    this.mixpanelService.trackFileUpload({
      chatId: this.id!,
      chatType: 'Existing',
      fileSize: file.size,
      fileFormat: file.type,
      uploadTime: 0, // NOTE: Upload attempt was not successful
      uploadType: 'drag-and-drop',
      errorType: reason ?? 'unknown',
    })
  }

  @action.bound
  async downloadImage(
    msg: RenderableConversationMessage,
    image: ImageAttachmentObject,
  ) {
    const file = await createFileFromImageUrl(image.url)
    this.logResultUse(msg, 'download')
    downloadFile(file)
  }

  @action.bound
  async copyImageUrl(
    msg: RenderableConversationMessage,
    image: ImageAttachmentObject,
  ) {
    try {
      await copyToClipboard(image.url)
      toast('Copied to clipboard')
      this.logResultUse(msg, 'copy-url')
    } catch {
      toast.error('Error copying to clipboard')
    }
  }

  @action.bound
  async openSharingModal() {
    if (!this.conversation) return

    const conversationId = this.conversation.id

    const onToggleSharing = () => {
      if (!this.conversation) return

      this.conversationsService.updateConversation(conversationId, {
        is_publicly_shared: !this.conversation.is_publicly_shared,
      })

      this.mixpanelService.trackChatSharingToggled({
        chatId: conversationId,
        linkOrigin: 'Button',
        shared: !this.conversation.is_publicly_shared,
      })
    }

    const onCopySharingUrl = () => {
      this.mixpanelService.trackChatSharingLinkCopied({
        chatId: conversationId,
        linkOrigin: 'Button',
      })
    }

    this.modalService.showCustom(
      'share-project',
      createElement(ShareModal, {
        type: 'Conversation',
        onToggle: onToggleSharing,
        onCopyUrl: onCopySharingUrl,
        isShared: this.conversation.is_publicly_shared ?? false,
        shareUrl: `${window.location.origin}/${ConversationsService.CONVERSATIONS_SLUG}/${conversationId}`,
      }),
    )

    this.mixpanelService.trackChatSharingOpened({
      chatId: conversationId,
      linkOrigin: 'Button',
    })
  }

  @action.bound
  focusOnPromptInput() {
    // bump the prompt input cursor to force focus
    this.promptInputFocusCursor = new Date()
  }

  @bound
  public scrollToBottom() {
    const scrollbarElement = document.getElementById('pageContainerScroll')
    if (scrollbarElement) {
      scrollbarElement.scrollTop = scrollbarElement.scrollHeight
    }
  }

  private checkForDelay() {
    this.clearDelayTimeout()
    this.delayedTimeoutId = setTimeout(() => {
      if (!this.responding) return
      this.checkForDelay() // Check again in case the user is still responding

      // This will trigger the computed property to re-evaluate and potentially cause a re-render in React.
      runInAction(() => {
        this.lastMessageUpdateAt = this.lastMessageUpdateAt
          ? new Date(this.lastMessageUpdateAt.getTime())
          : undefined
      })
    }, RESPONDING_DELAYED_INTERVAL_MS)
  }

  private clearDelayTimeout() {
    if (this.delayedTimeoutId) {
      clearTimeout(this.delayedTimeoutId)
      this.delayedTimeoutId = undefined
    }
  }
}

injected(
  ConversationViewModel,
  DI_TYPE.Api,
  DI_TYPE.ConversationStreamService,
  DI_TYPE.MixpanelService,
  DI_TYPE.ModalService,
  DI_TYPE.AuthService,
  DI_TYPE.RouterService,
  DI_TYPE.EventBusService,
  DI_TYPE.ConversationsService,
  DI_TYPE.FileUploadViewModel,
  DI_TYPE.UserService,
  DI_TYPE.SidebarService,
  DI_TYPE.Prompt,
  DI_TYPE.GemsService,
  DI_TYPE.SupportService,
)
