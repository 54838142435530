import { useIsPresent } from 'framer-motion'
import { useGlobalAudioPlayer } from 'react-use-audio-player'

import gemmaLogo from '../../assets/icons/gemma-logo.svg'
import { Button } from '../button/button'
import { QuestionaryPanel } from './questionary-panel'

interface IQuestionaryComplete {
  className?: string
  onDone: () => void
}

export function QuestionaryComplete({
  className,
  onDone,
}: IQuestionaryComplete) {
  const isPresent = useIsPresent()

  const { fade, stop } = useGlobalAudioPlayer()

  const handleDone = () => {
    fade(100, 0, 500)
    setTimeout(() => {
      stop()
    }, 500)

    onDone()
  }

  return (
    <QuestionaryPanel className={className}>
      <div className="mx-5 flex flex-col items-center gap-[52px]">
        <div
          className="h-14 w-14 bg-contain bg-center bg-no-repeat"
          style={{
            backgroundImage: `url("${gemmaLogo}")`,
          }}
        />
        <div className="text-cerosGrey500 text-center text-2xl">
          <p> Awesome, I think I've got enough to work with! </p>
          <p>You can always update these answers in your settings.</p>
        </div>
        <Button onClick={handleDone} size="large" disabled={!isPresent}>
          Let's go!
        </Button>
      </div>
    </QuestionaryPanel>
  )
}
