import { type ButtonHTMLAttributes } from 'react'

import ArrowBackIcon from '@/assets/icons/arrow-back.svg?react'

import { Button } from './button'

export const BackButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Button variant="white" className="h-8 w-8 min-w-8 p-0" {...props}>
      <ArrowBackIcon className="m-0 h-8 w-8 min-w-8" />
    </Button>
  )
}
