import type {
  Conversation,
  ConversationMessage,
  ConversationMessageStream,
} from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import toast from 'react-hot-toast'

import { DI_TYPE } from '@/di.types'
import type {
  ImageAttachmentObject,
  RenderableConversationMessage,
} from '@/models/conversation'
import type { Api } from '@/services/api'
import type { AuthService } from '@/services/auth'
import type { ConversationsService } from '@/services/conversations'
import type { MixpanelService, ResultUsedType } from '@/services/mixpanel'
import type { RouterService } from '@/services/router'
import {
  getMessageContentFirstType,
  getMessageContentTypes,
} from '@/utils/message'
import {
  bound,
  createFileFromImageUrl,
  downloadFile,
  findLastIndex,
} from '@/utils/utils'

import { BaseViewModel } from './base-view-model'

const DEFAULT_HISTORY_CHUNK_SIZE = 100

export class ConversationReadonlyViewModel extends BaseViewModel {
  @observable id: string | undefined = undefined

  @observable conversation: Conversation | undefined = undefined
  @observable messages: RenderableConversationMessage[] = []

  @observable loadedHistory = false
  @observable toastChunkErrorId: string | undefined = undefined

  constructor(
    private api: Api,
    private conversationsService: ConversationsService,
    private routerService: RouterService,
    private mixpanelService: MixpanelService,
    private authService: AuthService,
  ) {
    super()
    makeObservable(this)
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onDispose(): void {}

  @computed
  get isAuthenticated() {
    return this.authService.isAuthenticated
  }

  @action.bound
  setFactoryData(id: string | undefined) {
    // TODO: can be undefined? do we need homepage story to merge first
    if (id) {
      this.id = id
    }
  }

  @action.bound
  onInit() {
    reaction(
      () => this.conversationsService.initialized,
      async (conversationServiceInitialized) => {
        if (conversationServiceInitialized) {
          try {
            const session = this.id
              ? await this.conversationsService.fetchConversation(this.id)
              : null

            if (
              !session &&
              !this.isAuthenticated &&
              this.routerService.currentPath.includes('conversations/new')
            ) {
              this.routerService.replace('/signup')
              return
            }
            if (!session && !this.isAuthenticated) {
              this.routerService.replace('/login')
              return
            }
            if (!session) {
              this.routerService.replace('/conversations/not-found')
              return
            }

            this.conversation = session.conversation
          } catch {
            toast.error('Something went wrong')
            this.routerService.goTo('/')
            return
          }

          if (this.abortController) {
            const loadedHistory = await this.loadMessageHistory()
            if (loadedHistory) {
              runInAction(() => {
                this.loadedHistory = true
              })
            }
          }
        }
      },
      {
        fireImmediately: true,
      },
    )
  }

  @action.bound
  private async loadMessageHistoryChunk(
    conversationId: string,
    page: number,
    size: number,
  ) {
    const result = await this.api.conversations.getMessageHistory({
      params: { conversation_id: conversationId },
      query: { page, size },
    })

    if (result.status === 200) {
      return result.body
    } else {
      this.toastChunkErrorId = toast.error(
        'Failed to load conversation history',
        {
          duration: Infinity,
        },
      )
      return undefined
    }
  }

  @action.bound
  upsertMessageInStack(
    message: ConversationMessage | ConversationMessageStream,
  ) {
    const renderableMessage = {
      ...message,
      renderMetadata: {
        date: new Date(message.created_at ?? message.updated_at),
      },
    }

    let messageIndex = findLastIndex(this.messages, (existingMessage) => {
      return existingMessage.id === message.id
    })

    if (messageIndex > -1) {
      const existingMessage = this.messages[messageIndex]
      if (
        existingMessage.renderMetadata.date.getTime() ===
        renderableMessage.renderMetadata.date.getTime()
      ) {
        // if the message exists and the render date is the same, we don't need to move it in the array
        this.messages[messageIndex] = renderableMessage
        return
      }
    }

    // the date has changed, let's remove the message and reindex it as if it were a new message
    messageIndex > -1 && this.messages.splice(messageIndex, 1)

    // put the message in to the list in the correct place
    const firstOlderMessageIndex = findLastIndex(
      this.messages,
      (existingMessage) => {
        return (
          existingMessage.renderMetadata.date <=
          renderableMessage.renderMetadata.date
        )
      },
    )

    const insertIndex = firstOlderMessageIndex + 1
    this.messages.splice(insertIndex, 0, renderableMessage)
  }

  @action
  private applyMessageHistoryChunk(data: { items: ConversationMessage[] }) {
    // messages come as date desc, reverse that
    const messagesInDateOrder = data.items.reverse()
    messagesInDateOrder.forEach((message) => {
      // merge the messages in, there could be dupes from the pagination or we may be reloading an existing chunk
      this.upsertMessageInStack(message)
    })
  }

  @action.bound
  async loadMessageHistory(
    chunkSize: number = DEFAULT_HISTORY_CHUNK_SIZE,
    maxChunks: number = Infinity,
  ): Promise<boolean> {
    if (!this.conversation) return false

    let chunkNumber = 0
    let moreMessages = true

    // FUTURE: only grab the recent X messages, auto load more if the user scrolls up
    do {
      const data = await this.loadMessageHistoryChunk(
        this.conversation.id,
        chunkNumber,
        chunkSize,
      )

      if (!data) {
        return false
      }

      moreMessages = data.items.length >= chunkSize
      chunkNumber++

      if (!this.abortController) return false
      this.applyMessageHistoryChunk(data)
    } while (moreMessages && chunkNumber < maxChunks)

    toast.remove(this.toastChunkErrorId)
    return true
  }

  @action.bound
  async downloadImage(
    msg: RenderableConversationMessage,
    image: ImageAttachmentObject,
  ) {
    const file = await createFileFromImageUrl(image.url)
    this.logResultUse(msg, 'download')
    downloadFile(file)
  }

  @action.bound
  logResultUse(
    message: RenderableConversationMessage,
    resultUsedType: ResultUsedType,
  ) {
    const messageId = message.id
    if (!this.conversation) {
      throw new Error('conversation not found')
    }

    this.mixpanelService.trackResultUsed({
      chatId: this.conversation.id,
      messageId,
      messageContentTypes: getMessageContentTypes(message),
      respondingToId: message.responding_to_id,
      resultChangeType: getMessageContentFirstType(message),
      resultOrigin: 'AI Generated',
      resultUsedType,
      integrationOrigin: 'Chat',
    })
  }

  @bound
  public scrollToBottom() {
    const scrollbarElement = document.getElementById('pageContainerScroll')
    if (scrollbarElement) {
      scrollbarElement.scrollTop = scrollbarElement.scrollHeight
    }
  }
}

injected(
  ConversationReadonlyViewModel,
  DI_TYPE.Api,
  DI_TYPE.ConversationsService,
  DI_TYPE.RouterService,
  DI_TYPE.MixpanelService,
  DI_TYPE.AuthService,
)
