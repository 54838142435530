import './hamburger.css'

import { cls } from '@/utils/utils'

type HamburgerProps = {
  onClick: () => void
  isActive?: boolean
}

const Hamburger = ({ onClick, isActive }: HamburgerProps) => {
  const handleClick = (e) => {
    e.preventDefault()
    onClick()
  }

  return (
    <button
      className={cls(
        'bg-cerosWhite hover:border-cerosGrey300 shadow-navigationButton absolute left-4 top-6 z-40 h-10 w-10 rounded-lg',
      )}
      onClick={handleClick}
      data-automation="sidebar-toggle-btn"
    >
      <div className={cls('hamburger mx-auto', isActive && 'is-active')}>
        <span className="hamburger__bar"></span>
      </div>
    </button>
  )
}

export default Hamburger
