import { useIsPresent } from 'framer-motion'
import { observer } from 'mobx-react-lite'

import ArrowRightIcon from '@/assets/icons/arrow-right.svg?react'
import { Button } from '@/components/button/button'
import { GemmaQuestion } from '@/components/onboarding/gemma-question'
import { QuestionaryPanel } from '@/components/onboarding/questionary-panel'
import { UserAnswer } from '@/components/onboarding/user-answer'
import { BaseForm, type BaseFormField } from '@/utils/base-form'
import type { StepData } from '@/view-models/onboarding'

import { QuestionaryInputBind } from './bindings'

const formFields: Record<string, BaseFormField> = {
  name: {
    name: 'name',
    value: '',
    placeholder: 'Enter your name',
  },
}

const validationRule = (fields: typeof formFields) => {
  return fields.name.value.trim() !== ''
}

export const createOnboardingNameForm = () => {
  return new BaseForm(formFields, validationRule)
}

interface IOnboardingNameForm {
  data: StepData
  onSubmit: (formData: FormData) => void
}

export const OnboardingNameForm = observer(
  ({ data, onSubmit }: IOnboardingNameForm) => {
    const isPresent = useIsPresent()
    const { form } = data

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (form.isValid) {
        onSubmit(form.data)
      }
    }

    const handleOnChange = (e: React.FormEvent<HTMLFormElement>) => {
      const formData = new FormData(e.currentTarget)
      form.validate()
      form.updateFormData(formData)
    }

    return (
      <QuestionaryPanel>
        <form
          className="max-w-[640px]"
          autoComplete="off"
          onChange={handleOnChange}
          onSubmit={handleOnSubmit}
        >
          <GemmaQuestion value={data.question} />
          {form.isSubmitted && (
            <UserAnswer
              username={form.fields.name.value}
              value={form.fields.name.value}
            />
          )}

          <div className="relative mx-3 mt-9">
            <QuestionaryInputBind
              className="h-[60px] w-full pr-16"
              name={form.fields.name.name}
              value={form.fields.name.value}
              onChange={form.onChange}
              placeholder={form.fields.name.placeholder}
              disabled={form.isSubmitting || !isPresent}
              autoFocus
            />

            <Button
              size="large"
              className="absolute right-[10px] top-[10px] h-10 w-10 min-w-10 p-2 "
              type="submit"
              disabled={!form.isValid || form.isSubmitting || !isPresent}
            >
              <ArrowRightIcon className="h-6 w-6" />
            </Button>
          </div>
        </form>
      </QuestionaryPanel>
    )
  },
)
