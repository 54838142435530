import { cls } from '@/utils/utils'

interface ILoader {
  percentage: number
  is_failed: boolean
  text: string | null
}

export const Loader = ({ percentage, is_failed }: ILoader) => {
  const done = percentage === 100
  let label = `${percentage}%`
  if (is_failed) {
    label = '❌ Ooops!'
  } else if (done) {
    label = '🚀 Done!'
  }

  const barPercentage = is_failed ? 100 : percentage
  const inProgress = !done && !is_failed

  return (
    <div className="bg-cerosGrey100 border-cerosWhite my-2 grid w-full grid-cols-[1fr_max-content] items-center rounded-lg border-2 px-2 py-4 shadow-md">
      <div
        data-automation="loader-bar"
        className="bg-cerosGrey300 sd:min-w-[100px] h-[10px] w-full overflow-hidden rounded-md border-0"
      >
        <div
          style={{ width: `${barPercentage}%` }}
          className={cls(
            'h-[10px]',
            'rounded-[inherit]',
            'transition-[width_background-color] duration-1000',
            done
              ? 'bg-cerosPrimary'
              : is_failed
                ? 'bg-cerosAlertActive'
                : 'loader-bar-progressing-background',
          )}
        />
      </div>
      <div
        data-automation="loader-state"
        className={cls(
          'text-cerosGrey400 text-md ml-4 min-w-[35px]',
          inProgress && 'animate-pulse-subtle',
        )}
      >
        {label}
      </div>
    </div>
  )
}
