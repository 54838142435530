import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { LoadingDots } from '@/components/loading-dots/loading-dots'
import { DI_TYPE } from '@/di.types.js'
import { useViewModel } from '@/utils/view.js'

// This is the callback page for both the Okta authentication flow
export const AuthCallbackPage = observer(() => {
  const vm = useViewModel(DI_TYPE.AuthCallbackViewModel)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (!vm.isProcessingCallback) {
      vm.processCallback(searchParams)
    }
  }, [vm, searchParams])

  // using LogoLoader so auth flow is less flickery, this matches the app loading spinner
  return (
    <div className="flex h-full min-h-screen w-full items-center justify-center">
      <LoadingDots />
    </div>
  )
})

AuthCallbackPage.displayName = 'AuthCallbackPage'
