import { useCallback, useState } from 'react'

import { useIsMobile } from '@/utils/use-is-mobile'
import { useLongPress } from '@/utils/use-long-press'
import { cls } from '@/utils/utils'

import { TaskButtonMenu } from './task-button-menu'
import { TaskButtonMobileMenu } from './task-button-mobile-menu'

interface ITaskButton {
  className?: string
  backgroundColor?: string
  textColor: string
  title: string
  emoji?: string
  icon?: React.ReactNode
  onClick: () => void
  onEdit?: () => void
  onDelete?: () => void
  onShare?: () => void
}

export const TaskButton = ({
  emoji,
  title,
  onClick,
  onEdit,
  onDelete,
  onShare,
  className,
  backgroundColor,
  textColor,
  icon,
}: ITaskButton) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isMobile = useIsMobile()

  const onLongPress = useCallback(() => {
    if (!isMobile) return
    setIsMenuOpen(true)
  }, [setIsMenuOpen, isMobile])

  const longPressProps = useLongPress(onLongPress, {
    shouldPreventDefault: false,
  })

  return (
    <div
      className="group/gem relative"
      onContextMenu={(e) => {
        if (!isMobile) return

        e.preventDefault()
        e.stopPropagation()
        return false
      }}
    >
      <button
        className={cls(
          'h-[171px] w-[171px] overflow-hidden sm:h-[180px] sm:w-[180px]',
          'flex cursor-pointer items-center justify-center rounded-lg hover:brightness-[1.025]',
          'rounded-2xl p-4',
          className,
        )}
        style={{ backgroundColor }}
        onClick={onClick}
        {...longPressProps}
        data-automation={title}
      >
        <div className="mt-6 flex flex-col items-center justify-center gap-8">
          {icon ? (
            icon
          ) : (
            <div
              data-automation={`gem-emoji-${emoji}`}
              className="text-4xl text-[64px]"
            >
              {emoji}
            </div>
          )}

          <div
            className="relative flex max-w-full content-center items-center text-center align-middle text-base font-medium tracking-tight"
            style={{ color: textColor }}
          >
            <p
              className="w-full max-w-[160px] truncate"
              title={title}
              data-automation={`gem-title-${title}`}
            >
              {title}
            </p>
          </div>
        </div>
      </button>
      {(onDelete || onEdit || onShare) &&
        (isMobile ? (
          <TaskButtonMobileMenu
            title={title}
            isOpen={isMenuOpen}
            setIsOpen={setIsMenuOpen}
            onEdit={onEdit}
            onDelete={onDelete}
            onShare={onShare}
          />
        ) : (
          <TaskButtonMenu
            className="absolute right-2 top-2"
            isOpen={isMenuOpen}
            setIsOpen={setIsMenuOpen}
            onEdit={onEdit}
            onDelete={onDelete}
            onShare={onShare}
            data-automation="gem-actions"
          />
        ))}
    </div>
  )
}
