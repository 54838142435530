import { generateState } from '@okta/okta-auth-js'
import { useState } from 'react'

import { SocialLogin } from '../auth/social-login'
import { Button } from '../button/button'
import { LabeledTextInput } from '../input/labeled-text-input'

export interface SignUpEmailProps {
  email: string
  errorMessage: string | null
  isBusy: boolean
  onSubmit: (email: string) => void
}

export const SignUpEmail = ({
  email: defaultEmail,
  errorMessage,
  isBusy,
  onSubmit,
}: SignUpEmailProps): JSX.Element => {
  const [authState] = useState(generateState())
  const [email, setEmail] = useState(defaultEmail)

  return (
    <div className="flex w-full flex-col">
      <h1 className="text-cerosBlack mb-8 text-3xl font-bold md:mt-14">
        Sign up
      </h1>
      <SocialLogin authState={authState} />
      <div className="text-cerosGrey400 my-5 w-full text-center text-sm font-medium">
        or
      </div>
      <div className="text-cerosGrey400 mb-2 mt-7 text-sm font-medium">
        Email
      </div>
      <LabeledTextInput
        className=""
        value={email}
        autoFocus={true}
        onChange={(e) => {
          if (e.target.value.trim() !== email) setEmail(e.target.value)
        }}
        onKeyDown={(e: any) => {
          if (e.target.value.trim() === '') return
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            onSubmit(e.target.value)
          }
        }}
      />
      <div className="text-cerosDanger my-2 h-3 text-sm font-medium empty:h-0">
        {errorMessage}
      </div>
      <Button
        className="mb-16 mt-4 h-11 w-[120px]"
        variant="primary"
        disabled={!email || isBusy}
        onClick={() => onSubmit(email)}
      >
        Continue
      </Button>
    </div>
  )
}
