import type { TokenParams } from '@okta/okta-auth-js'
import type { OktaAuth } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { ProcessScreen } from '@/components/process-screen/process-screen.js'
import { DI_TYPE } from '@/di.types.js'
import { useViewModel } from '@/utils/view.js'

const redirectToSelfToGetToken = async (oktaAuth: OktaAuth) => {
  const tokenParams: TokenParams = {
    scopes: ['openid', 'email'],
  }
  // this will send the code to the redirectUri as a query param
  // It will automatically find the sessionToken from the cookie via the ceros okta login
  // If we aren't embedded in ceros we should not try to auto login this way
  await oktaAuth.token.getWithRedirect(tokenParams)
}
export const AutoLoginPage = observer(() => {
  const vm = useViewModel(DI_TYPE.LoginViewModel)
  const { oktaAuth } = useOktaAuth()

  useEffect(() => {
    // putting this here so the warning in this link doesn't keep popping up
    // https://github.com/okta/okta-react/issues/227#issuecomment-1167375982
    oktaAuth.options.restoreOriginalUri = undefined
    if (!vm.isAuthenticated && !vm.isBusy) {
      if (!vm.redirectingForAutoLoginAccessToken) {
        vm.setRedirectingForAutoLoginAccessToken(true)
        redirectToSelfToGetToken(oktaAuth).then(() => {
          vm.setRedirectingForAutoLoginAccessToken(false)
        })
      }
    }
  })

  if (vm.isAuthenticated) {
    return <Navigate to="/" replace={true} />
  }

  if (vm.errorWithAutoLogin) {
    return <Navigate to="/login" replace={true} />
  }

  return <ProcessScreen text="Logging In" />
})

AutoLoginPage.displayName = 'AutoLoginPage'
