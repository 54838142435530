import type { Stripe } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { injected } from 'brandi'
import type { IReactionDisposer } from 'mobx'
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { createElement } from 'react'
import { toast } from 'react-hot-toast'

import { DI_TYPE } from '@/di.types'
import { SubscriptionPlanModal } from '@/pages/modals/subscription-plan'
import { UpgradeFooter } from '@/pages/modals/subscription-plan/upgrade-footer'
import type { FeatureFlagService } from '@/services/feature-flag'
import type {
  BillingShownProperties,
  MixpanelService,
} from '@/services/mixpanel'
import type { ModalService } from '@/services/modal'
import type { RouterService } from '@/services/router'
import type { UserService } from '@/services/user'

import { BaseViewModel } from './base-view-model'

export class SubscriptionPlanViewModel extends BaseViewModel {
  @observable reactionDisposers: Array<IReactionDisposer> = []
  @observable upgradeSession: {
    url: string
    stripe_public_key: string
  } | null = null
  @observable managePortalUrl: string | null = null
  @observable stripeClient: Stripe | null = null

  public checkoutSessionIdParamName = 'checkout_session_id'

  constructor(
    private readonly modalService: ModalService,
    private readonly userService: UserService,
    private readonly routerService: RouterService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly mixpanelService: MixpanelService,
  ) {
    super()
    makeObservable(this)
  }

  protected async onInit() {
    reaction(
      () => [this.userService.initialized, this.featureFlagService.initialized],
      async () => {
        runInAction(() => (this.isActive = true))
      },
      { fireImmediately: true },
    )
  }

  protected onDispose(): void {
    this.reactionDisposers.map((disposer) => disposer())
  }

  @computed
  get planType() {
    return this.userService.subscription?.plan_type
  }

  @computed
  get hasBillingEnabled() {
    const billingFlag = this.featureFlagService.items.find(
      (item) => item.slug === 'billing',
    )

    return billingFlag && billingFlag.value
  }

  @computed
  get canUpgrade() {
    const hasUpgradablePlan =
      this.currentPlan == null || this.currentPlan.planType === 'free'

    return hasUpgradablePlan && this.hasBillingEnabled
  }

  @computed
  get currentPlan() {
    const plan = this.userService.subscription

    if (!plan) return null

    return {
      planType: plan.plan_type,
      remainingMessageCredits: plan.messages_remaining,
      totalAllowedMessageCredits: plan.total_messages_allowed,
      daysUntilcreditReset: plan.days_to_next_reset,
    }
  }

  @computed
  private get descriptionMessage() {
    if (!this.currentPlan) return ''

    const remainingMessageCredits = Number(
      this.currentPlan.remainingMessageCredits,
    )

    const daysToReset = this.currentPlan.daysUntilcreditReset
    const daysUntilReset = daysToReset
      ? ` Your message credits will renew in ${daysToReset} days.`
      : ''

    return remainingMessageCredits > 0
      ? `You have ${remainingMessageCredits} message${
          remainingMessageCredits === 1 ? '' : 's'
        } left.${daysUntilReset}`
      : `Need more time? ${daysUntilReset}`
  }

  @action
  private createCheckoutSession = async () => {
    if (this.stripeClient != null) {
      return
    }

    const session = await this.userService.getSubscriptionPlanUpgradeSession()
    if (!session) {
      toast.error('Could not create a checkout session')
      return null
    }

    this.upgradeSession = session
    this.stripeClient = await loadStripe(session.stripe_public_key)
  }

  @action
  private createPortalSession = async () => {
    if (this.managePortalUrl != null) {
      return
    }

    const sessionUrl = await this.userService.getManagementPortalSessionUrl()

    if (!sessionUrl) {
      toast.error('Could not create a plan management session')
      return
    }

    this.managePortalUrl = sessionUrl.url
  }

  @action
  completeActiveCheckoutSession = async (sessionId: string) => {
    const completed = await this.userService.completePlanUpgrade(sessionId)

    if (!completed) {
      toast.error('Could not complete checkout session')
      return
    }

    await this.userService.getSubscriptionData()
  }

  onUpgradeClicked = async () => {
    await this.createCheckoutSession()

    const stripeClient = this.stripeClient
    const stripeUrl = this.upgradeSession?.url

    if (stripeClient == null || stripeUrl == null) {
      return null
    }

    this.mixpanelService.trackUpgradeButtonClicked({
      billing_button_type: 'upgrade',
    })

    window.location.assign(stripeUrl)
  }

  onManageBillingClicked = async () => {
    await this.createPortalSession()

    const portalUrl = this.managePortalUrl

    if (!portalUrl) {
      return
    }

    this.mixpanelService.trackUpgradeButtonClicked({
      billing_button_type: 'manage',
    })

    this.routerService.goTo(portalUrl)
  }

  managePlanModal = (showConfetti: boolean) => {
    return createElement(SubscriptionPlanModal, {
      title: 'Manage your plan',
      description: createElement(UpgradeFooter),
      onClickUpgrade: this.onUpgradeClicked,
      onClickManagePlan: this.onManageBillingClicked,
      currentPlanType: this.currentPlan?.planType,
      showConfetti,
    })
  }

  /**
   *
   * @param source - passed to MixpanelService to identify from where it was triggered
   * @returns void
   */
  openPlanModal = async ({
    source,
    showConfetti = false,
  }: {
    source: BillingShownProperties['billing_source']
    showConfetti?: boolean
  }) => {
    const plan = this.currentPlan
    if (!plan || plan.planType !== 'free') {
      this.modalService.showCustom(
        'manage-plan-modal',
        this.managePlanModal(showConfetti),
      )

      this.mixpanelService.trackBillingShown({
        billing_source: 'plan-settings',
        billing_type: 'manage',
      })

      return
    }

    this.modalService.showCustom(
      'upgrade-modal',
      createElement(SubscriptionPlanModal, {
        title: 'Upgrade your plan',
        description: this.descriptionMessage,
        onClickUpgrade: this.onUpgradeClicked,
        onClickManagePlan: this.onManageBillingClicked,
        currentPlanType: plan.planType,
        showConfetti,
      }),
    )

    this.mixpanelService.trackBillingShown({
      billing_source: source,
      billing_type: 'upgrade',
    })

    const modal = this.modalService.findModalById('upgrade-modal')
    if (!modal) return

    // make sure we have fresh data
    await this.userService.getSubscriptionData()

    modal.content.props = {
      title: 'Upgrade your plan',
      description: this.descriptionMessage,
      onClickUpgrade: this.onUpgradeClicked,
      onClickManagePlan: this.onManageBillingClicked,
      currentPlan: this.currentPlan,
      showConfetti,
    }
  }
}

injected(
  SubscriptionPlanViewModel,
  DI_TYPE.ModalService,
  DI_TYPE.UserService,
  DI_TYPE.RouterService,
  DI_TYPE.FeatureFlagService,
  DI_TYPE.MixpanelService,
)
