import { cls } from '@/utils/utils.js'

interface IAppFooter {
  className?: string
}

export function AppFooter({ className }: IAppFooter) {
  return (
    <footer
      className={cls(
        'text-cerosGrey400 h-10 w-full text-center text-[8px] leading-10',
        className,
      )}
    >
      ceros
    </footer>
  )
}
