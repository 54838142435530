import ReuseIcon from '@/assets/icons/reuse.svg?react'
import { cls } from '@/utils/utils'

interface IReusePromptButton {
  className?: string
  onClick: () => void
}

export function ReusePromptButton({ className, onClick }: IReusePromptButton) {
  return (
    <button
      className={cls(
        'bg-cerosWhite shadow-button box-border h-7 w-7 rounded-lg border border-transparent p-[6px]',
        'hover:border-cerosGrey200 hover:shadow-none',
        className,
      )}
      onClick={onClick}
    >
      <ReuseIcon />
    </button>
  )
}
