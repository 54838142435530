import './main-layout.css'

import { cls } from '@/utils/utils'

import { HomePageButton } from '../button/home-page-button'
import Hamburger from '../hamburger/hamburger'

interface IMainLayout {
  sidebar: React.ReactNode
  content: React.ReactNode
  header?: React.ReactNode
  isSidebarOpen?: boolean
  onSidebarToggle: () => void
  isHomePage?: boolean
  onHomePageClick: () => void
}

export const MainLayout = ({
  sidebar,
  content,
  header,
  isSidebarOpen,
  onSidebarToggle,
  isHomePage,
  onHomePageClick,
}: IMainLayout) => {
  return (
    <div
      id="main-layout"
      className={cls(
        'h-screen',
        isSidebarOpen ? 'sidebar-open' : 'sidebar-closed',
      )}
    >
      {isSidebarOpen && (
        <div
          id="main-layout__backdrop"
          className={cls(
            'bg-cerosBlack/20 absolute z-40 h-full w-full lg:invisible',
            !isSidebarOpen && 'invisible',
          )}
          onClick={onSidebarToggle}
        />
      )}

      <div id="main-layout__sidebar" className="h-screen">
        {sidebar}
      </div>

      <Hamburger onClick={onSidebarToggle} isActive={isSidebarOpen} />
      <HomePageButton isVisible={!isHomePage} onClick={onHomePageClick} />

      <div id="main-layout__content" className="h-screen">
        <div id="main-layout__header" className="sticky z-30">
          <div
            id="main-layout__header-container"
            className="pointer-events-none absolute left-0 right-0 top-0"
          >
            {header}
          </div>
        </div>
        {content}
      </div>
    </div>
  )
}
