import type { PlayerEvent } from '@lottiefiles/react-lottie-player'
import { Player } from '@lottiefiles/react-lottie-player'
import { useEffect, useState } from 'react'
import { useGlobalAudioPlayer } from 'react-use-audio-player'

const audios = ['/audio/gemma-intro.mp3', '/audio/gemma-loop.mp3']
const lotties = {
  logo: '/lottie/logo-loading.json',
  bgIntro: '/lottie/onboarding-bg-intro.json',
  bgLoop: '/lottie/onboarding-bg-pulse.json',
}

interface IOnboardingMediaScene {
  onIntroComplete: () => void
  onIntroSoundComplete: () => void
}

export function OnboardingMediaScene({
  onIntroComplete,
  onIntroSoundComplete,
}: IOnboardingMediaScene) {
  const [state, updateState] = useState({
    source: lotties.bgIntro,
    loop: false,
  })

  const { load, fade } = useGlobalAudioPlayer()

  const handleOnEvent = (event: PlayerEvent) => {
    if (event === 'complete') {
      onIntroComplete()
      updateState({
        source: lotties.bgLoop,
        loop: true,
      })
    }
  }

  useEffect(() => {
    load(audios[0], {
      autoplay: true,
      initialVolume: 0,
      onend: () => {
        onIntroSoundComplete()
        load(audios[1], { autoplay: true, loop: true })
      },
    })
    fade(0, 100, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, fade])

  return (
    <div className="absolute inset-0 h-full w-full">
      <div className="relative h-full w-full">
        <Player
          autoplay
          loop={state.loop}
          src={state.source}
          className="absolute h-full w-full"
          onEvent={handleOnEvent}
          rendererSettings={{
            progressiveLoad: false,
            preserveAspectRatio: 'xMidYMid slice',
          }}
        />

        <Player
          autoplay
          src={lotties.logo}
          className="absolute left-1/2 top-1/2 h-[240px] w-[240px] -translate-x-1/2 -translate-y-1/2"
        />
      </div>
    </div>
  )
}
