import { cls } from '@/utils/utils'

export const SharedBadge = ({ className }: { className?: string }) => {
  return (
    <span
      className={cls(
        'bg-cerosGrey200 text-cerosGrey500 flex h-[20px] w-[49px] items-center justify-center rounded p-2 text-[10px] font-medium',
        className,
      )}
    >
      Shared
    </span>
  )
}
