import TagManager from 'react-gtm-module'

import { env } from '@/env'

export function initGoogleTagManager() {
  // Run GTM only on review deployments or prod.
  if (import.meta.env.DEV) return
  const tagManagerArgs = {
    gtmId: env().VITE_GTM_ID,
  }
  TagManager.initialize(tagManagerArgs)
}
