import { useIsPresent } from 'framer-motion'
import { observer } from 'mobx-react-lite'

import { Button } from '@/components/button/button'
import { GemmaQuestion } from '@/components/onboarding/gemma-question'
import { QuestionaryPanel } from '@/components/onboarding/questionary-panel'
import { UserAnswer } from '@/components/onboarding/user-answer'
import { BaseForm, type BaseFormField } from '@/utils/base-form'
import type { StepData } from '@/view-models/onboarding'

import { ChoicesBind } from './bindings'

const formFields: Record<string, BaseFormField> = {
  interests: {
    name: 'interests[]',
    value: [],
  },
}

const validationRule = (fields: typeof formFields) => {
  return fields.interests.value.length > 0
}

export const createOnboardingInterestsForm = () => {
  return new BaseForm(formFields, validationRule)
}

interface IOnboardingInterestsForm {
  username: string
  data: StepData
  onSubmit: (formData: FormData) => void
}

export const OnboardingInterestsForm = observer(
  ({ username, data, onSubmit }: IOnboardingInterestsForm) => {
    const isPresent = useIsPresent()
    const { form } = data

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (form.isValid) {
        onSubmit(form.data)
      }
    }

    const handleOnChange = (e: React.FormEvent<HTMLFormElement>) => {
      const formData = new FormData(e.currentTarget)
      form.validate()
      form.updateFormData(formData)
    }

    return (
      <QuestionaryPanel>
        <form
          autoComplete="off"
          onChange={handleOnChange}
          onSubmit={handleOnSubmit}
        >
          <GemmaQuestion value={data.question} />

          {form.isSubmitted && (
            <UserAnswer
              username={username}
              value={`My interests: ${form.fields.interests.value.join(', ')}`}
            />
          )}

          <fieldset className="mt-8 flex flex-wrap items-center justify-center gap-2 px-3">
            <ChoicesBind
              name={form.fields.interests.name}
              value={form.fields.interests.value}
              onChange={form.onChange}
              choices={data.choices || []}
              multi={true}
              disabled={form.isSubmitting || !isPresent}
            />
          </fieldset>

          <div className="mt-7 flex justify-center">
            <Button
              size="large"
              disabled={!form.isValid || form.isSubmitting || !isPresent}
            >
              Continue
            </Button>
          </div>
        </form>
      </QuestionaryPanel>
    )
  },
)
