import { Listbox } from '@headlessui/react'

import { cls } from '../../utils/utils'

export type Changes<T> =
  | { multiple: true; values: T[] }
  | { multiple: false; value: T }

interface ISelect<T> {
  options: T[]
  label?: string
  value: T
  name?: string
  multiple?: boolean
  by?: (keyof T & string) | ((a: T, z: T) => boolean)
  onChange?: (changes: Changes<T>) => void
  showOptionAs?: (option: T) => string
  selectClassName?: string
  disabled?: boolean
}

export function Select<T>({
  options,
  name,
  value,
  label,
  onChange,
  showOptionAs,
  multiple = false,
  by,
  selectClassName,
  disabled,
}: ISelect<T>) {
  return (
    <div className="relative flex flex-col">
      <Listbox
        name={name}
        value={value}
        by={by}
        onChange={(optionOrOptions: T | T[]) => {
          if (typeof onChange === 'function') {
            onChange(
              multiple
                ? {
                    multiple: true,
                    values: optionOrOptions as T[],
                  }
                : {
                    multiple: false,
                    value: optionOrOptions as T,
                  },
            )
          }
        }}
        disabled={disabled}
        multiple={multiple}
      >
        <Listbox.Button className="group w-full focus-visible:outline-none">
          {({ open }) => (
            <div
              className={cls(
                'bg-cerosWhite border-cerosGrey300 hover:bg-cerosGrey100 group-focus-visible:border-cerosPrimary flex flex-row items-center justify-between gap-2 rounded-lg border py-2.5 pl-4 pr-3',
                selectClassName,
                disabled && 'bg-cerosGrey200 text-cerosGrey400',
              )}
            >
              {label && (
                <div className="text-cerosGrey400 whitespace-nowrap text-sm font-medium leading-[28px] ">
                  {label}
                </div>
              )}
              <div className="grow-[2] text-left text-sm font-medium leading-7">
                {typeof showOptionAs === 'function'
                  ? showOptionAs(value)
                  : (value as string)}
              </div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                transform={open ? `scale(-1)` : ''}
              >
                <g id="Caret - Down">
                  <path
                    id="icon"
                    d="M10.5437 6H5.4563C5.05059 6 4.84741 6.56798 5.13429 6.90016L7.67799 9.84556C7.85583 10.0515 8.14417 10.0515 8.32201 9.84556L10.8657 6.90016C11.1526 6.56798 10.9494 6 10.5437 6Z"
                    fill="black"
                  />
                </g>
              </svg>
            </div>
          )}
        </Listbox.Button>
        <Listbox.Options className="group">
          <div className="bg-cerosWhite border-cerosGrey300 group-focus-visible:border-cerosPrimary absolute mt-1 w-full rounded-lg border p-0.5">
            {options.map((option, index) => (
              <Listbox.Option
                key={
                  option && typeof option === 'object' && 'id' in option
                    ? (option.id as string)
                    : index
                }
                value={option}
              >
                <div
                  className={
                    'hover:bg-cerosGrey100 ui-selected:bg-cerosGrey200 ui-active:bg-cerosGrey200 rounded-md py-2.5 pl-4 pr-3 text-sm'
                  }
                >
                  {typeof showOptionAs === 'function'
                    ? showOptionAs(option)
                    : (option as string)}
                </div>
              </Listbox.Option>
            ))}
          </div>
        </Listbox.Options>
      </Listbox>
    </div>
  )
}
