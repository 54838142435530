import type { Factory, TokenValue } from 'brandi'
import { useEffect, useMemo, useRef, useState } from 'react'

import { diContainer } from '@/di.container.js'
import { DI_TYPE } from '@/di.types.js'
import type { BaseViewModel } from '@/view-models/base-view-model.js'

export const GENERAL_ERROR_MESSAGE = `It looks like my services are temporarily unavailable. Please try again later, and I'll be back to help you.`

export type DrugEventValue = {
  clientX: number
  clientY: number
  data: { data: ArrayBuffer; filename: string } | null
  native: DragEvent | null
}

export function useViewModel<T extends BaseViewModel>(key: TokenValue<T>): T {
  const [vm] = useState(() => {
    return diContainer.get(key)
  })

  if (!vm) {
    throw new Error('ViewModel not found')
  }

  useEffect(() => {
    vm._start()
    return () => vm._stop()
  }, [vm])

  return vm
}

export function useFactoryViewModel<
  T extends BaseViewModel,
  Args extends unknown[],
>(key: TokenValue<Factory<T, Args>>, args: Args): T {
  const factory = useMemo(() => diContainer.get(key), [key])

  const [vm, setVm] = useState(() => {
    if (!factory) throw new Error('ViewModel factory not found')
    return factory(...args)
  })

  useEffect(() => {
    vm._start()
    return () => vm._stop()
  }, [vm])

  const lastDeps = useRef(args)

  useEffect(() => {
    if (!factory) throw new Error('ViewModel factory not found')

    const argsChanged =
      args.length !== lastDeps.current.length ||
      args.some((dep, index) => dep !== lastDeps.current[index])

    if (argsChanged) {
      setVm(factory(...args))
      lastDeps.current = args
    }
  }, [factory, args])

  return vm
}

export function useRouterService() {
  const routerService = useMemo(
    () => diContainer.get(DI_TYPE.RouterService),
    [],
  )
  return routerService
}

export function useAuthService() {
  const authService = useMemo(() => diContainer.get(DI_TYPE.AuthService), [])
  return authService
}

export function useSidebarService() {
  const sidebarService = useMemo(
    () => diContainer.get(DI_TYPE.SidebarService),
    [],
  )
  return sidebarService
}

export function useModalService() {
  const modalService = useMemo(() => diContainer.get(DI_TYPE.ModalService), [])
  return modalService
}

export function useUserService() {
  const userService = useMemo(() => diContainer.get(DI_TYPE.UserService), [])
  return userService
}

export function useOktaAuth() {
  const oktaAuth = useMemo(() => diContainer.get(DI_TYPE.OktaAuth), [])
  return oktaAuth
}

export function useFeatureFlagService() {
  const featureFlagService = useMemo(
    () => diContainer.get(DI_TYPE.FeatureFlagService),
    [],
  )
  return featureFlagService
}

export function useStorageService() {
  const storageService = useMemo(
    () => diContainer.get(DI_TYPE.StorageService),
    [],
  )
  return storageService
}
