import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import toast from 'react-hot-toast'

import { LoadingDots } from '@/components/loading-dots/loading-dots'
import { DI_TYPE } from '@/di.types.js'
import { useRouterService, useViewModel } from '@/utils/view.js'

// This is the callback page for both the Okta authentication flow
export const StripeCallbackPage = observer(() => {
  const vm = useViewModel(DI_TYPE.SubscriptionPlanViewModel)

  const routerService = useRouterService()
  const stripeCheckoutSessionIdParam = vm.checkoutSessionIdParamName
  const completeCheckoutSession = vm.completeActiveCheckoutSession

  useEffect(() => {
    const completeCheckoutSessionIfExists = async () => {
      const stripeCheckoutSessionId = routerService.getSearchParam(
        stripeCheckoutSessionIdParam,
      )

      if (!stripeCheckoutSessionId) {
        toast.error('No checkout session id found in the URL', {
          duration: Infinity,
        })
        return
      }

      // use ?checkout_session_id=test to test the UI without actually completing the session
      if (stripeCheckoutSessionId !== 'test') {
        await completeCheckoutSession(stripeCheckoutSessionId)
      }

      const returnPath = routerService.getSearchParam('return_path')

      routerService.deleteSearchParam(stripeCheckoutSessionIdParam)
      routerService.deleteSearchParam('return_path')

      routerService.goTo(returnPath ?? '/')
      vm.openPlanModal({ source: 'stripe-callback-page', showConfetti: true })
    }

    completeCheckoutSessionIfExists()
  }, [completeCheckoutSession, routerService, stripeCheckoutSessionIdParam, vm])

  return (
    <div className="flex h-full min-h-screen w-full items-center justify-center">
      <LoadingDots />
    </div>
  )
})

StripeCallbackPage.displayName = 'StripeCallbackPage'
