import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'

interface IOfflineIndicator {
  children: ReactNode
}

export const OfflineIndicator = ({ children }: IOfflineIndicator) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  useEffect(() => {
    const handleOffline = () => setIsOnline(false)
    const handleOnline = () => setIsOnline(true)

    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [])

  useEffect(() => {
    setIsOnline(isOnline)
  }, [isOnline])

  if (!isOnline) {
    return (
      <div className="bg-cerosWhite flex h-full w-full flex-col items-center justify-center">
        <section className="text-center font-medium">
          <h1 className="mb-4 text-base">Connection lost</h1>
          <p className="text-cerosDanger mb-1 text-xs">
            You are currently offline.
          </p>
          <p className="text-cerosDanger text-xs">
            Get back online to continue using the plugin.
          </p>
        </section>
      </div>
    )
  }

  return <>{children}</>
}
