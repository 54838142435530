import { MenuButton } from '@szhsin/react-menu'
import { useState } from 'react'

import MoreIcon from '@/assets/icons/more.svg?react'
import { cls, createFileFromImageUrl, downloadFile } from '@/utils/utils.js'

import { Menu, MenuItem } from '../menu/menu.js'

type UserNounProjectAttachment = {
  id: string
  icon_id: string | number
  url: string
  type: 'noun_project'
}
interface INounProjectAttachment {
  icon: UserNounProjectAttachment
}

export const NounProjectAttachment = ({ icon }: INounProjectAttachment) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleDownload = async () => {
    const file = await createFileFromImageUrl(icon.url)
    downloadFile(file)
  }

  return (
    <div className="group/nounProjectAttachment bg-cerosGrey100 border-cerosGrey200 relative flex h-[128px] w-[128px] items-center justify-center rounded-lg border">
      <img className="h-4/5 w-4/5" src={icon.url} alt={`icon ${icon.id}`} />
      <div
        data-automation="image-actions"
        className={cls(
          'invisible absolute right-1 top-1 group-hover/nounProjectAttachment:visible',
          isMenuOpen && 'visible',
        )}
      >
        <Menu
          align="start"
          direction="left"
          minSize="min-w-[100px]"
          arrow={true}
          arrowProps={{ className: 'w-2 h-2' }}
          viewScroll="close"
          menuButton={
            <MenuButton className="bg-cerosGrey100 active:bg-cerosGrey200 h-6 w-6 rounded-[4px] p-1">
              <MoreIcon />
            </MenuButton>
          }
          onMenuChange={({ open }) => setIsMenuOpen(open)}
        >
          <MenuItem onClick={handleDownload}>Download</MenuItem>
        </Menu>
      </div>
    </div>
  )
}
