import type { ReactNode } from 'react'

import { cls } from '@/utils/utils'

interface IQuestionaryPanel {
  children: ReactNode
  className?: string
}

export function QuestionaryPanel({ children, className }: IQuestionaryPanel) {
  return (
    <div
      className={cls(
        'bg-appBackground flex w-full max-w-[800px] items-center justify-center rounded-[20px]',
        className,
      )}
      style={{ boxShadow: '0px 0px 100px 0px rgba(0, 0, 0, 0.20)' }}
    >
      <div className="w-full px-[60px] py-[52px]">{children}</div>
    </div>
  )
}
