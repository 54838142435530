import type { ReactNode } from 'react'
import { forwardRef } from 'react'

import { cls } from '@/utils/utils.js'

export interface ITextInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'size' | 'type' | 'onChange'
  > {
  type?: 'text' | 'number' | 'password'
  size?: 'small' | 'large'
  variant?: 'normal' | 'alert' | 'success'
  icon?: ReactNode
  onChange?: (value: string) => void
}

export const TextInput = forwardRef<HTMLInputElement, ITextInputProps>(
  (
    {
      className,
      type = 'text',
      size = 'small',
      variant = 'normal',
      icon,
      value,
      onChange,
      ...rest
    },
    ref,
  ) => {
    return (
      <div className="relative w-full">
        <input
          className={cls(
            'bg-cerosWhite focus:bg-cerosWhite w-[100%] rounded-md border text-sm font-medium outline-none',
            'disabled:bg-cerosGrey100 disabled:text-cerosGrey400 disabled:hover:border-cerosGrey400 disabled:focus:border-cerosGrey400 disabled:active:border-cerosGrey400 disabled:cursor-not-allowed',
            size === 'small' && 'px-2 py-1',
            size === 'large' && 'p-2',
            variant === 'normal' &&
              'border-cerosGrey300 hover:bg-cerosGrey100 focus:border-cerosPrimary active:border-cerosPrimary',
            variant === 'alert' &&
              'border-cerosAlert hover:border-cerosAlertHover focus:border-cerosAlertActive active:border-cerosAlertActive',
            variant === 'success' &&
              'border-cerosSuccess hover:border-cerosSuccessHover focus:border-cerosSuccessActive active:border-cerosSuccessActive',
            icon && 'pl-11',
            className,
          )}
          onChange={(e) => onChange?.(e.target.value)}
          ref={ref}
          type={type}
          value={value}
          {...rest}
        />
        {icon ? (
          <div
            className={cls(
              'absolute left-0 top-0 flex h-5 w-5 items-center justify-center',
              size === 'small' && 'left-2 top-1',
              size === 'large' && 'left-2 top-2',
            )}
          >
            {icon}
          </div>
        ) : null}
      </div>
    )
  },
)
