import type OktaAuth from '@okta/okta-auth-js'
import { generateState } from '@okta/okta-auth-js'
import { injected } from 'brandi'
import { action, makeObservable } from 'mobx'

import { DI_TYPE } from '@/di.types.js'
import { env } from '@/env'
import type { MixpanelService } from '@/services/mixpanel.js'

type GoToIdpParams = {
  state?: string
  idpId?: string
  idpType?: string
}

export class OktaIdpService {
  constructor(
    private oktaAuth: OktaAuth,
    private mixpanelService: MixpanelService,
  ) {
    makeObservable(this)
  }

  @action.bound
  goToIdp(params: GoToIdpParams) {
    const {
      state = generateState(),
      idpId = env().VITE_GOOGLE_IDP_ID,
      idpType = 'Google',
    } = params

    this.mixpanelService.trackSocialIdpClick(idpId, idpType)
    this.oktaAuth.token.getWithRedirect({
      idp: idpId,
      prompt: 'login', // Force the user to authenticate, otherwise an existing Okta session takes precedence, see https://developer.okta.com/docs/reference/api/oidc/#parameter-details
      state,
    })
  }

  @action.bound
  async webfinger(email: string) {
    const response: any = await this.oktaAuth.webfinger({
      resource: `acct:${email}`,
      rel: 'okta:idp',
    })
    const link = response?.links?.[0]
    const idpType = link?.properties?.['okta:idp:type']
    const idpId = link?.properties?.['okta:idp:id']
    return link?.rel === 'okta:idp' && idpType && idpId
      ? { idpType, idpId }
      : null
  }
}

injected(OktaIdpService, DI_TYPE.OktaAuth, DI_TYPE.MixpanelService)
