import * as SentryReact from '@sentry/react'

import { env } from '@/env.js'
import { APP_VERSION, isProductionBuild } from '@/utils/utils.js'

const Sentry = SentryReact

export class SentryService {
  constructor() {
    const config: any = {
      dsn: env().VITE_SENTRY_DSN,
      enabled: isProductionBuild,
      integrations: [
        new SentryReact.BrowserTracing(),
        new SentryReact.Replay(),
      ],
      release: APP_VERSION,
      environment: env().VITE_APP_ENVIRONMENT,

      tracesSampleRate: 0.3,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    }
    SentryReact.init(config)
  }

  applyIdentity(user: SentryReact.User | null) {
    Sentry.setUser(user)
  }
}
