import { action, makeObservable, observable } from 'mobx'

export type BaseFormField = {
  name: string
  value: any
  placeholder?: string
}

export class BaseForm {
  @observable isValid = false
  @observable isSubmitting = false
  @observable isSubmitted = false
  @observable fields: Record<string, BaseFormField>
  @observable data: FormData = new FormData()

  constructor(
    fields: Record<string, BaseFormField>,
    private validationRule: (fields: Record<string, BaseFormField>) => boolean,
  ) {
    makeObservable(this)
    this.fields = fields

    this.validate()
  }

  @action.bound
  validate() {
    this.isValid = this.validationRule(this.fields)

    return this.isValid
  }

  @action.bound
  onChange(_field: string, value: any) {
    const field = _field.replace('[]', '')
    this.fields[field].value = value
  }

  @action.bound
  updateFormData(formData: FormData) {
    this.isSubmitted = false
    this.data = formData
  }
}
