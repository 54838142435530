import { useState } from 'react'

import { PASSWORD_ERROR as ERROR, passwordSchema } from '@/models/password'
import { cls } from '@/utils/utils'

import { Button } from '../button/button'
import { TextInput } from '../input/text-input'

export interface SignUpPasswordProps {
  email: string
  errorMessage: string | null
  isBusy: boolean
  onSubmit: (password: string) => void
}

export const SignUpPassword = ({
  email,
  errorMessage,
  isBusy,
  onSubmit,
}: SignUpPasswordProps): JSX.Element => {
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})

  const isSubmitDisabled =
    !password.length || !!Object.keys(errors).length || isBusy

  const handlePasswordChange = (password) => {
    setPassword(password)

    const validationResult = passwordSchema.safeParse(password)
    const errors = {}
    if (!validationResult.success) {
      for (const issue of validationResult.error.issues) {
        errors[issue.message] = true
      }
    }
    setErrors(errors)
  }

  const handleSubmitOnEnter = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      if (!isSubmitDisabled) onSubmit(e.target.value)
    }
  }

  return (
    <div className="flex w-full flex-col">
      <h1 className="text-cerosBlack text-3xl font-bold md:mt-14">
        Email verified
      </h1>
      <div
        className={cls(
          'text-cerosBlack px my-8 text-sm font-medium',
          errorMessage && 'text-cerosDanger',
        )}
      >
        {errorMessage ? errorMessage : `Choose password for ${email}`}
      </div>
      <div className="text-cerosBlack text-sm font-medium">
        Password must be
      </div>
      <ul className="text-cerosBlack mb-3 ml-5 list-inside list-disc text-sm font-medium">
        <li className={cls(errors[ERROR.LENGTH] && 'text-cerosDanger')}>
          at least 8 characters long
        </li>
        <li className={cls(errors[ERROR.UPPERCASE] && 'text-cerosDanger')}>
          contain uppercase letter
        </li>
        <li className={cls(errors[ERROR.LOWERCASE] && 'text-cerosDanger')}>
          contain lowercase letter
        </li>
        <li
          className={cls(errors[ERROR.NUMBER_OR_SYMBOL] && 'text-cerosDanger')}
        >
          contain number or symbol
        </li>
        <li className={cls(errors[ERROR.NO_WHITESPACE] && 'text-cerosDanger')}>
          not containing spaces
        </li>
      </ul>
      <div className="text-cerosGrey400 my-3 text-sm font-medium">Password</div>
      <TextInput
        type="password"
        onChange={handlePasswordChange}
        onKeyDown={handleSubmitOnEnter}
      />
      <Button
        className="mt-8 h-11 w-[120px]"
        variant="primary"
        disabled={isSubmitDisabled}
        onClick={() => onSubmit(password)}
      >
        Continue
      </Button>
    </div>
  )
}
