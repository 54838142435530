import { initContract } from '@ts-rest/core';
import { z } from 'zod';
const c = initContract();
export const checkoutSessionSchema = z.object({
    url: z.string(),
    stripe_public_key: z.string(),
});
const billingPlanTypeEnum = z.enum(['free', 'pro', 'enterprise']);
const freePlanSchema = z.object({
    user_id: z.string(),
    plan_type: z.literal(billingPlanTypeEnum['Values']['free']),
    days_to_next_reset: z.number(),
    messages_remaining: z.bigint(),
    total_messages_allowed: z.bigint(),
});
const proPlanSchema = z.object({
    user_id: z.string(),
    plan_type: z.literal(billingPlanTypeEnum['Values']['pro']),
    days_to_next_reset: z.null(),
    messages_remaining: z.null(),
    total_messages_allowed: z.null(),
});
const enterprisePlanSchema = z.object({
    user_id: z.string(),
    plan_type: z.literal(billingPlanTypeEnum['Values']['enterprise']),
    days_to_next_reset: z.null(),
    messages_remaining: z.null(),
    total_messages_allowed: z.null(),
});
export const billingUserSettingsSchema = z.discriminatedUnion('plan_type', [
    freePlanSchema,
    proPlanSchema,
    enterprisePlanSchema,
]);
export const portalSessionSchema = z.object({
    url: z.string(),
});
export const checkoutSessionCompleteSchema = z.object({
    is_complete: z.boolean(),
});
export const contract = c.router({
    createCheckoutSession: {
        method: 'POST',
        path: '/billing/create-checkout-session',
        body: z.object({ return_path: z.string() }),
        responses: {
            200: checkoutSessionSchema,
        },
        summary: 'Creates a Stripe checkout session',
    },
    checkoutSessionComplete: {
        method: 'POST',
        path: '/billing/checkout-session-complete',
        body: z.object({ checkout_session_id: z.string() }),
        responses: {
            200: checkoutSessionCompleteSchema,
        },
        summary: 'Notifies the server that the checkout session is complete',
    },
    createPortalSession: {
        method: 'POST',
        path: '/billing/create-portal-session',
        body: z.object({}),
        responses: {
            200: portalSessionSchema,
        },
        summary: 'Creates a Stripe portal session',
    },
    getBillingSettings: {
        method: 'GET',
        path: '/billing/user-settings',
        responses: {
            200: billingUserSettingsSchema,
        },
        summary: 'Gets the billing settings for a user',
    },
});
