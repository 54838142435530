import { observer } from 'mobx-react-lite'

import ClipboardIcon from '@/assets/icons/clipboard.svg?react'
import DownloadIcon from '@/assets/icons/download.svg?react'
import { Button } from '@/components/button/button.js'
import { IconButton } from '@/components/icon-button/icon-button.js'
import { ModalHeader } from '@/components/modal/modal-header.js'
import type { EditImageViewModel } from '@/view-models/edit-image.js'

const actionsButtonStyle =
  'w-7 h-7 p-[6px] hover:bg-[#e9ebec80] active:bg-[#dcdfe1cc] hover:backdrop-blur-md active:backdrop-blur-md'

interface IEditImageHeader {
  vm: EditImageViewModel
}

export const EditImageHeader = observer(({ vm }: IEditImageHeader) => {
  const handleDownload = () => vm.handleDownload()

  return (
    <ModalHeader
      actions={
        <>
          <IconButton
            className={actionsButtonStyle}
            icon={<ClipboardIcon />}
            onClick={vm.handleCopyToClipboard}
          />
          <IconButton
            className={actionsButtonStyle}
            icon={<DownloadIcon />}
            onClick={handleDownload}
          />
          <div className="hidden md:block">
            {vm.enabled ? (
              <Button tabIndex={1} disabled={vm.isBusy} onClick={vm.done}>
                Done
              </Button>
            ) : (
              <Button tabIndex={1} disabled={vm.isBusy} onClick={vm.edit}>
                Edit
              </Button>
            )}
          </div>
        </>
      }
      onClose={vm.close}
    />
  )
})

EditImageHeader.displayName = 'EditImageHeader'
