import { differenceInDays } from 'date-fns'
import type { ReactElement } from 'react'

import EffectsIcon from '@/assets/icons/effects.svg?react'
import { AnimatedBorder } from '@/components/animated-border/animated-border'
import { Pill } from '@/components/pill/pill'

const DAYS_TO_SHOW_AS_NEW = 15

type BetaTaskButtonProps = {
  created: number
  children: ReactElement
}

export const BetaTaskButton = ({ created, children }: BetaTaskButtonProps) => {
  const daysSinceCreated = differenceInDays(
    Date.now(),
    new Date(created * 1000),
  )

  if (daysSinceCreated < DAYS_TO_SHOW_AS_NEW) {
    return (
      <AnimatedBorder>
        <div className="relative rounded-2xl">
          <Pill
            className="absolute right-2 top-2 z-[1]"
            text="New"
            icon={<EffectsIcon className="fill-cerosWhite stroke-0" />}
          />
          {children}
        </div>
      </AnimatedBorder>
    )
  }

  return children
}
