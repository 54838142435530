import { cls } from '@/utils/utils'

interface ISidebar {
  className?: string
  children?: React.ReactNode
}

export const Sidebar = ({ className, children }: ISidebar) => {
  return (
    <div
      id="sidebar"
      className={cls(
        'bg-cerosWhite box-border flex h-full w-[300px] flex-col px-2 pb-6 pt-[26px] shadow-xl',
        className,
      )}
    >
      {children}
    </div>
  )
}
