import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'

import LogoIcon from '@/assets/icons/logo.svg?react'
import { ErrorContent } from '@/components/chat/error-content.js'
import { Message } from '@/components/chat/message.js'
import { MessageContext } from '@/components/chat/message-context.js'
import { UploadingMessage } from '@/components/chat/uploading-message.js'
import { LoadingDots } from '@/components/loading-dots/loading-dots.js'
import type { RenderableConversationMessage } from '@/models/conversation.js'
import type { ModalService } from '@/services/modal.js'
import { useModalService } from '@/utils/view.js'
import type { ConversationViewModel } from '@/view-models/conversation.js'

import { ReportModal } from '../modals/report.js'
import { MessageContent } from './message-content.js'

const handleOpenSendReport = (
  msg: RenderableConversationMessage,
  modal: ModalService,
  sendReport: ConversationViewModel['sendReport'],
) =>
  modal.show(
    <ReportModal onSubmit={(report) => sendReport(report, msg)} />,
    'Report',
  )

interface IMessagesView {
  vm: ConversationViewModel
}

export const MessagesView = observer(({ vm }: IMessagesView) => {
  const modal = useModalService()
  const totalMessages = vm.messages.length
  return (
    <>
      {vm.messages.map((msg, index) => {
        const firstMessage = index === 0
        const lastMessage = index === totalMessages - 1

        const isFromCurrentUser =
          msg.author.type === 'user' && msg.author.user.id === vm.userData.id

        const messageAuthorName =
          msg.author.type === 'user'
            ? isFromCurrentUser
              ? vm.userData.name
              : msg.author.user.name ?? msg.author.user.email
            : msg.author.name

        const messageInitials = isFromCurrentUser
          ? vm.userData.initials
          : messageAuthorName.slice(0, 2).toUpperCase()

        return (
          <Fragment key={msg.id}>
            <Message
              from={messageAuthorName}
              initials={messageInitials}
              time={msg.renderMetadata.date}
              avatar={
                msg.author.type === 'user' ? undefined : (
                  <LogoIcon className="h-[22.5px] w-[22.5px]" />
                )
              }
              onReport={
                msg.author.type === 'user'
                  ? undefined
                  : () => handleOpenSendReport(msg, modal, vm.sendReport)
              }
              onOpenPlayground={() => vm.openPlayground(msg)}
              disabled={msg.is_cloned}
            >
              <MessageContext.Provider
                value={{
                  isFirstMessage: firstMessage,
                  isLastMessage: lastMessage,
                }}
              >
                <MessageContent message={msg} vm={vm} />
              </MessageContext.Provider>
              {lastMessage && vm.respondingDelayed && <LoadingDots />}
            </Message>
          </Fragment>
        )
      })}

      {vm.fileUploadViewModel.fileUploading && (
        <UploadingMessage
          from={vm.userData.name}
          initials={vm.userData.initials}
        />
      )}
      {vm.fileUploadViewModel.showUploadErrorChatMessage && (
        <Message
          from={vm.userData.name}
          initials={vm.userData.initials}
          time={new Date()}
        >
          <ErrorContent
            dataAutomation="error-response"
            error={vm.fileUploadViewModel.uploadingErrorChatMessage}
          />
        </Message>
      )}
    </>
  )
})

MessagesView.displayName = 'MessagesView'
