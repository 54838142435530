import type { CreateOrUpdateGemRequest, Gem } from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import { action, computed, makeObservable, observable } from 'mobx'
import toast from 'react-hot-toast'
import { v4 as uuidv4 } from 'uuid'

import { DI_TYPE } from '@/di.types'
import type { FileProcessService } from '@/services/file-process'
import type { GemsService } from '@/services/gems'
import type { MixpanelService } from '@/services/mixpanel'
import type { ModalService } from '@/services/modal'
import { BG_GEM_COLORS, cycleColors } from '@/utils/color'

import { BaseViewModel } from './base-view-model'
import type {
  FileUploadViewModel,
  FileUploadViewModelFactory,
} from './file-upload'

export type GemState = {
  id?: string
  emoji?: string
  task_id?: string
  title?: string
  background_color?: string
  conversation_template_id?: string
  is_disabled?: boolean
  is_draft?: boolean
  prompt?: string
}

export type AttachmentStatus = 'uploading' | 'processing' | 'processed'
export type Attachment = {
  id: string
  mime_type: string
  filename: string
  url?: string
  status: AttachmentStatus
}

export type MODE = 'ADD' | 'EDIT'

const MAX_ATTACHMENTS = 100 // artificial limit to prevent UI overload

export class GemModalViewModel extends BaseViewModel {
  @observable fileUploadViewModel: FileUploadViewModel

  @observable gem: GemState = this.defaultGemState
  @observable emojiPickerVisible: boolean = false
  @observable isCreated: boolean = false // gem is created on BE (even with draft status)
  @observable gemToUpdate: Gem | undefined
  @observable attachments: Attachment[] = []

  constructor(
    private gemsService: GemsService,
    private modalService: ModalService,
    private mixpanelService: MixpanelService,
    private fileProcessService: FileProcessService,
    private fileUploadFactoryVM: FileUploadViewModelFactory,
  ) {
    super()
    makeObservable(this)

    this.fileUploadViewModel = this.fileUploadFactoryVM(
      async () => {},
      async () => {},
    )
    this.fileUploadViewModel.allowImages = false
  }

  onInit(): void {
    document.addEventListener('drop', this.onDrop)
  }

  onDispose(): void {
    document.removeEventListener('drop', this.onDrop)
  }

  @action.bound
  onDrop(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()

    const files = [...(event.dataTransfer?.files ?? [])]
    this.processFiles(files)
  }

  @action.bound
  setFactoryData(gemToUpdate?: Gem): void {
    this.gemToUpdate = gemToUpdate
    this.gem = gemToUpdate ? { ...gemToUpdate } : this.defaultGemState

    this.isCreated = Boolean(gemToUpdate)
    this.attachments =
      gemToUpdate?.attachments?.map((att) => ({
        ...att,
        status: 'processed',
      })) ?? []
  }

  @computed
  get defaultGemState(): GemState {
    return {
      title: '',
      prompt: '',
      background_color: cycleColors(
        BG_GEM_COLORS,
        this.gemsService?.gems?.length ?? 0,
      ),
      is_disabled: false,
      is_draft: true,
      emoji: '🤖',
    }
  }

  @computed
  get mode(): MODE {
    return this.gemToUpdate ? 'EDIT' : 'ADD'
  }

  @computed
  get isDataValid(): boolean {
    const { title, emoji, background_color, prompt } = this.gem
    const processing = this.attachments.some(
      (att) => att.status !== 'processed',
    )
    return Boolean(title && emoji && background_color && prompt && !processing)
  }

  @action.bound
  updateTitle(title: string) {
    this.gem = { ...this.gem, title }
  }

  @action.bound
  updateEmoji(emoji: string) {
    this.gem = { ...this.gem, emoji }
    this.emojiPickerVisible = false
  }

  @action.bound
  closeEmojiPicker = () => {
    this.emojiPickerVisible = false
  }

  @action.bound
  updateBackgroundColor(background_color: string) {
    this.gem = { ...this.gem, background_color }
  }

  @action.bound
  updatePrompt(prompt: string) {
    this.gem = { ...this.gem, prompt }
  }

  @action.bound
  toggleEmojiPicker() {
    this.emojiPickerVisible = !this.emojiPickerVisible
  }

  @action.bound
  async submit(): Promise<boolean> {
    this.fileProcessService.abortStream()
    this.gem.is_draft = false
    if (!this.isCreated) return this.createNewGem()
    return this.updateCurrentGem()
  }

  @action.bound
  cancel() {
    this.fileProcessService.abortStream()
    this.modalService.close()
  }

  @action.bound
  private async createNewGem(): Promise<boolean> {
    if (!this.isDataValid) return false

    const result = await this.gemsService.createGem(
      this.gem as CreateOrUpdateGemRequest,
    )

    if (result) this.mixpanelService.trackGemAdded(result)

    this.modalService.close()
    return Boolean(result)
  }

  @action.bound
  async deleteCurrentGem(): Promise<boolean> {
    if (!this.gemToUpdate || this.gem.id !== this.gemToUpdate.id) return false
    const result = await this.gemsService.deleteGem(this.gem.id)
    this.modalService.close()
    return result
  }

  @action.bound
  private async updateCurrentGem(): Promise<boolean> {
    if (!this.gem.id) return false
    const result = await this.gemsService.updateGem(
      this.gem.id,
      this.gem as CreateOrUpdateGemRequest,
    )
    if (result) {
      if (this.mode === 'ADD') this.mixpanelService.trackGemAdded(result)
      else this.mixpanelService.trackGemUpdated(result)
    }
    this.modalService.close()
    return Boolean(result)
  }

  private uploadFailed(id: string, file: any) {
    this.attachments = this.attachments.filter((att) => att.id !== id)
    this.mixpanelService.trackFileUpload({
      gemId: 'n/a',
      fileSize: file.size,
      fileFormat: file.type,
      uploadTime: 0,
      uploadType: 'click',
      errorType: this.fileUploadViewModel.fileUploadErrorType,
    })
  }

  private updateAttachmentStatus(id: string, status: AttachmentStatus) {
    this.attachments = this.attachments.map((att) =>
      att.id === id ? { ...att, status } : att,
    )
  }

  private updateAttachmentId(oldId: string, newId: string) {
    this.attachments = this.attachments.map((att) =>
      att.id === oldId ? { ...att, id: newId } : att,
    )
  }

  @action.bound
  async onUpload(event) {
    const files: File[] = [...event.target.files]
    event.target.value = '' // allow re-uploading the same file
    this.processFiles(files)
  }

  private async processFiles(files: File[]) {
    if (files.length + this.attachments.length > MAX_ATTACHMENTS) {
      toast.error(`You can upload up to ${MAX_ATTACHMENTS} files.`)
      return
    }

    if (!this.fileUploadViewModel.validateFiles(files)) {
      const errorTitle = this.fileUploadViewModel.uploadingErrorTitle
      if (errorTitle) toast.error(errorTitle)

      this.mixpanelService.trackFileUpload({
        gemId: 'n/a',
        fileSize: files[0].size,
        fileFormat: files[0].type,
        uploadTime: 0,
        uploadType: 'click',
        errorType: this.fileUploadViewModel.fileUploadErrorType,
      })
      return
    }

    if (!this.isCreated) {
      const result = await this.gemsService.createGem({
        ...this.gem,
        title: this.gem.title || 'draft',
        prompt: this.gem.prompt || 'draft',
      } as CreateOrUpdateGemRequest)
      this.gem.id = result?.id
      this.isCreated = true
    }

    files.forEach((file) => this.processFile(file))
  }

  private async processFile(file: File) {
    const attId = uuidv4()

    this.attachments.push({
      id: attId,
      filename: file.name,
      mime_type: file.type,
      status: 'uploading',
    })

    const startTime = performance.now()
    const url = await this.fileUploadViewModel.uploadFile(file)
    if (!url) {
      return this.uploadFailed(attId, file)
    }

    const gemId = this.gem.id
    const attResponse = await this.gemsService.pinAttachment(gemId!, url)
    if (!attResponse) {
      return this.uploadFailed(attId, file)
    }

    this.updateAttachmentStatus(attId, 'processing')
    try {
      const realAttId = await this.fileProcessService.isFileProcessed(
        attResponse.conversation_template_id,
        attResponse.message_id,
      )

      this.updateAttachmentId(attId, realAttId)
      this.updateAttachmentStatus(realAttId, 'processed')
      this.mixpanelService.trackFileUpload({
        gemId: this.gem.id,
        fileSize: file.size,
        fileFormat: file.type,
        uploadTime: performance.now() - startTime,
        uploadType: 'click',
      })
    } catch (e) {
      toast.error('Failed to upload file')
      return this.uploadFailed(attId, file)
    }
  }

  @action.bound
  async unpinAttachment(attachmentId: string) {
    const gemId = this.gem.id!
    const result = await this.gemsService.unpinAttachment(gemId, attachmentId)

    if (result) {
      this.attachments = this.attachments.filter(
        (att) => att.id !== attachmentId,
      )
      this.mixpanelService.trackUnpinAttachment(gemId, attachmentId)
    }
  }
}

injected(
  GemModalViewModel,
  DI_TYPE.GemsService,
  DI_TYPE.ModalService,
  DI_TYPE.MixpanelService,
  DI_TYPE.FileProcessService,
  DI_TYPE.FileUploadViewModel,
)
