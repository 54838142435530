import { injected } from 'brandi'
import format from 'date-fns/format'
import { action, makeObservable, observable, reaction } from 'mobx'
import toast from 'react-hot-toast'
import { z } from 'zod'

import { DI_TYPE } from '@/di.types'
import type { ConversationsService } from '@/services/conversations'
import type { RouterService } from '@/services/router'

import { BaseViewModel } from './base-view-model'

const studioIdSchema = z.coerce.number().nullable()

export class EmbedStudioNewViewModel extends BaseViewModel {
  @observable id: string | null = null

  constructor(
    private conversationsService: ConversationsService,
    private routerService: RouterService,
  ) {
    super()
    makeObservable(this)

    reaction(
      () => this.isActive,
      (isActive) => {
        if (isActive) {
          this.handleNewChatRoute()
        }
      },
    )
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onInit(): void {}

  onDispose(): void {}

  @action
  public async handleNewChatRoute() {
    const projectId = new URLSearchParams(
      this.routerService.currentLocation?.search,
    ).get('projectId')

    const result = studioIdSchema.safeParse(projectId)

    if (result.success) {
      const taskPromptType = 'task_ai_presentations'

      const data = await this.conversationsService.createConversation(
        {
          name: `Studio project ${format(new Date(), 'LLLL dd')}`,
          task_prompt_type: taskPromptType,
          studio_target_project_id: result.data || undefined,
        },
        { createSource: 'Studio embed' },
      )
      if (data) {
        const prompt = new URLSearchParams(
          this.routerService.currentLocation?.search,
        ).get('prompt')

        this.routerService.goTo(
          `/embed/studio/${data.id}${this.routerService.currentLocation?.search}`,
          {
            isExisting: false,
            prompt,
          },
        )
      }
    } else {
      // TODO: Check if we need special screen for this case
      toast.error('Invalid Studio ID')
    }
  }
}

injected(
  EmbedStudioNewViewModel,
  DI_TYPE.ConversationsService,
  DI_TYPE.RouterService,
)
