import { z } from 'zod';
export const searchBrandSchema = z.object({
    name: z.string().nullable(),
    domain: z.string(),
    claimed: z.boolean(),
    icon: z.string().nullable(),
    brandId: z.string(),
});
export const searchBrandResultSchema = z.array(searchBrandSchema);
export const brandLinkSchema = z.object({
    name: z.string(),
    url: z.string(),
});
export const brandLogoSchema = z.object({
    theme: z.string().nullable().optional(),
    formats: z.array(z.object({
        src: z.string(),
        size: z.number(),
    })),
    type: z.string(),
});
export const brandColorSchema = z.object({
    hex: z.string(),
    brightness: z.number(),
});
export const brandFontSchema = z.object({
    name: z.string(),
    type: z.string(),
});
export const brandImageSchema = z.object({
    formats: z.array(z.object({
        src: z.string(),
        size: z.number(),
    })),
});
export const brandSchema = z.object({
    name: z.string().nullable(),
    domain: z.string(),
    claimed: z.boolean(),
    description: z.string().nullable(),
    links: z.array(brandLinkSchema),
    logos: z.array(brandLogoSchema),
    colors: z.array(brandColorSchema),
    fonts: z.array(brandFontSchema),
    images: z.array(brandImageSchema),
});
