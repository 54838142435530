import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { errorResponseSchema, unprocessableContentResponseSchema, } from './error.js';
export const CheckVerificationCodeRequest = z.object({
    email: z.string().email(),
    code: z.string(),
});
export const CheckVerificationCodeResponse = z.object({
    valid: z.boolean(),
    locked: z.boolean(),
});
export const StartSignUpRequest = z.object({
    email: z.string().email(),
});
export const FinishSignUpRequest = z.object({
    email: z.string().email(),
    code: z.string(),
    password: z.string(),
});
export const RefreshTokenRequest = z.object({
    refresh_token: z.string(),
});
export const AuthEnvelopeResponse = z.object({
    access_token: z.string(),
    refresh_token: z.string(),
    expires_at: z.string(),
    user: z.object({
        id: z.string(),
        email: z.string(),
        // display_name: z.string(),
    }),
});
export const StartPasswordResetRequest = z.object({
    email: z.string().email(),
});
export const FinishPasswordResetRequest = z.object({
    email: z.string().email(),
    code: z.string(),
    password: z.string(),
});
const c = initContract();
export const contract = c.router({
    startSignUp: {
        method: 'POST',
        path: '/authentication/start-signup',
        responses: {
            204: null,
            400: errorResponseSchema,
            422: unprocessableContentResponseSchema,
            429: errorResponseSchema,
        },
        body: StartSignUpRequest,
        summary: 'Sign up with the given email',
    },
    checkActivationCode: {
        method: 'POST',
        path: '/authentication/check-activation-code',
        responses: {
            200: CheckVerificationCodeResponse,
            422: unprocessableContentResponseSchema,
            429: errorResponseSchema,
        },
        body: CheckVerificationCodeRequest,
        summary: 'Check if the given activation code is valid',
    },
    finishSignUp: {
        method: 'POST',
        path: '/authentication/v2/finish-signup',
        responses: {
            200: AuthEnvelopeResponse,
            400: errorResponseSchema,
            409: errorResponseSchema,
            422: unprocessableContentResponseSchema,
            429: errorResponseSchema,
        },
        body: FinishSignUpRequest,
        summary: 'Finish sign up with the given activation code',
    },
    refreshToken: {
        method: 'POST',
        path: '/authentication/v2/refresh',
        responses: {
            200: AuthEnvelopeResponse,
            400: errorResponseSchema,
            401: errorResponseSchema,
        },
        body: RefreshTokenRequest,
        summary: 'Issue new access and refresh tokens from a refresh token',
    },
    startPasswordReset: {
        method: 'POST',
        path: '/authentication/start-password-reset',
        responses: {
            204: null,
            400: errorResponseSchema,
            422: unprocessableContentResponseSchema,
            429: errorResponseSchema,
        },
        body: StartPasswordResetRequest,
        summary: 'Start password reset flow for the given email',
    },
    checkPasswordResetCode: {
        method: 'POST',
        path: '/authentication/check-password-reset-code',
        responses: {
            200: CheckVerificationCodeResponse,
            422: unprocessableContentResponseSchema,
            429: errorResponseSchema,
        },
        body: CheckVerificationCodeRequest,
        summary: 'Check if the code for password reset is valid',
    },
    finishPasswordReset: {
        method: 'POST',
        path: '/authentication/finish-password-reset',
        responses: {
            204: null,
            400: errorResponseSchema,
            409: errorResponseSchema,
            422: unprocessableContentResponseSchema,
            429: errorResponseSchema,
        },
        body: FinishPasswordResetRequest,
        summary: 'Set new password for the given email',
    },
});
