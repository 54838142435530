import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'

import { cls } from '@/utils/utils.js'

interface IIconButtonProps {
  dataAutomation?: string
  icon: ReactNode
}

export const IconButton = forwardRef<
  HTMLButtonElement,
  IIconButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, icon, dataAutomation, ...rest }, ref) => {
  return (
    <button
      data-automation={dataAutomation}
      className={cls(
        'hover:bg-cerosGrey100 active:bg-cerosGrey200 ring-cerosPrimary focus-visible:ring-cerosPrimary flex h-6 w-6 items-center justify-center rounded-md focus:ring-1 focus-visible:outline-none',
        className,
      )}
      ref={ref}
      {...rest}
    >
      {icon}
    </button>
  )
})

IconButton.displayName = 'IconButton'
