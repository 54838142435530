import { observer } from 'mobx-react-lite'

import { PromptField } from '@/components/chat/prompt-field/prompt-field'
import { LoadingDots } from '@/components/loading-dots/loading-dots'
import { BetaTaskButton } from '@/components/task-button/beta-task-button'
import { TaskButton } from '@/components/task-button/task-button'
import { Toggle } from '@/components/toggle/toggle'
import { ToggleButton } from '@/components/toggle/toggle-button'
import { UploadArea } from '@/components/upload-area/upload-area'
import { DI_TYPE } from '@/di.types'
import type { Prompt } from '@/stores/prompt'
import { getTextColor } from '@/utils/color'
import { cls } from '@/utils/utils'
import { useViewModel } from '@/utils/view'
import { MAX_PROMPT_LENGTH } from '@/view-models/prompt-input'

import { CustomGems } from './custom-gems'

export const HomePage = observer(() => {
  const vm = useViewModel(DI_TYPE.HomePageViewModel)
  const promptInputVM = useViewModel(DI_TYPE.PromptInputViewModel)

  const toggleHandler = () => vm.toggleCustomGemsTab()

  const onSubmit = (prompt: Prompt) => {
    if (!prompt.text) {
      return
    }

    vm.sendMessageText(prompt.text)
  }

  if (vm.initializing) {
    return (
      <div className="bg-cerosWhite absolute inset-0 z-30 flex items-center justify-center">
        <LoadingDots />
      </div>
    )
  }

  const tilesElements = vm.showCustomGemsTab ? (
    <CustomGems
      gems={vm.gems}
      onGemSelect={vm.onGemSelect}
      onGemAdd={vm.onGemAdd}
      onGemDelete={vm.onGemDelete}
      onGemEdit={vm.onGemUpdate}
      onGemShare={vm.onGemShare}
    />
  ) : (
    vm.tasks.map((task) => (
      <BetaTaskButton created={task.created} key={task.task_prompt_type}>
        <TaskButton
          emoji={task.emoji}
          title={task.title}
          backgroundColor={task.background_color}
          textColor={getTextColor(task.background_color)}
          onClick={() => vm.onTaskSelect(task)}
        />
      </BetaTaskButton>
    ))
  )

  return (
    <>
      {vm.fileUploadViewModel.draggingType && (
        <UploadArea type={vm.fileUploadViewModel.draggingType} />
      )}
      <div
        id="home-page-layout"
        className="relative h-screen max-h-screen overflow-hidden"
      >
        <div
          id="home-page"
          className="relative h-screen max-h-screen overflow-hidden"
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'minmax(min-content, 30%) 1fr min-content',
          }}
        >
          <>
            <div
              id="bottom-fade"
              className="pointer-events-none absolute bottom-3 z-10 w-full sm:bottom-1"
            >
              <div className="bg-fade-bottom progressive-blur-bottom h-[68px] w-full sm:h-[62px]" />
              <div className="bg-cerosChatBg h-[70px] w-full md:h-[95px]" />
            </div>
          </>
          <div className="flex items-end justify-center overflow-auto overflow-x-hidden">
            <div className="flex flex-col items-center px-4 pt-[64px] text-center">
              <div className="flex flex-col items-center justify-center">
                <h2
                  className={cls(
                    'font-object-sans mb-6 max-w-[80%] text-[28px] leading-[40px] md:max-w-[750px]',
                    'md:md-h:text-[46px] md:md-h:leading-[56px] lg:md-h:text-[50px] lg:md-h:leading-[56px]',
                  )}
                >
                  Welcome{vm.firstname ? ` ${vm.firstname}` : ''}!
                </h2>
                <p className="text-cerosGrey600 lg:md-h:text-base max-w-[39rem] whitespace-pre-wrap text-sm leading-[1.5]">
                  Choose one of the options below or start chatting with Gemma.
                </p>
              </div>
              <Toggle
                className="mt-10"
                defaultTab={vm.showCustomGemsTab ? 'right' : 'left'}
                onToggle={toggleHandler}
              >
                <ToggleButton tab="left" dataAutomation="default-gems-toggle">
                  Default Gems
                </ToggleButton>
                <ToggleButton tab="right" dataAutomation="custom-gems-toggle">
                  Your Gems
                </ToggleButton>
              </Toggle>
            </div>
          </div>
          <div className="custom-scrollbar chat-custom-scrollbar overflox-x-hidden box-border flex items-start justify-center overflow-auto pt-8 md:pt-12">
            <div className="lg:md-h:gap-4 inline-grid w-auto max-w-[768px] grid-cols-2 justify-items-start gap-3 pb-8 sm:grid-cols-3 md:grid-cols-4">
              {tilesElements}
            </div>
          </div>
          <div className="relative z-30 mx-auto w-full max-w-[960px] place-self-end self-end pb-4 pt-4 md:pb-7">
            <PromptField
              vm={promptInputVM}
              autoFocus
              dataAutomation="prompt-input"
              disabled={vm.isCreatingConversation}
              stealFocus={true}
              onUpload={vm.onFileInputChange}
              maxLength={MAX_PROMPT_LENGTH}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </>
  )
})

HomePage.displayName = 'HomePage'
