import type { ImageToolType } from '@/components/chat/generated-images-grid'

// FUTURE: this should come from the backend
// TODO: should not be null
export function resolveImageToolName(tool: string | null): string | undefined {
  switch (tool) {
    case 'noun_project':
      return 'The Noun Project'
    case 'remove_bg':
      return 'Remove BG'
    case null:
      return undefined
    default:
      return formatToolName(tool)
  }
}

function formatToolName(toolName: string): string {
  return toolName
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

// FUTURE: this should come from the backend
// TODO: should not be null
export function resolveImageToolType(tool: string | null): ImageToolType {
  switch (tool) {
    case 'noun_project':
    case 'unsplash':
      return 'search'
    case 'remove_bg':
    case 'upscaler':
      return tool
    case 'stable_diffusion':
      return 'generative'
    default:
      return 'search'
  }
}
