import { makeObservable, observable } from 'mobx'

export abstract class BaseService {
  @observable isBusy = false
  @observable initialized = false

  constructor() {
    makeObservable(this)
  }

  protected onInit() {}
}
