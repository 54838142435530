import {
  autoPlacement,
  offset,
  useClick,
  useFloating,
  useInteractions,
} from '@floating-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { Button } from '@/components/button/button'
import { EmojiPicker } from '@/components/emoji-picker/emoji-picker'
import { SidebarItemIcon } from '@/components/sidebar/sidebar-item-icon'
import type { IMenuItem } from '@/services/sidebar'
import { cls } from '@/utils/utils'
import { useModalService } from '@/utils/view'

interface IRenameConversationModal {
  item: IMenuItem
}

export const RenameConversationModal = ({ item }: IRenameConversationModal) => {
  const modalService = useModalService()
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false)

  const iconValue = item.icon.value
  const title = item.title
  const onRename = item.onRename

  const emojiRef = useRef<HTMLDivElement>(null)
  const emojiPickerRef = useRef<HTMLElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const { y, strategy, refs, context } = useFloating({
    middleware: [
      offset(16),
      autoPlacement({
        alignment: 'start',
      }),
    ],
    open: isEmojiPickerOpen,
    onOpenChange: (value) => setIsEmojiPickerOpen(value),
  })

  const click = useClick(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([click])

  const closeModal = useCallback(() => {
    modalService.close()
  }, [modalService])

  const closeRenamingAndSave = useCallback(() => {
    setIsEmojiPickerOpen(false)

    if (emojiRef.current && inputRef.current) {
      if (
        (iconValue !== emojiRef.current!.innerHTML ||
          title !== inputRef.current!.value) &&
        onRename
      ) {
        onRename(emojiRef.current!.innerHTML, inputRef.current!.value)
      }
    }

    closeModal()
  }, [iconValue, title, onRename, closeModal])

  const closeRenamingAndReset = useCallback(() => {
    setIsEmojiPickerOpen(false)
    closeModal()
  }, [closeModal])

  const onClickOutside = useCallback((event: any) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      event.stopPropagation()
      setIsEmojiPickerOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', onClickOutside, true)
    return () => document.removeEventListener('click', onClickOutside, true)
  }, [onClickOutside])

  return (
    <>
      <div className="mt-4 flex items-center justify-center gap-2">
        <button
          ref={refs.setReference}
          {...getReferenceProps()}
          onContextMenu={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          className="hover:cursor-pointer"
        >
          <SidebarItemIcon
            ref={emojiRef}
            icon={
              emojiRef.current?.innerHTML
                ? { type: 'emoji', value: emojiRef.current.innerHTML }
                : item.icon
            }
            className={cls(
              'h-[46px] w-[46px] rounded-md border border-solid p-2',
              isEmojiPickerOpen ? 'border-cerosPrimary' : 'border-cerosGrey300',
            )}
          />
        </button>
        {isEmojiPickerOpen && (
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              zIndex: 30,
            }}
            {...getFloatingProps()}
          >
            <EmojiPicker
              ref={emojiPickerRef}
              onEmojiSelect={(value) => {
                if (emojiRef.current) {
                  emojiRef.current.innerHTML = value
                }

                setIsEmojiPickerOpen(false)
              }}
            />
          </div>
        )}

        <input
          ref={inputRef}
          className={cls(
            'outline-cerosPrimary flex-1 truncate rounded-md bg-transparent p-2 text-lg font-normal outline hover:cursor-text',
          )}
          data-automation={`input-${item.id}`}
          defaultValue={item.title}
        />
      </div>
      <div className="mt-10 flex items-center justify-center gap-4">
        <Button variant="secondary" onClick={closeRenamingAndReset}>
          Cancel
        </Button>
        <Button variant="primary" onClick={closeRenamingAndSave} tabIndex={1}>
          Rename
        </Button>
      </div>
    </>
  )
}
