import { injected } from 'brandi'
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { pathToRegexp } from 'path-to-regexp'

import { DI_TYPE } from '@/di.types'

import type { ApiClient } from './api-client'
import type { AuthService } from './auth'
import type { FeatureFlagService } from './feature-flag'
import { LOGIN_RETURN_STORE_KEY, type SaveLoginReturn } from './login'
import type { LogRocketService } from './logrocket'
import type { MixpanelService } from './mixpanel'
import type { RouterService } from './router'
import type { SentryService } from './sentry'
import type { UserService } from './user'

export class InitService {
  @observable private isSplashScreenShowed = false

  constructor(
    private authService: AuthService,
    private apiClient: ApiClient,
    private routerService: RouterService,
    private featureFlagService: FeatureFlagService,
    private saveLoginReturn: SaveLoginReturn,
    private mixpanelService: MixpanelService,
    private logRocketService: LogRocketService,
    private sentryService: SentryService,
    private userService: UserService,
  ) {
    makeObservable(this)
    this.onInit()
  }

  private async startSplashScreen(showTime: number) {
    await new Promise((resolve) => setTimeout(resolve, showTime))
    runInAction(() => {
      this.isSplashScreenShowed = true
    })
  }

  @computed
  get initialized() {
    return (
      this.isSplashScreenShowed &&
      this.authService.initialized &&
      this.routerService.initialized &&
      this.userService.initialized &&
      this.featureFlagService.initialized
    )
  }

  @action.bound
  private onInit() {
    this.startSplashScreen(1000)
    this.applyAuthDataToServices()
    this.registerLoginReturnObserver()
  }

  private applyAuthDataToServices() {
    reaction(
      () => this.authService.auth,
      (auth) => {
        if (auth && auth.data.access_token) {
          this.apiClient.setToken(auth?.data.access_token)

          this.sentryService.applyIdentity({
            id: auth.data.user.id,
            email: auth.data.user.email,
          })

          this.mixpanelService.applyIdentity(
            auth.data.user.id,
            auth.data.user.email,
            this.authService.sessionId,
          )
          this.logRocketService.applyIdentity(
            auth.data.user.id,
            auth.data.user.email,
          )
        }
      },
    )
  }

  @action.bound
  private registerLoginReturnObserver() {
    reaction(
      () => this.routerService.currentUri,
      (uri, prevUri) => {
        const [url, search] = uri.split('?')
        const searchParams = new URLSearchParams(search)
        const loginReturn = searchParams.get(LOGIN_RETURN_STORE_KEY)

        if (loginReturn != null) {
          return this.saveLoginReturn({
            uri: loginReturn,
            createdAt: Date.now(),
          })
        }
        const saveReturn =
          pathToRegexp(url).test('/login') ||
          (pathToRegexp(url).test('/signup') &&
            prevUri.includes('conversations/new'))
        if (saveReturn) {
          this.saveLoginReturn({
            uri: prevUri,
            createdAt: Date.now(),
          })
        }
      },
    )
  }
}

injected(
  InitService,
  DI_TYPE.AuthService,
  DI_TYPE.ApiClient,
  DI_TYPE.RouterService,
  DI_TYPE.FeatureFlagService,
  DI_TYPE.SaveLoginReturn,
  DI_TYPE.MixpanelService,
  DI_TYPE.LogRocketService,
  DI_TYPE.SentryService,
  DI_TYPE.UserService,
)
