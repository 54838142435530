import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

interface IUserAnswer {
  username: string
  value: string
}

export function UserAnswer({ username, value }: IUserAnswer) {
  return (
    <div className="flex gap-3 p-3">
      <div className="relative h-12 w-12 rounded-lg bg-[#F88A8A]">
        <div
          className="text-cerosWhite absolute inset-0 flex items-center justify-center"
          style={{
            fontSize: '28px',
            lineHeight: '28px',
          }}
        >
          {username[0]}
        </div>
      </div>
      <div className="flex-1">
        <div className="text-cerosGrey600 text-base">{username}</div>
        <ReactMarkdown
          className="markdown-body prose prose-sm text-cerosGrey500 mt-3 max-w-none text-2xl"
          components={{
            p: ({ children, ...rest }) => (
              <p {...rest} style={{ margin: 0 }}>
                {children}
              </p>
            ),
          }}
        >
          {value}
        </ReactMarkdown>
      </div>
    </div>
  )
}
