import type { ReactNode } from 'react'
import { forwardRef } from 'react'
import FocusLock from 'react-focus-lock'

import { cls } from '@/utils/utils.js'

export type BaseModalProps = {
  backdrop?: boolean
  onClose?: () => void
}

interface ModalBackdropProps extends BaseModalProps {
  children: ReactNode
  kind?: string
  zIndex?: number
  closeOnClick?: boolean
}
export const ModalBackdrop = forwardRef<HTMLDivElement, ModalBackdropProps>(
  (
    {
      children,
      zIndex = 50,
      kind = 'small',
      backdrop,
      closeOnClick = true,
      onClose,
    }: ModalBackdropProps,
    ref,
  ) => {
    return (
      <FocusLock>
        <div
          ref={ref}
          id="modal-backdrop"
          className={cls(
            'absolute inset-0 flex h-full flex-col items-center justify-center px-4 py-5',
            kind && kind === 'full' && 'p-0',
            backdrop && 'bg-cerosWhite/40 backdrop-blur-xl',
          )}
          style={{ zIndex }}
          tabIndex={1}
          onClick={(e) => {
            if ((e.target as HTMLElement)?.id !== 'modal-backdrop') return
            if (backdrop && closeOnClick && onClose) {
              onClose()
              e.preventDefault()
            }
          }}
          onKeyDown={() => {}} // sonarcloud issue fix
        >
          {children}
        </div>
      </FocusLock>
    )
  },
)
