import { useRef } from 'react'
import { toast } from 'react-hot-toast'
import type { ReactMarkdownProps } from 'react-markdown/lib/complex-types'
import type { PluggableList } from 'react-markdown/lib/react-markdown.js'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown.js'
import RehypeHighlight from 'rehype-highlight'
import RehypeRaw from 'rehype-raw'
import RemarkGfm from 'remark-gfm'

import { isHrefForHost } from '@/utils/url'
import { cls, copyRichTextToClipboard, copyToClipboard } from '@/utils/utils.js'
import type { DrugEventValue } from '@/utils/view.js'

import { Button } from '../button/button'
import { ReusePromptButton } from '../reuse-prompt-button/reuse-prompt-button'
import { Tooltip } from '../tooltip/tooltip'

const PreCode = (props: ReactMarkdownProps) => {
  const ref = useRef<HTMLPreElement>(null)

  const handleCopyToClipboard = async () => {
    if (ref.current) {
      const code = ref.current.innerText
      try {
        await copyToClipboard(code)
        toast('Copied to clipboard')
      } catch {
        toast.error('Error copying to clipboard')
      }
    }
  }

  return (
    <div className="group/codeItem relative">
      <button
        className="text-cerosWhite bg-cerosGrey400 absolute right-1 top-1 hidden rounded-md px-2 py-1 text-xs group-hover/codeItem:inline-block"
        onClick={handleCopyToClipboard}
      >
        Copy
      </button>
      <pre ref={ref}>{props.children}</pre>
    </div>
  )
}

const ScrollableTable = (props: ReactMarkdownProps) => {
  const { node: _node, ...rest } = props
  return (
    <div className="max-w-100% sidebar-custom-scrollbar overflow-x-auto">
      <table {...rest} />
    </div>
  )
}

interface ITextContent {
  text?: string
  draggable?: boolean
  onDrag?: (event: DrugEventValue) => void
  onDragEnd?: (event: DrugEventValue) => void
  onDragStart?: (event: DrugEventValue) => void
  onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void
  onReuse?: () => void
  dataAutomation?: string
  isDocument?: boolean
}

export const TextContent = ({
  text,
  draggable = true,
  onDrag,
  onDragEnd,
  onDragStart,
  onClick,
  onReuse,
  dataAutomation,
  isDocument,
}: ITextContent) => {
  const handleDrag = (e: React.DragEvent<unknown>) => {
    onDrag?.({
      clientX: e.clientX,
      clientY: e.clientY,
      data: null,
      native: e.nativeEvent,
    })
  }

  const handleDragStart = (e: React.DragEvent<unknown>) => {
    onDragStart?.({
      clientX: e.clientX,
      clientY: e.clientY,
      data: null,
      native: e.nativeEvent,
    })
  }

  const handleDragEnd = (e: React.DragEvent<unknown>) => {
    onDragEnd?.({
      clientX: e.clientX,
      clientY: e.clientY,
      data: null,
      native: e.nativeEvent,
    })
  }
  const copyItem = () => {
    copyRichTextToClipboard(text ?? '')
      .then(() => {
        toast.success('Copied content to clipboard')
      })
      .catch(() => {
        toast.error('Failed to copy to clipboard')
      })
  }
  return (
    <div
      data-automation={dataAutomation}
      onClick={onClick}
      className={cls(
        'relative pr-6',
        isDocument ? 'border-cerosGrey300 my-4 border-l pb-10 pl-4' : 'mb-2',
      )}
    >
      {isDocument && text && text.length > 0 && (
        <Button
          className="absolute bottom-0 left-3 h-[32px] min-w-[70px] p-1"
          variant="secondary"
          onClick={copyItem}
        >
          Copy
        </Button>
      )}
      <div className="overflow-auto">
        <div className="custom-scrollbar overflow-auto">
          <ReactMarkdown
            remarkPlugins={[RemarkGfm]}
            rehypePlugins={[
              [
                RehypeHighlight,
                {
                  detect: false,
                  ignoreMissing: true,
                },
              ],
              ...(isDocument
                ? [
                    [
                      RehypeRaw,
                      { allowDangerousHtml: false },
                    ] as PluggableList[number],
                  ]
                : []),
            ]}
            className={cls(
              'markdown-body prose prose-md text-cerosBlack max-w-none',
              // type === 'outgoing' && 'text-cerosWhite',
              // type === 'error' && 'text-cerosDanger',
            )}
            components={{
              // NOTE: we want all Gemma links to open in a new tag, if they're not for our domain
              a: ({ children, ...props }) => {
                const isRelative = props.href
                  ? isHrefForHost(props.href, window.location.host)
                  : false
                return (
                  <a
                    {...props}
                    target={isRelative ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                  >
                    {children}
                  </a>
                )
              },
              pre: PreCode,
              table: ScrollableTable,
            }}
            children={text ?? ''}
          />
        </div>
      </div>
      {draggable && (
        <div
          className="absolute left-0 right-0 top-0 h-2 cursor-grab active:h-full active:cursor-grabbing"
          draggable={true}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        />
      )}

      {onReuse && (
        <div className="absolute -top-8 right-[9px]">
          <Tooltip
            className="left-1/2 w-[111px] -translate-x-[38%] text-center lg:-left-1/2 lg:-translate-x-[5%]"
            text="Reuse prompt"
            placement="top"
          >
            <ReusePromptButton
              className="invisible group-hover/messageItem:visible"
              onClick={onReuse}
            />
          </Tooltip>
        </div>
      )}
    </div>
  )
}
