import type { FetchEventSourceInit } from '@microsoft/fetch-event-source'
import type { AxiosError } from 'axios'
import axios from 'axios'
import { injected } from 'brandi'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { v4 as uuidv4 } from 'uuid'

import { DI_TYPE } from '@/di.types.js'
import { env } from '@/env.js'
import type { AuthModel } from '@/models/auth.js'
import type { Api } from '@/services/api.js'

import type { ApiClient } from './api-client.js'
import type { StorageService } from './storage.js'

const DATA_STORAGE_KEY = 'auth_data'
const REFRESH_RELEASE_DATE = new Date(
  env().VITE_REFRESH_TOKEN_RELEASE_DATE ?? '2024-2-25',
)

export class AuthService {
  @observable auth: AuthModel | undefined = undefined
  @observable intervalId: ReturnType<typeof setInterval> | undefined = undefined
  @observable initialized = false
  @observable sessionId = uuidv4()
  @observable pendingRefresh: Promise<null> | null = null

  constructor(
    private apiClient: ApiClient,
    private api: Api,
    private storageService: StorageService,
  ) {
    makeObservable(this)

    this.init()
  }

  async init() {
    this.apiClient.addHTTP401Interceptor(async (error: AxiosError) => {
      this.startPendingRefresh()
      await this.pendingRefresh

      if (this.isAuthenticated && error.config) {
        // refresh was successul, retry the original request
        const config = error.config
        config.headers['Authorization'] =
          `Bearer ${this.auth?.data.access_token}`
        return axios.request(config)
      }
      return error
    })

    this.apiClient.addEventSource401Handler(
      async (err: Error, input: RequestInfo, init?: FetchEventSourceInit) => {
        this.startPendingRefresh()
        await this.pendingRefresh

        if (this.isAuthenticated) {
          // retry fetch event source after successful refresh
          return this.apiClient.fetchEventSource(input, init)
        }
        // throw the original error on unsuccessful refresh
        throw err
      },
    )

    const authData = await this.storageService.load(DATA_STORAGE_KEY)
    if (authData) {
      try {
        const parsedData = JSON.parse(authData)
        if (!!parsedData) {
          this.apiClient.setToken(parsedData.data.access_token)

          if (!parsedData.data.user || !parsedData.data.access_token) {
            throw new Error(`[AuthService] Invalid auth data`)
          } else {
            runInAction(() => {
              this.auth = parsedData
            })
          }
        }
      } catch (e) {
        if (e instanceof SyntaxError) this.clearAuthData()
        else throw e
      }
    }

    runInAction(() => {
      // NOTE: should be initialized even if there is no auth data
      this.initialized = true
    })
  }

  private startPendingRefresh() {
    if (!this.pendingRefresh) {
      this.pendingRefresh = this.maybeRefreshTokens()
      this.pendingRefresh.finally(() => {
        this.pendingRefresh = null
      })
    }
  }

  private maybeRefreshTokens() {
    return new Promise<null>((resolve) => {
      const token = this.getTokenForRefresh()
      if (!token) {
        this.clearAuthData()
        resolve(null)
        return
      }
      this.doTokenRefresh(token)
        .then((response) => {
          const success = response.status === 200 && response.body
          if (success) {
            this.applyToken({
              data: {
                ...response.body,
                expires_at: new Date(response.body.expires_at),
              },
              type: 'auth0',
            })
          } else {
            this.clearAuthData()
          }
        })
        .finally(() => {
          resolve(null)
        })
    })
  }

  private getTokenForRefresh() {
    const accessToken = this.auth?.data.access_token
    const refreshToken = this.auth?.data.refresh_token

    if (refreshToken) {
      return refreshToken
    }
    if (accessToken && this.useAccessTokenForRefresh) {
      return accessToken
    }
    // this means we have no way or no need to refresh
    return null
  }

  get useAccessTokenForRefresh() {
    // Special case for access tokens issued before we started expiring them.
    // They have no expiration in auth data and no refresh token.
    // To let them stay logged in, the refresh endpoint will accept
    // old access tokens for 30 days after release.
    const useAccessUntil = new Date(REFRESH_RELEASE_DATE)
    useAccessUntil.setDate(useAccessUntil.getDate() + 30)
    const expiration = this.auth?.data.expires_at
    return !expiration && new Date() < useAccessUntil
  }

  private async doTokenRefresh(token: string) {
    this.api.clearAuthHeader()
    return this.api.authentication.refreshToken({
      body: { refresh_token: token },
    })
  }

  private async setStoredAuthData(authData) {
    const saved = await this.storageService.save(
      DATA_STORAGE_KEY,
      JSON.stringify(authData),
    )
    return saved
  }

  private clearStoredAuthData() {
    const saved = this.storageService.save(DATA_STORAGE_KEY, '')
    return saved
  }

  clearAuthData() {
    this.clearStoredAuthData()
    runInAction(() => {
      this.auth = undefined
    })
  }

  @computed
  get isAuthenticated() {
    return !!this.auth?.data.access_token
  }

  @action.bound
  async applyToken(authData: AuthModel | undefined) {
    if (!!authData) {
      const saved = await this.setStoredAuthData(authData)

      if (saved) {
        this.apiClient.setToken(authData.data.access_token)
        runInAction(() => {
          this.auth = authData
          this.initialized = true
        })
      }
    } else {
      const saved = await this.clearStoredAuthData()
      if (saved) {
        this.apiClient.setToken('')
        runInAction(() => {
          this.auth = undefined
        })
      }
    }
  }
}

injected(AuthService, DI_TYPE.ApiClient, DI_TYPE.Api, DI_TYPE.StorageService)
