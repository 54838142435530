import { injected } from 'brandi'
import { action, makeObservable, reaction } from 'mobx'

import { DI_TYPE } from '@/di.types'
import { ConversationsService } from '@/services/conversations'
import type { RouterService } from '@/services/router'

import { BaseViewModel } from './base-view-model'

export class ConversationNewViewModel extends BaseViewModel {
  constructor(
    private conversationsService: ConversationsService,
    private routerService: RouterService,
  ) {
    super()
    makeObservable(this)

    reaction(
      () => this.isActive,
      (isActive) => {
        if (isActive) {
          this.handleNewChatRoute()
        }
      },
    )
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onInit(): void {}

  onDispose(): void {}

  @action
  public async handleNewChatRoute() {
    const search = this.routerService.currentLocation?.search
    const taskSearch = new URLSearchParams(search).get('task')

    const task =
      taskSearch &&
      this.conversationsService.tasks.find(
        (t) => t.task_prompt_type === `task_${taskSearch}`,
      )
    const params = task
      ? {
          name: task.title,
          task_prompt_type: task.task_prompt_type,
          emoji: task.emoji,
        }
      : {}

    const data = await this.conversationsService.createConversation(params, {
      createSource: 'New chat URL',
    })
    if (data) {
      const prompt = new URLSearchParams(search).get('prompt')
      this.routerService.goTo(
        `/${ConversationsService.CONVERSATIONS_SLUG}/${data.id}`,
        {
          isExisting: false,
          prompt,
        },
      )
    }
  }
}

injected(
  ConversationNewViewModel,
  DI_TYPE.ConversationsService,
  DI_TYPE.RouterService,
)
