import type {
  AttachmentResponse,
  CreateOrUpdateGemRequest,
  Gem,
  Gems,
} from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { toast } from 'react-hot-toast'

import { DI_TYPE } from '@/di.types'

import type { Api } from './api'
import type { AuthService } from './auth'
import { BaseService } from './base-service'
import type { MixpanelService } from './mixpanel'

export class GemsService extends BaseService {
  @observable
  private loadedData: Gems = { items: [] }

  static GEMS_SLUG = 'gems'

  constructor(
    private api: Api,
    private authService: AuthService,
    private mixpanelService: MixpanelService,
  ) {
    super()
    makeObservable(this)

    this.onInit()
  }

  async onInit() {
    await this.loadAllGems()

    runInAction(() => {
      this.initialized = true
    })

    reaction(
      () => {
        return this.authService.auth?.data.user.id
      },
      async (auth) => {
        if (auth) await this.loadAllGems()
      },
    )
  }

  @computed
  get gems(): Gem[] {
    return this.loadedData.items ?? []
  }

  @action.bound
  private async loadAllGems(): Promise<Gems | undefined> {
    if (!this.authService.isAuthenticated) return { items: [] }

    const result = await this.api.gems.getAllGems()

    if (result.status === 200) {
      runInAction(() => {
        this.loadedData.items = result.body.items
      })
      return result.body
    } else {
      toast.error('Error loading gems')
      return undefined
    }
  }

  public async getGem(gemId: string): Promise<Gem | undefined> {
    const result = await this.api.gems.getGem({ params: { gem_id: gemId } })

    if (result.status === 200) {
      return result.body.gem
    } else {
      return undefined
    }
  }

  @action.bound
  public isGemOwner(gem: Gem): boolean {
    if (!gem.user_id) return false
    return this.authService.auth?.data.user.id === gem.user_id
  }

  public async createGem(
    data: CreateOrUpdateGemRequest,
  ): Promise<Gem | undefined> {
    const result = await this.api.gems.createGem({ body: data })

    if (result.status === 201) {
      await this.loadAllGems()
      return result.body
    } else {
      toast.error('Error creating gem')
      return undefined
    }
  }

  public async updateGem(
    gem_id: string,
    data: CreateOrUpdateGemRequest,
  ): Promise<Gem | undefined> {
    const result = await this.api.gems.updateGem({
      params: { gem_id },
      body: data,
    })

    if (result.status === 200) {
      await this.loadAllGems()
      return result.body
    } else {
      toast.error('Error updating gem')
      return undefined
    }
  }

  public async updateSharedState(
    gem: Gem,
    isShared: boolean,
  ): Promise<Gem | undefined> {
    const result = await this.api.gems.updateGem({
      params: { gem_id: gem.id },
      body: { ...gem, is_publicly_shared: isShared },
    })

    if (result.status === 200) {
      await this.loadAllGems()
      this.mixpanelService.trackGemSharingToggled(gem.id, isShared)
      return result.body
    } else {
      toast.error('Error updating gem')
      return undefined
    }
  }

  public async deleteGem(gem_id: string): Promise<boolean> {
    const result = await this.api.gems.deleteGem({ params: { gem_id } })

    if (result.status === 200) {
      await this.loadAllGems()
      this.mixpanelService.trackGemDeleted(result.body)
      return true
    } else {
      toast.error('Error deleting gem')
      return false
    }
  }

  public async pinAttachment(
    gem_id: string,
    url: string,
  ): Promise<AttachmentResponse | undefined> {
    const result = await this.api.gems.pinAttachment({
      params: { gem_id },
      body: { pin: { url, type: 'upload_pin' } },
    })

    if (result.status === 201) {
      await this.loadAllGems()
      return result.body
    } else {
      toast.error('Failed to upload file')
      return undefined
    }
  }

  public async unpinAttachment(
    gem_id: string,
    attachment_id: string,
  ): Promise<Gems | undefined> {
    const result = await this.api.gems.unpinAttachment({
      params: { gem_id, attachment_id },
      body: {},
    })

    if (result.status === 200) {
      await this.loadAllGems()
      return result.body
    } else {
      toast.error('Failed to remove file')
      return undefined
    }
  }
}

injected(GemsService, DI_TYPE.Api, DI_TYPE.AuthService, DI_TYPE.MixpanelService)
