import { useEffect, useMemo, useRef } from 'react'

import { Button } from '@/components/button/button'
import { cls } from '@/utils/utils'
import type { PromptsViewModel, PromptViewData } from '@/view-models/prompts'

function resizeTextareas(one: HTMLTextAreaElement, two: HTMLTextAreaElement) {
  one.style.height = 'auto'
  two.style.height = 'auto'

  const height = Math.max(one.scrollHeight, two.scrollHeight) + 5
  one.style.height = `${height}px`
  two.style.height = `${height}px`
}

export const PromptEntry = ({
  prompt,
  vm,
}: {
  prompt: PromptViewData
  vm: PromptsViewModel
}) => {
  const globalTextarea = useRef<HTMLTextAreaElement | null>(null)
  const userTextarea = useRef<HTMLTextAreaElement | null>(null)

  // NOTE: resizes textareas on opening and closing, as well as when the global prompt changes
  // we did have autoresize on typing but the page jumped around as the the textareas resized
  useEffect(() => {
    if (globalTextarea.current && userTextarea.current) {
      resizeTextareas(globalTextarea.current, userTextarea.current)
    }
  }, [prompt.open, prompt.globalValue])

  const onCopyRight = () => {
    if (globalTextarea.current && userTextarea.current) {
      const overwrite =
        userTextarea.current.value.trim().length > 0
          ? window.confirm(
              'Are you sure you want to overwrite the user prompt?',
            )
          : true

      if (!overwrite) return

      userTextarea.current.value = globalTextarea.current.value
      resizeTextareas(globalTextarea.current, userTextarea.current)
    }
  }

  const ro = useMemo(
    () =>
      new ResizeObserver(() => {
        if (globalTextarea.current && userTextarea.current) {
          globalTextarea.current.style.height =
            userTextarea.current.style.height
        }
      }),
    [],
  )

  useEffect(() => {
    if (userTextarea.current) {
      ro.observe(userTextarea.current)
    } else {
      ro.disconnect()
    }
  }, [ro, globalTextarea, userTextarea])

  return (
    <div
      key={prompt.promptType}
      className="border-cerosGrey600 border-t-none mt-4 rounded-md border border-solid"
    >
      <div
        className={cls(
          'bg-cerosGrey600 text-cerosWhite relative cursor-pointer px-4 py-2',
          'grid grid-cols-[1fr_max-content_min-content] items-center gap-4',
          prompt.open ? 'rounded-t-md' : 'rounded-md',
        )}
        onClick={() => vm.togglePrompt(prompt.promptType, !prompt.open)}
      >
        <h2 className="text-lg">{prompt.promptType}</h2>
        <span
          className={cls(
            'text-sm',
            prompt.isDefault ? 'text-cerosSuccess' : 'text-cerosDanger',
          )}
        >
          {prompt.isDefault ? 'Using default prompt' : 'Using custom prompt'}
        </span>
        <span
          className={cls(
            'h-3 text-sm',
            prompt.open ? 'rotate-0' : 'rotate-180',
          )}
        >
          {'\u2303'}
        </span>
      </div>
      <div
        className={cls(
          'my-4 grid grid-cols-2 gap-2 px-4',
          prompt.open ? '' : 'hidden',
        )}
      >
        <div>
          <div className="mb-2 grid grid-cols-[1fr_max-content]">
            <h3 className="text-md text-cerosGrey600">Default prompt</h3>
            <button
              className="mr-2 cursor-pointer"
              title="Copy to right"
              onClick={onCopyRight}
            >
              ➡
            </button>
          </div>
          <textarea
            ref={globalTextarea}
            className="bg-cerosWhite border-cerosGrey200 min-h-10 w-full resize-none rounded-md border p-2"
            value={prompt.globalValue}
            disabled={true}
          />
        </div>
        <div>
          <h3 className="text-md text-cerosGrey600 mb-2">User prompt</h3>
          <textarea
            ref={userTextarea}
            className="bg-cerosWhite border-cerosGrey200 mb-2 min-h-10 w-full rounded-md border p-2"
            value={prompt.visibleValue}
            onChange={(e) => {
              vm.onPromptChange(prompt.promptType, e.currentTarget.value)
            }}
          />
          <div className="flex items-center justify-end gap-2">
            <span
              className={cls(
                'text-sm',
                prompt.isDefault
                  ? 'text-cerosSuccessActive'
                  : 'text-cerosDangerActive',
              )}
            >
              {prompt.isDefault
                ? 'Using default prompt'
                : 'Using custom prompt'}
            </span>
            <Button
              variant="secondary"
              type="button"
              disabled={
                vm.isSaving || (prompt.isDefault && !prompt.hasPendingChanges)
              }
              onClick={() => vm.onReset(prompt.promptType)}
            >
              Reset
            </Button>
            <Button
              variant="primary"
              type="button"
              disabled={vm.isSaving || !prompt.hasPendingChanges}
              onClick={() => vm.onSave(prompt.promptType)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
