import type { FormikErrors } from 'formik'
import type { ZodSchema } from 'zod'

export function formValidateFromZodSchema<T>(
  schema: ZodSchema,
): (values: T) => void | object | Promise<FormikErrors<T>> {
  return (values: any) => {
    const result = schema.safeParse(values)
    if (result.success) {
      return
    }

    return result.error.formErrors.fieldErrors
  }
}
