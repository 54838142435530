import type { AttachmentPin, Gem } from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { createElement } from 'react'
import toast from 'react-hot-toast'

import { DI_TYPE } from '@/di.types.js'
import { DeleteGemModal } from '@/pages/modals/delete-gem.js'
import { GemModal } from '@/pages/modals/gem/gem-modal.js'
import { ShareModal } from '@/pages/modals/share.js'
import type { Api } from '@/services/api.js'
import type { AuthService } from '@/services/auth.js'
import type { Task } from '@/services/conversations.js'
import type { ConversationsService } from '@/services/conversations.js'
import { GemsService } from '@/services/gems.js'
import type { MixpanelService } from '@/services/mixpanel.js'
import type { ModalService } from '@/services/modal.js'
import type { RouterService } from '@/services/router.js'
import type { SidebarService } from '@/services/sidebar.js'
import type { StorageService } from '@/services/storage.js'
import type { UserService } from '@/services/user.js'

import { BaseViewModel } from './base-view-model.js'
import type {
  FileUploadViewModel,
  FileUploadViewModelFactory,
} from './file-upload.js'

const TAB_URL_KEY = 'tab'
const TAB_STORE_KEY = 'homepage-selected-tab'

export class HomePageViewModel extends BaseViewModel {
  @observable fileUploadViewModel: FileUploadViewModel
  @observable isCreatingConversation = false
  @observable showCustomGemsTab = false

  constructor(
    protected api: Api,
    protected conversationsService: ConversationsService,
    protected mixpanelService: MixpanelService,
    protected routerService: RouterService,
    protected fileUploadFactoryVM: FileUploadViewModelFactory,
    protected userService: UserService,
    protected sidebarService: SidebarService,
    protected modalService: ModalService,
    protected gemsService: GemsService,
    protected authService: AuthService,
    protected storageService: StorageService,
  ) {
    super()
    makeObservable(this)

    this.fileUploadViewModel = this.fileUploadFactoryVM(
      this.fileDropSuccess,
      this.fileDropFailed,
    )

    this.showCustomGemsTab = this.loadCustomGemsTab()
  }

  private loadCustomGemsTab(): boolean {
    const storageValue = this.storageService.load(TAB_STORE_KEY)
    const searchParamValue = this.routerService.getSearchParam(TAB_URL_KEY)
    return [storageValue, searchParamValue].some((value) => value === 'gems')
  }

  @action.bound
  onInit() {
    // TODO: This is a workaround for child (dependency) view model to mimic lifecycle used in hook. We need better way to automatically do this for view models in dependency list. [#503](https://github.com/opendesigndev/gemma-ui/issues/503)
    this.fileUploadViewModel._start()
  }

  protected onDispose(): void {
    // dispose to make sure event listeners are cleaned up
    this.fileUploadViewModel._stop()
  }

  @computed
  get initializing(): boolean {
    return (
      !this.conversationsService.initialized || !this.sidebarService.initialized
    )
  }

  @action.bound
  toggleCustomGemsTab() {
    if (this.loadCustomGemsTab()) {
      this.storageService.remove(TAB_STORE_KEY)
      this.routerService.deleteSearchParam(TAB_URL_KEY)
      this.showCustomGemsTab = false
    } else {
      this.storageService.save(TAB_STORE_KEY, 'gems')
      this.routerService.setSearchParam(TAB_URL_KEY, 'gems')
      this.showCustomGemsTab = true
    }
  }

  @action.bound
  async onTaskSelect(task: Task) {
    this.wrapInCreatingConversation(async () => {
      const conversation = await this.conversationsService.createConversation(
        { task_prompt_type: task.task_prompt_type },
        { createSource: 'Homepage default task', taskTitle: task.title },
      )
      if (!conversation) return

      this.routerService.goTo(`/conversations/${conversation.id}`, {
        isExisting: false,
        expectGemmaResponse: true,
      })
    })
  }

  @action.bound
  async onGemSelect(gem: Gem) {
    this.wrapInCreatingConversation(async () => {
      const conversation = await this.conversationsService.createConversation(
        { gem_id: gem.id },
        { createSource: 'New chat Gem', gemTitle: gem.title },
      )
      if (!conversation) return

      this.routerService.goTo(`/conversations/${conversation.id}`, {
        isExisting: false,
        expectGemmaResponse: true,
      })
    })
  }

  @action.bound
  async onGemAdd() {
    if (!this.authService.auth) return
    this.modalService.showCustom(
      'gem-upsert',
      createElement(GemModal, {
        closeOnBackdropClick: false, // NOTE: Prevents closing the modal when trying to select text in input, could be solved by checking original mousedown event instead but went for the easy option
      }),
    )
  }

  @action.bound
  async onGemUpdate(gem: Gem) {
    if (!this.authService.auth) return
    this.modalService.showCustom(
      'gem-upsert',
      createElement(GemModal, {
        gemToUpdate: gem,
        closeOnBackdropClick: false, // NOTE: Prevents closing the modal when trying to select text in input, could be solved by checking original mousedown event instead but went for the easy option
      }),
    )
  }

  @action.bound
  onGemDelete(gem: Gem) {
    this.modalService.show(
      createElement(DeleteGemModal, {
        onDelete: () => this.gemsService.deleteGem(gem.id),
      }),
      'Delete Gem',
    )
  }

  @action.bound
  onGemShare(gem: Gem) {
    this.modalService.showCustom(
      'share-gem',
      createElement(ShareModal, {
        type: 'Gem',
        onToggle: (isShared: boolean) => this.onShareToggle(gem, isShared),
        onCopyUrl: () => this.onShareCopyUrl(gem),
        isShared: gem.is_publicly_shared ?? false,
        shareUrl: `${window.location.origin}/${GemsService.GEMS_SLUG}/${gem.id}`,
      }),
    )

    this.mixpanelService.trackGemSharingOpened(gem.id)
  }

  @action.bound
  onShareToggle(gem: Gem, isShared: boolean) {
    this.gemsService.updateSharedState(gem, isShared)
  }

  @action.bound
  onShareCopyUrl(gem: Gem) {
    this.mixpanelService.trackGemSharingLinkCopied(gem.id)
  }

  @action.bound
  async sendMessageText(message: string) {
    this.wrapInCreatingConversation(async () => {
      const conversation = await this.conversationsService.createConversation(
        {},
        { createSource: 'Homepage prompt' },
      )
      if (!conversation) return

      const messageResult = await this.api.conversations.postTextMessage({
        params: { conversation_id: conversation.id },
        body: { content: message },
      })

      if (messageResult.status !== 201) {
        toast.error('Error sending message to conversation')
        return
      }

      this.routerService.goTo(`/conversations/${conversation.id}`, {
        isExisting: false,
        expectGemmaResponse: true,
      })
    })
  }

  protected async createConversationWithAttachment(file: File) {
    this.wrapInCreatingConversation(async () => {
      const startTime = performance.now()

      const conversation = await this.conversationsService.createConversation(
        {},
        { createSource: 'Homepage file upload' },
      )
      if (!conversation) return

      this.routerService.goTo(`/conversations/${conversation.id}`, {
        isExisting: false,
        expectGemmaResponse: true,
      })

      const url = await this.fileUploadViewModel.uploadFile(file)
      if (!url) return

      const attachmentContent =
        this.fileUploadViewModel.buildAttachmentContentForFile(file, url)
      const pin: AttachmentPin = {
        type: attachmentContent.type,
        url: attachmentContent.url,
      }

      const result = await this.api.conversations.pinAttachment({
        params: { conversation_id: conversation.id },
        body: { pin },
      })

      if (result.status !== 201) {
        toast.error('Error pinning attachment to conversation')
        return
      }

      const endTime = performance.now()
      const timeToCreate = endTime - startTime

      this.mixpanelService.trackFileUpload({
        chatId: conversation.id,
        chatType: 'New',
        fileSize: file.size,
        fileFormat: file.type,
        uploadTime: timeToCreate,
        uploadType: 'click',
      })
    })
  }

  @action.bound
  async onFileInputChange(event) {
    if (this.fileUploadViewModel.validateFiles(event.target.files)) {
      await this.createConversationWithAttachment(event.target.files[0])
    } else {
      this.mixpanelService.trackFileUpload({
        chatId: 'n/a',
        chatType: 'New',
        fileSize: event.target.files[0].size,
        fileFormat: event.target.files[0].type,
        uploadTime: 0, // NOTE: Upload attempt was not successful
        uploadType: 'click',
        errorType: this.fileUploadViewModel.fileUploadErrorType,
      })
    }
  }

  @action.bound
  async fileDropSuccess(file: File) {
    await this.createConversationWithAttachment(file)
  }

  @action.bound
  async fileDropFailed(file: File, reason?: string) {
    this.mixpanelService.trackFileUpload({
      chatId: 'n/a',
      chatType: 'New',
      fileSize: file.size,
      fileFormat: file.type,
      uploadTime: 0, // NOTE: Upload attempt was not successful
      uploadType: 'drag-and-drop',
      errorType: reason ?? 'unknown',
    })
  }

  get tasks(): Task[] {
    return this.conversationsService.tasks
  }

  get gems(): Gem[] {
    return this.gemsService.gems
  }

  get firstname(): string | undefined {
    return this.userService.userData.firstname
  }

  protected wrapInCreatingConversation<T>(fn: () => Promise<T>): Promise<T> {
    runInAction(() => {
      this.isCreatingConversation = true
    })

    return fn().finally(() => {
      runInAction(() => {
        this.isCreatingConversation = false
      })
    })
  }
}

injected(
  HomePageViewModel,
  DI_TYPE.Api,
  DI_TYPE.ConversationsService,
  DI_TYPE.MixpanelService,
  DI_TYPE.RouterService,
  DI_TYPE.FileUploadViewModel,
  DI_TYPE.UserService,
  DI_TYPE.SidebarService,
  DI_TYPE.ModalService,
  DI_TYPE.GemsService,
  DI_TYPE.AuthService,
  DI_TYPE.StorageService,
)
