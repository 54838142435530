import type { Editor } from '@tiptap/core'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import toast from 'react-hot-toast'

import type { RenderableConversationMessage } from '../models/conversation'
import type { Api } from '../services/api'
import type {
  DocumentCopiedSource,
  MixpanelService,
} from '../services/mixpanel'
import { tryToGetSelectionRange } from '../utils/document'
import { debounce } from '../utils/utils'
import { BaseViewModel } from './base-view-model'

export const LOCAL_STORAGE_DRAFT_KEY_PREFIX = 'gemma-document-draft'

export class DocumentViewModel extends BaseViewModel {
  @observable editor: Editor | null = null
  @observable draft: string = ''
  @observable showDiff: boolean = false
  @observable isNew: boolean = false
  @observable loaderPosition: DOMRect | false = false
  @observable currentItemId: string | undefined = undefined
  @observable isEditorOpen: boolean = true
  @observable highlightedSelection: string | undefined = undefined
  @observable conversationId: string | undefined = undefined
  @observable loadedHistory: boolean = false

  private MAX_WAIT_FOR_DOCUMENT_UPDATE = 60000
  private documentUpdateTimeoutRef: number | undefined

  constructor(
    private api: Api,
    private mixpanelService: MixpanelService,
  ) {
    super()
    makeObservable(this)
  }

  @action.bound
  protected onInit(): void {
    // only open by default on desktop
    if (window.matchMedia('(min-width: 1024px)').matches) {
      this.isEditorOpen = true
    } else {
      this.isEditorOpen = false
    }
    const debouncedSaveDraft = debounce((id: string, draft: string) => {
      localStorage.setItem(`${LOCAL_STORAGE_DRAFT_KEY_PREFIX}:${id}`, draft)
    }, 1000)
    reaction(
      () => this.draft,
      (d) => {
        const id = this.conversationId
        if (id) {
          debouncedSaveDraft(id, d)
        }
      },
    )
    // load draft from local storage
    // when we change conversation and history is loaded and editor is initialized
    reaction(
      () =>
        this.editor && this.loadedHistory ? this.conversationId : undefined,
      (id) => {
        if (id && !this.currentItemId) {
          const draft = localStorage.getItem(
            `${LOCAL_STORAGE_DRAFT_KEY_PREFIX}:${id}`,
          )
          if (draft) {
            this.draft = draft
            if (this.editor) {
              this.editor.chain().setContent(draft, false).run()
            }
          }
        }
      },
    )
  }

  protected onDispose(): void {}

  @computed
  get textEditorContext() {
    if (this.draft) {
      return { draft: this.draft }
    } else if (this.highlightedSelection) {
      return { selection: this.highlightedSelection }
    }
    return undefined
  }

  @action.bound
  onDocumentChange(newId?: string) {
    this.currentItemId = newId
    this.isNew = false
  }

  @action.bound
  onDocumentAdded(message: RenderableConversationMessage) {
    const documentItem = message.items.find((item) => {
      return item.content.type === 'inline_document'
    })
    if (documentItem && this.conversationId) {
      // remove draft from local storage as it's not relevant anymore
      localStorage.removeItem(
        `${LOCAL_STORAGE_DRAFT_KEY_PREFIX}:${this.conversationId}`,
      )
      this.draft = ''
    }

    if (documentItem && documentItem.id !== this.currentItemId) {
      const isGenerated = !message.renderMetadata.streaming
      this.updateCurrentItem(documentItem.id, isGenerated)
    }
  }

  @action.bound
  private updateCurrentItem(itemId: string, generated?: boolean) {
    this.currentItemId = itemId
    // updates from text fragment endpoint don't get streamed, just added
    // so we handle them differently
    if (this.loadedHistory) {
      const isNew = this.loaderPosition || generated
      this.showDiff = isNew ? true : false
      this.isNew = isNew ? true : false
      this.loaderPosition = false
      window.clearTimeout(this.documentUpdateTimeoutRef)
    }
  }

  onDocumentContentChange = debounce(
    (value: string, msgId: string, itemId: string) => {
      this.api.conversations.updateTextMessage({
        params: { id: msgId, itemId },
        body: { content: value },
      })
    },
    1000,
  )

  @action.bound
  highlightSelection() {
    const editor = this.editor
    if (editor) {
      const hasTextSelected =
        editor.state.selection.from < editor?.state.selection.to
      if (hasTextSelected) {
        const selectedText = tryToGetSelectionRange(0)?.toString()
        if (selectedText && selectedText.length > 0) {
          this.highlightedSelection = selectedText
          editor
            ?.chain()
            .setHighlight({
              color: 'var(--gemma-selection-color)',
            })
            .run()
        }
      }
    }
  }

  @action.bound
  onPromptInputFocused() {
    const editor = this.editor
    if (editor) {
      this.highlightSelection()
      this.isNew = false
      this.showDiff = false
    }
  }

  @action.bound
  onPromptInputBlurred() {
    const editor = this.editor
    if (editor) {
      editor.chain().selectAll().unsetHighlight().blur().run()
      this.highlightedSelection = undefined
    }
  }

  /**
   * @throws on unsuccessful response
   */
  @action.bound
  sendEditTextMsg = async (
    {
      occurrence,
      text,
      msgId,
      itemId,
      messageIndex,
    }: {
      occurrence: number
      text: string
      msgId: string
      itemId: string
      messageIndex: number
    },
    loaderPosition?: DOMRect,
  ) => {
    if (this.conversationId) {
      if (loaderPosition) {
        this.loaderPosition = loaderPosition
      }
      const res = await this.api.conversations.postTextEditMessage({
        params: {
          conversation_id: this.conversationId,
          message_id: msgId,
          item_id: itemId,
        },
        body: { occurence_number: occurrence, fragment: text },
      })
      if (res.status !== 201 && res.status !== 200) {
        // on success this will get unset when new document is added
        this.loaderPosition = false
        throw new Error('Failed to send edit text message')
      }
      this.onFragmentRegenerated(messageIndex)
      // in case it doesn't get unset
      this.documentUpdateTimeoutRef = window.setTimeout(() => {
        this.loaderPosition = false
        toast.error('Failed to update the document')
      }, this.MAX_WAIT_FOR_DOCUMENT_UPDATE)
    }
  }

  onCopyDocument = (index: number, source: DocumentCopiedSource) => {
    if (this.conversationId) {
      this.mixpanelService.trackDocumentCopied({
        chatId: this.conversationId,
        documentVersionIndex: index,
        source,
      })
    }
  }

  onFragmentRegenerated = (index: number) => {
    if (this.conversationId) {
      this.mixpanelService.trackDocumentSelectionRegenerated({
        chatId: this.conversationId,
        documentVersionIndex: index,
      })
    }
  }
}
