import type { Gem } from '@ceros/gemma-api-spec'
import { MenuButton } from '@szhsin/react-menu'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import EditableIcon from '@/assets/icons/editable.svg?react'
import FlagIcon from '@/assets/icons/flag.svg?react'
import MoreIcon from '@/assets/icons/more.svg?react'
import SettingsIcon from '@/assets/icons/settings.svg?react'
import ShareIcon from '@/assets/icons/share.svg?react'
import { Drawer, DrawerItem, DrawerTitle } from '@/components/drawer/drawer.js'
import { Menu, MenuDivider, MenuItem } from '@/components/menu/menu.js'
import { DI_TYPE } from '@/di.types.js'
import { useIsMobile } from '@/utils/use-is-mobile.js'
import { cls } from '@/utils/utils.js'
import { useViewModel } from '@/utils/view.js'

type ActiveGemProps = {
  conversationId: string
  gem: Gem
}

export const ActiveGem = observer(({ conversationId, gem }: ActiveGemProps) => {
  const vm = useViewModel(DI_TYPE.ActiveGemViewModel)

  const isMobile = useIsMobile()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const menu = (
    <Menu
      align="end"
      direction="bottom"
      minSize="min-w-[140px]"
      arrow={true}
      arrowProps={{ className: 'w-6 h-6' }}
      viewScroll="close"
      menuButton={
        <MenuButton data-automation="gem-menu-button" className={'h-6'}>
          <MoreIcon />
        </MenuButton>
      }
    >
      <MenuItem
        className="flex justify-start gap-2 rounded-b-md px-2.5 py-2 text-sm font-medium leading-tight"
        onClick={() => vm.onGemSelect(gem)}
      >
        <EditableIcon className="h-4 w-4" /> New Chat
      </MenuItem>
      <MenuItem
        className="flex justify-start gap-2 rounded-b-md px-2.5 py-2 text-sm font-medium leading-tight"
        onClick={() => vm.onGemUpdate(gem)}
      >
        <SettingsIcon className="h-4 w-4" /> Edit gem
      </MenuItem>
      <MenuItem
        className="flex justify-start gap-2 rounded-b-md px-2.5 py-2 text-sm font-medium leading-tight"
        onClick={() => vm.onGemShare(gem)}
      >
        <ShareIcon className="h-4 w-4" /> Share gem
      </MenuItem>
      <MenuDivider />
      <MenuItem
        className="flex justify-start gap-2 rounded-b-md px-2.5 py-2 text-sm font-medium leading-tight"
        onClick={() => vm.onGemReport(conversationId, gem.id)}
      >
        <FlagIcon className="h-4 w-4" /> Report
      </MenuItem>
    </Menu>
  )

  const mobileMenu = (
    <>
      <button
        data-automation="gem-mobile-menu-button"
        className={'h-6'}
        onClick={() => setIsMobileMenuOpen(true)}
      >
        <MoreIcon />
      </button>
      <Drawer
        isOpen={isMobileMenuOpen}
        setIsOpen={setIsMobileMenuOpen}
        header={<DrawerTitle>{gem.title}</DrawerTitle>}
      >
        <DrawerItem
          onClick={() => {
            setIsMobileMenuOpen(false)
            vm.onGemSelect(gem)
          }}
          icon={<EditableIcon />}
        >
          New Chat
        </DrawerItem>
        <DrawerItem
          onClick={() => {
            setIsMobileMenuOpen(false)
            vm.onGemUpdate(gem)
          }}
          icon={<SettingsIcon />}
        >
          Edit gem
        </DrawerItem>
        <DrawerItem
          onClick={() => {
            setIsMobileMenuOpen(false)
            vm.onGemShare(gem)
          }}
          icon={<ShareIcon />}
        >
          Share gem
        </DrawerItem>
        <DrawerItem
          onClick={async () => {
            setIsMobileMenuOpen(false)
            vm.onGemReport(conversationId, gem.id)
          }}
          icon={<FlagIcon />}
        >
          Report
        </DrawerItem>
      </Drawer>
    </>
  )

  return (
    <div className="align-items-top z-20 mx-24 my-6 flex justify-center gap-1.5 text-base font-medium sm:gap-2.5">
      <span>{gem.emoji}</span>
      <span className={cls('truncate', isMobile ? 'max-w-20' : 'max-w-60')}>
        {gem.title}
      </span>
      {isMobile ? mobileMenu : menu}
    </div>
  )
})
