import { cva, type VariantProps } from 'class-variance-authority'
import { useId } from 'react'
import { twMerge } from 'tailwind-merge'

const choicerButtonClasses = cva(
  'flex h-10 flex-row items-center gap-2 rounded-lg border px-4 text-sm font-medium hover:cursor-pointer',
  {
    variants: {
      intent: {
        default:
          'border-transparent bg-cerosWhite hover:border-cerosGrey300 peer-checked:border-cerosGrey300 peer-focus:peer-checked:border-cerosGrey300 text-cerosBlack peer-focus:peer-checked:bg-cerosGrey200 peer-checked:bg-cerosGrey200  peer-focus-visible:peer-checked:border-cerosPrimary peer-focus-visible:border-cerosPrimary box-border ',
        alternate:
          'border-cerosGrey300 bg-cerosWhite hover:border-cerosGrey300 hover:bg-cerosGrey100 peer-checked:border-cerosPrimary peer-focus:peer-checked:border-cerosPrimary text-cerosBlack peer-focus:peer-checked:bg-cerosOtherBlue100 peer-checked:bg-cerosOtherBlue100  peer-focus-visible:peer-checked:border-cerosPrimary peer-focus-visible:border-cerosPrimary box-border ',
      },
    },
    defaultVariants: {
      intent: 'default',
    },
  },
)

interface IChoice extends VariantProps<typeof choicerButtonClasses> {
  name: string
  value: string
  label?: string
  disabled?: boolean
  multi?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  emoji?: string
}

export function Choice({
  name,
  value,
  label,
  disabled,
  multi,
  checked = false,
  emoji,
  onChange,
  intent,
}: IChoice) {
  const id = useId()
  return (
    <div className="flex">
      <input
        id={id}
        name={name}
        className="peer h-0 w-0 opacity-0"
        type={multi ? 'checkbox' : 'radio'}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <label
        htmlFor={id}
        className={twMerge(choicerButtonClasses({ intent }), '')}
        style={{
          boxShadow:
            !intent || intent === 'default'
              ? '0px 10px 25px 0px rgba(0, 0, 0, 0.06)'
              : '0px 1px 1px 0px rgba(17, 2, 59, 0.04), 0px 1px 2px 0px rgba(17, 2, 59, 0.04), 0px 3px 3px 0px rgba(17, 2, 59, 0.04), 0px 12px 5px 0px rgba(17, 2, 59, 0.01)',
        }}
      >
        {emoji && <span>{emoji}</span>}
        <span>{label ? label : value}</span>
      </label>
    </div>
  )
}
