import { observer } from 'mobx-react-lite'

import { DI_TYPE } from '@/di.types'
import { useViewModel } from '@/utils/view'

export const ConversationLatestPage = observer(() => {
  useViewModel(DI_TYPE.ConversationLatestViewModel)
  return null
})

ConversationLatestPage.displayName = 'ConversationLatestPage'
