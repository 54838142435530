import type { ReactElement } from 'react'

import { cls } from '@/utils/utils'

type PillProps = {
  text: string
  icon: ReactElement
  className?: string
}

export const Pill = ({ text, icon, className }: PillProps) => (
  <div
    className={cls(
      'text-cerosWhite flex w-fit items-center rounded-md bg-gradient-to-br from-[#FF7E55] from-15% to-[#484CF8] px-1.5 py-0.5 text-xs shadow-[0px_2px_5px_#11023B1A]',
      className,
    )}
  >
    <div className="mr-0.5 h-4 w-4">{icon}</div>
    {text}
  </div>
)
