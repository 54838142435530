import '../di.container.js'
import '../di.types.js'
import '../index.css'

import type { Container } from 'brandi'
import { reaction } from 'mobx'
import ReactDOM from 'react-dom/client'

import { LoadingDots } from '@/components/loading-dots/loading-dots.js'
import { Toaster } from '@/components/toaster/toaster.js'
import { DI_TYPE } from '@/di.types.js'

import { App } from '../app.js'

const rootElement = document.getElementById('root')

if (!(rootElement instanceof HTMLElement)) {
  throw new Error(
    'Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?',
  )
}

const reactRoot = ReactDOM.createRoot(rootElement)

function SplashScreen() {
  return (
    <>
      <div className="flex h-screen w-full items-center justify-center">
        <LoadingDots />
      </div>
      <Toaster />
    </>
  )
}

const renderRoot = (isAppReady: boolean) => {
  reactRoot.render(isAppReady ? <App /> : <SplashScreen />)
}

export function runApp(container: Container) {
  const initService = container.get(DI_TYPE.InitService)

  reaction(
    () => initService.initialized,
    (isAppReady) => renderRoot(isAppReady),
    {
      fireImmediately: true,
    },
  )
}
