import type { Choices as ChoicesSchema } from '@ceros/gemma-api-spec'
import { motion } from 'framer-motion'
import { createContext, useContext } from 'react'

import type { BlockComponent } from '@/models/blocks'
import { cls } from '@/utils/utils'

import { MessageContext } from '../message-context'
import { BlockIterator } from './block-iterator'
import type { ButtonVariant, IButton } from './button'

const choicesAnimationVariant = {
  hidden: {
    x: '-100vw', //move out of the site
  },
  visible: {
    x: 0, // bring it back to normal
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.3,
    },
  },
}

export type IChoices = Omit<ChoicesSchema, 'v'> & {
  animate?: boolean
  buttonVariant?: ButtonVariant
  choicesClassName?: string
  buttonClassName?: string
  v: IButton[]
  value?: string
}

export interface IChoicesContext {
  value?: string
  buttonVariant: ButtonVariant
}

export const ChoicesContext = createContext<IChoicesContext | undefined>(
  undefined,
)

export const Choices = ({
  animate,
  buttonVariant = 'rectangle',
  choicesClassName,
  buttonClassName,
  v,
  value,
  ...rest
}: BlockComponent<IChoices>) => {
  const messageContext = useContext(MessageContext)
  animate = animate === undefined ? messageContext?.isLastMessage : animate

  return (
    <motion.div
      initial={animate ? 'hidden' : 'visible'}
      animate="visible"
      variants={choicesAnimationVariant}
      className={cls('flex flex-wrap gap-4', choicesClassName)}
    >
      <ChoicesContext.Provider value={{ buttonVariant, value }}>
        <BlockIterator
          blocks={v as any}
          className={buttonClassName}
          {...rest}
        />
      </ChoicesContext.Provider>
    </motion.div>
  )
}
