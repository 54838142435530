import { z } from 'zod'

import { Button } from '@/components/button/button'
import type { Action } from '@/models/blocks'
import { ActionType, type BlockComponent } from '@/models/blocks'
import { onActionTypeHandler } from '@/utils/action'
import { formValidateFromZodSchema } from '@/utils/blocks/form'
import { cls } from '@/utils/utils'

import classicIcon from '../../../../../assets/images/ai-presentations/classic.jpg'
import graphicIcon from '../../../../../assets/images/ai-presentations/graphic.jpg'
import playfulIcon from '../../../../../assets/images/ai-presentations/playful.jpg'
import briefIcon from '../../../../../assets/images/brief.png'
import piggyBankIcon from '../../../../../assets/images/piggy-bank.png'
import rocketIcon from '../../../../../assets/images/rocket.png'
import { ButtonSelection } from '../component/button-selection'
import { FieldError } from '../component/field-error'
import type { IFormStep } from '../component/form-step'
import { FormSteps } from '../component/form-steps'
import { TextareaField } from '../component/textarea-field'

interface IValues {
  topic: string
  presentation_type: string
  style: string
  brand: string
}

const styleIcon = (icon: string, type?: string) => (
  <div
    style={{
      backgroundImage: `url("${icon}")`,
    }}
    className={cls(
      'w-ful h-full bg-center bg-no-repeat',
      type === 'presentation_type' ? 'bg-[length:100px]' : 'bg-cover',
    )}
  />
)

const formSteps: IFormStep<IValues>[] = [
  {
    key: 'presentation_type',
    label: 'Presentation Type',
    content: ({ formik }) => (
      <ButtonSelection
        name="presentation_type"
        buttonVariant="square"
        buttons={[
          {
            t: 'button',
            icon: styleIcon(rocketIcon, 'presentation_type'),
            l: 'General',
          },
          {
            t: 'button',
            icon: styleIcon(piggyBankIcon, 'presentation_type'),
            l: 'Sales Pitch',
          },
          {
            t: 'button',
            icon: styleIcon(briefIcon, 'presentation_type'),
            l: 'Creative Brief',
          },
        ]}
        className="my-1 py-[1px]"
        onSelection={() => setTimeout(formik.submitForm, 100)} // artificial delay to see the selected state change
      />
    ),
    validate: formValidateFromZodSchema(
      z.object({
        presentation_type: z
          .string()
          .min(1, 'Please choose a presentation type'),
      }),
    ),
  },
  {
    key: 'topic',
    label: "What's your presentation about?",
    content: ({ formik }) => (
      <div className="flex flex-col">
        <TextareaField
          name="topic"
          inputClassName="resize-none h-36 p-2"
          placeholder="For example, a presentation about the history of cheese"
          autoComplete="off"
          autoFocus
        />
        <div className="mt-6 flex items-center justify-end">
          <FieldError name="topic" className="mt-0 grow pr-2" />
          <Button
            disabled={formik.isSubmitting || !formik.isValid}
            type="submit"
            className="h-[44px] min-w-[120px] rounded-lg px-4 py-2"
          >
            Next
          </Button>
        </div>
      </div>
    ),
    validate: formValidateFromZodSchema(
      z.object({
        topic: z
          .string()
          .min(1, "I'll need some inspiration to get things moving"),
      }),
    ),
  },
  {
    key: 'style',
    label: 'Styling',
    content: ({ formik }) => (
      <ButtonSelection
        name="style"
        buttonVariant="square"
        buttons={[
          { t: 'button', l: 'Playful', icon: styleIcon(playfulIcon) },
          { t: 'button', l: 'Classic', icon: styleIcon(classicIcon) },
          { t: 'button', l: 'Graphic', icon: styleIcon(graphicIcon) },
        ]}
        className="my-1 py-[1px]"
        onSelection={() => setTimeout(formik.submitForm, 100)} // artificial delay to see the selected state change
      />
    ),
    validate: formValidateFromZodSchema(
      z.object({
        style: z.string().min(1, 'Please choose a style'),
      }),
    ),
  },
  {
    key: 'brand',
    label: 'Branding',
    content: ({ formik }) => (
      <div className="relative">
        <TextareaField
          name="brand"
          placeholder="Optional - Enter a brand name"
          inputClassName="p-2 h-36 resize-none" // make room for the button
          autoComplete="off"
          autoFocus
        />
        <div className="mt-6 flex items-center justify-end">
          <FieldError name="brand" className="mt-0 grow" />
          <Button
            disabled={formik.isSubmitting || !formik.isValid}
            type="submit"
            className="h-[44px] min-w-[120px] rounded-lg px-4 py-2"
          >
            Create
          </Button>
        </div>
      </div>
    ),
    validate: formValidateFromZodSchema(
      z.object({
        brand: z.string().optional(),
      }),
    ),
  },
]

// FUTURE: pass these from backend
// NOTE: you must define a value for each input or react will moan about controlled/uncontrolled inputs
const initialValues: IValues = {
  presentation_type: '',
  topic: '',
  style: '',
  brand: '',
}

export const AiPresentationForm = ({ onAction }: BlockComponent<{}>) => {
  const onFormSubmit = (action: Action) => {
    // FUTURE: send this to API by invoking a tool
    const values = action.data.values as IValues
    const message: string =
      `Please create a presentation about _${values.topic.trim()}_ in a _${values.style.trim()}_ style, using the _${values.presentation_type.trim()}_ presentation type.` +
      (values.brand ? ` Use the brand _${values.brand.trim()}_.` : '')
    onAction({ type: ActionType.REPLY, data: { value: message } })
  }

  return (
    <div className="-ml-[64px] -mr-[7px] w-auto sm:mx-auto sm:w-full">
      <FormSteps
        steps={formSteps}
        onAction={(action) =>
          onActionTypeHandler(action, ActionType.SUBMIT, onFormSubmit, onAction)
        }
        initialValues={initialValues}
      />
    </div>
  )
}
