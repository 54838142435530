import { ModalBackdrop } from './modal-backdrop.js'

export function DefaultModal({
  title,
  content,
  zIndex,
  backdrop,
  onClose,
}: {
  title?: string
  content: any
  zIndex: number
  backdrop?: boolean
  onClose?: () => void
}) {
  return (
    <ModalBackdrop zIndex={zIndex} backdrop={backdrop} onClose={onClose}>
      <div className="bg-cerosWhite shadow-normal-elevation flex w-full max-w-[320px] flex-col rounded-[10px] px-8 py-6 md:max-w-sm">
        {!!title && (
          <label className="mb-2 text-center text-xl font-medium">
            {title}
          </label>
        )}
        {content}
      </div>
    </ModalBackdrop>
  )
}
