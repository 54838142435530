import { Button } from '@/components/button/button.js'
import { cls } from '@/utils/utils.js'

interface IAbortButton {
  className?: string
  onClick: () => void
}
export const AbortButton = ({ onClick, className }: IAbortButton) => {
  return (
    <Button
      onClick={onClick}
      data-automation="stop-generating"
      className={cls(
        'text-lg',
        'h-11',
        'text-cerosBlack shadow-button border-0',
        'border-cerosWhite text-cerosBlack hover:border-cerosGrey300 active:border-cerosGrey400 bg-cerosWhite border-2 border-solid',
        'shadow-md hover:shadow-none active:shadow-none',
        'bg-cerosWhite hover:bg-cerosWhite active:bg-cerosGrey100',
        className,
      )}
      variant="secondary"
    >
      Stop generating
    </Button>
  )
}
