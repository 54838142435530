import type { Form as FormSchema } from '@ceros/gemma-api-spec'

import type { BlockComponent } from '@/models/blocks'

import { AiPresentationForm } from './form/template/ai-presentation-form'
import { ImageStylesForm } from './form/template/image-styles-form'

export const Form = ({ template, onAction }: BlockComponent<FormSchema>) => {
  switch (template) {
    case 'image_styles':
      return <ImageStylesForm onAction={onAction} />
    case 'ai_presentation':
      return <AiPresentationForm onAction={onAction} />
    default:
      return <div>Unknown template {template}</div>
  }
}
