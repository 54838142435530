import { useRef, useState } from 'react'

import { Button } from '@/components/button/button.js'
import { TextArea } from '@/components/text-area/text-area.js'
import type { CreateFeedbackForm } from '@/services/support'
import { useModalService } from '@/utils/view.js'

interface IFeedbackModal {
  onSubmit: (type: CreateFeedbackForm['type'], message: string) => void
}

export function FeedbackModal({ onSubmit }: IFeedbackModal) {
  const [type, setType] =
    useState<CreateFeedbackForm['type']>('Feature Request')
  const [message, setMessage] = useState<string>('')
  const modalService = useModalService()
  const ref = useRef<HTMLTextAreaElement>(null)

  const handleCancel = () => {
    modalService.close()
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setMessage('')
    if (ref.current) {
      ref.current.value = ''
    }
    onSubmit(type, message)
    modalService.close()
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey && message.trim() !== '') {
      handleSubmit(event)
    }
  }

  return (
    <form
      name="feedback-form"
      className="m-0 p-0"
      onSubmit={handleSubmit}
      onKeyDown={handleKeyDown}
    >
      <p className="mb-4 mt-5 text-base font-medium">Feedback type</p>

      <label className="flex gap-2">
        <input
          name="isFeatureRequest"
          type="radio"
          value={type}
          onChange={() => setType('Feature Request')}
          checked={type === 'Feature Request'}
        />
        Feature request
      </label>

      <label className="flex gap-2">
        <input
          name="isBugReport"
          type="radio"
          value={type}
          onChange={() => setType('Bug Report')}
          checked={type === 'Bug Report'}
        />
        Bug report
      </label>

      <label className="flex gap-2">
        <input
          name="isComment"
          type="radio"
          value={type}
          onChange={() => setType('Comment')}
          checked={type === 'Comment'}
        />
        Comment
      </label>

      <label className="flex gap-2">
        <input
          name="isQuestion"
          type="radio"
          value={type}
          onChange={() => setType('Question')}
          checked={type === 'Question'}
        />
        Question
      </label>

      <TextArea
        autoFocus
        className="mt-7 h-[80px] w-full text-sm"
        name="message"
        placeholder="Tell us more.."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        ref={ref}
      />

      <div className="mt-6 flex items-center justify-center gap-4">
        <Button
          type="button"
          size="small"
          variant="secondary"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          size="small"
          variant="primary"
          disabled={message.trim() === ''}
        >
          Submit
        </Button>
      </div>
    </form>
  )
}
