import React from 'react'

import { cls } from '@/utils/utils'

export const QuestionaryInput = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(
  (
    { className, ...rest }: React.InputHTMLAttributes<HTMLInputElement>,
    ref,
  ) => {
    return (
      <input
        ref={ref}
        className={cls(
          'text-cerosBlack border-cerosGrey300 placeholder-cerosGrey400 bg-cerosWhite h-[52px] rounded-xl border px-4 py-3 text-base font-medium outline-none',
          'hover:border-cerosGrey300 hover:bg-cerosGrey100',
          'active:border-cerosGrey300 active:bg-cerosGrey200',
          'focus:border-cerosPrimary focus:bg-cerosWhite',
          'disabled:border-cerosGrey300 disabled:bg-cerosGrey100 disabled:text-cerosGrey400 disabled:cursor-not-allowed',
          className,
        )}
        {...rest}
      />
    )
  },
)
