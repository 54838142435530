import { captureException } from '@sentry/react'
import axios from 'axios'
import { injected } from 'brandi'

import { DI_TYPE } from '@/di.types.js'
import type {
  Auth0StartModel,
  AuthDataModel,
  AuthModel,
  AuthType,
  UserInfo,
} from '@/models/auth.js'
import type { ApiClient } from '@/services/api-client.js'

class DeadlineExceededError extends Error {}
class ConnectionClosedError extends Error {}
class UpstreamError extends Error {}

export class AuthRepository {
  constructor(private apiClient: ApiClient) {}

  async startAuth0(): Promise<{
    data: Auth0StartModel | undefined
    status: number
  }> {
    try {
      const { data, status } = await this.apiClient.post<Auth0StartModel>(
        '/authentication/start',
      )

      return { data, status }
    } catch (e) {
      return { data: undefined, status: 500 }
    }
  }

  async getAuth0AccessToken(
    read_key: Auth0StartModel['read_key'],
    abortController?: AbortController,
  ): Promise<AuthDataModel | undefined> {
    const childAbortController = new AbortController()
    // this starts ticking as soon as user loads our webpage, and it can be left there - hence a long timer.
    const MAX_DELAY = 60 * 60 * 1000
    const fetch = (): Promise<AuthDataModel> =>
      new Promise((resolve, reject) => {
        this.apiClient.fetchEventSource(
          `/authentication/completed/stream?read_key=${read_key}`,
          {
            signal: childAbortController.signal,
            onerror(err) {
              reject(err)
            },
            onclose() {
              reject(new ConnectionClosedError())
            },
            onmessage(ev) {
              if (ev.event === 'done') {
                const data = JSON.parse(ev.data)
                resolve(data)
              }
              if (ev.event === 'error') {
                reject(new UpstreamError(ev.data ?? 'server error encountered'))
              }
            },
          },
        )
        abortController?.signal?.addEventListener('abort', () =>
          reject(abortController?.signal?.reason),
        )
      })
    const deadline = (): Promise<AuthDataModel> =>
      new Promise((_, reject) => {
        setTimeout(
          () =>
            reject(
              new DeadlineExceededError(
                `authentication ${read_key} didn't complete before deadline`,
              ),
            ),
          MAX_DELAY,
        )
      })
    try {
      return await Promise.race([fetch(), deadline()])
    } finally {
      childAbortController.abort()
    }
  }

  async getUserInfo(): Promise<{
    data: UserInfo | undefined
    status: number
  }> {
    try {
      const { data, status } = await this.apiClient.get<UserInfo>(
        '/authentication/userinfo',
      )

      return { data, status }
    } catch (e) {
      return { data: undefined, status: 500 }
    }
  }

  async exchangeTokenSSO(
    accessToken: string,
    refreshToken?: string,
    state?: string,
  ): Promise<{
    authInfo: AuthModel
    status: number
    emailNotWhitelisted: boolean
  }> {
    const errorReturn = {
      status: 500,
      authInfo: {
        type: 'auth0' as AuthType,
        data: {
          access_token: '',
          user: {
            id: '',
            email: '',
            // display_name: '',
          },
        },
      },
      emailNotWhitelisted: false,
    }
    try {
      const results = await this.apiClient.post(
        '/authentication/v2/exchange',
        JSON.stringify({
          access_token: accessToken,
          refresh_token: refreshToken, // although it seems odd to pass this up just to get it back, we need the server to know it for auth0 flow
          state,
        }),
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      if (results?.data?.error) {
        captureException(new Error('SSO failed'), {
          extra: { error: results.data.error },
        })
      }
      return {
        status: results.status,
        authInfo: {
          type: 'auth0' as AuthType,
          data: results.data,
        },
        emailNotWhitelisted: false,
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data.code === 'EMAIL_NOT_WHITELISTED') {
          errorReturn.emailNotWhitelisted = true
        }
      }
      captureException(e)
      return errorReturn
    }
  }

  async storeTokenSSO(
    writeKey: string,
    accessToken: string,
    refreshToken?: string,
  ): Promise<{
    status: number
  }> {
    const errorReturn = {
      status: 500,
    }
    try {
      const results = await this.apiClient.post(
        '/authentication/v2/store',
        JSON.stringify({
          write_key: writeKey,
          access_token: accessToken,
          refresh_token: refreshToken, // although it seems odd to pass this up just to get it back, we need the server to know it for auth0 flow
        }),
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      if (results?.data?.error) {
        captureException(new Error('SSO failed'), {
          extra: { error: results.data.error },
        })
      }
      return {
        status: results.status,
      }
    } catch (e) {
      captureException(e)
      return errorReturn
    }
  }

  async acceptTerms(
    termsAndConditions: boolean,
    privacyPolicy: boolean,
  ): Promise<{
    status: number
    detail: string | undefined
  }> {
    const errorReturn = {
      status: 500,
      detail: 'Failed to update terms status',
    }
    try {
      const results = await this.apiClient.post(
        '/user/properties/terms',
        JSON.stringify({
          tac_accepted: termsAndConditions,
          privacy_policy_accepted: privacyPolicy,
        }),
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )

      return {
        status: results.status,
        detail: results.data?.detail,
      }
    } catch (e) {
      captureException(e)
      return errorReturn
    }
  }
}

injected(AuthRepository, DI_TYPE.ApiClient)
