import { injected } from 'brandi'
import { action, makeObservable, reaction } from 'mobx'

import { DI_TYPE } from '@/di.types'
import { ConversationsService } from '@/services/conversations'
import type { RouterService } from '@/services/router'

import { BaseViewModel } from './base-view-model'

export class ConversationLatestViewModel extends BaseViewModel {
  constructor(
    private conversationsService: ConversationsService,
    private routerService: RouterService,
  ) {
    super()
    makeObservable(this)

    reaction(
      () => [this.isActive, this.conversationsService.initialized],
      ([isActive, conversationsServiceInitialized]) => {
        if (isActive && conversationsServiceInitialized) {
          this.handleLatestChatRoute()
        }
      },
    )
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onInit(): void {}

  onDispose(): void {}

  @action
  public async handleLatestChatRoute() {
    const isAnyConversations = this.conversationsService.items.length > 0

    if (isAnyConversations) {
      this.routerService.goTo(
        `/${ConversationsService.CONVERSATIONS_SLUG}/${this.conversationsService.items[0].id}`,
      )
    } else {
      this.routerService.goTo('/')
    }
  }
}

injected(
  ConversationLatestViewModel,
  DI_TYPE.ConversationsService,
  DI_TYPE.RouterService,
)
