import type { ConversationWithUserAccess } from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import { action, makeObservable, observable, runInAction } from 'mobx'

import { DI_TYPE } from '@/di.types'
import type {
  ConversationsService,
  ConversationUpdatedEventData,
} from '@/services/conversations'
import { type EventBusService, RegisteredEvents } from '@/services/event-bus'
import type { RouterService } from '@/services/router'

import { BaseViewModel } from './base-view-model'

export class SessionViewModel extends BaseViewModel {
  @observable id: string = ''
  @observable session: ConversationWithUserAccess | undefined = undefined

  constructor(
    private eventBusService: EventBusService,
    private conversationsService: ConversationsService,
    private routerService: RouterService,
  ) {
    super()
    makeObservable(this)
  }

  @action.bound
  setFactoryData(id: string) {
    runInAction(() => {
      this.id = id
    })
  }

  protected async onInit() {
    this.eventBusService.subscribe<ConversationUpdatedEventData>(
      RegisteredEvents.ConversationUpdated,
      (event) => this.updateCurrentSession(event.detail),
    )

    const session = await this.conversationsService.fetchConversation(this.id)
    this.setCurrentSession(session)
  }

  protected onDispose(): void {
    this.eventBusService.unsubscribe<ConversationUpdatedEventData>(
      RegisteredEvents.ConversationUpdated,
      (event) => this.updateCurrentSession(event.detail),
    )
  }

  private setCurrentSession(session: ConversationWithUserAccess | null) {
    if (session == null) {
      return this.routerService.replace('/conversations/not-found')
    }

    runInAction(() => {
      this.session = session
    })
  }

  private updateCurrentSession(updatedSession: ConversationUpdatedEventData) {
    if (!updatedSession || !this.session) return

    const newSession: ConversationWithUserAccess = {
      ...this.session,
      conversation: {
        ...this.session?.conversation,
        ...updatedSession,
      },
    }

    this.setCurrentSession(newSession)
  }
}

injected(
  SessionViewModel,
  DI_TYPE.EventBusService,
  DI_TYPE.ConversationsService,
  DI_TYPE.RouterService,
)
