import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'

import { cls } from '@/utils/utils.js'

interface IButton {
  size?: 'default' | 'small' | 'large'
  variant?: 'primary' | 'secondary' | 'danger' | 'white'
  children?: ReactNode
}

export const Button = forwardRef<
  HTMLButtonElement,
  IButton & ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      className,
      children,
      size = 'default',
      variant = 'primary',
      disabled,
      ...rest
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        disabled={disabled}
        className={cls(
          'text-cerosWhite flex items-center justify-center gap-x-2 rounded-md text-sm font-medium',
          disabled && 'cursor-not-allowed',

          size === 'default' && 'h-[36px] min-w-[100px] px-4 py-2',
          size === 'small' && 'h-[28px] min-w-[80px] px-4 py-1',
          size === 'large' && 'h-[44px] min-w-[120px] px-4 py-3',

          variant === 'primary' &&
            'bg-cerosPrimary hover:bg-cerosPrimaryHover active:bg-cerosPrimaryActive disabled:bg-cerosGrey400',
          variant === 'secondary' &&
            `border-cerosGrey400 text-cerosBlack hover:border-cerosGrey500 active:border-cerosGrey600
        disabled:border-cerosGrey300 disabled:text-cerosGrey400
        border bg-transparent
        hover:bg-transparent active:bg-transparent disabled:bg-transparent`,
          variant === 'white' &&
            'bg-cerosWhite hover:bg-cerosWhiteHover active:bg-cerosWhiteActive text-cerosGrey400',
          variant === 'danger' &&
            'bg-cerosDanger hover:bg-cerosDangerHover active:bg-cerosDangerActive disabled:bg-cerosGrey400',
          className,
        )}
        {...rest}
      >
        {children}
      </button>
    )
  },
)

Button.displayName = 'Button'
