import type { Blocks as BlocksSchema } from '@ceros/gemma-api-spec'
import { Fragment } from 'react'

import type { ActionHandler } from '@/models/blocks'

import { blockToComponent } from '../blocks-content'

interface IBlockIterator {
  blocks: Array<BlocksSchema>
  className?: string
  onAction?: ActionHandler
}

export const BlockIterator = ({ blocks, ...rest }: IBlockIterator) => {
  return (
    <>
      {blocks.map((block, index) => {
        return <Fragment key={index}>{blockToComponent(block, rest)}</Fragment>
      })}
    </>
  )
}
