import { useMemo, useState } from 'react'

import { Button } from '@/components/button/button.js'
import { TextArea } from '@/components/text-area/text-area.js'
import { useModalService } from '@/utils/view.js'

export type ReportType = 'chat' | 'gem'

interface IReportModal {
  type?: ReportType
  onSubmit: (report: {
    isNotSafe: boolean
    isNotAccurate: boolean
    isNotHelpful: boolean
    message: string
  }) => void
}

export function ReportModal({ onSubmit, type }: IReportModal) {
  const modalService = useModalService()

  const [isNotSafe, setIsNotSafe] = useState(false)
  const [isNotAccurate, setIsNotAccurate] = useState(false)
  const [isNotHelpful, setIsNotHelpful] = useState(false)
  const [message, setMessage] = useState('')

  const isSubmitAllowed = useMemo(
    () => !!message.trim() || isNotSafe || isNotAccurate || isNotHelpful,
    [isNotAccurate, isNotHelpful, isNotSafe, message],
  )

  const handleCancel = () => {
    modalService.close()
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    onSubmit({
      isNotSafe,
      isNotAccurate,
      isNotHelpful,
      message,
    })

    modalService.close()
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey && isSubmitAllowed) {
      handleSubmit(event)
    }
  }

  const description = `This ${type === 'gem' ? 'Gem' : 'response'} was:`

  return (
    <form
      name="report-form"
      className="m-0 p-0"
      onSubmit={handleSubmit}
      onKeyDown={handleKeyDown}
    >
      <p className="mb-2 text-sm font-medium">{description}</p>

      <label className="flex gap-2">
        <input
          name="isNotSafe"
          type="checkbox"
          checked={isNotSafe}
          onChange={(e) => setIsNotSafe(e.target.checked)}
        />
        Harmful/unsafe
      </label>

      <label className="flex gap-2">
        <input
          name="isNotAccurate"
          type="checkbox"
          checked={isNotAccurate}
          onChange={(e) => setIsNotAccurate(e.target.checked)}
        />
        Not accurate
      </label>

      <label className="flex gap-2">
        <input
          name="isNotHelpful"
          type="checkbox"
          checked={isNotHelpful}
          onChange={(e) => setIsNotHelpful(e.target.checked)}
        />
        Not helpful
      </label>

      <TextArea
        autoFocus
        className="mt-4 h-[80px] w-full text-sm"
        name="message"
        placeholder="Tell us what we can improve"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />

      <div className="mt-6 flex items-center justify-center gap-4">
        <Button
          type="button"
          size="small"
          variant="secondary"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          size="small"
          variant="primary"
          disabled={!isSubmitAllowed}
        >
          Submit
        </Button>
      </div>
    </form>
  )
}
