import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect } from 'react'

import { DefaultModal } from '@/components/modal/default-modal.js'
import { useModalService, useRouterService } from '@/utils/view.js'

const DEFAULT_Z_INDEX = 50

export const ModalServiceView = observer(() => {
  const modalService = useModalService()
  const routerService = useRouterService()

  const handleRouteChange = useCallback(() => {
    // FIXME: We might remove this if decide to use separate routes for modals
    modalService.closeAll()
  }, [modalService])

  useEffect(() => {
    const unlisten = routerService.history.listen(handleRouteChange)
    return () => {
      unlisten()
    }
  }, [handleRouteChange, routerService.history])

  if (!modalService.isOpen) {
    return null
  }

  return (
    <>
      {modalService.modalQueue.map((modal, index) => {
        const zIndex = DEFAULT_Z_INDEX + index
        const renderBackdrop = index === modalService.modalQueue.length - 1
        const key = `modal.id-${modal.id}`
        if (modal.custom) {
          const CustomModal = React.cloneElement(modal.content, {
            backdrop: renderBackdrop,
          })
          return <React.Fragment key={key}>{CustomModal}</React.Fragment>
        }
        return (
          <DefaultModal
            key={key}
            title={modal.title}
            content={modal.content}
            zIndex={zIndex}
            backdrop={renderBackdrop}
            onClose={modal.onClose}
          />
        )
      })}
    </>
  )
})

ModalServiceView.displayName = 'ModalServiceView'
