import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'

import { cls } from '@/utils/utils.js'
import type { EditImageViewModel } from '@/view-models/edit-image.js'

interface IEditDrawingTool {
  vm: EditImageViewModel
  index?: number
}

const emptyFunction = () => {}

const getCursor = (width: number, enabled: boolean) => {
  if (enabled) {
    const widthHalf = width ? width / 2 : 0
    const cursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%231B00FB" opacity="0.5" height="${width}" viewBox="0 0 ${width} ${width}" width="${width}"><circle cx="${widthHalf}" cy="${widthHalf}" r="${widthHalf}" fill="%231B00FB" /></svg>') ${widthHalf} ${widthHalf}, auto`
    return cursor
  }

  return undefined
}

export const EditDrawingTool = observer(({ vm, index }: IEditDrawingTool) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const startDrawingHandler = (event: any) => {
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d')
    if (context) {
      const currentPosition = {
        x: event.nativeEvent.offsetX,
        y: event.nativeEvent.offsetY,
      }
      vm.startDrawing(currentPosition)
      vm.drawCircle(
        { x: currentPosition.x, y: currentPosition.y },
        context,
        vm.magicEraser.value,
      )
    }
  }

  const drawHandler = (event: React.MouseEvent) => {
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d')
    if (context && vm.isDrawing) {
      const currentPosition = {
        x: event.nativeEvent.offsetX,
        y: event.nativeEvent.offsetY,
      }
      vm.drawCurve(
        { x: vm.pervPosition.x, y: vm.pervPosition.y },
        { x: currentPosition.x, y: currentPosition.y },
        context,
        vm.magicEraser.value,
      )
      vm.draw(vm.pervPosition, currentPosition)
    }
  }

  const stopDrawingHandler = () => {
    vm.stopDrawing()
  }

  useEffect(() => {
    const container = containerRef.current
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d', { willReadFrequently: true })
    const imageLoadHandler = () => {
      vm.setIsBusy(false)
    }
    const imageErrorHandler = (e: any) => {
      console.error(e)
      console.error('Error loading image', JSON.stringify(vm.actualImageUrl))
      vm.setIsBusy(false)
    }

    if (container && canvas && context) {
      vm.setIsBusy(true)
      const image = new Image()
      image.addEventListener('load', imageLoadHandler)
      image.addEventListener('error', imageErrorHandler)
      image.src = vm.actualImageUrl

      const resizeCanvas = () => {
        if (!image.width || !image.height) {
          return
        }
        const containerWidth = container.clientWidth
        const containerHeight = container.clientHeight

        const widthRatio = containerWidth / image.width
        const heightRatio = containerHeight / image.height
        const scale = Math.min(widthRatio, heightRatio)

        const scaledWidth = image.width * scale
        const scaledHeight = image.height * scale
        const imageScale = Math.min(
          scaledWidth / canvas.width,
          scaledHeight / canvas.height,
        )

        canvas.width = scaledWidth
        canvas.height = scaledHeight

        vm.reviveDrawing(canvas, imageScale)
      }

      image.onload = () => {
        resizeCanvas()
      }

      const resizeObserver = new ResizeObserver(resizeCanvas)
      resizeObserver.observe(container)

      return () => {
        resizeObserver.unobserve(container)
        image.removeEventListener('load', imageLoadHandler)
        image.removeEventListener('error', imageErrorHandler)
      }
    }
  }, [vm, vm.actualImageUrl])

  const cursor = getCursor(vm.magicEraser.value, vm.magicEraser.enabled)

  const actualImageUrl =
    typeof index === 'number' ? vm.inputUrls[index] : vm.actualImageUrl

  return (
    <div
      ref={containerRef}
      className="relative h-full w-full overflow-hidden"
      onContextMenu={(event) => event.preventDefault()} // disable context menu
    >
      <div
        className="h-full w-full bg-contain bg-center bg-no-repeat"
        style={{ backgroundImage: `url("${actualImageUrl}")` }}
      />

      <canvas
        id="edit-canvas"
        className={cls(
          'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform opacity-0',
          vm.magicEraser.enabled && 'opacity-40',
        )}
        style={{ cursor }}
        ref={canvasRef}
        onMouseDown={
          vm.magicEraser.enabled ? startDrawingHandler : emptyFunction
        }
        onMouseMove={vm.magicEraser.enabled ? drawHandler : emptyFunction}
        onMouseUp={vm.magicEraser.enabled ? stopDrawingHandler : emptyFunction}
        onMouseOut={vm.magicEraser.enabled ? stopDrawingHandler : emptyFunction}
      />
    </div>
  )
})

EditDrawingTool.displayName = 'EditDrawingTool'
