import { useCallback, useRef } from 'react'

interface Options {
  shouldPreventDefault?: boolean
  delay?: number
}

const isTouchEvent = (ev: Event): ev is TouchEvent => {
  return 'touches' in ev
}

const preventDefault = (ev: Event) => {
  if (!isTouchEvent(ev)) return

  if (ev.touches.length < 2 && ev.preventDefault) {
    ev.preventDefault()
  }
}

export const useLongPress = (
  callback: (e: TouchEvent | MouseEvent) => void,
  { shouldPreventDefault = false, delay = 300 }: Options = {},
) => {
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const target = useRef<EventTarget>()

  const start = useCallback(
    (event: TouchEvent | MouseEvent) => {
      // prevent ghost click on mobile devices
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
        })
        target.current = event.target
      }
      timeout.current = setTimeout(() => callback(event), delay)
    },
    [callback, delay, shouldPreventDefault],
  )

  const clear = useCallback(() => {
    // clearTimeout and removeEventListener
    timeout.current && clearTimeout(timeout.current)

    if (shouldPreventDefault && target.current) {
      target.current.removeEventListener('touchend', preventDefault)
    }
  }, [shouldPreventDefault])

  return {
    onMouseDown: (e: any) => start(e),
    onTouchStart: (e: any) => start(e),
    onMouseUp: clear,
    onMouseLeave: clear,
    onTouchEnd: clear,
  } as const
}
