import { useState } from 'react'

import CheckMarkIcon from '@/assets/icons/check-mark.svg?react'
import { PASSWORD_ERROR as ERROR, passwordSchema } from '@/models/password'
import { cls } from '@/utils/utils'

import { Button } from '../button/button'
import { TextInput } from '../input/text-input'

export interface PasswordResetPasswordProps {
  email: string
  initialErrorMessage: string | null
  isBusy: boolean
  onSubmit: (password: string) => void
}

const LiWithCheckMark = ({ children, isChecked }) => (
  <li
    className={cls(
      isChecked && 'text-cerosSuccessActive flex list-none items-center',
    )}
  >
    {isChecked ? (
      <CheckMarkIcon className="mr-2 flex-shrink-0" />
    ) : (
      <span className="mr-2" />
    )}
    <span>{children}</span>
  </li>
)

export const PasswordResetPassword = ({
  email,
  initialErrorMessage,
  isBusy,
  onSubmit,
}: PasswordResetPasswordProps): JSX.Element => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [errorMessage, setErrorMessage] = useState<string | null>(
    initialErrorMessage,
  )

  const isSubmitDisabled =
    !password.length || !!Object.keys(errors).length || isBusy

  const handleOnSubmit = () => {
    if (password !== confirmPassword) {
      setErrorMessage('The password does not match the confirmation password.')
      return
    }
    onSubmit(password)
  }

  const handlePasswordChange = (password) => {
    setPassword(password)
    setErrorMessage(null)

    const validationResult = passwordSchema.safeParse(password)
    const errors = {}
    if (!validationResult.success) {
      for (const issue of validationResult.error.issues) {
        errors[issue.message] = true
      }
    }
    setErrors(errors)
  }

  const handleConfirmPasswordChange = (confirmPassword) => {
    setConfirmPassword(confirmPassword)
    setErrorMessage(null)
  }

  const handleSubmitOnEnter = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      if (!isSubmitDisabled) handleOnSubmit()
    }
  }

  return (
    <div className="flex w-full flex-col">
      <h1 className="text-cerosBlack mb-2 text-3xl font-bold md:mt-14">
        Set a new password
      </h1>
      <div className={'text-cerosBlack px mb-14 text-sm font-medium'}>
        {`Choose a new strong password for ${email}`}
      </div>
      <TextInput
        placeholder="New password"
        type="password"
        value={password}
        onChange={handlePasswordChange}
        onKeyDown={handleSubmitOnEnter}
        className="mb-2"
      />
      <TextInput
        placeholder="Confirm new password"
        type="password"
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
        onKeyDown={handleSubmitOnEnter}
        className="mb-4"
      />
      {errorMessage ? (
        <div className="text-cerosDanger text-sm font-medium">
          {errorMessage}
        </div>
      ) : (
        <>
          <div className="text-cerosBlack text-sm font-medium">
            Password must be
          </div>
          <ul className="text-cerosBlack ml-5 list-inside list-disc text-sm font-medium">
            <LiWithCheckMark
              isChecked={password.length > 0 && !errors[ERROR.LENGTH]}
            >
              at least 8 characters long
            </LiWithCheckMark>
            <LiWithCheckMark
              isChecked={password.length > 0 && !errors[ERROR.UPPERCASE]}
            >
              contain uppercase letter
            </LiWithCheckMark>
            <LiWithCheckMark
              isChecked={password.length > 0 && !errors[ERROR.LOWERCASE]}
            >
              contain lowercase letter
            </LiWithCheckMark>
            <LiWithCheckMark
              isChecked={password.length > 0 && !errors[ERROR.NUMBER_OR_SYMBOL]}
            >
              contain number or symbol
            </LiWithCheckMark>
            <LiWithCheckMark
              isChecked={password.length > 0 && !errors[ERROR.NO_WHITESPACE]}
            >
              not containing spaces
            </LiWithCheckMark>
          </ul>
        </>
      )}
      <Button
        className="mt-14 inline-flex h-11 w-auto"
        variant="primary"
        disabled={isSubmitDisabled}
        onClick={() => handleOnSubmit()}
      >
        Reset password
      </Button>
    </div>
  )
}
