import { z } from 'zod'

import { Button } from '@/components/button/button'
import type { Action } from '@/models/blocks'
import { ActionType, type BlockComponent } from '@/models/blocks'
import { onActionTypeHandler } from '@/utils/action'
import { formValidateFromZodSchema } from '@/utils/blocks/form'
import { cls } from '@/utils/utils'

import icon3DModel from '../../../../../assets/images/image-styles/3d-model.jpg'
import iconAnalogFilm from '../../../../../assets/images/image-styles/analog-film.jpg'
import iconAnime from '../../../../../assets/images/image-styles/anime.jpg'
import iconCinematic from '../../../../../assets/images/image-styles/cinematic.jpg'
import iconComicBook from '../../../../../assets/images/image-styles/comic-book.jpg'
import iconCraftClay from '../../../../../assets/images/image-styles/craft-clay.jpg'
import iconDefault from '../../../../../assets/images/image-styles/default.jpg'
import iconDigitalArt from '../../../../../assets/images/image-styles/digital-art.jpg'
import iconEnhance from '../../../../../assets/images/image-styles/enhance.jpg'
import iconFantasyArt from '../../../../../assets/images/image-styles/fantasy-art.jpg'
import iconIsometric from '../../../../../assets/images/image-styles/isometric.jpg'
import iconLineArt from '../../../../../assets/images/image-styles/line-art.jpg'
import iconNeonPunk from '../../../../../assets/images/image-styles/neon-punk.jpg'
import iconPhotographic from '../../../../../assets/images/image-styles/photographic.jpg'
import iconPixelArt from '../../../../../assets/images/image-styles/pixel-art.jpg'
import { ButtonSelection } from '../component/button-selection'
import { FieldError } from '../component/field-error'
import type { IFormStep } from '../component/form-step'
import { FormSteps } from '../component/form-steps'
import { TextareaField } from '../component/textarea-field'

interface IValues {
  topic: string
  style: string
}

const styleIcon = (icon: string, type?: string) => (
  <div
    style={{
      backgroundImage: `url("${icon}")`,
    }}
    className={cls(
      'w-ful h-full bg-center bg-no-repeat',
      type === 'presentation_type' ? 'bg-[length:100px]' : 'bg-cover',
    )}
  />
)

const formSteps: IFormStep<IValues>[] = [
  {
    key: 'topic',
    label: 'What would you like an image of?',
    content: ({ formik }) => (
      <div className="flex flex-col">
        <TextareaField
          name="topic"
          inputClassName="resize-none h-36 p-2"
          placeholder="For example, a bustling cityscape bathed in neon lights at night."
          autoComplete="off"
          autoFocus
        />
        <div className="mt-6 flex items-center justify-end">
          <FieldError name="topic" className="mt-0 grow pr-2" />
          <Button
            disabled={formik.isSubmitting || !formik.isValid}
            type="submit"
            className="h-[44px] min-w-[120px] rounded-lg px-4 py-2"
          >
            Next
          </Button>
        </div>
      </div>
    ),
    validate: formValidateFromZodSchema(
      z.object({
        topic: z
          .string()
          .min(1, "I'll need some inspiration to get things moving"),
      }),
    ),
  },
  {
    key: 'style',
    label: 'Styling',
    content: ({ formik }) => (
      <div className="relative">
        <p className="text-cerosGrey500 mb-3">
          Select an effect style to customize your image.
        </p>
        <ButtonSelection
          name="style"
          buttonVariant="square"
          buttons={[
            { t: 'button', l: 'Default', icon: styleIcon(iconDefault) },
            { t: 'button', l: 'Enhance', icon: styleIcon(iconEnhance) },
            { t: 'button', l: 'Anime', icon: styleIcon(iconAnime) },
            {
              t: 'button',
              l: 'Photographic',
              icon: styleIcon(iconPhotographic),
            },
            { t: 'button', l: 'Digital Art', icon: styleIcon(iconDigitalArt) },
          ]}
          className="my-1 py-[1px]"
          onSelection={() => setTimeout(formik.submitForm, 100)} // artificial delay to see the selected state change
        />
        <ButtonSelection
          name="style"
          buttonVariant="square"
          buttons={[
            { t: 'button', l: 'Comic Book', icon: styleIcon(iconComicBook) },
            { t: 'button', l: 'Fantasy Art', icon: styleIcon(iconFantasyArt) },
            { t: 'button', l: 'Line Art', icon: styleIcon(iconLineArt) },
            { t: 'button', l: 'Craft Clay', icon: styleIcon(iconCraftClay) },
            { t: 'button', l: 'Cinematic', icon: styleIcon(iconCinematic) },
          ]}
          className="my-1 py-[1px]"
          onSelection={() => setTimeout(formik.submitForm, 100)} // artificial delay to see the selected state change
        />
        <ButtonSelection
          name="style"
          buttonVariant="square"
          buttons={[
            { t: 'button', l: 'Pixel Art', icon: styleIcon(iconPixelArt) },
            { t: 'button', l: '3D Model', icon: styleIcon(icon3DModel) },
            { t: 'button', l: 'Analog Film', icon: styleIcon(iconAnalogFilm) },
            { t: 'button', l: 'Neon Punk', icon: styleIcon(iconNeonPunk) },
            { t: 'button', l: 'Isometric', icon: styleIcon(iconIsometric) },
          ]}
          className="my-1 py-[1px]"
          onSelection={() => setTimeout(formik.submitForm, 100)} // artificial delay to see the selected state change
        />
      </div>
    ),
    validate: formValidateFromZodSchema(
      z.object({
        style: z.string().min(1, 'Please choose a style'),
      }),
    ),
  },
]

// FUTURE: pass these from backend
// NOTE: you must define a value for each input or react will moan about controlled/uncontrolled inputs
const initialValues: IValues = {
  topic: '',
  style: '',
}

export const ImageStylesForm = ({ onAction }: BlockComponent<{}>) => {
  const onFormSubmit = (action: Action) => {
    // FUTURE: send this to API by invoking a tool
    const { topic, style } = action.data.values as IValues
    const message: string = `Please create some images of _${topic.trim()}_ in a _${style}_ style.`
    onAction({ type: ActionType.REPLY, data: { value: message } })
  }

  return (
    <div className="-ml-[64px] -mr-[7px] w-auto sm:mx-auto sm:w-full">
      <FormSteps
        steps={formSteps}
        onAction={(action) =>
          onActionTypeHandler(action, ActionType.SUBMIT, onFormSubmit, onAction)
        }
        initialValues={initialValues}
      />
    </div>
  )
}
