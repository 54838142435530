export class HeartbeatMonitor {
  private interval: number
  private heartbeatTimer: ReturnType<typeof setTimeout> | null = null
  private onMissed: () => void

  constructor(interval: number, onMissed: () => void) {
    this.interval = interval
    this.onMissed = onMissed
  }

  startMonitoring() {
    this.stopMonitoring()
    this.heartbeatTimer = setTimeout(this.onMissed, this.interval)
  }

  receivedHeartbeat() {
    this.startMonitoring()
  }

  stopMonitoring() {
    if (this.heartbeatTimer) {
      clearTimeout(this.heartbeatTimer)
      this.heartbeatTimer = null
    }
  }
}
