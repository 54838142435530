import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { LoadingDots } from '@/components/loading-dots/loading-dots.js'
import { Modal } from '@/components/modal/modal.js'
import type { BaseModalProps } from '@/components/modal/modal-backdrop.js'
import { DI_TYPE } from '@/di.types.js'
import type { ResultOrigin } from '@/services/mixpanel.js'
import { useViewModel } from '@/utils/view.js'

import { EditImageHeader } from './edit-header.js'
import { EditImagePanel } from './edit-panel.js'
import { EditImagePreview } from './edit-preview.js'

export interface IEditImagePage extends BaseModalProps {
  urls: string[]
  index: number
  dataSource: ResultOrigin
  height?: number
  width?: number
  messageId?: string
}

export const EditImagePage = observer(
  ({
    urls,
    index,
    dataSource,
    width,
    height,
    messageId,
    backdrop,
  }: IEditImagePage) => {
    const vm = useViewModel(DI_TYPE.EditImageViewModel)

    useEffect(() => {
      if (width && height) {
        vm.setImageDimensions({ width, height })
      }
    }, [width, height, vm])

    useEffect(() => {
      vm.initData({
        index,
        inputUrls: urls,
        dataSource,
        messageId,
      })
    }, [vm, index, urls, dataSource, messageId])

    return (
      <Modal kind="full" backdrop={backdrop}>
        <div className="flex h-full flex-col" onKeyDown={vm.onKeyDown}>
          <EditImageHeader vm={vm} />
          <div className="relative flex max-h-[calc(100%-52px)] w-full flex-1 flex-col items-center justify-center self-center">
            {vm.isBusy && !vm.enabled && (
              <div className="bg-cerosWhite absolute inset-0 z-30 flex items-center justify-center">
                <LoadingDots />
              </div>
            )}
            <div className="flex h-full w-full">
              {vm.inputUrls.length && <EditImagePreview vm={vm} />}
              {vm.enabled && <EditImagePanel vm={vm} />}
            </div>
          </div>
        </div>
      </Modal>
    )
  },
)

EditImagePage.displayName = 'EditImagePage'
