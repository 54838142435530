import { MenuButton } from '@szhsin/react-menu'
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { createElement, Fragment } from 'react'

import { DI_TYPE } from '@/di.types'
import type { IMenuGroup } from '@/services/sidebar'
import { cls } from '@/utils/utils'
import { useViewModel } from '@/utils/view'

import { AnimatedBorder } from '../animated-border/animated-border'
import { Menu, MenuDivider, MenuItem } from '../menu/menu'
import { Tooltip } from '../tooltip/tooltip'
import { UpgradeButton } from '../upgrade-button/upgrade-button'

export const ProfileMenu = ({
  actions,
  isOpen,
  setIsOpen,
  className,
  profileSection,
}: {
  actions: IMenuGroup[]
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  className?: string
  profileSection: ReactNode
}) => {
  const vm = useViewModel(DI_TYPE.SubscriptionPlanViewModel)

  return (
    <Menu
      align="start"
      direction="top"
      minSize={'min-w-[268px]'}
      arrow={true}
      portal={false}
      arrowProps={{ className: 'w-2 h-2' }}
      viewScroll="initial"
      overflow="hidden"
      gap={6}
      menuButton={
        <MenuButton
          className={cls(
            'bg-cerosWhite hover:bg-cerosGrey100 active:bg-cerosGrey200 mx-2 mt-4 flex h-[56px] gap-2 rounded-xl px-3.5 py-2',
            isOpen && 'bg-cerosGrey100',
            className,
          )}
        >
          {profileSection}
        </MenuButton>
      }
      onMenuChange={({ open }) => setIsOpen(open)}
    >
      {vm.canUpgrade && (
        <MenuItem className="justify-start gap-2">
          <AnimatedBorder className="hidden group-hover/animated-border:block group-focus/animated-border:block">
            <UpgradeButton
              onClick={() => vm.openPlanModal({ source: 'upgrade-button' })}
              className="w-[248px]"
            />
          </AnimatedBorder>
        </MenuItem>
      )}
      {actions.map((group, index) => {
        return (
          <Fragment key={group.id}>
            {group.data.map((action) => {
              const menuItem = (
                <MenuItem
                  key={action.id}
                  className="h-8 justify-start gap-2 py-5 pl-5"
                  onClick={action.onClick}
                >
                  {createElement(action.icon)}
                  {action.title}
                </MenuItem>
              )

              if (action.tooltipText) {
                return <Tooltip text={action.tooltipText}>{menuItem}</Tooltip>
              }

              return menuItem
            })}
            {actions.length - 1 !== index && <MenuDivider />}
          </Fragment>
        )
      })}
    </Menu>
  )
}
