import type { PromptTemplate } from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import toast from 'react-hot-toast'
import { z } from 'zod'

import { DI_TYPE } from '@/di.types.js'
import type { Api } from '@/services/api.js'
import type { StorageService } from '@/services/storage.js'
import type { UserService } from '@/services/user.js'

import { BaseViewModel } from './base-view-model.js'

type PromptType = PromptTemplate['prompt_type']

export type PromptViewData = {
  promptType: PromptType
  globalValue?: string
  userValue?: string
  visibleValue: string
  isDefault: boolean
  hasPendingChanges: boolean
  open: boolean
}

type PromptTemplateByType = {
  [promptType in PromptType]?: PromptTemplate
}

type PendingValues = {
  [promptType in PromptType]?: string
}

const toggleStateSchema = z.record(z.string(), z.boolean())

type ToggleState = {
  [promptType in PromptType]?: boolean
}

function keyByPromptType(items: PromptTemplate[]) {
  const byType = {}
  items.forEach((prompt) => {
    byType[prompt.prompt_type] = prompt
  })

  return byType
}
export class PromptsViewModel extends BaseViewModel {
  @observable globalPrompts: PromptTemplateByType | undefined = undefined
  @observable userPrompts: PromptTemplateByType | undefined = undefined
  @observable pendingValues: PendingValues = {}
  @observable promptToggleState: ToggleState = {}
  @observable isSaving = false

  constructor(
    private api: Api,
    private storageService: StorageService,
    private userService: UserService,
  ) {
    super()
    makeObservable(this)
  }

  @action.bound
  async onInit() {
    await this.loadPromptToggleState()
    this.loadGlobalPrompts()
    this.loadUserPrompts()
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onDispose(): void {}

  @action.bound
  async loadGlobalPrompts() {
    const result = await this.api.promptTemplates.getGlobalPromptTemplates()
    if (result.status === 200) {
      runInAction(() => {
        this.globalPrompts = keyByPromptType(result.body.items)
      })
    } else {
      toast.error('Failed to load Failed to load global prompts', {
        duration: Infinity,
      })
    }
  }

  @action.bound
  async loadUserPrompts() {
    const result = await this.api.promptTemplates.getUserPromptTemplates()
    if (result.status === 200) {
      runInAction(() => {
        this.userPrompts = keyByPromptType(result.body.items)
      })
    } else {
      toast.error('Failed to load Failed to load user prompts', {
        duration: Infinity,
      })
    }
  }

  @computed
  get isLoading() {
    return this.globalPrompts === undefined || this.userPrompts === undefined
  }

  @computed
  get isEnabled() {
    return this.userService.properties?.allow_prompt_overrides ?? false
  }

  @computed
  get prompts(): PromptViewData[] {
    const prompts = Object.keys(this.globalPrompts ?? {}).map(
      (promptType, index) => {
        const defaultOpenValue = index === 0
        const globalValue = this.globalPrompts?.[promptType]?.prompt_value
        const userValue = this.userPrompts?.[promptType]?.prompt_value
        const pendingValue = this.pendingValues?.[promptType]
        const visibleValue = pendingValue ?? userValue ?? ''

        return {
          promptType: promptType as PromptType,
          globalValue,
          userValue,
          visibleValue,
          isDefault: userValue === undefined || userValue === globalValue,
          hasPendingChanges:
            pendingValue !== undefined && userValue !== pendingValue,
          open: this.promptToggleState?.[promptType] ?? defaultOpenValue,
        }
      },
    )

    return prompts
  }

  @action.bound
  async onPromptChange(promptType: PromptType, value: string) {
    this.pendingValues[promptType] = value
  }

  @action.bound
  async onSave(promptType: PromptType) {
    const globalValue = this.globalPrompts?.[promptType]?.prompt_value
    const value = this.pendingValues[promptType] ?? undefined

    if (value && value.length > 0 && value !== globalValue) {
      this.save(promptType, value)
    } else {
      this.reset(promptType)
    }
  }

  private async save(promptType: PromptType, value: string) {
    const result = await this.api.promptTemplates.setPromptTemplate({
      params: { prompt_type: promptType },
      body: {
        prompt_value: value,
      },
    })

    if (result.status === 200) {
      runInAction(() => {
        this.userPrompts = {
          ...this.userPrompts,
          [promptType]: result.body,
        }
      })
    } else {
      toast.error('Failed to save prompt')
    }
  }

  @action.bound
  async onReset(promptType: PromptType) {
    this.reset(promptType)
  }

  private async reset(promptType: PromptType) {
    const result = await this.api.promptTemplates.resetPromptTemplate({
      params: { prompt_type: promptType },
    })

    if (result.status === 200) {
      runInAction(() => {
        delete this.pendingValues[promptType]
        this.userPrompts = {
          ...this.userPrompts,
          [promptType]: undefined,
        }
      })
    } else {
      toast.error('Failed to reset prompt')
    }
  }

  @action.bound
  async togglePrompt(promptType: PromptType, value: boolean) {
    this.promptToggleState[promptType] = value
    this.savePromptToggleState()
  }

  private async loadPromptToggleState() {
    const storedValue = await this.storageService.load('promptToggleState')
    if (storedValue) {
      try {
        const jsonValue = JSON.parse(storedValue)
        const validationResult = toggleStateSchema.safeParse(jsonValue)
        this.promptToggleState = validationResult.success
          ? validationResult.data
          : {}
      } catch (error) {
        console.error(error)
        this.storageService.remove('promptToggleState')
      }
    }
  }

  private async savePromptToggleState() {
    await this.storageService.save(
      'promptToggleState',
      JSON.stringify(this.promptToggleState),
    )
  }
}

injected(
  PromptsViewModel,
  DI_TYPE.Api,
  DI_TYPE.StorageService,
  DI_TYPE.UserService,
)
