import type { Gem } from '@ceros/gemma-api-spec'

import PlusIcon from '@/assets/icons/plus.svg?react'
import { TaskButton } from '@/components/task-button/task-button'
import { getTextColor } from '@/utils/color'

interface CustomGemsProps {
  gems: Gem[]
  onGemSelect: (gem: Gem) => void
  onGemAdd: () => void
  onGemDelete: (gem: Gem) => void
  onGemEdit: (gem: Gem) => void
  onGemShare: (gem: Gem) => void
}

export const CustomGems = ({
  gems,
  onGemAdd,
  onGemSelect,
  onGemDelete,
  onGemEdit,
  onGemShare,
}: CustomGemsProps) => {
  const addGemButton = (
    <TaskButton
      key={'add-gem'}
      icon={<PlusIcon className="fill-cerosWhite h-12 w-12" />}
      title={'Add a Gem'}
      textColor={'white'}
      className="to-cerosPrimary from-cerosIndigo border-cerosWhite bg-gradient-to-bl"
      onClick={() => onGemAdd()}
      data-automation="add-gem-button"
    />
  )

  const gemButtons = gems.map((gem) => (
    <TaskButton
      key={gem.id}
      emoji={gem.emoji}
      title={gem.title}
      backgroundColor={gem.background_color}
      textColor={getTextColor(gem.background_color)}
      onClick={() => onGemSelect(gem)}
      onDelete={() => onGemDelete(gem)}
      onEdit={() => onGemEdit(gem)}
      onShare={() => onGemShare(gem)}
      data-automation={`gem-button-${gem.id}`}
    />
  ))

  return addGemButton ? [addGemButton, ...gemButtons] : gemButtons
}

CustomGems.displayName = 'CustomGems'
