import { initContract } from '@ts-rest/core';
import { z } from 'zod';
const c = initContract();
export const featureFlagSchema = z.object({
    id: z.string().uuid(),
    value: z.boolean(),
    label: z.string(),
    description: z.string(),
    slug: z.string(),
});
export const featureFlagsSchema = z.object({
    items: z.array(featureFlagSchema),
});
export const userFeatureFlagsSchema = z.object({
    items: z.array(featureFlagSchema),
    // Production (and certain other envs) might preclude users changing their own flags
    //  this is UI hint to disable the whole interface
    isEditable: z.boolean(),
});
const featureFlagsErrorResponseSchema = z.discriminatedUnion('error_type', [
    // Given endpoint is disabled on this environment (e.g. production)
    z.object({ error_type: z.literal('endpoint_disabled') }),
]);
const setFeatureFlagRequestSchema = z.object({
    value: z.boolean(),
});
const resetFeatureFlagRequestSchema = z.object({});
const resetAllFeatureFlagsRequestSchema = z.object({});
export const contract = c.router({
    setFeatureFlag: {
        method: 'POST',
        path: '/feature-flags/user/:flag_id/set',
        responses: {
            200: featureFlagSchema,
            400: featureFlagsErrorResponseSchema,
        },
        body: setFeatureFlagRequestSchema,
        summary: 'Update state of given feature flag for current user',
    },
    resetFeatureFlag: {
        method: 'POST',
        path: '/feature-flags/user/:flag_id/reset',
        responses: {
            200: featureFlagSchema,
            400: featureFlagsErrorResponseSchema,
        },
        body: resetFeatureFlagRequestSchema,
        summary: 'Reset given feature flag to initial state for current user',
    },
    resetAllFeatureFlags: {
        method: 'POST',
        path: '/feature-flags/user/reset-all',
        responses: {
            200: featureFlagsSchema,
            400: featureFlagsErrorResponseSchema,
        },
        body: resetAllFeatureFlagsRequestSchema,
        summary: 'Reset all feature flags to initial state for current user',
    },
    getUserFeatureFlags: {
        method: 'GET',
        path: '/feature-flags/user',
        responses: {
            200: userFeatureFlagsSchema,
        },
        summary: 'Returns list of feature flags for current user',
    },
    getGlobalFeatureFlags: {
        method: 'GET',
        path: '/feature-flags/global',
        responses: {
            200: featureFlagsSchema,
        },
        summary: 'Returns list of feature flags with global defaults',
    },
});
