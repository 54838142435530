import { injected } from 'brandi'
import { action, makeObservable, observable, runInAction } from 'mobx'
import toast from 'react-hot-toast'

import { DI_TYPE } from '@/di.types.js'
import type { AuthRepository } from '@/repositories/auth.js'
import type { RouterService } from '@/services/router.js'
import type { UserService } from '@/services/user.js'

import { BaseViewModel } from './base-view-model.js'

export class TermsViewModel extends BaseViewModel {
  @observable isBusy = false

  constructor(
    private authRepository: AuthRepository,
    private routerService: RouterService,
    private userService: UserService,
  ) {
    super()
    makeObservable(this)
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onInit(): void {}

  onDispose(): void {}

  @action.bound
  async onContinue(termsAndConditions: boolean, privacyPolicy: boolean) {
    if (!termsAndConditions || !privacyPolicy) {
      toast.error(
        'You must accept all terms & conditions and the privacy policy to continue',
      )
      return
    }

    this.isBusy = true
    const { status, detail } = await this.authRepository.acceptTerms(
      termsAndConditions,
      privacyPolicy,
    )

    if (status === 204) {
      await this.userService.updateAuthenticatedUserProperties()
      this.routerService.goTo('/')
    } else {
      toast.error(detail || 'Failed to accept terms')
    }

    runInAction(() => {
      this.isBusy = false
    })
  }
}

injected(
  TermsViewModel,
  DI_TYPE.AuthRepository,
  DI_TYPE.RouterService,
  DI_TYPE.UserService,
)
