import type { OktaAuthOptions } from '@okta/okta-auth-js'
import OktaAuth from '@okta/okta-auth-js'

import { env } from '@/env'

export function getOktaConfig(): OktaAuthOptions {
  return {
    issuer: env().VITE_OKTA_ISSUER,
    clientId: env().VITE_OKTA_CLIENT_ID,
    // If you change the redirectUri it must be updated at Okta too. For example this is the link for dev:
    // https://ceros-admin.oktapreview.com/admin/app/oidc_client/instance/0oa1lbfs131cU9a3L0h8/#tab-general
    redirectUri: `${window.location.protocol}//${window.location.host}/callback`,
    scopes: ['openid', 'email', 'profile', 'offline_access'],
    pkce: true,
    responseMode: 'query',
    responseType: 'code',
    tokenManager: {
      storage: 'sessionStorage',
    },
  }
}

export function oktaAuthFactory() {
  return new OktaAuth(getOktaConfig())
}
