import { captureException } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Link, useSearchParams } from 'react-router-dom'

import { EmbeddedPrompt } from '@/components/auth/embedded-prompt'
import { OktaLoginForm } from '@/components/auth/okta-login-form'
import { SocialLogin } from '@/components/auth/social-login'
import { Spinner } from '@/components/spinner/spinner.js'
import { DI_TYPE } from '@/di.types.js'
import { LoginType } from '@/models/login'
import { useViewModel } from '@/utils/view'

export const LoginPage = observer(() => {
  const vm = useViewModel(DI_TYPE.LoginViewModel)
  const [searchParams] = useSearchParams()
  const [loginData] = useState(() => {
    try {
      return vm.getLoginDataFromSearchParams(searchParams)
    } catch (e) {
      captureException(e)
    }
  })

  useEffect(() => {
    if (loginData) {
      toast.remove('login-data-error')
    } else {
      toast.error('Invalid login data', {
        id: 'login-data-error',
        duration: Infinity,
      })
    }
  }, [loginData])

  useEffect(() => {
    if (!vm.isInitialized && !vm.isBusy && loginData) {
      vm.login(loginData)
    }
  }, [vm, loginData])

  const wasAuthenticated = useRef(vm.isAuthenticated)
  useEffect(() => {
    wasAuthenticated.current = vm.isAuthenticated
  }, [vm.isAuthenticated])

  return (
    <div className="flex grow flex-col">
      {!vm.isBusy &&
        loginData &&
        loginData.loginType === LoginType.enum.okta && (
          <div className="grid-cols-auto grid h-[540px] grid-rows-[1fr_min-content]">
            <div className="mx-auto mb-14 flex w-full max-w-[400px]">
              <div className="flex w-full flex-col">
                <h1 className="text-cerosBlack mb-8 text-3xl font-bold md:mt-14">
                  Sign in
                </h1>
                <SocialLogin authState={loginData.state} />
                <div className="text-cerosGrey400 my-5 w-full text-center text-sm font-medium">
                  or
                </div>
                <div className="h-64">
                  <OktaLoginForm authState={loginData.state} />
                </div>
                <div className="mt-8 w-full text-center text-sm">
                  <Link
                    to="/forgot-password"
                    className="text-cerosPrimary hover:underline"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      {!vm.isBusy &&
        loginData &&
        loginData.loginType === LoginType.enum.embedded && (
          <EmbeddedPrompt onContinue={vm.continueAuth0} />
        )}
      {(vm.isBusy || !loginData) && (
        <div className="flex grow justify-center">
          <Spinner className="h-10 w-10" />
        </div>
      )}
    </div>
  )
})

LoginPage.displayName = 'LoginPage'
