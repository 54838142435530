type HexColor = string

export const BG_GEM_COLORS = [
  '#B6EFCE',
  '#FCEBA5',
  '#F8C4B9',
  '#DAE3E2',
  '#DEC6E8',
  '#F0C3A2',
  '#A9DAD1',
  '#E0E88C',
  '#C3CBF7',
]

export function getTextColor(backgroundColor: HexColor): string {
  // Convert hex to RGB
  const r = parseInt(backgroundColor.substring(1, 3), 16)
  const g = parseInt(backgroundColor.substring(3, 5), 16)
  const b = parseInt(backgroundColor.substring(5, 7), 16)

  // Calculate luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b

  // Choose black font for light backgrounds, white for dark
  return luminance > 140 ? 'black' : 'white'
}

export function cycleColors(colors: HexColor[], index: number): HexColor {
  return colors[index % colors.length]
}
