import { useCurrentEditor } from '@tiptap/react'
import { observer } from 'mobx-react-lite'
import type React from 'react'
import { useEffect } from 'react'

import { getDocumentContent } from '../../utils/document'
import type { ConversationViewModel } from '../../view-models/conversation'

/**
 * it could be hook, but useCurrentEditor wouldn't work :(
 */
export const Updater: React.FC<{ vm: ConversationViewModel }> = observer(
  ({ vm }) => {
    const { editor } = useCurrentEditor()
    const { msg, index } = vm.currentDocument

    useEffect(() => {
      const isStreaming = msg?.renderMetadata.streaming === true
      if (msg) {
        if (isStreaming) {
          if (index === 0) {
            editor?.view.dom.classList.add('gemma-editor--streaming')
          }
          editor?.setEditable(false, false)
        } else {
          editor?.view.dom.classList.remove('gemma-editor--streaming')
          editor?.setEditable(true, false)
        }

        if (!isStreaming || index === 0) {
          const content = getDocumentContent(msg)
          queueMicrotask(() => {
            editor?.chain().setContent(content, false).run()
          })
        }
      }
    }, [msg, index, editor])

    useEffect(() => {
      vm.documentViewModel.editor = editor
    }, [editor, vm.documentViewModel])

    return null
  },
)
