import { forwardRef, type ReactNode } from 'react'

import type { IMenuItem } from '@/services/sidebar'
import { cls } from '@/utils/utils'

interface ISidebarItemIconWrapper {
  className?: string
  children?: ReactNode
}

function SidebarItemIconWrapper({
  children,
  className,
}: ISidebarItemIconWrapper) {
  return (
    <div
      className={cls(
        'box-border flex h-7 w-7 items-center justify-center rounded border border-dashed border-transparent',
        className,
      )}
    >
      {children}
    </div>
  )
}

interface ISidebarItemIcon {
  icon: IMenuItem['icon']
  className?: string
}

export const SidebarItemIcon = forwardRef<HTMLDivElement, ISidebarItemIcon>(
  ({ icon, className }, ref) => {
    return (
      <SidebarItemIconWrapper className={className}>
        {icon.type === 'icon' && <div ref={ref}>{icon.value}</div>}
        {icon.type === 'emoji' && (
          <div ref={ref} className="text-[20px]">
            {icon.value}
          </div>
        )}
        {icon.type === 'url' && (
          <div
            ref={ref}
            className="h-5 w-5 bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url("${icon.value}")` }}
          />
        )}
      </SidebarItemIconWrapper>
    )
  },
)
