import type { ReactNode } from 'react'

import CloseIcon from '@/assets/icons/close.svg?react'
import { cls } from '@/utils/utils.js'

import { IconButton } from '../icon-button/icon-button.js'

interface IHeader {
  title?: string
  icon?: ReactNode
  actions?: ReactNode
  onClose?: () => void
  className?: string
}

export const ModalHeader = ({
  title,
  icon,
  onClose,
  actions,
  className,
}: IHeader) => {
  return (
    <div
      className={cls(
        'border-cerosGrey200 flex h-[52px] items-center justify-between gap-4 border-b px-5 pl-4 pr-[10px]',
        className,
      )}
      style={{ appRegion: 'drag' }}
    >
      <div className="flex flex-grow items-center gap-2">
        {icon}
        {title && (
          <label className="text-cerosGrey600 flex-grow self-baseline text-sm font-medium">
            {title}
          </label>
        )}
      </div>
      <div className="flex items-center gap-4" style={{ appRegion: 'no-drag' }}>
        {actions}
        <IconButton
          className="h-7 w-7 p-[6px] hover:bg-[#e9ebec80] hover:backdrop-blur-md active:bg-[#dcdfe1cc] active:backdrop-blur-md"
          icon={<CloseIcon />}
          onClick={onClose}
        />
      </div>
    </div>
  )
}
