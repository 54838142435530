import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { unprocessableContentResponseSchema } from './error.js';
export const contentTypeEnum = z.enum(['image/png', 'image/jpg', 'image/jpeg']);
export const presignedURLRequestSchema = z.object({
    content_type: contentTypeEnum,
    file_name: z.string().optional(),
});
export const presignedPostFormDataSchema = z.object({
    key: z.string(),
    AWSAccessKeyId: z.string(),
    policy: z.string(),
    signature: z.string(),
    content_type: z.string().optional(),
    x_amz_security_token: z.string().optional(),
});
export const presignedURLResponseSchema = z.object({
    url: z.string(),
    read_url: z.string(),
    form_data: presignedPostFormDataSchema,
});
const c = initContract();
export const contract = c.router({
    startSignUp: {
        method: 'POST',
        path: '/files/presigned_urls',
        responses: {
            200: presignedURLResponseSchema,
            422: unprocessableContentResponseSchema,
        },
        body: presignedURLRequestSchema,
        summary: 'Get presigned URL to upload an image to S3',
    },
});
