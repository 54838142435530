export type DetachedPromiseControls<T> = {
  promise: Promise<T>
  resolve: (value?: unknown) => T
  reject: (value?: unknown) => Error
}

export function detachPromiseControls<T>(): DetachedPromiseControls<T> {
  let resolver
  let rejecter
  const promise = new Promise<T>((resolve, reject) => {
    resolver = resolve
    rejecter = reject
  })
  return {
    promise,
    resolve: resolver as unknown as () => T,
    reject: rejecter as unknown as () => Error,
  }
}

export function asyncRetry<T>(
  promiseFactory: (...args: unknown[]) => Promise<T>,
  retries = 3,
): Promise<T> {
  return promiseFactory().catch((err) => {
    if (--retries <= 0) throw err
    return asyncRetry(promiseFactory, retries)
  })
}

/**
 * Pauses the execution of an asynchronous function for a specified time.
 * @param {number} time - The amount of time to sleep in milliseconds.
 * @returns {Promise} A promise that resolves after the specified time.
 */
export async function sleep(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

/**
 * Creates a promise that rejects in a specified amount of time.
 * @param {number} time - The amount of time before the promise is rejected in milliseconds.
 * @returns {Promise} A promise that rejects after the specified time.
 */
export async function delayedRejection<T>(time: number) {
  return new Promise<T>((_resolve, reject) => setTimeout(reject, time))
}
