import { initContract } from '@ts-rest/core';
import { z } from 'zod';
const c = initContract();
export const promptTemplateSchema = z.object({
    prompt_value: z.string(),
    prompt_type: z.string(),
});
export const promptTemplatesSchema = z.object({
    items: z.array(promptTemplateSchema),
});
const setPromptTemplateRequestSchema = z.object({
    prompt_value: z.string(),
});
export const contract = c.router({
    setPromptTemplate: {
        method: 'POST',
        path: '/prompt-templates/user/:prompt_type/set',
        responses: {
            200: promptTemplateSchema,
        },
        body: setPromptTemplateRequestSchema,
        summary: 'Update the user override for a given prompt template',
    },
    resetPromptTemplate: {
        method: 'POST',
        path: '/prompt-templates/user/:prompt_type/reset',
        responses: {
            200: promptTemplateSchema,
        },
        body: null,
        summary: 'Reset the prompt template back to the default',
    },
    resetAllPromptTemplates: {
        method: 'POST',
        path: '/prompt-templates/user/reset-all',
        responses: {
            200: promptTemplatesSchema,
        },
        body: null,
        summary: 'Reset all prompt templates back to the defaults',
    },
    getUserPromptTemplates: {
        method: 'GET',
        path: '/prompt-templates/user',
        responses: {
            200: promptTemplatesSchema,
        },
        summary: 'Returns list of prompt template overrides for current user',
    },
    getGlobalPromptTemplates: {
        method: 'GET',
        path: '/prompt-templates/global',
        responses: {
            200: promptTemplatesSchema,
        },
        summary: 'Returns list of prompt template global defaults',
    },
});
