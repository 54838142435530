export const UpgradeFooter = () => {
  return (
    <>
      Looking to further amplify your creativity? Explore the{' '}
      <a
        href="https://www.ceros.com"
        target="_blank"
        rel="noreferrer"
        className="text-cerosPrimary underline"
      >
        Ceros Suite
      </a>
    </>
  )
}
