import vectorLine1 from '../../assets/images/vector-line-1.svg'
import vectorStar from '../../assets/images/vector-star.svg'

interface IProfileHeader {
  name?: string | null
}

export const ProfileHeader = ({ name }: IProfileHeader) => {
  return (
    <div className="font-object-sans md:md-h:text-[50px] whitespace-pre-wrap text-center text-[35px]">
      {!!name && (
        <>
          <div>Hey {name}! Your</div>
          Gemma
          <span className="relative ml-1">
            <span
              className="mr-1 inline-block h-9 w-9"
              style={{ backgroundImage: `url("${vectorStar}")` }}
            />
            <span className="relative text-[#5098D6]">
              {'your '}
              <span
                className="absolute -bottom-2 left-0 inline-block h-[15px] w-full bg-center bg-no-repeat"
                style={{ backgroundImage: `url("${vectorLine1}")` }}
              />
            </span>
          </span>
          rules.
        </>
      )}
      {!name && `Knowing you better boosts Gemma’s help!\u00A0\u00A0✨`}
    </div>
  )
}
