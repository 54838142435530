import { injected } from 'brandi'

import { DI_TYPE } from '@/di.types'
import type { Interests, Question, Roles, UserProperties } from '@/models/user'
import type { Api } from '@/services/api'
import type { ApiClient } from '@/services/api-client'

export class UserRepository {
  constructor(
    private apiClient: ApiClient,
    private api: Api,
  ) {}

  public async getOnboardingNameQuestion(
    abortController?: AbortController,
  ): Promise<{
    data: Question | undefined
    status: number
  }> {
    try {
      const { data, status } = await this.apiClient.get<Question>(
        `/user/onboarding/name-question/`,
        { signal: abortController?.signal },
      )
      return { data, status }
    } catch (error: any) {
      if (error.code === 'ERR_CANCELED') {
        return { data: undefined, status: 200 }
      } else {
        return { data: undefined, status: 500 }
      }
    }
  }

  public async getOnboardingRolesQuestion(
    abortController?: AbortController,
  ): Promise<{
    data: Question | undefined
    status: number
  }> {
    try {
      const { data, status } = await this.apiClient.get<Question>(
        `/user/onboarding/roles-question/`,
        { signal: abortController?.signal },
      )
      return { data, status }
    } catch (error: any) {
      return { data: undefined, status: 500 }
    }
  }

  public async getOnboardingInterestQuestion(
    abortController?: AbortController,
  ): Promise<{
    data: Question | undefined
    status: number
  }> {
    try {
      const { data, status } = await this.apiClient.get<Question>(
        `/user/onboarding/interests-question/`,
        { signal: abortController?.signal },
      )
      return { data, status }
    } catch (error: any) {
      return { data: undefined, status: 500 }
    }
  }

  public async getUserProperties(): Promise<{
    data: UserProperties | undefined
    status: number
  }> {
    try {
      const { data, status } =
        await this.apiClient.get<UserProperties>(`/user/properties/`)
      return { data, status }
    } catch (error: any) {
      return { data: undefined, status: 500 }
    }
  }

  public async updateUserProperties(formData: FormData): Promise<{
    data: UserProperties | undefined
    status: number
  }> {
    try {
      const { data, status } = await this.apiClient.patch<UserProperties>(
        `/user/properties/`,
        formData,
      )
      return { data, status }
    } catch (error: any) {
      return { data: undefined, status: 500 }
    }
  }

  public async getUserAvailableRoles() {
    try {
      const { data, status } = await this.apiClient.get<Roles>(
        '/user/properties/roles-available',
      )
      return { data, status }
    } catch (err: unknown) {
      return { data: undefined, status: 500 }
    }
  }

  public async getUserAvailableInterests() {
    try {
      const { data, status } = await this.apiClient.get<Interests>(
        '/user/properties/interests-available',
      )
      return { data, status }
    } catch (err: unknown) {
      return { data: undefined, status: 500 }
    }
  }

  public async onboardingStarted() {
    try {
      const { data, status } = await this.apiClient.post(
        '/user/properties/onboarding-started',
      )
      return { data, status }
    } catch (err: unknown) {
      return { data: undefined, status: 500 }
    }
  }

  public async onboardingSkipped() {
    try {
      const { data, status } = await this.apiClient.post(
        '/user/properties/onboarding-skipped',
      )
      return { data, status }
    } catch (err: unknown) {
      return { data: undefined, status: 500 }
    }
  }

  public async getUserSubscriptionData() {
    const { status, body } = await this.api.billing.getBillingSettings()

    if (status !== 200) {
      return null
    }

    return body
  }

  public async getStripeCheckoutSession() {
    const session = await this.api.billing.createCheckoutSession({
      body: {
        return_path: window.location.pathname, // send the user back to the same page after the checkout
      },
    })

    if (session.status !== 200) {
      return null
    }

    return session.body
  }

  public async completeStripeCheckout(sessionId: string) {
    const { status, body } = await this.api.billing.checkoutSessionComplete({
      body: {
        checkout_session_id: sessionId,
      },
    })

    if (status !== 200) {
      return null
    }

    return body.is_complete
  }

  public async getStripePortalSession() {
    const session = await this.api.billing.createPortalSession()

    if (session.status !== 200) {
      return null
    }

    return session.body
  }
}

injected(UserRepository, DI_TYPE.ApiClient, DI_TYPE.Api)
