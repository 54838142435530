import { Switch as SwitchUI } from '@headlessui/react'
import type { ComponentProps } from 'react'
import { useCallback, useState } from 'react'

type UseSwitchProps = {
  onToggle?: (enabled: boolean) => unknown
  initialValue: boolean
}

type SwitchProps = {
  enabled: boolean
  onToggle?: (enabled: boolean) => void
} & ComponentProps<typeof SwitchUI>

export const useSwitch = ({
  onToggle,
  initialValue = false,
}: UseSwitchProps) => {
  const [isEnabled, setIsEnabled] = useState(initialValue)

  const toggle = useCallback(() => {
    const newEnabledValue = !isEnabled
    setIsEnabled(newEnabledValue)

    if (onToggle != null) {
      onToggle(newEnabledValue)
    }
  }, [onToggle, isEnabled])

  return { toggle, isEnabled }
}

export const Switch = ({ enabled = false, onToggle, ...rest }: SwitchProps) => {
  return (
    <SwitchUI
      checked={enabled}
      onChange={onToggle}
      className={`${
        enabled ? 'bg-cerosSuccess' : 'bg-cerosGrey400'
      } relative inline-flex h-5 w-[38px] items-center rounded-full`}
      data-automation-enabled={enabled}
      {...rest}
    >
      <span className="sr-only">Enable notifications</span>
      <span
        className={`${
          enabled ? 'translate-x-[20px]' : 'translate-x-[2px]'
        } bg-cerosWhite inline-block h-4 w-4 transform rounded-full transition`}
      />
    </SwitchUI>
  )
}
