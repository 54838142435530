import * as RadixSlider from '@radix-ui/react-slider'

import { cls } from '@/utils/utils'

export function Slider({ className, ...rest }: RadixSlider.SliderProps) {
  return (
    <form>
      <RadixSlider.Root
        className={cls(
          'relative flex h-4 touch-none select-none items-center',
          className,
        )}
        {...rest}
      >
        <RadixSlider.Track className="bg-cerosGrey400 relative h-1 grow rounded-full">
          <RadixSlider.Range className="bg-cerosPrimary  radix-disabled:bg-cerosGrey500 absolute h-full rounded-full" />
        </RadixSlider.Track>
        <RadixSlider.Thumb className="radix-disabled:cursor-not-allowed bg-cerosWhite hover:bg-cerosGrey100 active:bg-cerosGrey200 border-cerosGrey300 radix-disabled:bg-cerosWhite box-border block h-4 w-4 cursor-ew-resize rounded-full border" />
      </RadixSlider.Root>
    </form>
  )
}
