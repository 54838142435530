import { useState } from 'react'
import { Link } from 'react-router-dom'

import { BackButton } from '../button/back-button'
import { Button } from '../button/button'
import { LabeledTextInput } from '../input/labeled-text-input'

export interface PasswordResetEmailProps {
  email: string
  errorMessage: string | null
  isBusy: boolean
  onSubmit: (email: string) => void
}

export const PasswordResetEmail = ({
  email: defaultEmail,
  errorMessage,
  isBusy,
  onSubmit,
}: PasswordResetEmailProps): JSX.Element => {
  const [email, setEmail] = useState(defaultEmail)

  return (
    <div className="flex w-full flex-col">
      <h1 className="text-cerosBlack mb-2 text-3xl font-bold md:mt-14">
        Reset your password
      </h1>
      <div className="text-cerosBlack px mb-14 text-sm font-medium">
        Enter the email associated with your account and we will send you a link
        to reset your password.
      </div>
      <Link to="/login" className="mb-4">
        <BackButton />
      </Link>
      <LabeledTextInput
        placeholder="Enter your email"
        className=""
        value={email}
        autoFocus={true}
        onChange={(e) => {
          if (e.target.value.trim() !== email) setEmail(e.target.value)
        }}
        onKeyDown={(e: any) => {
          if (e.target.value.trim() === '') return
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            onSubmit(e.target.value)
          }
        }}
      />
      <div className="text-cerosDanger my-2 h-3 text-sm font-medium empty:h-0">
        {errorMessage}
      </div>
      <Button
        className="mb-16 mt-4 h-11 w-[120px]"
        variant="primary"
        disabled={!email || isBusy}
        onClick={() => onSubmit(email)}
      >
        Send link
      </Button>
    </div>
  )
}
