import { injected } from 'brandi'
import isEqual from 'lodash/isEqual'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { type ChangeEvent, createElement, type FormEvent } from 'react'
import { toast } from 'react-hot-toast'

import { type Changes } from '@/components/select/select'
import { DI_TYPE } from '@/di.types'
import { type UserProperties } from '@/models/user'
import { UnsavedChangesModal } from '@/pages/modals/unsaved-changes'
import type { MixpanelService } from '@/services/mixpanel'
import type { ModalService } from '@/services/modal'
import type { RouterService } from '@/services/router'
import type { UserService } from '@/services/user'

import { BaseViewModel } from './base-view-model'

export class ProfileViewModel extends BaseViewModel {
  @observable private previousFormState: UserProperties | undefined
  @observable formState: UserProperties | undefined

  @observable currentResponsibility: string | undefined
  @observable currentInterests: string[]

  @observable
  private routerUnblock: (() => void) | undefined = undefined

  constructor(
    private userService: UserService,
    public modalService: ModalService,
    public routerService: RouterService,
    public mixpanelService: MixpanelService,
  ) {
    super()
    this.currentResponsibility = this.userService.properties?.roles[0]
    this.currentInterests = this.userService.properties?.interests ?? []
    makeObservable(this)
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onDispose(): void {}

  protected onInit(): void {
    this.mixpanelService.track('Profile Opened')

    reaction(
      () => this.userService.properties,
      () => {
        this.previousFormState = this.userService.properties
        this.formState = this.userService.properties
      },
      {
        fireImmediately: true,
      },
    )

    reaction(
      () => this.isDirty,
      (isDirty) => this.dirtyCheckMiddleware(isDirty),
    )
  }

  @computed
  get isDirty() {
    return !isEqual(this.previousFormState, this.formState)
  }

  @computed
  get name() {
    return this.userService.properties?.name
  }

  @computed
  get availableRoles() {
    return (this.userService.availableRoles?.roles ?? []).map((role) => {
      return role.name
    })
  }

  @computed
  get availableInterests() {
    return this.userService.availableInterests?.interests ?? []
  }

  @action.bound
  private dirtyCheckMiddleware(isDirty: boolean) {
    if (isDirty) {
      if (!this.routerUnblock) {
        this.routerUnblock = this.routerService.history.block((blocker) => {
          const isAlreadyOpen =
            this.modalService.findModalById('Unsaved changes')
          if (!isAlreadyOpen) {
            this.modalService.show(
              createElement(UnsavedChangesModal, {
                onDiscard: () => {
                  this.routerUnblock?.()
                  this.routerService.goTo(
                    blocker.location.pathname,
                    blocker.location.state,
                  )
                },
              }),
              'Unsaved changes',
            )
          }
        })
      }
    } else {
      this.routerUnblock?.()
    }
  }

  @action.bound
  fieldChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = e.target

    if (this.previousFormState && this.formState) {
      switch (name) {
        case 'name':
          this.formState = { ...this.formState, name: value }
          break
        case 'job_title':
          this.formState = { ...this.formState, job_title: value }
          break
        case 'organization':
          this.formState = { ...this.formState, organization: value }
          break
        case 'additional_info':
          this.formState = { ...this.formState, additional_info: value }
          break
        case 'interests[]':
          const found = this.formState.interests?.find((v) => v === value)
          if (found) {
            this.formState = {
              ...this.formState,
              interests: this.formState.interests?.filter((v) => v !== value),
            }
          } else {
            this.formState = {
              ...this.formState,
              interests: [...(this.formState.interests ?? []), value],
            }
          }

          this.previousFormState.interests?.sort()
          this.formState.interests?.sort()

          break
      }
    }
  }

  @action.bound
  rolesFiledChange(data: Changes<string>) {
    if (this.previousFormState && this.formState) {
      this.formState = {
        ...this.formState,
        roles: data.multiple ? data.values : [data.value],
      }
    }
  }

  @action.bound
  async onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)

    if (formData.get('interests[]') === null) {
      formData.set('interests[]', '')
    }

    if (this.isDirty) {
      const result = await this.userService.updateProperties(formData)
      if (result) {
        toast.success('User properties has been updated successfully')
        this.mixpanelService.trackProfileUpdate(result)
      } else {
        toast.error('Error during properties update')
      }
    }
  }
}

injected(
  ProfileViewModel,
  DI_TYPE.UserService,
  DI_TYPE.ModalService,
  DI_TYPE.RouterService,
  DI_TYPE.MixpanelService,
)
