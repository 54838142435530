import type { Brand, SearchBrand } from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { match } from 'path-to-regexp'
import { toast } from 'react-hot-toast'

import { DI_TYPE } from '@/di.types.js'
import { searchBrandResultSchema } from '@/models/brandfetch.js'
import type { BrandfetchRepository } from '@/repositories/brandfetch.js'
import type { Api } from '@/services/api.js'
import type { ApiClient } from '@/services/api-client.js'
import type { MixpanelService, ResultUsedType } from '@/services/mixpanel.js'
import type { RouterService } from '@/services/router.js'
import { bound } from '@/utils/utils.js'

import { BaseViewModel } from './base-view-model.js'

export interface BrandfetchLocationState {
  messageId?: string
  query?: string
}

export class BrandfetchViewModel extends BaseViewModel {
  @observable messageId: string | undefined = undefined
  @observable searchQuery = ''
  @observable selectedDomain?: SearchBrand['domain'] = undefined
  @observable selectedBrand?: Brand = undefined
  @observable results: SearchBrand[] = []
  @observable isBusy: boolean = false

  constructor(
    private brandfetchRepository: BrandfetchRepository,
    private routerService: RouterService,
    private mixpanelService: MixpanelService,
    private apiClient: ApiClient,
    private api: Api,
  ) {
    super()
    makeObservable(this)
  }

  setFactoryData(messageId: string | undefined) {
    this.messageId = messageId
    this.trackIntegrationOpened()
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onInit(): void {}

  onDispose(): void {}

  @computed
  get conversationId() {
    const fn = match('/conversations/:conversationId{/:integration}?', {
      decode: decodeURIComponent,
    })

    const result = fn(this.routerService.currentPath)

    if (result) {
      const { conversationId } = result.params as { conversationId: string }

      return conversationId
    }

    return undefined
  }

  @computed
  get launchedFrom() {
    return this.messageId ? 'Chat' : 'Sidebar'
  }

  @action.bound
  updateSearchQuery(query: string) {
    this.isBusy = true
    this.searchQuery = query
  }

  @action.bound
  async openDetail(domain: SearchBrand['domain']) {
    this.selectedDomain = domain
    this.loadBrand(domain)
  }

  @action.bound
  async loadBrand(domain: SearchBrand['domain']) {
    try {
      this.isBusy = true
      const res = await this.api.brandfetchGetBrand({
        params: { domain },
      })

      if (res.status === 200) {
        const result = res.body
        runInAction(() => {
          this.selectedBrand = result
        })
      } else {
        toast.error('Failed to load brand')
      }
      runInAction(() => {
        this.isBusy = false
      })
    } catch (e) {
      toast.error('Failed to load brand')
      console.error(e)
      runInAction(() => {
        this.isBusy = false
      })
    }
  }

  @action.bound
  async searchBrand(query: string) {
    if (query.trim() !== '') {
      try {
        this.isBusy = true
        const { data, status } =
          await this.brandfetchRepository.searchBrand(query)

        if (status === 200) {
          const result = searchBrandResultSchema.parse(data)

          runInAction(() => {
            this.results = result
          })
        } else {
          runInAction(() => {
            this.results = []
            this.isBusy = false
          })
        }

        this.trackIntegrationSearch(query)

        runInAction(() => {
          this.isBusy = false
        })
      } catch (e) {
        toast.error('Failed to search brand')
        console.error(e)
        runInAction(() => {
          this.results = []
          this.isBusy = false
        })
      }
    } else {
      runInAction(() => {
        this.results = []
        this.isBusy = false
      })
    }
  }

  private trackIntegrationOpened() {
    this.mixpanelService.trackIntegrationOpened('Brandfetch', this.launchedFrom)
  }

  private trackIntegrationSearch(query: string) {
    this.mixpanelService.trackIntegrationSearch(
      query,
      this.launchedFrom,
      'Brandfetch',
    )
  }

  @bound
  trackResultUsed(resultId: string, resultType: string, type: ResultUsedType) {
    this.mixpanelService.trackResultUsed({
      chatId: this.conversationId,
      integrationOrigin: this.launchedFrom,
      messageId: this.messageId,
      queryValue: this.searchQuery,
      resultOrigin: 'Brandfetch',
      resultUsedType: type,
    })
  }

  @action.bound
  closeDetail() {
    this.selectedDomain = undefined
    this.selectedBrand = undefined
    this.results = []
  }
}

injected(
  BrandfetchViewModel,
  DI_TYPE.BrandfetchRepository,
  DI_TYPE.RouterService,
  DI_TYPE.MixpanelService,
  DI_TYPE.ApiClient,
  DI_TYPE.Api,
)
