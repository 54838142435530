import { captureException } from '@sentry/react'
import { injected } from 'brandi'
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
  when,
} from 'mobx'
import { toast } from 'react-hot-toast'
import { z } from 'zod'

import { DI_TYPE } from '@/di.types.js'
import type { UnsplashRepository } from '@/repositories/unsplash.js'
import type { MixpanelService } from '@/services/mixpanel.js'
import type { ModalService } from '@/services/modal.js'
import type { RouterService } from '@/services/router.js'
import {
  copyToClipboard,
  createFileFromImageUrl,
  downloadFile,
} from '@/utils/utils.js'

import { BaseViewModel } from './base-view-model.js'

const unsplashSchema = z.array(
  z.object({
    id: z.string(),
    blur_hash: z.string().nullable(), // sometimes API reuturns null
    color: z.string(),
    alt_description: z.string().nullable(), // same as above
    height: z.number(),
    urls: z.object({
      full: z.string(),
      thumb: z.string(),
      raw: z.string(),
      regular: z.string(),
    }),
    width: z.number(),
  }),
)

type InitData = {
  messageId?: string
  conversationId?: string
}

export class UnsplashViewModel extends BaseViewModel {
  @observable images: any[] = []
  @observable isLoading: boolean = false
  @observable query: string = ''
  @observable page: number = 1
  @observable total: number = 0
  @observable totalPages: number = 1
  @observable messageId: string = ''
  @observable conversationId: string = ''
  @observable loadingCount: number = 0
  perPage: number = 30

  constructor(
    private unsplashRepository: UnsplashRepository,
    public routerService: RouterService,
    public modalService: ModalService,
    private mixpanelService: MixpanelService,
  ) {
    super()
    makeObservable(this)
    when(
      () => this.loadingCount === 1,
      () => this.trackIntegrationOpened(),
    )
    reaction(
      () => [this.query, this.loadingCount] as [string, number],
      ([query, loadingCount], prev) => {
        if (query !== '' && loadingCount === 1) {
          // NOTE: trigger on first open of modal
          this.trackIntegrationSearch(query)
        }
        if (
          query !== '' &&
          query !== prev[0] &&
          loadingCount === prev[1] &&
          loadingCount > 1
        ) {
          // NOTE: trigger on search query change
          this.trackIntegrationSearch(query)
        }
      },
    )
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onInit(): void {}

  onDispose(): void {}

  @action.bound
  async searchImages() {
    if (
      this.isLoading ||
      this.page - 1 === this.totalPages ||
      this.query === ''
    ) {
      return
    }

    runInAction(() => {
      this.isLoading = true
    })
    const { data, status } = await this.unsplashRepository.searchImages({
      query: this.query,
      page: this.page,
      per_page: this.perPage,
    })
    if (status === 200) {
      try {
        const results = unsplashSchema.parse(data.results)

        runInAction(() => {
          this.images = [...this.images, ...results]
          this.isLoading = false
          this.page = this.page + 1
          this.total = data.total
          this.totalPages = data.total_pages
          this.loadingCount = this.loadingCount + 1
        })
      } catch (e) {
        runInAction(() => {
          this.isLoading = false
        })
        // NOTE: had weird issue with zod parsing since Unsplash API sometimes returns null for blur_hash, so decided to wrap it in try/catch for schema parsing
        captureException(e)
      }
    }

    if (status === 500) {
      toast.error('Failed to load images')
      runInAction(() => {
        this.isLoading = false
      })

      return
    }

    return data.results
  }

  @action.bound
  async newImages() {
    if (this.isLoading || this.page - 1 === this.totalPages) {
      return
    }

    runInAction(() => {
      this.isLoading = true
    })
    const { data, status } = await this.unsplashRepository.newImages({
      page: this.page,
      per_page: this.perPage,
    })
    if (status === 200) {
      try {
        // NOTE: Editorial feed returns just an array of images, not an object with total and total_pages and results as in search
        const results = unsplashSchema.parse(data)

        runInAction(() => {
          this.images = [...this.images, ...results]
          this.isLoading = false
          this.page = this.page + 1
          // this.total = data.total NOTE: since editorial feed doesn't return total
          // this.totalPages = data.total_pages NOTE: same as above
          this.loadingCount = this.loadingCount + 1
        })
      } catch (e) {
        runInAction(() => {
          this.isLoading = false
        })
        // NOTE: had weird issue with zod parsing since Unsplash API sometimes returns null for blur_hash, so decided to wrap it in try/catch for schema parsing
        captureException(e)
      }
    }

    if (status === 500) {
      toast.error('Failed to load images')
      runInAction(() => {
        this.isLoading = false
      })

      return
    }

    return data
  }

  @action.bound
  async trackDownload(id: string): Promise<string | null> {
    const { data, status } = await this.unsplashRepository.trackDownload(id)

    if (status === 500) {
      toast.error('Failed to track download')
      return null
    }

    this.mixpanelService.trackResultUsed({
      chatId: this.conversationId,
      integrationOrigin: this.launchedFrom,
      messageId: this.messageId,
      messageContentTypes: ['image'],
      queryValue: this.query,
      resultOrigin: 'Unsplash',
      resultUsedType: 'download',
    })

    return data.url
  }

  @action.bound
  async onDownload(id: string) {
    const downloadUrl = await this.trackDownload(id)

    if (downloadUrl) {
      const file = await createFileFromImageUrl(downloadUrl)
      downloadFile(file)
    }
  }

  @action.bound
  trackCopyPath(_: string) {
    this.mixpanelService.trackResultUsed({
      chatId: this.conversationId,
      integrationOrigin: this.launchedFrom,
      messageId: this.messageId,
      messageContentTypes: ['image'],
      queryValue: this.query,
      resultOrigin: 'Unsplash',
      resultUsedType: 'copy-url',
    })
  }

  @action.bound
  async onCopyPath(path: string) {
    try {
      await copyToClipboard(path)
      this.trackCopyPath(path)
      toast.success('Copied to clipboard')
    } catch {
      toast.error('Error copying to clipboard')
    }
  }

  @action.bound
  setQuery(query: string) {
    this.query = query
    // Reset pagination
    this.page = 1
    this.totalPages = 1
    this.images = []
  }

  @action.bound
  trackIntegrationOpened() {
    this.mixpanelService.trackIntegrationOpened('Unsplash', this.launchedFrom)
  }

  @action.bound
  trackIntegrationSearch(query: string) {
    this.mixpanelService.trackIntegrationSearch(
      query,
      this.launchedFrom,
      'Unsplash',
    )
  }

  @action.bound
  initData({ messageId, conversationId }: InitData) {
    this.messageId = messageId ?? ''
    this.conversationId = conversationId ?? ''
  }

  @computed
  get launchedFrom() {
    return this.messageId ? 'Chat' : 'Sidebar'
  }

  nextPage(startIndex?: number, stopIndex?: number) {
    if (!startIndex || !stopIndex) return 1
    return Math.ceil(stopIndex / this.perPage)
  }
}

injected(
  UnsplashViewModel,
  DI_TYPE.UnsplashRepository,
  DI_TYPE.RouterService,
  DI_TYPE.ModalService,
  DI_TYPE.MixpanelService,
)
