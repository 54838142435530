import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'

import { LoadingDots } from '@/components/loading-dots/loading-dots.js'
import { ReadonlyFooter } from '@/components/readonly-footer'
import { DI_TYPE } from '@/di.types.js'
import { useFactoryViewModel } from '@/utils/view.js'

import { GemTile } from './gem-tile'

export const GemPage = observer(() => {
  const { gemId } = useParams()

  const vm = useFactoryViewModel(DI_TYPE.GemViewModel, [gemId])

  if (!vm.gem) {
    return (
      <div className="bg-cerosWhite absolute inset-0 z-30 flex items-center justify-center">
        <LoadingDots />
      </div>
    )
  }

  return (
    <div id="gem-layout" className="flex h-screen flex-col">
      <div className="flex h-full w-full flex-auto flex-wrap place-content-center">
        <GemTile
          gem={vm.gem}
          onCloneAndUseGem={vm.cloneAndUseGem}
          isCloning={vm.isCloning}
        />
      </div>
      {!vm.isAuthenticated && <ReadonlyFooter />}
    </div>
  )
})

GemPage.displayName = 'GemPage'
