import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'

import { DI_TYPE } from '@/di.types'
import { useFactoryViewModel } from '@/utils/view'

import { ConversationPage } from './conversation-page'
import { ConversationReadonlyPage } from './readonly/conversation-readonly-page'

export const Conversation = observer(() => {
  const { conversationId = '' } = useParams()

  const sessionVm = useFactoryViewModel(DI_TYPE.SessionViewModel, [
    conversationId,
  ])

  if (!sessionVm.session) return null

  return sessionVm.session.user_access.can_write ? (
    <ConversationPage />
  ) : (
    <ConversationReadonlyPage />
  )
})

Conversation.displayName = 'Conversation'
