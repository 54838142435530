import { Button } from '../button/button'

interface IEmbeddedPrompt {
  onContinue: () => void
}

export const EmbeddedPrompt = ({ onContinue }: IEmbeddedPrompt) => {
  return (
    <div className="mx-auto w-full max-w-[400px] text-center">
      <h1 className="text-cerosBlack	mb-8 mt-16 text-3xl font-bold">
        Welcome to Gemma
      </h1>
      <Button className="w-full" onClick={onContinue}>
        Sign in
      </Button>
    </div>
  )
}
