import type { Gem } from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import toast from 'react-hot-toast'

import { DI_TYPE } from '@/di.types'
import type { AuthService } from '@/services/auth'
import type { ConversationsService } from '@/services/conversations'
import { GemsService } from '@/services/gems'
import { LOGIN_RETURN_STORE_KEY } from '@/services/login'
import type { MixpanelService } from '@/services/mixpanel'
import type { RouterService } from '@/services/router'

import { BaseViewModel } from './base-view-model'

export class GemViewModel extends BaseViewModel {
  @observable id: string | undefined = undefined
  @observable gem: Gem | undefined = undefined
  @observable isCloning = false

  constructor(
    private gemsService: GemsService,
    private routerService: RouterService,
    private mixpanelService: MixpanelService,
    private authService: AuthService,
    private conversationsService: ConversationsService,
  ) {
    super()
    makeObservable(this)
  }

  onDispose(): void {} // FIX ME - make sure init and cleanup happens here correctly

  @computed
  get isAuthenticated() {
    return this.authService.isAuthenticated
  }

  @action.bound
  setFactoryData(id: string | undefined) {
    if (id) {
      this.id = id
    }
  }

  @action.bound
  async onInit() {
    reaction(
      () => this.gemsService.initialized,
      async (gemServiceInitialized) => {
        if (gemServiceInitialized) {
          try {
            const gem = this.id ? await this.gemsService.getGem(this.id) : null

            if (!gem) {
              this.routerService.replace('/gems/not-found')
              return
            }

            this.gem = gem
          } catch {
            toast.error('Something went wrong')
            this.routerService.goTo('/')
            return
          }
        }
      },
      {
        fireImmediately: true,
      },
    )
  }

  @computed
  get loginReturnUrl(): string {
    const urlParams = new URLSearchParams({
      [LOGIN_RETURN_STORE_KEY]: `${window.location.origin}/${GemsService.GEMS_SLUG}/${this.id}`,
    })
    return `/login?${urlParams.toString()}`
  }

  @action.bound
  private async useGem(gem: Gem) {
    const conversation = await this.conversationsService.createConversation(
      { gem_id: gem.id },
      { createSource: 'New chat Gem', gemTitle: gem.title },
    )

    this.isCloning = false

    if (!conversation) {
      toast.error('Error creating conversation')
      return
    }
    this.routerService.goTo(`/conversations/${conversation.id}`, {
      isExisting: false,
      expectGemmaResponse: true,
    })
  }

  @action.bound
  private async cloneGem(): Promise<Gem | undefined> {
    if (!this.gem || !this.isAuthenticated) {
      this.routerService.goTo(this.loginReturnUrl)
      return
    }

    this.isCloning = true

    if (this.gemsService.isGemOwner(this.gem)) {
      return this.gem
    }

    const { title, emoji, background_color, prompt, conversation_template_id } =
      this.gem
    const clonedGem = await this.gemsService.createGem({
      title,
      emoji,
      background_color,
      prompt,
      conversation_template_id,
      is_publicly_shared: false,
      is_disabled: false,
      is_draft: false,
    })

    if (!clonedGem) {
      toast.error('Error creating gem')
      return
    }

    this.mixpanelService.trackGemCloned({
      gemId: clonedGem?.id,
      originalGemId: this.gem.id,
      attachmentsLength: this.gem.attachments?.length ?? 0,
    })

    return clonedGem
  }

  @action.bound
  async cloneAndUseGem() {
    const clonedGem = await this.cloneGem()
    if (!clonedGem) return
    this.useGem(clonedGem)
  }
}

injected(
  GemViewModel,
  DI_TYPE.GemsService,
  DI_TYPE.RouterService,
  DI_TYPE.MixpanelService,
  DI_TYPE.AuthService,
  DI_TYPE.ConversationsService,
)
