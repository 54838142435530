import { MenuButton } from '@szhsin/react-menu'
import { format, formatRelative } from 'date-fns'
import type { ReactNode } from 'react'
import { useState } from 'react'

import MoreIcon from '@/assets/icons/more.svg?react'
import { env } from '@/env.js'
import { cls } from '@/utils/utils.js'

import { Menu, MenuItem } from '../menu/menu.js'
import { Tooltip } from '../tooltip/tooltip.js'

interface IMessage {
  from: string
  initials: string
  time?: Date
  avatar?: ReactNode
  children?: ReactNode
  disabled?: boolean
  onReport?: () => void
  onOpenPlayground?: () => void
}

const currentDate = new Date()

export const Message = ({
  from,
  initials,
  disabled,
  time,
  avatar,
  children,
  onReport,
  onOpenPlayground,
}: IMessage) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <div
      className={cls(
        'group/messageItem hover:bg-cerosWhite/30 relative rounded-lg p-2 pb-4 pl-[70px]',
        isMenuOpen && 'bg-cerosGrey100',
      )}
    >
      <div
        className={cls(
          'bg-cerosWhite absolute left-2 flex h-10 w-10 items-center justify-center rounded-md',
          disabled && 'bg-cerosGrey200 shadow-none',
        )}
      >
        {!avatar ? (
          <span
            className={cls(
              'text-cerosGrey600 text-sm uppercase',
              disabled && 'text-cerosGrey400',
            )}
          >
            {initials}
          </span>
        ) : (
          avatar
        )}
      </div>
      <div className="flex grow flex-col">
        <div className="mb-2 flex items-center gap-2">
          <label className="text-cerosGrey600 text-md font-medium">
            {from}
          </label>
          {time && (
            <Tooltip
              className="translate-x-5 text-center"
              text={formatRelative(time, currentDate)}
              placement="top-start"
            >
              <span className="text-cerosGrey400 text-sm">
                {format(time, 'HH:mm')}
              </span>
            </Tooltip>
          )}
        </div>
        {children}
      </div>

      {onReport && (
        <div className="absolute right-3 top-3">
          <Menu
            align="end"
            direction="bottom"
            minSize={
              env().VITE_ENABLE_LANGTAIL_PLAYGROUNDS
                ? 'min-w-[180px]'
                : 'min-w-[100px]'
            }
            arrow={true}
            arrowProps={{ className: 'w-2 h-2' }}
            viewScroll="close"
            menuButton={
              <MenuButton
                data-automation="message-actions"
                className={cls(
                  'bg-cerosGrey100 hover:bg-cerosGrey300 active:bg-cerosGrey300 invisible h-6 w-6 rounded-[4px] p-1 group-hover/messageItem:visible',
                  isMenuOpen && 'bg-cerosGrey300 visible',
                )}
              >
                <MoreIcon />
              </MenuButton>
            }
            onMenuChange={({ open }) => setIsMenuOpen(open)}
          >
            <MenuItem onClick={onReport}>Report</MenuItem>
            {env().VITE_ENABLE_LANGTAIL_PLAYGROUNDS && (
              <MenuItem onClick={onOpenPlayground}>
                See OpenAI requests
              </MenuItem>
            )}
          </Menu>
        </div>
      )}
    </div>
  )
}
