import { MenuButton } from '@szhsin/react-menu'

import EditIcon from '@/assets/icons/edit.svg?react'
import MoreIcon from '@/assets/icons/more.svg?react'
import ShareIcon from '@/assets/icons/share.svg?react'
import TrashIcon from '@/assets/icons/trash.svg?react'
import { cls } from '@/utils/utils'

import { Menu, MenuItem } from '../menu/menu'

type TaskButtonMenuProps = {
  className?: string
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  onEdit?: () => void
  onDelete?: () => void
  onShare?: () => void
}

export const TaskButtonMenu = ({
  isOpen,
  setIsOpen,
  onEdit,
  onDelete,
  onShare,
  className,
}: TaskButtonMenuProps) => {
  return (
    <div className={className}>
      <Menu
        className="relative z-50"
        align="start"
        direction="right"
        minSize={'min-w-[100px]'}
        arrow={true}
        arrowProps={{ className: 'w-2 h-2' }}
        viewScroll="close"
        onMenuChange={({ open }) => setIsOpen(open)}
        menuButton={
          <MenuButton
            data-automation="gem-actions"
            data-testid="task-button-menu"
            className={cls(
              'hover:bg-cerosGrey100 active:bg-cerosGrey200 hidden h-7 w-7 items-center justify-center rounded-lg group-focus-within/gem:flex group-hover/gem:flex',
              isOpen && 'bg-cerosGrey200 hover:bg-cerosGrey200 flex',
            )}
          >
            <MoreIcon />
          </MenuButton>
        }
      >
        {onShare && (
          <MenuItem
            onClick={onShare}
            className={'justify-normal gap-2'}
            data-automation="share-gem-button"
          >
            <ShareIcon />
            Share
          </MenuItem>
        )}
        {onEdit && (
          <MenuItem
            onClick={onEdit}
            className={'justify-normal gap-2'}
            data-automation="edit-gem-button"
          >
            <EditIcon />
            Edit
          </MenuItem>
        )}
        {onDelete && (
          <MenuItem
            onClick={onDelete}
            className={'text-cerosDanger justify-normal gap-2'}
            data-automation="delete-gem-button"
          >
            <TrashIcon />
            Delete
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}
