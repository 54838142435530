import { Security } from '@okta/okta-react'
import { useEffect, useMemo } from 'react'

import { useOktaAuth } from '../../utils/view.js'

export function useRestoreOriginalUri() {
  const restoreOriginalUri = useMemo(() => {
    return () => {
      // FUTURE: use router service to remove query params if this gets hit
      throw new Error('restoreOriginalUri not implemented')
    }
  }, [])

  return restoreOriginalUri
}

export const OktaAuthWrapper = ({ children }) => {
  const okta = useOktaAuth()
  const restoreOriginalUri = useRestoreOriginalUri()

  useEffect(() => {
    // putting this here so the warning in this link doesn't keep popping up
    // https://github.com/okta/okta-react/issues/227#issuecomment-1167375982
    okta.options.restoreOriginalUri = undefined
  })

  return (
    <Security oktaAuth={okta} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  )
}
