import type { ActionType } from '@/models/blocks'
import { type Action } from '@/models/blocks'

export function onActionTypeHandler<T extends Action>(
  action: T,
  actionType: ActionType,
  onMatchAction: (action: T) => void | Promise<void>,
  onMissAction?: (action: T) => void | Promise<void>,
) {
  if (action.type === actionType) {
    onMatchAction(action)
  } else if (onMissAction) {
    onMissAction(action)
  }
}
