import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { Choice } from '@/components/onboarding/choice'
import { QuestionaryInput } from '@/components/onboarding/questionary-inputs'

export const QuestionaryInputBind = observer(
  ({ value, onChange, name, ...rest }: any) => {
    return (
      <QuestionaryInput
        {...rest}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
    )
  },
)

export const ChoicesBind = observer(
  ({ name, value, onChange, choices, multi, ...rest }: any) => {
    const [selectedValues, setSelectedValues] = useState<string[]>(value)

    const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
      let newValues: string[] = []
      if (multi) {
        if (e.target.checked) {
          newValues = [...selectedValues, e.target.value]
        } else {
          newValues = selectedValues.filter((value) => value !== e.target.value)
        }
      } else {
        newValues = [e.target.value]
      }
      setSelectedValues(newValues)
      onChange(name, newValues)
    }

    return (
      choices as {
        name: string
        emoji: string
      }[]
    )?.map((choice) => (
      <Choice
        {...rest}
        key={choice.name}
        name={name}
        value={choice.name}
        label={`${choice.emoji} ${choice.name}`}
        multi={multi}
        onChange={handleSelect}
        checked={value.includes(choice.name)}
      />
    ))
  },
)
