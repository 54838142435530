import { Toaster as ReactHotToaster } from 'react-hot-toast'

export function Toaster() {
  return (
    <ReactHotToaster
      toastOptions={{
        style: {
          border: 'none',
          borderRadius: '8px',
          padding: '4px 2px 4px 4px',
          color: 'white',
          background: '#303234',
          fontFamily: 'Avenir Next',
          fontSize: '14px',
          lineHeight: '20px',
          margin: 0,
        },
        error: {
          style: {
            padding: '4px 2px 4px 8px',
          },
        },
        success: {
          style: {
            padding: '4px 2px 4px 8px',
          },
        },
      }}
    />
  )
}
