import { Fragment } from 'react'

import type { IMenuItem } from '@/services/sidebar'

import { Drawer, DrawerItem, DrawerTitle } from '../drawer/drawer'

export function SidebarItemMobileMenu({
  isOpen,
  setIsOpen,
  onRename,
  item,
}: {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  onRename: () => void
  item: IMenuItem
}) {
  return (
    <Drawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      header={<DrawerTitle>{item.title}</DrawerTitle>}
    >
      {item.actions.map((group) => {
        return (
          <Fragment key={group.id}>
            {group.data.map((action) => {
              const menuItem = (
                <DrawerItem
                  key={action.id}
                  onClick={() => {
                    setIsOpen(false)

                    if (action.id === 'rename') {
                      return onRename()
                    }
                    action.onClick()
                  }}
                >
                  {action.title}
                </DrawerItem>
              )

              return menuItem
            })}
          </Fragment>
        )
      })}
    </Drawer>
  )
}
