import type { BillintPlanType } from '@ceros/gemma-api-spec'
import type { FunctionComponentElement, ReactNode } from 'react'
import { useCallback } from 'react'
import Confetti from 'react-confetti'

import CloseIcon from '@/assets/icons/close.svg?react'
import { Button } from '@/components/button/button'
import { IconButton } from '@/components/icon-button/icon-button'
import { ModalBackdrop } from '@/components/modal/modal-backdrop'
import { cls } from '@/utils/utils'
import { useModalService } from '@/utils/view'

type SubscriptionPlanModalProps = {
  title: string
  description: string | FunctionComponentElement<any>
  currentPlanType?: BillintPlanType
  showConfetti?: boolean
  onClickUpgrade: () => void
  onClickManagePlan: () => void
}

const PlanBlock = ({
  name,
  price,
  description,
  children,
  className,
}: {
  name: string
  price: number
  description: string
  children: ReactNode
  className?: string
}) => {
  return (
    <div
      className={cls(
        'align-center flex flex-1 flex-col items-center justify-between gap-12 p-6',
        className,
      )}
    >
      <div className="flex flex-col items-center gap-6">
        <div className="flex flex-col items-center">
          <h2 className="text-center text-3xl">{name}</h2>
          <p className="text-cerosGrey500 p-1 text-center">${price}/month</p>
        </div>
        <span>{description}</span>
      </div>
      {children}
    </div>
  )
}

export const SubscriptionPlanModal = ({
  title,
  description,
  currentPlanType,
  onClickUpgrade,
  onClickManagePlan,
  showConfetti = false,
}: SubscriptionPlanModalProps) => {
  const modalService = useModalService()

  const close = useCallback(() => {
    modalService.close()
  }, [modalService])
  return (
    <ModalBackdrop zIndex={50} backdrop closeOnClick>
      {showConfetti && <Confetti />}

      <div
        className="bg-cerosWhite shadow-normal-elevation relative flex h-[670px] max-w-sm flex-col rounded-2xl px-8 py-6 sm:h-auto sm:w-[726px] sm:max-w-full"
        data-automation="subscription-plan-modal"
      >
        <IconButton
          className="absolute right-8 top-6 h-6 w-6"
          icon={<CloseIcon />}
          onClick={close}
          tabIndex={2}
        />
        <label className="mb-6 p-0 text-left text-xl font-medium" htmlFor="">
          {title}
        </label>
        <div className="flex flex-col-reverse sm:flex-col">
          <div className="border-cerosGrey300 flex h-full w-full flex-col overflow-hidden rounded-2xl border sm:h-[250px] sm:flex-row">
            <PlanBlock
              name="Free"
              price={0}
              description="50 messages every 7 days."
            >
              {(!currentPlanType || currentPlanType === 'free') && (
                <Button
                  disabled
                  className="disabled:bg-cerosGrey100 disabled:text-cerosGrey500 h-[44px] w-full"
                >
                  Your current plan
                </Button>
              )}
            </PlanBlock>
            <PlanBlock
              name="Pro"
              price={25}
              description="No limits on anything!"
              className="bg-cerosPurple"
            >
              {!currentPlanType || currentPlanType === 'free' ? (
                <Button
                  className="bg-brand-grad shadow-navigationButton h-[44px] w-full hover:cursor-pointer hover:shadow-none"
                  tabIndex={1}
                  onClick={onClickUpgrade}
                >
                  Upgrade to pro plan
                </Button>
              ) : (
                <Button
                  className="bg-brand-grad shadow-navigationButton h-[44px] w-full hover:cursor-pointer hover:shadow-none"
                  tabIndex={1}
                  onClick={onClickManagePlan}
                >
                  Manage your plan
                </Button>
              )}
            </PlanBlock>
          </div>
          <p className="text-cerosGrey500 mb-6 mt-0 w-full text-center text-sm font-normal sm:mb-0 sm:mt-6">
            {description}
          </p>
        </div>
      </div>
    </ModalBackdrop>
  )
}
