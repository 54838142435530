import type OktaAuth from '@okta/okta-auth-js'
import { captureException } from '@sentry/react'
import { injected } from 'brandi'
import { observable } from 'mobx'
import { toast } from 'react-hot-toast'

import { DI_TYPE } from '@/di.types.js'
import { LoginAction } from '@/models/login.js'
import type { AuthRepository } from '@/repositories/auth.js'
import type { AuthService } from '@/services/auth.js'
import type { Auth0Service } from '@/services/auth0.js'
import type {
  GetAndRemoveLoginReturn,
  GetLoginDataForState,
} from '@/services/login.js'
import type { RouterService } from '@/services/router.js'

import { BaseViewModel } from './base-view-model.js'

interface ICallbackOptions {
  state?: string
}

class CallbackError extends Error {}
class EmailNotWhitelistedError extends Error {}

export class AuthCallbackViewModel extends BaseViewModel {
  @observable isProcessingCallback = false

  constructor(
    private authRepository: AuthRepository,
    private authService: AuthService,
    private auth0Service: Auth0Service,
    public routerService: RouterService,
    private oktaAuth: OktaAuth,
    private getLoginDataForState: GetLoginDataForState,
    private getAndRemoveLoginReturn: GetAndRemoveLoginReturn,
  ) {
    super()
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onInit(): void {}

  onDispose() {
    this.abortController?.abort()
  }

  async processCallback(searchParams: URLSearchParams) {
    this.isProcessingCallback = true

    try {
      const error = searchParams.get('error') || undefined
      const error_description =
        searchParams.get('error_description') || undefined
      const state = searchParams.get('state') || undefined
      const code = searchParams.get('code') // redirect flow will have this on successful authentication
      const fromLogin = searchParams.get('fromLogin') // IDP flow will have this param on successful authentication
      const fromAuth0 = searchParams.get('fromAuth0') // auth0 flow will have this param once flow has started, wait for read key to populate

      if (error) {
        this.handleCallbackError(error, error_description)
      } else if (code) {
        await this.processOktaCallback({ state })
      } else if (fromLogin) {
        await this.processIdpCallback({ state })
      } else if (fromAuth0) {
        await this.processAuth0Callback({ state })
      } else {
        throw new CallbackError('Unknown callback flow')
      }
    } catch (err) {
      captureException(err)
      const message =
        err instanceof CallbackError ? err.message : 'Failed to authenticate'
      this.onCallbackError(message)
    }
  }

  private handleCallbackError(error: string, error_description?: string) {
    console.error(error, error_description)
    if (
      error === 'access_denied' &&
      error_description?.includes(
        'User is not assigned to the client application', // okta provides no error code, best we can do is check the error description
      )
    ) {
      this.routerService.goTo('/login/unassigned')
    } else {
      throw new Error(
        `Failed to authenticate: ${error}, ${error_description || 'n/a'}`,
      )
    }
  }

  private async processOktaCallback({ state }: ICallbackOptions) {
    // this will parse the code from the query params and convert to the tokens
    const tokenResponse = await this.oktaAuth.token.parseFromUrl()
    const accessToken = tokenResponse.tokens.accessToken?.accessToken
    const refreshToken = tokenResponse.tokens.refreshToken?.refreshToken

    if (!state) {
      throw new CallbackError('Missing state')
    }

    if (state !== tokenResponse.state) {
      throw new CallbackError('State mismatch')
    }

    if (accessToken) {
      try {
        await this.handleTokenSSO(state, accessToken, refreshToken)
        this.onLoginSuccess(state)
      } catch (err) {
        if (err instanceof EmailNotWhitelistedError) {
          this.routerService.goTo('/login/unassigned')
        }
      }
    } else {
      throw new CallbackError('Failed to get access token')
    }
  }

  private async processIdpCallback({ state }: ICallbackOptions) {
    // the requestContext for idp login should have sent a state, persist the same state for the token request, in case it is a write_key
    if (!state) {
      throw new CallbackError('Missing state')
    }

    // redirect to get a code now that the user has logged in with the IDP
    this.oktaAuth.token.getWithRedirect({ state })
  }

  private async processAuth0Callback({ state }: ICallbackOptions) {
    if (!state) {
      throw new CallbackError('Missing state')
    }

    const data = await this.auth0Service.waitForReadKeyDataToBePresent()
    if (!data) {
      throw new CallbackError('Failed to fetch auth data')
    }

    await this.authService.applyToken({ data, type: 'auth0' })
    this.onLoginSuccess(state)
  }

  private async handleTokenSSO(
    state: string,
    accessToken: string,
    refreshToken?: string,
  ) {
    const loginData = await this.getLoginDataForState(state)
    const action = loginData?.loginAction

    switch (action) {
      case LoginAction.enum.store:
        await this.storeTokenSSO(state, accessToken, refreshToken)
        break
      case LoginAction.enum.exchange:
      default:
        await this.exchangeTokenSSO(accessToken, refreshToken)
    }
  }

  // @action.bound
  private async exchangeTokenSSO(accessToken: string, refreshToken?: string) {
    const { status, authInfo, emailNotWhitelisted } =
      await this.authRepository.exchangeTokenSSO(accessToken, refreshToken)

    if (status === 200 && authInfo) {
      await this.authService.applyToken({ data: authInfo.data, type: 'auth0' })
    } else {
      if (emailNotWhitelisted === true) {
        throw new EmailNotWhitelistedError()
      }
      throw new CallbackError('Failed to exchange access token')
    }
  }

  // @action.bound
  private async storeTokenSSO(
    state: string,
    accessToken: string,
    refreshToken?: string,
  ) {
    const { status } = await this.authRepository.storeTokenSSO(
      state,
      accessToken,
      refreshToken,
    )

    if (status !== 204) {
      throw new CallbackError('Failed to exchange access token')
    }
  }

  onLoginSuccess(state: string) {
    // FUTURE: consider whether user has accepted terms & conditions
    const loginData = this.getLoginDataForState(state)
    const action = loginData?.loginAction

    switch (action) {
      case LoginAction.enum.store:
        let path = '/login/success'
        if (loginData?.utmSource) {
          path += `?utm_source=${loginData?.utmSource}`
        }
        this.routerService.goTo(path)
        break
      case LoginAction.enum.exchange:
      default:
        const loginReturn = this.getAndRemoveLoginReturn()
        const uri =
          loginReturn && Date.now() < loginReturn.createdAt + 5 * 60 * 1000
            ? loginReturn.uri
            : '/'
        this.routerService.goTo(uri)
    }
  }

  onCallbackError(message: string) {
    toast.error(message, {
      id: 'auth-callback-error',
      duration: Infinity,
    })
  }
}

injected(
  AuthCallbackViewModel,
  DI_TYPE.AuthRepository,
  DI_TYPE.AuthService,
  DI_TYPE.Auth0Service,
  DI_TYPE.RouterService,
  DI_TYPE.OktaAuth,
  DI_TYPE.GetLoginDataForState,
  DI_TYPE.GetAndRemoveLoginReturn,
)
