import type { ReactNode } from 'react'
import { useState } from 'react'

import { cls } from '@/utils/utils'

import { type Tab, ToggleContext } from './toggle-context'

type ToggleProps = {
  children: ReactNode
  defaultTab?: Tab
  onToggle?: () => void
  className?: string
}

export const Toggle = ({
  defaultTab,
  children,
  onToggle,
  className,
}: ToggleProps) => {
  const [activeTab, setActiveTab] = useState(defaultTab ?? 'left')

  const handleClick = (newState: Tab) => {
    if (activeTab === newState) return
    setActiveTab(newState)
    onToggle?.()
  }

  return (
    <ToggleContext.Provider value={{ activeTab, handleClick }}>
      <div
        className={cls(
          'bg-cerosWhite border-cerosGrey200 relative m-2 flex h-10 w-80 rounded-lg border',
          className,
        )}
      >
        {children}
      </div>
    </ToggleContext.Provider>
  )
}

Toggle.displayName = 'Toggle'
