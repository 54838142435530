import { injected } from 'brandi'

import { DI_TYPE } from '@/di.types.js'
import type { ApiClient } from '@/services/api-client.js'

export interface SearchBody {
  // name: string
  // user_id: string
  // email: string
  // chat_id: string
  // plugin_version: string
  // origin: string
  // environment: 'Production' | 'Development'
  query: string
  per_page?: number
  page?: number
}

export class UnsplashRepository {
  constructor(private apiClient: ApiClient) {}

  public async searchImages({
    query,
    page = 1,
    per_page = 30,
  }: SearchBody): Promise<{
    data: any
    status: number
  }> {
    try {
      const { data, status } = await this.apiClient.get<SearchBody>(
        `/proxy/unsplash/search/photos?query=${query}&page=${page}&per_page=${per_page}`,
      )
      return { data, status }
    } catch (error: any) {
      return { data: null, status: 500 }
    }
  }

  public async newImages({
    page = 1,
    per_page = 30,
  }: Omit<SearchBody, 'query'>): Promise<{
    data: any
    status: number
  }> {
    try {
      const { data, status } = await this.apiClient.get<SearchBody>(
        `/proxy/unsplash/photos?page=${page}&per_page=${per_page}`,
      )
      return { data, status }
    } catch (error: any) {
      return { data: null, status: 500 }
    }
  }

  public async trackDownload(id: string): Promise<{
    data: any
    status: number
  }> {
    try {
      const { data, status } = await this.apiClient.get<SearchBody>(
        `/proxy/unsplash/photos/${id}/download`,
      )
      return { data, status }
    } catch (error: any) {
      return { data: null, status: 500 }
    }
  }
}

injected(UnsplashRepository, DI_TYPE.ApiClient)
