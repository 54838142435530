import { z } from 'zod'

export const LoginType = z.enum(['embedded', 'okta'])
export const LOGIN_TYPE_QUERY_PARAM = 'login_type'

export const LoginAction = z.enum(['store', 'exchange'])
export const LOGIN_ACTION_QUERY_PARAM = 'login_action'

export const UTM_SOURCE_QUERY_PARAM = 'utm_source'

export interface ILoginData {
  state: string
  loginType: z.infer<typeof LoginType>
  loginAction: z.infer<typeof LoginAction>
  utmSource?: string
}

export const loginReturnSchema = z.object({
  uri: z.string(),
  createdAt: z.number(),
})

export type LoginReturn = z.infer<typeof loginReturnSchema>
