import { Outlet } from 'react-router-dom'

import { BreathingAnimation } from '../chat/breathing-animation'

export const AppLayout = () => {
  return (
    <>
      <BreathingAnimation />
      <Outlet />
    </>
  )
}
