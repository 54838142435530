import { injected } from 'brandi'
import LogRocket from 'logrocket'

import { DI_TYPE } from '@/di.types'
import { env } from '@/env.js'
import { APP_VERSION, isProductionBuild } from '@/utils/utils.js'

import { type EventBusService, RegisteredEvents } from './event-bus'

const urlBlockList =
  /^.*(\/socket.io\/|\/api\/v2\/api-keys.*|\/api\/v1\/auth\/token|\/api\/v1\/authn|\/api\/v1\/password\/change|\/oauth2\/.*|\/auth)$/
// eslint-disable-next-line max-len
const hostBlockList =
  /^.*(www.google-analytics.com\/.*|www.facebook.com\/.*|www.googletagmanager.com\/.*|app.pendo.io\/.*|connect.facebook.net\/.*|r.lr-in.com.*)$/

const queryParamReplacePattern = /(code)=.*[&$]/

export const REMOVED_CONST = '<Removed>'

function requestSanitizer(request: any) {
  if (
    request == null ||
    urlBlockList.test(request.url) ||
    hostBlockList.test(request.url)
  ) {
    return null
  }
  if (request.headers.authorization) {
    request.headers.authorization = REMOVED_CONST
  }
  if (request.hasOwnProperty('name')) {
    request.name = request?.name?.replace(queryParamReplacePattern, '')
  }
  if (request.hasOwnProperty('url')) {
    request.url = request?.url?.replace(queryParamReplacePattern, '')
  }
  return request
}

interface Identity {
  id: string
  email: string
  doNotTrack: boolean
}

export class LogRocketService {
  private enabled: boolean = isProductionBuild
  private identity: Identity | null = null

  constructor(private eventBusService: EventBusService) {
    if (!this.enabled) return

    const LOGROCKET_ID: string = env().VITE_LOGROCKET_APP_ID
    LogRocket.init(LOGROCKET_ID, {
      console: { isEnabled: true },
      network: { requestSanitizer },
      release: APP_VERSION,
      rootHostname: 'gemma.ai',
      mergeIframes: true,
      shouldSendData: () => {
        // do not send any data to LogRocket if identity is marked as doNotTrack
        if (this.identity?.doNotTrack) {
          return false
        }

        return true
      },
    })

    LogRocket.getSessionURL((sessionURL) => {
      this.eventBusService.publish(RegisteredEvents.LogRocketGetSessionURL, {
        sessionURL,
      })
    })
  }

  applyIdentity(id: string, email: string) {
    if (!this.enabled) return

    this.identity = {
      id,
      email,
      doNotTrack: email.toLowerCase().endsWith('@ceros.com'),
    }

    if (this.identity.doNotTrack) {
      // LogRocket has an uninstall method but they don't define it in their types
      const LogRocketWithUninstall = LogRocket as typeof LogRocket & {
        uninstall: () => void
      }
      LogRocketWithUninstall.uninstall()
      return
    }

    LogRocket.identify(id, { email })
  }
}

injected(LogRocketService, DI_TYPE.EventBusService)
