import type { Placement } from '@floating-ui/react'
import {
  autoUpdate,
  flip,
  offset,
  useFloating,
  useHover,
  useInteractions,
  useTransitionStatus,
} from '@floating-ui/react'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'

import { cls } from '@/utils/utils.js'

interface ITooltip {
  containerClassName?: string
  className?: string
  children: ReactNode
  text: string
  placement?: Placement
  moveX?: number
  moveY?: number
  /**
   * Can be set to true to trigger opening the tooltip.
   * It's a way to open it w/o user interaction, but it will still get closed on hover out.
   */
  autoOpen?: true
  position?: 'absolute' | 'fixed'
}

export function Tooltip({
  containerClassName,
  className,
  children,
  text,
  placement,
  moveX,
  moveY,
  autoOpen,
  position,
}: ITooltip) {
  const [wasAutoOpen, setWasAutoOpen] = useState<boolean>()
  const [isOpen, setIsOpen] = useState(autoOpen ?? false)

  const setIsOpenWithOverride = (value: boolean) => {
    if (autoOpen === undefined) {
      setIsOpen(value)
    }
  }

  const { x, y, strategy, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpenWithOverride,
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip()],
    placement,
    strategy: position === 'fixed' ? 'fixed' : 'absolute',
  })

  const hover = useHover(context, { delay: { open: 800, close: 0 } })

  const { getReferenceProps, getFloatingProps } = useInteractions([hover])
  const transitionStatus = useTransitionStatus(context)

  useEffect(() => {
    if (autoOpen) {
      setIsOpen(autoOpen)
      setWasAutoOpen(autoOpen)
    } else if (autoOpen === undefined && wasAutoOpen) {
      setIsOpen(false) // close once override is removed
      setWasAutoOpen(false)
    }
  }, [autoOpen, wasAutoOpen, isOpen])

  return (
    <div className={containerClassName ?? ''}>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {isOpen && (
        <div
          className={cls(
            'bg-cerosGrey600 text-cerosWhite rounded-md px-4 py-1 text-xs font-medium',
            'transition duration-300 ease-in-out',
            transitionStatus.status === 'open' ? 'opacity-100' : 'opacity-0',
            className,
          )}
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ? y + (moveY || 0) : 0,
            left: x ? x + (moveX || 0) : 0,
          }}
          {...getFloatingProps()}
        >
          {text}
        </div>
      )}
    </div>
  )
}
