import BlurredBGImage from '@/assets/icons/blurred-bg.svg?react'
import { cls } from '@/utils/utils'

interface IBreathingAnimation {
  className?: string
}

export const BreathingAnimation = ({ className }: IBreathingAnimation) => {
  return (
    <div
      className={cls(
        'animate-spin-slow-mobile md:animate-spin-slow pointer-events-none',
        'absolute  -top-[798px] left-1/2 z-20 -ml-[564px] h-[1128px] w-[1128px]',
        className,
      )}
    >
      <BlurredBGImage className="h-full w-full" />
    </div>
  )
}
