import { captureException } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { PasswordResetCode } from '@/components/password-reset/password-reset-code'
import { PasswordResetEmail } from '@/components/password-reset/password-reset-email'
import { PasswordResetPassword } from '@/components/password-reset/password-reset-password'
import { DI_TYPE } from '@/di.types.js'
import { useViewModel } from '@/utils/view'

export const PasswordResetPage = observer(() => {
  const vm = useViewModel(DI_TYPE.PasswordResetViewModel)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    try {
      vm.loadPasswordResetDataFromSearchParams(searchParams, setSearchParams)
    } catch (e) {
      captureException(e)
    }
  }, [vm, searchParams, setSearchParams])

  const PasswordResetComponent = () => {
    switch (vm.state) {
      case 'code':
        return (
          <PasswordResetCode
            email={vm.email ?? ''}
            errorMessage={vm.errorMessage}
            isBusy={vm.isBusy}
            onSubmit={(code: string) => vm.checkPasswordResetCode(code)}
            resendCode={vm.resendCode}
          />
        )
      case 'password':
        return (
          <PasswordResetPassword
            email={vm.email ?? ''}
            initialErrorMessage={vm.errorMessage}
            isBusy={vm.isBusy}
            onSubmit={(password: string) => vm.finishPasswordReset(password)}
          />
        )
      default:
        return (
          <PasswordResetEmail
            email={vm.email ?? ''}
            errorMessage={vm.errorMessage}
            isBusy={vm.isBusy}
            onSubmit={(email: string) => vm.startPasswordReset(email)}
          />
        )
    }
  }

  return (
    <div className="flex grow flex-col">
      <div className="grid-cols-auto grid h-[540px] grid-rows-[1fr_min-content]">
        <div className="mx-auto mb-14 flex w-full max-w-[400px]">
          {PasswordResetComponent()}
        </div>
      </div>
    </div>
  )
})

PasswordResetPage.displayName = 'PasswordResetPage'
