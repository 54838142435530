import { injected } from 'brandi'
import { action, makeObservable } from 'mobx'

import { DI_TYPE } from '@/di.types.js'
import type { OktaIdpService } from '@/services/okta-idp.js'

import { BaseViewModel } from './base-view-model.js'

export class SocialLoginViewModel extends BaseViewModel {
  constructor(private oktaIdpService: OktaIdpService) {
    super()
    makeObservable(this)
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onInit(): void {}

  onDispose(): void {}

  @action.bound
  goToIdp(state: string, idpId: string, idpType?: string) {
    this.oktaIdpService.goToIdp({ state, idpId, idpType })
  }
}

injected(SocialLoginViewModel, DI_TYPE.OktaIdpService)
