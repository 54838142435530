import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { blocksSchema } from './blocks.js';
import { errorResponseSchema } from './error.js';
const c = initContract();
export const uploadStatusEnum = z.enum([
    'IN_QUEUE',
    'IN_PROGRESS',
    'COMPLETED',
    'FAILED',
]);
export const nounProjectIconParamsSchema = z.discriminatedUnion('filetype', [
    z.object({ filetype: z.literal('svg'), color: z.string() }),
    z.object({ filetype: z.literal('png'), color: z.string(), size: z.number() }),
]);
export const conversationMessageIconSchema = z.object({
    icon_id: z.number(),
    params: nounProjectIconParamsSchema,
    url: z.string().url(),
});
export const conversationMessageImageSchema = z.object({
    id: z.string().uuid(),
    url: z.string().url(),
    title: z.string().nullable(),
    blur_hash: z.string().nullable(),
    thumbnail_url: z.string().url().nullable(),
    description: z.string().nullable(),
    width: z.number(),
    height: z.number(),
    open_url: z.string().nullable(),
    metadata: z.object({
        image_url: z.string().url().optional(),
        original_image_id: z.string().uuid().optional(),
        noun_project_id: z.number().optional(),
        author_name: z.string().optional(),
        author_url: z.string().url().optional(),
    }),
});
export const conversationMessageImageEditSessionSchema = z.object({
    input: conversationMessageImageSchema,
    result: conversationMessageImageSchema,
});
const textContentType = z.object({
    type: z.literal('text'),
    text: z.string(),
});
const documentContentType = z.object({
    type: z.literal('inline_document'),
    text: z.string(),
});
const errorContentType = z.object({
    type: z.literal('error'),
    text: z.string(),
});
const imagesContentType = z.object({
    type: z.literal('images'),
    tool: z
        .enum([
        'stable_diffusion',
        'unsplash',
        'noun_project',
        'upscaler',
        'remove_bg',
    ])
        .optional(),
    search_term: z.string().nullable(),
    search_url: z.string().url().nullable(), // used for Noun Project
    results: z.array(conversationMessageImageSchema), // 1-4 images
});
const imageSearchContentType = z.object({
    type: z.enum(['unsplash_search', 'noun_project_search']),
    query: z.string(),
});
const nounProjectPinContentType = z.object({
    type: z.literal('noun_project_pin'),
    icon: conversationMessageIconSchema,
});
const imageEditSessionPinContentType = z.object({
    type: z.literal('image_edit_session_pin'),
    session: conversationMessageImageEditSessionSchema,
});
const imagePinContentType = z.object({
    type: z.literal('image_pin'),
    url: z.string(),
});
const pdfPinContentType = z.object({
    type: z.literal('pdf_pin'),
    url: z.string(),
    preview_url: z.string().optional(),
    filename: z.string().optional(),
    mime_type: z.string().optional(),
    status: uploadStatusEnum.optional(),
});
const uploadPinContentType = z.object({
    type: z.literal('upload_pin'),
    url: z.string(),
    preview_url: z.string().optional(),
    filename: z.string().optional(),
    mime_type: z.string().optional(),
    status: uploadStatusEnum.optional(),
});
const blocksJsonContentType = z.object({
    type: z.literal('blocks_json'),
    blocks: blocksSchema,
});
const embeddedVideoContentType = z.object({
    type: z.literal('embedded_video'),
    embed_code: z.string(),
    source: z.string(),
});
const aiPresentationContentType = z.object({
    type: z.literal('ai_presentation'),
    url: z.string().url(),
});
export const conversationMessageContentTypeSchema = z.discriminatedUnion('type', [
    textContentType,
    documentContentType,
    errorContentType,
    imagesContentType,
    imageSearchContentType,
    nounProjectPinContentType,
    imageEditSessionPinContentType,
    imagePinContentType,
    pdfPinContentType,
    uploadPinContentType,
    blocksJsonContentType,
    embeddedVideoContentType,
    aiPresentationContentType,
]);
// this is extended version of conversationMessageSchema
// with additional types for placeholders - currently only for images
export const conversationMessageStreamContentSchema = z.object({
    id: z.string().uuid(),
    content: z.discriminatedUnion('type', [
        textContentType,
        documentContentType,
        errorContentType,
        imagesContentType,
        imageSearchContentType,
        nounProjectPinContentType,
        imageEditSessionPinContentType,
        imagePinContentType,
        blocksJsonContentType,
        embeddedVideoContentType,
        // Note: Currently it's not possible to have partial results for images_placeholder.
        // Either we have all results or none.
        z.object({
            // TODO: can be removed?
            type: z.literal('images_placeholder'),
            number_of_results: z.number().positive(),
        }),
        z.object({
            type: z.literal('loader'),
            percentage: z.number().min(0).max(100),
            text: z.string().nullable(),
            is_failed: z.boolean(),
        }),
    ]),
});
export const conversationMessageContentSchema = z.object({
    id: z.string().uuid(),
    content: conversationMessageContentTypeSchema,
    is_publicly_shared: z.boolean().optional(),
    is_liked: z.boolean().optional(),
});
export const contentWithOwnerSchema = z.object({
    message_content: conversationMessageContentSchema,
    owner: z.object({
        user_id: z.string().uuid(),
        name: z.string(),
    }),
});
export const updateContentRequestSchema = z.object({
    is_publicly_shared: z.boolean(),
});
export const reactContentRequestSchema = z.object({
    like: z.boolean(),
});
export const cloneContentResponseSchema = z.object({
    task_id: z.string().uuid(),
});
export const getCloneContentTaskRequestSchema = z.object({
    task_id: z.string().uuid(),
});
export const getCloneContentTaskResponseSchema = z.object({
    task_id: z.string().uuid(),
    status: z.enum(['IN_PROGRESS', 'COMPLETED', 'FAILED']),
    conversation_id: z.string().uuid().optional(),
});
export const contract = c.router({
    getContent: {
        method: 'GET',
        path: '/message_contents/:content_id',
        responses: {
            200: contentWithOwnerSchema,
            404: errorResponseSchema,
        },
    },
    updateContent: {
        method: 'PATCH',
        path: '/message_contents/:content_id',
        responses: {
            200: conversationMessageContentSchema,
            401: errorResponseSchema,
            403: errorResponseSchema,
            404: errorResponseSchema,
        },
        body: updateContentRequestSchema,
        summary: 'Publishes/unpublishes a content',
    },
    cloneContent: {
        method: 'POST',
        path: '/message_contents/:content_id/clone',
        responses: {
            202: cloneContentResponseSchema,
        },
        body: z.never(),
        summary: 'Starts a task to clone a content into a new conversation',
    },
    getCloneContentTask: {
        method: 'GET',
        path: '/message_contents/clone/:task_id',
        responses: {
            200: getCloneContentTaskResponseSchema,
            404: errorResponseSchema,
            500: errorResponseSchema,
        },
        summary: 'Fetch state of a clone task',
    },
    reactContent: {
        method: 'POST',
        path: '/message_contents/:content_id/feedback',
        responses: {
            204: null,
            400: errorResponseSchema,
            404: errorResponseSchema,
        },
        body: reactContentRequestSchema,
        summary: 'Posts a thumbs up/down',
    },
});
