import { observer } from 'mobx-react-lite'
import type { ChangeEvent, KeyboardEvent } from 'react'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import toast from 'react-hot-toast'
import { useGlobalAudioPlayer } from 'react-use-audio-player'

import PaperclipIcon from '@/assets/icons/paperclip.svg?react'
import type { Prompt } from '@/stores/prompt.js'
import { useIsMobile } from '@/utils/use-is-mobile.js'
import { cls } from '@/utils/utils.js'
import type { PromptInputViewModel } from '@/view-models/prompt-input.js'
import { MAX_PROMPT_LENGTH } from '@/view-models/prompt-input.js'

import { IconButton } from '../../icon-button/icon-button.js'
import { TextArea } from '../../text-area/text-area.js'
import { Tooltip } from '../../tooltip/tooltip.js'
import { ContextButton } from './context-button.js'

interface IPromptField {
  disabled?: boolean
  promptInputRef?: React.RefObject<HTMLTextAreaElement>
  dataAutomation?: string
  stealFocus?: boolean
  autoFocus?: boolean
  height?: React.InputHTMLAttributes<HTMLTextAreaElement>['height']
  maxLength?: React.InputHTMLAttributes<HTMLTextAreaElement>['maxLength']
  onUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (prompt: Prompt) => void
  onFocus?: (event: React.FocusEvent) => void
  onBlur?: (event: React.FocusEvent) => void
  vm: PromptInputViewModel
}

const AUDIO_FILES = {
  startRecord: '/audio/start-record.mp3',
  endRecord: '/audio/end-record.mp3',
}

export const PromptField = observer(
  ({
    promptInputRef,
    height,
    stealFocus,
    maxLength = MAX_PROMPT_LENGTH,
    dataAutomation,
    disabled,
    onUpload,
    autoFocus,
    onSubmit,
    onFocus,
    onBlur,
    vm,
  }: IPromptField) => {
    const isMobile = useIsMobile()

    const ref = useRef<HTMLTextAreaElement>(promptInputRef?.current ?? null)
    const counterRef = useRef<HTMLParagraphElement>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [voiceInputSent, setVoiceInputSent] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [textAreaFocused, setTextAreaFocused] = useState(false)
    const { load } = useGlobalAudioPlayer()

    const {
      promptText,
      isRecording,
      voiceInputStart,
      voiceInputStopAndSubmit,
      speechRecordingStarted,
      speechRecordingEnded,
      speechInitializing,
      onPromptSubmit,
      onPromptChange,
    } = vm

    const handlePromptSubmit = useCallback(
      () => onPromptSubmit(onSubmit),
      [onPromptSubmit, onSubmit],
    )

    const handleVoiceInputSubmit = useCallback(
      () => voiceInputStopAndSubmit(onSubmit),
      [voiceInputStopAndSubmit, onSubmit],
    )

    const sendVoiceInputIfLimit = useCallback(() => {
      if (isRecording) {
        voiceInputStopAndSubmit(onSubmit)
        setHasError(false)
        toast.error('Max character limit reached', {
          position: 'bottom-center',
        })
      }
    }, [isRecording, voiceInputStopAndSubmit, onSubmit])

    const updateInputHeight = useCallback(
      (value: string) => {
        if (ref.current == null) {
          return
        }

        if (counterRef.current) {
          const valueLength = String(value).length
          counterRef.current.innerText = `${valueLength}/${maxLength}`
          counterRef.current.style.display = 'none'
          ref.current.style.borderColor = ''

          if (valueLength >= maxLength - 200) {
            counterRef.current.style.display = 'block'
            counterRef.current.style.color = 'red'
            ref.current.style.borderColor = 'red'

            setHasError(true)

            if (valueLength > maxLength) {
              if (!voiceInputSent) {
                sendVoiceInputIfLimit()
                setVoiceInputSent(true)
              }
            } else {
              setVoiceInputSent(false)
            }
          } else {
            setHasError(false)
          }
        }

        ref.current.style.height = height ? String(height) : '24px'
        ref.current.style.height = height
          ? String(height)
          : `${ref.current.scrollHeight}px`

        ref.current.scrollTop = ref.current.scrollHeight
      },
      [height, maxLength, sendVoiceInputIfLimit, voiceInputSent],
    )

    const resetInputHeight = useCallback(() => {
      if (ref.current == null) {
        return
      }

      ref.current.style.height = isNaN(height as any) ? '24px' : String(height)
    }, [height])

    const handleOnKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (isRecording) {
        e.preventDefault()
        return
      }

      const target = e.target as HTMLTextAreaElement

      if (e.key === 'Enter' && !e.shiftKey && target.value !== '') {
        e.preventDefault()

        if (String(promptText).length <= maxLength) {
          setHasError(false)
          handlePromptSubmit()

          if (counterRef.current) {
            counterRef.current.style.display = 'none'
          }

          target.focus()
        }
      }
    }

    const handleMicrophoneClick = () => {
      if (isRecording) {
        handleVoiceInputSubmit()
      } else {
        voiceInputStart()
      }
    }

    const onPromptInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value == null || e.target.value.length === 0) {
        resetInputHeight()
      }
      onPromptChange(e.target.value)
    }

    useEffect(() => {
      if (!disabled && stealFocus) ref.current?.focus()
    }, [disabled, ref, stealFocus])

    useEffect(() => {
      if (promptText == null || promptText.length === 0) {
        resetInputHeight()
      }

      updateInputHeight(promptText)
    }, [updateInputHeight, promptText, resetInputHeight])

    useLayoutEffect(() => {
      if (ref.current) {
        const textarea = ref.current
        const valueLength = textarea.value.length

        if (stealFocus) {
          textarea.focus()
        }
        textarea.setSelectionRange(valueLength, valueLength)
      }
    }, [ref, stealFocus])

    useEffect(() => {
      if (speechRecordingStarted && isRecording) {
        load(AUDIO_FILES.startRecord, {
          autoplay: true,
          initialVolume: 0.5,
        })
      }
      if (speechRecordingEnded && speechRecordingStarted) {
        load(AUDIO_FILES.endRecord, {
          autoplay: true,
          initialVolume: 0.5,
        })
      }
    }, [speechRecordingStarted, speechRecordingEnded, load, isRecording])

    return (
      <div
        data-automation={dataAutomation}
        className="relative flex w-full px-2 lg:px-0"
      >
        <div
          className={cls(
            'relative flex max-h-64 w-full items-start justify-between gap-1 p-0 py-[7px]',
            'bg-cerosWhite border-cerosGrey300 hover:border-cerosGrey400 rounded-2xl border',
            'shadow-inputDefault transition-shadow',
            'overflow-hidden',
            isRecording && !hasError && 'animate-shadow-pulsate-recording',
            isRecording && hasError && 'animate-shadow-pulsate-limit',
            !isRecording && hasError && 'shadow-inputRecordingFail',
            disabled && 'placeholder:text-cerosGrey300',
            textAreaFocused &&
              !disabled &&
              'border-cerosPrimary hover:border-cerosPrimary',
            textAreaFocused && disabled && 'border-cerosGrey300 opacity-50',
          )}
        >
          <div className="absolute bottom-[7px] left-[7px]">
            <Tooltip
              className="w-[130px] -translate-y-4 text-center"
              text="Upload a file"
              placement="left"
            >
              {
                <IconButton
                  disabled={disabled}
                  icon={<PaperclipIcon className="h-6 w-6" />}
                  onClick={() => fileInputRef?.current?.click()}
                  className="bg-cerosWhite h-12 w-12 rounded-lg disabled:opacity-50"
                />
              }
            </Tooltip>
            <input
              ref={fileInputRef}
              className="hidden"
              type="file"
              multiple={false}
              onChange={onUpload}
            />
          </div>
          <div
            className={cls(
              'custom-scrollbar wtf flex h-full min-h-12 w-full items-start overflow-y-auto pt-3',
            )}
          >
            <div
              className={cls(
                'divider border-cerosGrey300 absolute left-16 block h-6 border-l',
                (textAreaFocused || !!promptText) && 'invisible',
              )}
            />
            <TextArea
              ref={ref}
              onKeyDown={handleOnKeyDown}
              value={promptText}
              height={height}
              onFocus={(event) => {
                setTextAreaFocused(true)
                onFocus?.(event)
              }}
              onBlur={(event) => {
                setTextAreaFocused(false)
                onBlur?.(event)
              }}
              className={cls(
                'prompt-input-area',
                'w-full overflow-auto rounded-none font-normal outline-none',
                'text-cerosBlack',
                'caret-cerosPrimary scroll-p-0 pb-0 pl-16 pr-16 pt-0 placeholder:pl-2',
                'disabled:bg-cerosWhite disabled:text-cerosGrey400 disabled:pointer-events-none disabled:cursor-not-allowed',
                'hover:bg-cerosWhite',
                'border-none',
              )}
              disabled={disabled}
              onChange={onPromptInputChange}
              autoFocus={autoFocus}
              placeholder={
                isMobile
                  ? 'Chat to Gemma...'
                  : 'Chat to Gemma about anything...'
              }
            />
          </div>
          <ContextButton
            isRecording={isRecording}
            microphoneLoading={speechInitializing}
            disabled={disabled}
            hasError={hasError}
            handleMicrophoneClick={handleMicrophoneClick}
            handleSubmit={handlePromptSubmit}
            promptValue={promptText}
            className="absolute bottom-[7px] right-[7px]"
          />
          <span
            ref={counterRef}
            className="absolute bottom-0 right-0 hidden translate-y-full text-sm"
          >
            0/{maxLength}
          </span>
        </div>
        {isRecording && (
          <div
            className={cls(
              'pointer-events-none fixed inset-0',
              'border-cerosPrimary rounded-lg border-[3px]',
              hasError && 'border-cerosSecondaryPink',
            )}
          />
        )}
      </div>
    )
  },
)

PromptField.displayName = 'PromptField'
