import type { CSSProperties } from 'react'

import { cls } from '@/utils/utils.js'

import BlueSpinnerIcon from './assets/spinner.svg?react'
import GreySpinnerIcon from './assets/spinner-grey.svg?react'

export interface SpinnerProps {
  size?: 'extra-small' | 'small' | 'medium' | 'large'
  type?: 'blue' | 'grey'
  className?: string
  style?: CSSProperties
}

export const Spinner = ({
  size = 'medium',
  type = 'blue',
  className,
  style,
}: SpinnerProps): JSX.Element => {
  const SpinnerIcon = type === 'blue' ? BlueSpinnerIcon : GreySpinnerIcon
  const animationDuration = type === 'blue' ? '1.25s' : '0.6s'
  return (
    <SpinnerIcon
      role="progressbar"
      className={cls(
        'animate-spin',
        size === 'extra-small' && 'h-[11px] w-[11px]',
        size === 'small' && 'h-7 w-7',
        size === 'medium' && 'h-9 w-9',
        size === 'large' && 'h-11 w-11',
        className,
      )}
      style={{ animationDuration, ...style }}
    />
  )
}
