import { action, computed, makeObservable, observable } from 'mobx'

export interface IModalShape {
  id: string
  content: JSX.Element
  title?: string
  custom?: boolean
  backdrop?: boolean
  closeOnBackdropClick?: boolean
  onClose: () => void
}

export type ModalOptions = Pick<
  IModalShape,
  'title' | 'backdrop' | 'custom' | 'closeOnBackdropClick'
>

export class ModalService {
  @observable modalQueue: IModalShape[] = []

  constructor() {
    makeObservable(this)

    document.addEventListener('keydown', this.onKeyDown)
  }

  @action.bound
  show(content: JSX.Element, title: string = '') {
    this.modalQueue.push({
      id: title,
      content,
      title,
      custom: false,
      onClose: () => this.closeLast(),
    })
  }

  @action.bound
  showCustom(id: string, content: JSX.Element, options: ModalOptions = {}) {
    // prevents duplicate modals
    if (this.findModalById(id)) {
      console.warn(`Modal with id ${id} already exists`)
      return
    }

    this.modalQueue.push({
      id,
      content,
      ...options,
      custom: true,
      onClose: () => this.closeLast(),
    })
  }

  @action.bound
  close() {
    this.closeLast()
  }

  @action.bound
  closeById(id: string) {
    this.modalQueue = this.modalQueue.filter((modal) => modal.id !== id)
  }

  @action.bound
  findModalById(id: string) {
    return this.modalQueue.find((modal) => modal.id === id)
  }

  @action.bound
  closeAll() {
    this.modalQueue = []
  }

  @action.bound
  closeLast() {
    const last = this.modalQueue.pop()
    // TODO: might be useful for other close methods
    last?.content.props.onClose?.()
  }

  @computed
  get isOpen(): boolean {
    return this.modalQueue.length > 0
  }

  @action.bound
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeLast()
    }
  }
}
