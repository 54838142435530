import { injected } from 'brandi'
import { action, computed, makeObservable } from 'mobx'

import { DI_TYPE } from '@/di.types'
import type { UserService } from '@/services/user'

import { BaseViewModel } from './base-view-model'

export class WithUserProperties extends BaseViewModel {
  constructor(private userService: UserService) {
    super()
    makeObservable(this)
  }

  onInit(): void {}

  onDispose(): void {}

  @action.bound
  oktaUserIdMatch(oktaUserId: string | null) {
    if (typeof oktaUserId !== 'string') return true
    return this.userService?.properties?.okta_id === oktaUserId
  }

  @computed
  get isFetchingUserProperties() {
    return this.userService.isFetchingUserProperties
  }
}

injected(WithUserProperties, DI_TYPE.UserService)
