import type {
  ConversationMessage,
  ConversationMessageContent,
} from '@ceros/gemma-api-spec'

import type { RenderableConversationMessage } from '@/models/conversation'

export const PENDING_GENERATION_MESSAGE_ID = 'pending-generation'

export function getMessageContentFirstType(
  message: RenderableConversationMessage,
) {
  return message.items[0]?.content.type
}

export function getMessageContentText(
  message: RenderableConversationMessage | ConversationMessage,
) {
  const textContent = message.items
    .filter((item) => item.content.type === 'text')
    .map((item) =>
      // stupid typescript issue with filter to figure out later
      item.content.type === 'text' ? item.content.text : undefined,
    )

  const messageText =
    textContent.length > 0 ? textContent.join('\n\n') : undefined

  return messageText
}

export function getMessageContentTypes(
  message: RenderableConversationMessage | ConversationMessage,
) {
  const types = new Set<string>()
  message.items.forEach((item) => {
    types.add(item.content.type)
  })

  return Array.from(types.values())
}

export function generatePendingMessageId(from: 'user' | 'bot') {
  return `pending-${from}-${new Date().getTime()}`
}

export function createPendingMessageFromUser(
  conversationId: string,
  user: { id: string; email: string },
  items: ConversationMessageContent[],
): RenderableConversationMessage {
  const createdDate = new Date()
  return {
    id: generatePendingMessageId('user'),
    conversation_id: conversationId,
    author: {
      type: 'user',
      user,
    },
    items,
    created_at: createdDate.toISOString(),
    updated_at: createdDate.toISOString(),
    is_cloned: false,
    renderMetadata: {
      date: createdDate,
    },
  }
}

export function createPendingMessageFromBot(
  conversationId: string,
  removeOnFirstGeneratedMessage = false,
  items: ConversationMessageContent[] = [],
): RenderableConversationMessage {
  const createdDate = new Date()
  return {
    id: removeOnFirstGeneratedMessage
      ? PENDING_GENERATION_MESSAGE_ID
      : generatePendingMessageId('bot'),
    conversation_id: conversationId,
    author: {
      type: 'bot',
      name: 'Gemma',
    },
    items,
    created_at: createdDate.toISOString(),
    updated_at: createdDate.toISOString(),
    is_cloned: false,
    renderMetadata: {
      date: createdDate,
    },
  }
}
