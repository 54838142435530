import type {
  ConversationMessage,
  ConversationMessageItem,
} from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import { action, makeObservable } from 'mobx'

import { DI_TYPE } from '@/di.types'
import { delayedRejection } from '@/utils/async'

import { BaseService } from './base-service'
import type { ConversationStreamService } from './conversation-stream'

const REQUEST_TIMEOUT = 240_000 // 4 minutes

export class FileProcessService extends BaseService {
  streamController: AbortController | undefined = undefined

  constructor(private streamService: ConversationStreamService) {
    super()
    makeObservable(this)
  }

  @action.bound
  onDispose() {
    this.abortStream()
  }

  @action.bound
  abortStream() {
    this.streamController?.abort()
  }

  @action.bound
  private isItemProcessed(item: ConversationMessageItem) {
    const isImageProcessed = item.content.type === 'image_pin'
    const isPdfProcessed =
      item.content.type === 'pdf_pin' && item.content.status === 'COMPLETED'
    const isUploadProcessed =
      item.content.type === 'upload_pin' && item.content.status === 'COMPLETED'

    return isImageProcessed || isPdfProcessed || isUploadProcessed
  }

  @action.bound
  async isFileProcessed(
    conversationId: string,
    messageId: string,
  ): Promise<string> {
    const connection = new Promise<string>((resolve, reject) => {
      this.streamController = new AbortController()
      const callbacks = {
        onStart: () => {},
        onPing: () => {},
        onMessageGenerating: () => {},
        onMessageFinished: (message: ConversationMessage) => {
          if (message.id === messageId) {
            message.items.forEach((item) => {
              if (this.isItemProcessed(item)) {
                resolve(item.id)
                return
              }
              if (item.content.type === 'error') {
                reject(item.content.text)
                return
              }
            })
          }
        },
        onHeartbeatMissed: () => {},
        onError: () => true,
      }
      this.streamService
        .connect(
          conversationId,
          callbacks,
          this.streamController,
          REQUEST_TIMEOUT,
        )
        .catch((err) => {
          reject(err)
        })
    })

    return Promise.race([connection, delayedRejection<string>(REQUEST_TIMEOUT)])
  }
}

injected(FileProcessService, DI_TYPE.ConversationStreamService)
