import type { Gem } from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import { action, computed, makeObservable } from 'mobx'
import { createElement } from 'react'
import toast from 'react-hot-toast'

import { DI_TYPE } from '@/di.types.js'
import { GemModal } from '@/pages/modals/gem/gem-modal.js'
import { ReportModal } from '@/pages/modals/report.js'
import { ShareModal } from '@/pages/modals/share.js'
import type { AuthService } from '@/services/auth.js'
import type { ConversationsService } from '@/services/conversations.js'
import { GemsService } from '@/services/gems.js'
import type { MixpanelService } from '@/services/mixpanel.js'
import type { ModalService } from '@/services/modal.js'
import type { RouterService } from '@/services/router.js'
import type { CreateReportForm, SupportService } from '@/services/support.js'

import { BaseViewModel } from './base-view-model.js'

export class ActiveGemViewModel extends BaseViewModel {
  constructor(
    private mixpanelService: MixpanelService,
    private modalService: ModalService,
    private authService: AuthService,
    private routerService: RouterService,
    private conversationsService: ConversationsService,
    private gemsService: GemsService,
    private supportService: SupportService,
  ) {
    super()
    makeObservable(this)
  }

  onInit(): void {}
  onDispose(): void {}

  @computed
  get initializing(): boolean {
    return !this.conversationsService.initialized
  }

  @action.bound
  async onGemSelect(gem: Gem) {
    const conversation = await this.conversationsService.createConversation(
      { gem_id: gem.id },
      { createSource: 'New chat Gem', gemTitle: gem.title },
    )

    if (!conversation) {
      toast.error('Error creating conversation')
      return
    }
    this.routerService.goTo(`/conversations/${conversation.id}`, {
      isExisting: false,
      expectGemmaResponse: true,
    })
  }

  @action.bound
  async onGemUpdate(gem: Gem) {
    if (!this.authService.auth?.data.user) {
      toast.error('You need to log in first')
      return
    }
    this.modalService.showCustom(
      'gem-upsert',
      createElement(GemModal, {
        gemToUpdate: gem,
        closeOnBackdropClick: false,
      }),
    )
  }

  @action.bound
  onShareToggle(gem: Gem, isShared: boolean) {
    this.gemsService.updateSharedState(gem, isShared)
  }

  @action.bound
  onShareCopyUrl(gem: Gem) {
    this.mixpanelService.trackGemSharingLinkCopied(gem.id)
  }

  @action.bound
  onGemShare(gem: Gem) {
    this.modalService.showCustom(
      'share-gem',
      createElement(ShareModal, {
        type: 'Gem',
        onToggle: (isShared: boolean) => this.onShareToggle(gem, isShared),
        onCopyUrl: () => this.onShareCopyUrl(gem),
        isShared: gem.is_publicly_shared ?? false,
        shareUrl: `${window.location.origin}/${GemsService.GEMS_SLUG}/${gem.id}`,
      }),
    )
    this.mixpanelService.trackGemSharingOpened(gem.id)
  }

  @action.bound
  onGemReport(conversationId: string, gemId: string) {
    this.modalService.show(
      createElement(ReportModal, {
        type: 'gem',
        onSubmit: (report) => this.sendReportGem(conversationId, gemId, report),
      }),
      'Report',
    )
  }

  @action.bound
  async sendReportGem(
    conversationId: string,
    gemId: string,
    report: CreateReportForm,
  ) {
    if (!this.authService.auth?.data.user) {
      toast.error('You need to log in first')
      return
    }
    this.supportService.sendReportGem(conversationId, gemId, report)
  }
}

injected(
  ActiveGemViewModel,
  DI_TYPE.MixpanelService,
  DI_TYPE.ModalService,
  DI_TYPE.AuthService,
  DI_TYPE.RouterService,
  DI_TYPE.ConversationsService,
  DI_TYPE.GemsService,
  DI_TYPE.SupportService,
)
