import type { Gem } from '@ceros/gemma-api-spec'
import { observer } from 'mobx-react-lite'

export type GemTileProps = {
  gem: Gem
  isCloning: boolean
  onCloneAndUseGem: () => void
}

export const GemTile = observer(
  ({ gem, isCloning, onCloneAndUseGem }: GemTileProps) => {
    return (
      <div
        className={`bg-cerosWhite flex h-[543px] w-[272px] flex-col gap-8 rounded-2xl p-2 shadow md:h-[298px] md:w-[768px] md:flex-row md:gap-9 md:p-5`}
      >
        <div
          className="flex size-64 flex-col justify-evenly rounded-xl"
          style={{ backgroundColor: gem.background_color }}
        >
          <div className="size-24 self-center text-[100px] leading-[90px]">
            {gem.emoji}
          </div>
          <span className="mx-6 truncate text-center text-base font-medium text-black">
            {gem.title}
          </span>
        </div>

        <div className="flex h-60 w-64 flex-col md:mt-4 md:w-[434px]">
          <div className="flex h-full w-64 flex-col justify-center md:w-[434px]">
            <h1 className="h-10 w-full max-w-60 content-baseline truncate text-center text-2xl font-medium leading-tight text-black md:max-w-96 md:text-left">
              ‘{gem.title}’ Gem
            </h1>
            <div className="flex h-32 items-center justify-start overflow-y-auto text-wrap">
              <p className="text-cerosGrey500 h-32 w-64 p-0 font-medium md:w-[434px]">
                {gem.prompt}
              </p>
            </div>
          </div>
          <div className="mt-1 flex h-9 w-full justify-end self-end md:w-40">
            <button
              onClick={onCloneAndUseGem}
              disabled={isCloning}
              className="bg-cerosPrimary hover:bg-cerosPrimaryHover disabled:bg-cerosPrimaryHover text-cerosWhite h-9 w-full rounded-md px-4 py-2 text-sm font-medium leading-tight md:self-end"
            >
              Use Gem
            </button>
          </div>
        </div>
      </div>
    )
  },
)

GemTile.displayName = 'GemTile'
