import { motion } from 'framer-motion'
import { useRef, useState } from 'react'

import HomeIcon from '@/assets/icons/home.svg?react'

import { Tooltip } from '../tooltip/tooltip'

interface IProps {
  isVisible: boolean
  onClick: () => void
}

export const HomePageButton = ({ isVisible, onClick }: IProps) => {
  const buttonRef = useRef<HTMLDivElement>(null)
  const [initialState] = useState(isVisible ? 'visible' : 'hidden')

  return (
    <motion.div
      ref={buttonRef}
      initial={initialState}
      animate={isVisible ? 'visible' : 'hidden'}
      variants={{
        visible: {
          opacity: 1,
          transition: { duration: 0.1 },
        },
        hidden: { opacity: 0, transition: { duration: 0.1 } },
      }}
      onAnimationComplete={(definition) => {
        if (buttonRef.current) {
          buttonRef.current.style.visibility =
            definition === 'hidden' ? 'hidden' : 'visible'
        }
      }}
      className="absolute left-16 top-6 z-40"
    >
      <Tooltip text="Back to dashboard" placement="bottom-end" position="fixed">
        <button
          className="bg-cerosWhite hover:border-cerosGrey300 shadow-navigationButton flex h-10 w-10 items-center justify-center rounded-lg"
          onClick={onClick}
          data-automation="home-page-btn"
        >
          <HomeIcon />
        </button>
      </Tooltip>
    </motion.div>
  )
}
