import { useSyncExternalStore } from 'react'

const createMediaQueryStore = (media: string) => {
  const mediaQueryList = window.matchMedia(media)

  return {
    subscribe: (listener: () => void) => {
      mediaQueryList.addEventListener('change', listener)
      return () => mediaQueryList.removeEventListener('change', listener)
    },
    getSnapshot: () => mediaQueryList.matches,
  }
}

export const useMatchMedia = (media: string): boolean => {
  const mediaQueryStore = createMediaQueryStore(media)

  return useSyncExternalStore(
    mediaQueryStore.subscribe,
    mediaQueryStore.getSnapshot,
  )
}
