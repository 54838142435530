import { createContext, useEffect, useState } from 'react'

export interface IAudioContext {
  canAutoplay: boolean
}

// FUTURE: we could use `useGlobalAudioPlayer` from `react-use-audio-player` to keep the same audio instance if browsers get stricter about autoplay
const audio = new Audio()
export const AudioContext = createContext<IAudioContext>({ canAutoplay: false })

const silentDataUri =
  'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV'

export const AudioContextProvider = ({ children }) => {
  const [canAutoplay, setCanAutoplay] = useState(false)

  useEffect(() => {
    const handleClick = () => {
      audio.src = silentDataUri
      audio
        .play()
        .then(() => {
          setCanAutoplay(true)
        })
        .catch(() => {
          setCanAutoplay(false)
        })

      removeListener()
    }

    const removeListener = () => {
      window.removeEventListener('click', handleClick)
    }

    window.addEventListener('click', handleClick)
    return removeListener
  }, [])

  return (
    <AudioContext.Provider value={{ canAutoplay }}>
      {children}
    </AudioContext.Provider>
  )
}
