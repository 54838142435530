import { createElement, Fragment, useState } from 'react'

import { Menu, MenuDivider, MenuItem } from '@/components/menu/menu.js'
import type { IMenuItem } from '@/services/sidebar'
import { cls } from '@/utils/utils'

import { Tooltip } from '../tooltip/tooltip'

type SidebarItemMenuProps = {
  className?: string
  item: IMenuItem
  setIsOpen: (value: boolean) => void
  onRename: () => void
  children: JSX.Element
}

export const useMenuDisclosure = (): [boolean, (value: boolean) => void] => {
  return useState<boolean>(false)
}

export const SidebarItemMenu = ({
  className,
  item,
  onRename,
  setIsOpen,
  children,
}: SidebarItemMenuProps) => {
  return (
    <Menu
      className={cls('z-50', className)}
      align="end"
      direction="bottom"
      minSize={'min-w-[142px]'}
      arrow={true}
      arrowProps={{ className: 'w-1 h-[10px]' }}
      viewScroll="close"
      onMenuChange={({ open }) => setIsOpen(open)}
      menuButton={children}
    >
      {item.actions.map((group, index) => {
        return (
          <Fragment key={group.id}>
            {group.data.map((action) => {
              const menuItem = (
                <MenuItem
                  key={action.id}
                  className={cls('justify-start gap-2', action.className)}
                  onClick={(e) => {
                    e.stopPropagation = true
                    e.syntheticEvent.stopPropagation()
                    if (action.id === 'rename') {
                      return onRename()
                    }
                    action.onClick()
                  }}
                >
                  {createElement(action.icon, {
                    className: 'h-4 w-4',
                  } as any)}
                  {action.title}
                </MenuItem>
              )

              if (action.tooltipText != null) {
                return (
                  <Tooltip
                    text={action.tooltipText}
                    placement="bottom-end"
                    key={`${group.id}-tooltip`}
                  >
                    {menuItem}
                  </Tooltip>
                )
              }

              return menuItem
            })}
            {item.actions.length - 1 !== index && <MenuDivider />}
          </Fragment>
        )
      })}
    </Menu>
  )
}

SidebarItemMenu.displayName = 'SidebarItemMenu'
