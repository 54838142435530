import { injected } from 'brandi'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { toast } from 'react-hot-toast'
import type { SetURLSearchParams } from 'react-router-dom'

import { DI_TYPE } from '@/di.types.js'
import { passwordSchema } from '@/models/password.js'
import type { Api } from '@/services/api.js'
import type { AuthService } from '@/services/auth.js'
import type { MixpanelService } from '@/services/mixpanel.js'
import type { OktaIdpService } from '@/services/okta-idp.js'
import type { RouterService } from '@/services/router.js'

import { BaseViewModel } from './base-view-model.js'

const SEARCH_PARAM_EMAIL = 'email'
const SEARCH_PARAM_CODE = 'code'

type PasswordResetState = 'email' | 'code' | 'password'

export class PasswordResetViewModel extends BaseViewModel {
  @observable email: string | null = null
  @observable code: string | null = null
  @observable errorMessage: string | null = null
  @observable searchParams: URLSearchParams | null = null
  @observable setSearchParams: SetURLSearchParams | null = null
  @observable isBusy = false

  constructor(
    private api: Api,
    private authService: AuthService,
    private mixpanelService: MixpanelService,
    private oktaIdpService: OktaIdpService,
    private routerService: RouterService,
  ) {
    super()
    makeObservable(this)
  }

  onInit(): void {}

  onDispose(): void {}

  @computed
  get state(): PasswordResetState {
    if (this.email && this.code) return 'password'
    if (this.email) return 'code'
    return 'email'
  }

  @action.bound
  setEmail(email: string | null) {
    this.email = email
    if (email) this.errorMessage = null
    this.setSearchParam(SEARCH_PARAM_EMAIL, email)
  }

  @action.bound
  setCode(code: string | null) {
    this.code = code
    if (code) this.errorMessage = null
    this.setSearchParam(SEARCH_PARAM_CODE, code)
  }

  @action.bound
  loadPasswordResetDataFromSearchParams(
    searchParams: URLSearchParams,
    setSearchParams: SetURLSearchParams,
  ): void {
    const email = searchParams.get(SEARCH_PARAM_EMAIL)
    const code = searchParams.get(SEARCH_PARAM_CODE)
    this.searchParams = searchParams
    this.setSearchParams = setSearchParams
    this.setEmail(email)
    this.setCode(code)
  }

  setSearchParam(name: string, value: string | null) {
    if (this.searchParams && this.setSearchParams) {
      if (value) {
        this.searchParams.set(name, value)
      } else {
        this.searchParams.delete(name)
      }
      this.setSearchParams(this.searchParams)
    }
  }

  handleErrorResponse(status: number, body: any) {
    if (status === 422 && body.detail?.length) {
      this.errorMessage = body.detail[0].msg
    } else if (status === 400 && typeof body.detail === 'string') {
      toast.error(body.detail)
    } else {
      toast.error('Something went wrong')
    }
  }

  @action.bound
  async startPasswordReset(email: string) {
    this.isBusy = true
    const idpProps = await this.oktaIdpService.webfinger(email)
    if (idpProps) {
      toast.error(
        'Your account uses a single sign on provider, or Google login. Please try to log in.',
        {
          duration: 8000,
        },
      )
      this.isBusy = false
      return
    }

    const { status, body } = await this.api.authentication.startPasswordReset({
      body: { email },
    })
    this.mixpanelService.track('Password Reset Started', { email, status })
    runInAction(() => {
      this.isBusy = false
    })
    if (status === 204) {
      this.setEmail(email)
      this.setCode(null)
    } else if (status === 400 && body.code === 'EMAIL_NOT_WHITELISTED') {
      this.mixpanelService.track('Signup Access Requested', { email })
      this.routerService.goTo({ pathname: '/login/unassigned' })
    } else {
      this.handleErrorResponse(status, body)
    }
  }

  @action.bound
  async resendCode() {
    const email = this.email ?? ''
    const { status, body } = await this.api.authentication.startPasswordReset({
      body: { email },
    })
    this.mixpanelService.track('Password Reset Resend Code', { email, status })
    if (status === 204 && body) {
      toast.success('Reset code sent')
      this.errorMessage = null
    } else {
      this.handleErrorResponse(status, body)
    }
  }

  @action.bound
  async checkPasswordResetCode(code: string): Promise<boolean> {
    this.isBusy = true
    const email = this.email ?? ''
    const { status, body } =
      await this.api.authentication.checkPasswordResetCode({
        body: { email, code },
      })
    this.mixpanelService.track('Password Reset Check Activation Code', {
      email,
      status,
    })
    runInAction(() => {
      this.isBusy = false
    })
    if (status === 200 && body?.valid) {
      this.setCode(code)
      return false
    } else if (status === 200 && !body?.valid) {
      this.errorMessage = 'Reset code is not valid'
      this.setCode(null)
      return true
    } else {
      this.handleErrorResponse(status, body)
      return false
    }
  }

  @action.bound
  async finishPasswordReset(password: string) {
    const validationResult = passwordSchema.safeParse(password)
    if (!validationResult.success) {
      const issue = validationResult.error.issues[0]
      toast.error(issue.message)
      return
    }

    const email = this.email ?? ''
    const code = this.code ?? ''
    this.isBusy = true
    const { status, body } = await this.api.authentication.finishPasswordReset({
      body: { email, code, password },
    })
    this.mixpanelService.track('Password Reset Finish', { email, status })
    if (status === 204) {
      this.setEmail(null)
      this.setCode(null)
      toast.success('Password reset!')
      this.routerService.goTo({ pathname: '/login' })
      this.isBusy = false
    } else {
      this.isBusy = false
      this.handleErrorResponse(status, body)
    }
  }
}

injected(
  PasswordResetViewModel,
  DI_TYPE.Api,
  DI_TYPE.AuthService,
  DI_TYPE.MixpanelService,
  DI_TYPE.OktaIdpService,
  DI_TYPE.RouterService,
)
