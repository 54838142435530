import type { MouseEventHandler, ReactNode } from 'react'
import { Drawer as DrawerComponent } from 'vaul'

import CloseIcon from '@/assets/icons/close.svg?react'
import { cls } from '@/utils/utils'

export const DrawerSeparator = () => {
  return <div className="border-t-cerosGrey300 h-[1px] w-full border-t" />
}

export const DrawerTitle = ({
  children,
  className,
}: {
  children: string
  className?: string
}) => (
  <DrawerComponent.Title
    className={cls('p-9 pb-4 text-center text-2xl font-medium', className)}
  >
    {children}
  </DrawerComponent.Title>
)

export const DrawerItem = ({
  children,
  className,
  onClick,
  icon,
}: {
  children: ReactNode
  onClick: MouseEventHandler<HTMLButtonElement>
  icon?: ReactNode
  className?: string
}) => (
  <button
    onClick={onClick}
    className={cls(
      'bg-cerosWhite flex w-full items-center justify-start gap-3 px-5 py-2 text-left font-normal',
      'active:bg-cerosGrey300',
      className,
    )}
  >
    {icon}
    {children}
  </button>
)

export function Drawer({
  isOpen,
  setIsOpen,
  header,
  children,
}: {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  header: ReactNode
  children: ReactNode
}) {
  return (
    <DrawerComponent.Root open={isOpen} onClose={() => setIsOpen(false)}>
      <DrawerComponent.Portal>
        <DrawerComponent.Overlay className="bg-cerosOverlayDrawer pointer-events-none fixed inset-0 z-40 backdrop-blur-xl" />
        <DrawerComponent.Content className="fixed inset-x-0 bottom-0 z-50 flex h-auto flex-col p-4">
          <div className="bg-cerosWhite shadow-cerosLow relative w-full overflow-hidden rounded-2xl">
            {header}
            <DrawerSeparator />
            <div className="flex w-full flex-col gap-2 py-2">{children}</div>
            <button
              aria-label="Close"
              onClick={() => setIsOpen(false)}
              className="active:bg-cerosGrey300 absolute right-4 top-4 z-[999] rounded-full p-1"
            >
              <CloseIcon className="h-4 w-4 " />
            </button>
          </div>
        </DrawerComponent.Content>
      </DrawerComponent.Portal>
    </DrawerComponent.Root>
  )
}
