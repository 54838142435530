interface IErrorContent {
  dataAutomation?: string
  error?: string
}

export const ErrorContent = ({ error, dataAutomation }: IErrorContent) => {
  return (
    <>
      <p data-automation={dataAutomation} className="text-cerosDanger text-md">
        {error}
      </p>
      {/* <div className="mt-2 flex gap-2 items-center">
        <div className="w-4 h-4 bg-cerosBlack" />
        <a className="flex-1 text-sm text-cerosPrimary" href="/">
          Regenerate response
        </a>
      </div> */}
    </>
  )
}
