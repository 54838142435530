import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'

import ChevronDownIcon from '@/assets/icons/chevron-down.svg?react'
import CloseIcon from '@/assets/icons/close.svg?react'
import { cls } from '@/utils/utils.js'

interface ICollapsibleEditSection {
  title: string
  icon: ReactNode
  children: ReactNode
  open?: boolean
  onChange?: (open: boolean) => void
  disabled?: boolean
}

export const CollapsibleEditSection = ({
  title,
  icon,
  children,
  open = false,
  onChange,
  disabled,
}: ICollapsibleEditSection) => {
  const [isOpen, setIsOpen] = useState(open)

  const toggle = () => {
    if (open === undefined) {
      setIsOpen((prev) => {
        onChange?.(!prev)
        return !prev
      })
    } else {
      onChange?.(!open)
    }
  }

  const handleClick = () => {
    if (!disabled) {
      toggle()
    }
  }

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <section
      className={cls(
        'bg-cerosGrey100 border-cerosGrey300 relative box-border border-b',
        isOpen && 'bg-cerosGrey200',
      )}
    >
      <div
        className={cls(
          'hover:text-cerosGrey500 flex cursor-default items-center justify-between px-6 py-4 text-sm',
          disabled && 'text-cerosGrey400 hover:text-cerosGrey400',
        )}
        onClick={handleClick}
      >
        <div className="flex items-center gap-2">
          {icon}
          {title}
        </div>

        {isOpen ? (
          <CloseIcon className="h-4 w-4" />
        ) : (
          <ChevronDownIcon className="h-4 w-4" />
        )}
      </div>
      {isOpen && (
        <div className="flex flex-col gap-3 px-6 pb-4 text-sm">{children}</div>
      )}
      {disabled && (
        <div className="bg-cerosWhite absolute inset-0 cursor-not-allowed opacity-50" />
      )}
    </section>
  )
}
