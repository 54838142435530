import type { ReactNode } from 'react'

import { Message } from './message.js'

interface IUploadingMessage {
  from: string
  initials: string
  time?: Date
  avatar?: ReactNode
}

export function UploadingMessage({
  from,
  initials,
  time,
  avatar,
}: IUploadingMessage) {
  return (
    <Message from={from} initials={initials} avatar={avatar} time={time}>
      <span className="bg-cerosGrey100 text-cerosGrey400 inline-block rounded px-2 py-1 text-sm">
        Uploading...
      </span>
    </Message>
  )
}

UploadingMessage.displayName = 'UploadingMessage'
