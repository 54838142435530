import type { FormikHelpers } from 'formik'
import { Form, Formik } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { useContext, useRef, useState } from 'react'

import { MessageContext } from '@/components/chat/message-context'
import { ActionType, type BlockComponent } from '@/models/blocks'

import type { IFormStep } from './form-step'
import { FormStep } from './form-step'

export interface IFormContentComponentProps {
  onUpdateData: (key: string, value: any) => void
}

export interface IAiTemplateFormProps<Values = any> {
  steps: IFormStep<Values>[]
  initialValues: Values
}

export const FormSteps = ({
  steps,
  initialValues, // NOTE: you must define a value for each input or react will moan about controlled/uncontrolled inputs
  onAction,
}: BlockComponent<IAiTemplateFormProps>) => {
  const [stepNumber, setStepNumber] = useState(0)
  const [valueSnapshot, setValueSnapshot] = useState(initialValues)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const step = steps[stepNumber]
  const isLastStep = stepNumber === steps.length - 1

  const updateValueSnapshot = (values: any) => {
    setValueSnapshot({ ...valueSnapshot, ...values })
  }

  const goToStep = async (stepIndex: number, bag: FormikHelpers<any>) => {
    setStepNumber(stepIndex)
    await bag.setTouched({})
    bag.setErrors({})
    await bag.validateForm()
  }

  const onSubmit = (values) => {
    setIsSubmitting(true)

    onAction({
      type: ActionType.SUBMIT,
      data: { values },
    })
  }

  const onStepSubmit = async (values, bag: FormikHelpers<any>) => {
    updateValueSnapshot(values)

    if (step.onSubmit) {
      step.onSubmit(values, bag)
    }

    if (isLastStep) {
      return onSubmit(values)
    } else {
      bag.setSubmitting(false)
      goToStep(stepNumber + 1, bag)
    }
  }

  const isStepOpen = (stepIndex: number) => {
    return !isSubmitting && stepIndex === stepNumber
  }

  const [openStepHeights, setOpenStepHeights] = useState({})
  const stepRefs = useRef({})
  const messageContext = useContext(MessageContext)

  const motionVariants = {
    open: (stepKey) => ({
      opacity: 1,
      height: openStepHeights[stepKey] || 'auto',
    }),
    collapsed: {
      opacity: 0,
      height: 0,
    },
  }

  return (
    <div className="bg-cerosWhite border-1 border-cerosWhite shadow-accordion grid grid-cols-1 overflow-clip rounded-2xl md:max-w-[840px]">
      <Formik
        initialValues={valueSnapshot}
        enableReinitialize={true}
        onSubmit={onStepSubmit}
        validate={step.validate || undefined}
        validateOnBlur={true}
        validateOnMount={true}
      >
        {(formik) => {
          return (
            <Form>
              <AnimatePresence initial={false}>
                {steps.map((step, stepIndex) => {
                  // NOTE: close the always form if it's not the last message (i.e.once the form has been submitted)
                  const isOpen =
                    isStepOpen(stepIndex) && messageContext?.isLastMessage!!
                  const onOpenStep =
                    stepIndex < stepNumber ||
                    (stepIndex === stepNumber + 1 && formik.isValid)
                      ? () => {
                          goToStep(stepIndex, formik)
                        }
                      : undefined

                  return (
                    <FormStep
                      key={step.key}
                      step={step}
                      isOpen={isOpen}
                      isComplete={isSubmitting || stepIndex < stepNumber}
                      onOpenStep={onOpenStep}
                      formik={formik}
                    >
                      <motion.div
                        key={`animation-${step.key}`}
                        initial="collapsed"
                        animate={isOpen ? 'open' : 'collapsed'}
                        variants={motionVariants}
                        custom={step.key}
                        transition={{ duration: 0.5, ease: 'easeInOut' }}
                        className="-mx-8 overflow-hidden px-8"
                        ref={(el) => {
                          stepRefs.current[step.key] = el
                        }}
                        onAnimationStart={() => {
                          const height = stepRefs.current[step.key].scrollHeight
                          if (isOpen) {
                            setOpenStepHeights((prev) => ({
                              ...prev,
                              [step.key]: height,
                            }))
                          }
                        }}
                      >
                        <fieldset className="py-4" disabled={!isOpen}>
                          {step.content({ formik, isLastStep })}
                        </fieldset>
                      </motion.div>
                    </FormStep>
                  )
                })}
              </AnimatePresence>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
