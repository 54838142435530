import { DeleteModal } from '@/pages/modals/delete'

interface IDeleteChatModal {
  onDelete: () => void
}

export function DeleteChatModal({ onDelete }: IDeleteChatModal) {
  return (
    <DeleteModal
      message="Are you sure you want to permanently delete this chat?"
      onDelete={onDelete}
    />
  )
}
