import { observer } from 'mobx-react-lite'

import { PromptEntry } from '@/components/prompt/prompt-entry'
import { DI_TYPE } from '@/di.types'
import { useViewModel } from '@/utils/view'

export const PromptsPage = observer(() => {
  const vm = useViewModel(DI_TYPE.PromptViewModel)

  if (!vm.isEnabled) {
    return <div className="h-full w-full p-4">Prompt editing is disabled.</div>
  }

  return (
    <div className="flex h-full w-full justify-center overflow-auto p-4">
      <div className="flex h-full w-full  max-w-[2000px] flex-col">
        {!vm.isLoading &&
          vm.prompts.map((prompt) => (
            <PromptEntry key={prompt.promptType} prompt={prompt} vm={vm} />
          ))}
      </div>
    </div>
  )
})
