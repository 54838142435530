import type { Gem } from '@ceros/gemma-api-spec'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'

import PaperclipIcon from '@/assets/icons/paperclip.svg?react'
import gemHeaderSvg from '@/assets/images/gem-header.svg'
import gemHeaderMobileSvg from '@/assets/images/gem-header-mobile.svg'
import { Attachment } from '@/components/attachment/attachment'
import { Button } from '@/components/button/button'
import { EmojiPicker } from '@/components/emoji-picker/emoji-picker'
import { TextInput } from '@/components/input/text-input'
import { Modal } from '@/components/modal/modal'
import { DI_TYPE } from '@/di.types'
import { useIsMobile } from '@/utils/use-is-mobile'
import { debounce } from '@/utils/utils'
import { useFactoryViewModel } from '@/utils/view'

import { GemPromptInput } from './gem-prompt-input'

interface IGemModal {
  gemToUpdate?: Gem
  closeOnBackdropClick?: boolean
}

export const GemModal = observer(
  ({ gemToUpdate, closeOnBackdropClick }: IGemModal) => {
    const vm = useFactoryViewModel(DI_TYPE.GemModalViewModel, [gemToUpdate])
    const isMobile = useIsMobile()
    const fileInputRef = useRef<HTMLInputElement>(null)

    const title = `${vm.mode === 'ADD' ? 'Create' : 'Edit'} your custom Gem`

    return (
      <Modal
        backdrop={true}
        className="w-[358px] sm:w-[480px]"
        closeOnBackdropClick={closeOnBackdropClick}
        onClose={vm.cancel}
      >
        <div
          className="flex flex-col p-6"
          data-automation="update-custom-gem-modal"
        >
          <div className="flex h-[140px] w-full content-center rounded-2xl bg-gradient-to-l from-indigo-200 via-stone-200 to-stone-200 p-2.5 pb-3.5">
            <p className="flex w-full justify-center self-center text-xl font-medium leading-7 text-neutral-600 sm:text-2xl">
              {title}
            </p>
            <div
              className="absolute -ml-8 -mt-8 h-[162px] w-[340px] sm:-ml-5 sm:-mt-0.5 sm:h-[154px] sm:w-[440px] "
              style={{
                backgroundImage: `url("${
                  isMobile ? gemHeaderMobileSvg : gemHeaderSvg
                }")`,
              }}
            />
          </div>

          <div className="mt-6 flex gap-2">
            <fieldset className="flex w-full flex-wrap items-center justify-start gap-2">
              <label htmlFor="gemName" className="flex text-sm leading-tight">
                Name your Gem
              </label>
              <TextInput
                id="gemName"
                autoFocus
                className="h-[40px] w-full rounded-xl p-3 text-xs leading-none tracking-tight"
                name="gemName"
                maxLength={50}
                value={vm.gem.title}
                onChange={vm.updateTitle}
                placeholder="Name your Gem"
                data-automation="custom-gem-name"
              />
            </fieldset>

            <fieldset className="flex w-[40px] flex-wrap items-center justify-start gap-2">
              <label htmlFor="gemIcon" className="flex text-sm leading-tight">
                Emoji
              </label>
              <TextInput
                id="gemIcon"
                className="h-[40px] w-[40px] cursor-pointer rounded-xl px-0 text-center text-2xl"
                name="gemIcon"
                value={vm.gem.emoji}
                onClick={debounce(vm.toggleEmojiPicker, 10)}
                data-automation="custom-gem-emoji-picker"
              />
              {vm.emojiPickerVisible && (
                <div className="absolute z-20">
                  <EmojiPicker
                    onEmojiSelect={vm.updateEmoji}
                    onClickOutside={vm.closeEmojiPicker}
                  />
                </div>
              )}
            </fieldset>
          </div>

          <GemPromptInput
            prompt={vm.gem.prompt}
            onPromptChange={vm.updatePrompt}
          />

          <div className="mt-4 flex flex-col gap-2">
            <div className="flex text-sm leading-tight">Knowledge</div>
            <div className="flex text-xs leading-tight text-neutral-400">
              Enhance your Custom Gem with even more context by uploading a file
              below
            </div>

            {vm.attachments.length > 0 && (
              <div className="flex max-h-40 flex-wrap gap-2 overflow-scroll">
                {vm.attachments.map(({ id, filename, mime_type, status }) => (
                  <Attachment
                    key={id}
                    name={filename}
                    type={mime_type}
                    isLoading={status !== 'processed'}
                    className="w-[151px] sm:w-[212px]"
                    onClose={() => vm.unpinAttachment(id)}
                  />
                ))}
              </div>
            )}

            <Button
              onClick={() => fileInputRef?.current?.click()}
              className="mt-2 h-[40px] w-[104px] rounded-lg bg-white px-4 py-2 text-black hover:bg-neutral-100 active:bg-neutral-200"
            >
              <PaperclipIcon className="relative h-4 w-4" /> Upload
            </Button>
            <input
              ref={fileInputRef}
              className="hidden"
              type="file"
              data-automation="custom-gem-file-input"
              multiple={false}
              onChange={vm.onUpload}
            />
          </div>

          <div className="mt-6 flex justify-end gap-2">
            <Button
              onClick={vm.cancel}
              variant="secondary"
              className="h-[40px] min-w-[120px] rounded-lg px-4 py-2"
            >
              Cancel
            </Button>
            <Button
              onClick={vm.submit}
              data-automation="create-gem-button"
              disabled={!vm.isDataValid}
              className="h-[40px] min-w-[120px] rounded-lg px-4 py-2"
            >
              {vm.mode === 'ADD' ? 'Create gem' : 'Save changes'}
            </Button>
          </div>
        </div>
      </Modal>
    )
  },
)
