import { MenuButton, MenuItem } from '@szhsin/react-menu'
import React from 'react'

import ClipboardIcon from '@/assets/icons/clipboard.svg?react'
import EditIcon from '@/assets/icons/editable.svg?react'
import MoreIcon from '@/assets/icons/more.svg?react'
import TextArt from '@/assets/images/vector-empty-lines.svg?react'

import { useIsMobile } from '../../utils/use-is-mobile'
import { Menu } from '../menu/menu'

export const DocumentPreview: React.FC<{
  id: string
  date: Date
  version: number
  streaming?: boolean
  openEditor: () => void
  copyRichText: () => void
}> = ({ version, date, streaming, openEditor, copyRichText }) => {
  const isMobile = useIsMobile()
  return (
    <div className="border-cerosWhite/100 bg-cerosWhite/100 group relative mb-4 flex w-[300px] flex-row rounded-lg border-2 shadow-md">
      <div
        className="bg-brand-grad-light my-2 ml-3 flex h-[60px] w-[80px] cursor-pointer items-end justify-center rounded-lg"
        onClick={openEditor}
      >
        <div className="bg-cerosWhite/100 folded-corner flex h-[50px] w-[60px] items-end justify-center rounded-t-md">
          <TextArt className="-mb-[32px] mr-[4px] scale-[0.6]" />
        </div>
      </div>
      <div
        className="text-cerosGrey500 flex h-[72px] cursor-pointer items-center justify-center gap-4 px-4"
        onClick={openEditor}
      >
        <span>
          <strong className="text-sm font-semibold">
            {!streaming
              ? `Version ${version > -1 ? version + 1 : 'unknown'}`
              : 'Working on it...'}
          </strong>
          {!streaming && (
            <>
              <br />
              <span
                className="text-sm"
                data-automation="copy-and-content-block"
              >
                {date.toLocaleDateString()} {date.toLocaleTimeString()}
              </span>
            </>
          )}
        </span>
      </div>
      <Menu
        align="start"
        direction="left"
        minSize={'min-w-[150px]'}
        arrow={true}
        portal={!isMobile}
        arrowProps={{ className: 'w-2 h-2' }}
        viewScroll="initial"
        overflow="hidden"
        menuButton={
          <MenuButton className="bg-cerosGrey100 active:bg-cerosGrey200 absolute right-2 top-2 hidden h-6 w-6 rounded-[4px] p-1 opacity-0 group-hover:block group-hover:opacity-100">
            <MoreIcon />
          </MenuButton>
        }
      >
        <MenuItem
          className="hover:bg-cerosGrey100 active:bg-cerosGrey200 flex cursor-pointer items-center gap-2 rounded-t-md p-2"
          onClick={copyRichText}
        >
          <ClipboardIcon /> Copy
        </MenuItem>
        <MenuItem
          className="hover:bg-cerosGrey100 active:bg-cerosGrey200 flex cursor-pointer items-center gap-2 rounded-b-md p-2"
          onClick={openEditor}
        >
          <EditIcon />
          Edit
        </MenuItem>
      </Menu>
    </div>
  )
}
