import { captureException } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { SignUpCode } from '@/components/signup/signup-code'
import { SignUpEmail } from '@/components/signup/signup-email'
import { SignUpPassword } from '@/components/signup/signup-password'
import { DI_TYPE } from '@/di.types.js'
import { useViewModel } from '@/utils/view'

export const SignUpPage = observer(() => {
  const vm = useViewModel(DI_TYPE.SignUpViewModel)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    try {
      vm.loadSignUpDataFromSearchParams(searchParams, setSearchParams)
    } catch (e) {
      captureException(e)
    }
  }, [vm, searchParams, setSearchParams])

  const SignUpComponent = () => {
    switch (vm.state) {
      case 'code':
        return (
          <SignUpCode
            email={vm.email ?? ''}
            errorMessage={vm.errorMessage}
            isBusy={vm.isBusy}
            onSubmit={(code: string) => vm.checkActivationCode(code)}
            resendCode={vm.resendCode}
          />
        )
      case 'password':
        return (
          <SignUpPassword
            email={vm.email ?? ''}
            errorMessage={vm.errorMessage}
            isBusy={vm.isBusy}
            onSubmit={(password: string) => vm.finishSignUp(password)}
          />
        )
      default:
        return (
          <SignUpEmail
            email={vm.email ?? ''}
            errorMessage={vm.errorMessage}
            isBusy={vm.isBusy}
            onSubmit={(email: string) => vm.startSignUp(email)}
          />
        )
    }
  }

  return (
    <div className="flex grow flex-col">
      <div className="grid-cols-auto grid h-[540px] grid-rows-[1fr_min-content]">
        <div className="mx-auto mb-14 flex w-full max-w-[400px]">
          {SignUpComponent()}
        </div>
      </div>
    </div>
  )
})

SignUpPage.displayName = 'SignUpPage'
