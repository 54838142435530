import { forwardRef } from 'react'

import { cls } from '@/utils/utils.js'

interface ITextArea extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  dataAutomation?: string
}

export const TextArea = forwardRef<HTMLTextAreaElement, ITextArea>(
  ({ className, style, ...rest }, ref) => {
    return (
      <textarea
        ref={ref}
        className={cls(
          'h-[48px] w-full resize-none px-4 py-[14px]',
          'bg-cerosWhite rounded-md border font-medium outline-none',
          'disabled:bg-cerosGrey100 disabled:text-cerosGrey400 disabled:hover:border-cerosGrey400 disabled:focus:border-cerosGrey400 disabled:active:border-cerosGrey400 disabled:cursor-not-allowed',
          'border-cerosGrey300 hover:bg-cerosGrey100 focus:border-cerosPrimary focus:bg-cerosWhite active:border-cerosPrimary',
          className,
        )}
        style={style}
        {...rest}
      />
    )
  },
)

TextArea.displayName = 'TextArea'
