import type { Button as ButtonSchema } from '@ceros/gemma-api-spec'
import { motion } from 'framer-motion'
import { useContext } from 'react'

import { ActionType, type BlockComponent } from '@/models/blocks'
import { cls } from '@/utils/utils'

import { ChoicesContext } from './choices'

const buttonAnimationVariant = {
  hidden: {
    x: -10,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
}

export type ButtonVariant = 'rectangle' | 'square'

export type IButton = Omit<ButtonSchema, 'icon'> & {
  icon?: string | JSX.Element
  className?: string
}

function buildActionPayload(label: string, value: string, action: string) {
  if (action.startsWith('show-modal')) {
    return {
      type: ActionType.SHOW_MODAL,
      data: {
        modal: action.split(':')[1],
        label,
        value,
      },
    }
  }

  switch (action) {
    case ActionType.CHOICE:
    case ActionType.REPLY:
    case ActionType.SUBMIT:
      return {
        type: action,
        data: {
          label,
          value,
        },
      }
    default:
      throw new Error(`Unknown button action type ${action}`)
  }
}

export const Button = ({
  icon,
  l,
  v,
  action,
  className,
  onAction,
}: BlockComponent<IButton>) => {
  const label = l
  const value = v || l
  const choicesContext = useContext(ChoicesContext)
  const buttonVariant = choicesContext?.buttonVariant || 'rectangle'
  const selected = choicesContext?.value === value

  return (
    <motion.div
      variants={buttonAnimationVariant}
      className={cls(
        className,
        'flex w-full md:w-[unset]',
        buttonVariant === 'square' && 'justify-center md:w-full md:flex-1',
      )}
    >
      <button
        type="button"
        className={cls(
          'text-md flex items-center justify-center gap-x-2 overflow-clip rounded-md font-medium',
          buttonVariant === 'rectangle' && 'h-[36px] min-w-[100px] px-6 py-6 ',
          buttonVariant === 'square' &&
            'grid h-auto w-full max-w-[334px] items-center justify-stretch md:max-w-[260px]',
          'border-cerosGrey200 text-cerosBlack bg-cerosWhite border border-solid',
          'shadow-accordion-button hover:shadow-none active:shadow-none',
          (selected &&
            'border-cerosOtherBlue800 outline-cerosOtherBlue800 border outline outline-1') ||
            'hover:border-cerosGrey300 active:border-cerosGrey400',
        )}
        {...(onAction
          ? {
              onClick: () =>
                onAction(
                  buildActionPayload(label, value, action || ActionType.REPLY),
                ),
            }
          : {})}
      >
        {(icon || buttonVariant === 'square') && (
          <span
            className={cls(
              buttonVariant === 'square' &&
                'bg-cerosGrey100 h-[160px] w-full text-[32px] leading-[92px]',
            )}
          >
            {icon}
          </span>
        )}
        <span
          className={cls(
            'flex items-center justify-center',
            buttonVariant === 'square' ? 'py-2' : 'h-[60px]',
          )}
        >
          <span>{label}</span>
        </span>
      </button>
    </motion.div>
  )
}
