import './loading-dots.css'

import { cls } from '@/utils/utils.js'

interface ILoadingDots {
  className?: string
}

export function LoadingDots({ className }: ILoadingDots) {
  return (
    <div className={cls('loading', className)}>
      {/* These divs are the 3 dots in CSS */}
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
