import { useFormikContext } from 'formik'

import { ActionType } from '@/models/blocks'
import { onActionTypeHandler } from '@/utils/action'
import { cls } from '@/utils/utils'

import type { ButtonVariant, IButton } from '../../button'
import { Choices } from '../../choices'
import { FieldError } from './field-error'

export interface IButtonSelection {
  name: string
  buttonVariant?: ButtonVariant
  buttons: IButton[]
  className?: string
  validateOnChoice?: boolean
  onSelection?: (value: string) => void
}

export const ButtonSelection = ({
  name,
  buttons,
  buttonVariant,
  className,
  validateOnChoice = true,
  onSelection,
}: IButtonSelection) => {
  const { values, setFieldValue } = useFormikContext()
  const value =
    typeof values === 'object' && !!values && name in values
      ? values[name]
      : undefined

  // Ensure all buttons have an action type of choice if nothing is set explicitly
  const choices = buttons.map((button) => ({
    ...button,
    action: button.action || ActionType.CHOICE,
  }))

  return (
    <>
      <Choices
        animate={!value}
        buttonVariant={buttonVariant}
        choicesClassName={cls('justify-center my-4', className)}
        buttonClassName={'min-w-[100px]'}
        onAction={(action) =>
          onActionTypeHandler(
            action,
            ActionType.CHOICE,
            async (choiceAction) => {
              const chosenValue = choiceAction.data.value
              await setFieldValue(name, chosenValue, validateOnChoice)
              onSelection && onSelection(chosenValue)
            },
            () => {
              throw new Error(`Unable to handle button action '${action.type}'`)
            },
          )
        }
        v={choices}
        value={value}
      />
      <FieldError name={name} />
    </>
  )
}
