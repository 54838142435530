interface IEmbedLayout {
  content: React.ReactNode
}

export const EmbedLayout = ({ content }: IEmbedLayout) => {
  return (
    <>
      <div
        id="embed-layout"
        className="relative h-screen w-full overflow-hidden"
      >
        {content}
      </div>
    </>
  )
}
