import type { AppRouter } from '@ceros/gemma-api-spec'
import { contract as gemmaContract, initClient } from '@ceros/gemma-api-spec'
import type { AxiosError, AxiosResponse, Method } from 'axios'
import axios, { isAxiosError } from 'axios'
import { injected } from 'brandi'

import { env } from '@/env.js'

export type Api = ReturnType<typeof createApi>
export function createApi() {
  return createApiForContract(gemmaContract)
}
export type ApiResponse = {
  status: number
  body: any
  headers: Headers
}

injected(createApi)

function createApiForContract<Contract extends AppRouter>(contract: Contract) {
  let authorizationHeader = env().VITE_API_KEY ?? ''
  let api401handler: (error: AxiosError) => any
  const ext = {
    setToken: (token: string) => {
      authorizationHeader = token ? `Bearer ${token}` : ''
    },
    clearAuthHeader: () => {
      authorizationHeader = ''
    },
    addHTTP401Interceptor: (onHTTP401: (error: AxiosError) => any) => {
      api401handler = onHTTP401
    },
  }

  const baseURL = env().VITE_API_ENDPOINT
  const client = initClient(contract, {
    baseUrl: baseURL,
    baseHeaders: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    api: async ({ path, method, headers, body }): Promise<ApiResponse> => {
      const response = await axios
        .request({
          method: method as Method,
          url: path,
          headers: {
            Authorization: authorizationHeader,
            ...headers,
          },
          data: body,
        })
        .catch((e) => {
          if (!isAxiosError(e)) throw e
          const response = e.response as AxiosResponse
          return response.status === 401 &&
            e.config &&
            !e.config.url?.endsWith(
              gemmaContract.authentication.refreshToken.path,
            )
            ? api401handler(e)
            : response
        })
        .catch((e) => {
          if (!isAxiosError(e)) throw e
          return e.response
        })
      return {
        status: response.status,
        body: response.data,
        headers: new Headers(Object.entries(response.headers)),
      }
    },
  })

  return {
    ...client,
    ...ext,
  }
}
