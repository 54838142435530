import type { FetchEventSourceInit } from '@microsoft/fetch-event-source'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import type { AxiosError, AxiosInstance } from 'axios'
import axios from 'axios'
import { injected } from 'brandi'

import { DI_TYPE } from '@/di.types'
import { env } from '@/env.js'

import type { Api } from './api'

export class RetriableError extends Error {}
export class FatalError extends Error {}
export class AuthenticationError extends FatalError {}

export class ApiClient {
  private http: AxiosInstance
  public get: AxiosInstance['get']
  public post: AxiosInstance['post']
  public getUri: AxiosInstance['getUri']
  public patch: AxiosInstance['patch']
  public delete: AxiosInstance['delete']
  private eventSource401Handler:
    | ((err: Error, input: RequestInfo, init?: FetchEventSourceInit) => any)
    | undefined

  constructor(private api: Api) {
    const baseURL = env().VITE_API_ENDPOINT
    const http = axios.create({
      baseURL,
      timeout: 2 * 60 * 1000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: env().VITE_API_KEY ?? '',
      },
    })

    this.http = http
    this.get = http.get
    this.post = http.post
    this.getUri = http.getUri
    this.patch = http.patch
    this.delete = http.delete
    this.eventSource401Handler = undefined
  }

  public setToken(token: string) {
    this.api.setToken(token)
    this.http.defaults.headers['Authorization'] = token ? `Bearer ${token}` : ''
  }

  public addHTTP401Interceptor(onHTTP401: (error: AxiosError) => any) {
    this.api.addHTTP401Interceptor(onHTTP401)
    this.http.interceptors.response.use(
      (response) => response,
      function (error) {
        if (error?.response?.status === 401 && error.config) {
          return onHTTP401(error)
        }
        return Promise.reject(error)
      },
    )
  }

  public addEventSource401Handler(
    on401: (err: Error, input: RequestInfo, init?: FetchEventSourceInit) => any,
  ) {
    this.eventSource401Handler = on401
  }

  private async doFetchEventSource(
    input: RequestInfo,
    init?: FetchEventSourceInit,
  ) {
    return fetchEventSource(`${env().VITE_API_ENDPOINT}${input}`, {
      headers: {
        Authorization: `${this.http.defaults.headers['Authorization']}`,
      },
      openWhenHidden: true,
      ...init,
    })
  }

  public async fetchEventSource(
    input: RequestInfo,
    init?: FetchEventSourceInit,
  ) {
    // modify onopen callback to detect 401 responses
    const { onopen: onopenIn } = init || {}
    const onopen = async (response: Response) => {
      if (response.status === 401) throw new AuthenticationError()
      if (onopenIn) return onopenIn(response)
    }

    return this.doFetchEventSource(input, {
      ...init,
      onopen,
    }).catch((error) => {
      if (error instanceof AuthenticationError && this.eventSource401Handler) {
        return this.eventSource401Handler(error, input, init)
      }
      throw error
    })
  }
}

injected(ApiClient, DI_TYPE.Api)
