import { observer } from 'mobx-react-lite'

import { DI_TYPE } from '@/di.types'
import { useViewModel } from '@/utils/view'

export const ConversationNewPage = observer(() => {
  useViewModel(DI_TYPE.ConversationNewViewModel)
  return null
})

ConversationNewPage.displayName = 'ConversationNewPage'
