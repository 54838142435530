import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import type { ElementRef } from 'react'
import { useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'

import { Button } from '@/components/button/button.js'
import { LoadingDots } from '@/components/loading-dots/loading-dots.js'
import { ReadonlyFooter } from '@/components/readonly-footer.js'
import { DI_TYPE } from '@/di.types.js'
import { ConversationsService } from '@/services/conversations.js'
import { LOGIN_RETURN_STORE_KEY } from '@/services/login.js'
import { useFactoryViewModel } from '@/utils/view.js'

import { MessagesReadonlyView } from './messages-readonly-view.js'

export const ConversationReadonlyPage = observer(() => {
  const { conversationId } = useParams()
  const messageContainerRef = useRef<ElementRef<'div'>>(null)
  const bottomLineRef = useRef<ElementRef<'div'>>(null)

  const vm = useFactoryViewModel(DI_TYPE.ConversationReadonlyViewModel, [
    conversationId,
  ])

  const cloneUrl = `/${ConversationsService.CONVERSATIONS_SLUG}/${vm.conversation?.id}/clone`
  const loginReturn = new URLSearchParams({
    [LOGIN_RETURN_STORE_KEY]: cloneUrl,
  })

  const prevLoadedHistoryRef = useRef<boolean>(vm.loadedHistory)

  useEffect(() => {
    const scrollbarElement = document.getElementById('pageContainerScroll')

    if (vm.loadedHistory && !prevLoadedHistoryRef.current) {
      vm.scrollToBottom() // NOTE: scroll to bottom on first message history load
    }

    // NOTE: Scroll to bottom when the last message is from user
    if (scrollbarElement && vm.messages.length > 0) {
      if (vm.messages[vm.messages.length - 1].author.type === 'user') {
        vm.scrollToBottom()
      }
    }

    prevLoadedHistoryRef.current = vm.loadedHistory
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vm.loadedHistory, // check for scroll if the history has loaded
    vm.messages.length, // check for scroll if a new message appears
  ])

  if (!vm.conversation) {
    return (
      <div className="bg-cerosWhite absolute inset-0 z-30 flex items-center justify-center">
        <LoadingDots />
      </div>
    )
  }

  return (
    <div
      id="conversation-layout"
      className="relative flex h-screen max-h-screen items-center justify-center overflow-hidden"
    >
      <div
        id="conversation-page"
        data-automation={vm.conversation.id}
        className="relative h-full w-full overflow-hidden"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '1fr auto',
        }}
      >
        {!vm.loadedHistory && <div className="flex-grow" />}
        {vm.loadedHistory && vm.messages.length > 0 && (
          <motion.div
            className="chat-custom-scrollbar custom-scrollbar flex h-full flex-col overflow-y-auto overflow-x-clip"
            id="pageContainerScroll"
            initial={{}}
            animate="visible"
            transition={{
              duration: 0.8,
              delay: 0,
            }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <div className="pointer-events-none absolute top-0 z-10 flex w-full flex-col">
              <div className="bg-cerosChatBg relative z-20 flex h-20 w-full items-end justify-center">
                <h1 className="mb-2 p-0 text-2xl font-medium leading-6">
                  {vm.conversation.name}
                </h1>
              </div>
              <div className="bg-fade-top progressive-blur-top h-[55px] w-full" />
            </div>
            <div className="min-h-[calc(100% - 72px)] relative flex flex-1 flex-col items-center justify-end pt-28">
              <section
                ref={messageContainerRef}
                className="flex w-full min-w-[350px] max-w-[960px] flex-col gap-1 pb-[6px] pl-[6px] pt-[20px]"
              >
                <MessagesReadonlyView vm={vm} />
                <div ref={bottomLineRef} className="h-1 w-full" />
              </section>
            </div>
            <div className="sticky bottom-0 z-10 flex w-full flex-col items-center">
              <div className="bg-fade-bottom progressive-blur-bottom h-[55px] w-full" />
              <div className="bg-cerosChatBg z-20 flex h-full w-full flex-col items-center justify-end gap-4 pt-4">
                {vm.isAuthenticated ? (
                  <Link to={cloneUrl}>
                    <Button variant="primary">Continue with Gemma</Button>
                  </Link>
                ) : (
                  <Link to={`/login?${loginReturn.toString()}`}>
                    <Button variant="primary">Start creating with Gemma</Button>
                  </Link>
                )}
                <ReadonlyFooter />
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  )
})

ConversationReadonlyPage.displayName = 'ConversationReadonlyPage'
