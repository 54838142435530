import { observer } from 'mobx-react-lite'

import InfoIcon from '@/assets/icons/info.svg?react'
import { Button } from '@/components/button/button'
import { LabeledTextInput } from '@/components/input/labeled-text-input'
import { Choice } from '@/components/onboarding/choice'
import { ProfileHeader } from '@/components/profile-header/profile-header'
import { Select } from '@/components/select/select'
import { TextAreaProfile } from '@/components/text-area/text-area-profile'
import { Tooltip } from '@/components/tooltip/tooltip'
import { DI_TYPE } from '@/di.types'
import { useViewModel } from '@/utils/view'
import type { ProfileViewModel } from '@/view-models/profile'

export const ProfilePage = observer(() => {
  const vm = useViewModel<ProfileViewModel>(DI_TYPE.ProfileViewModel)

  return (
    <>
      <div className="flex h-screen flex-col">
        <div className="custom-scrollbar flex min-h-0 flex-grow flex-col items-center overflow-auto pt-[64px]">
          <form
            onSubmit={vm.onSubmit}
            className="md-h:gap-16 flex flex-grow flex-col items-center justify-center gap-8 px-6 pb-6"
          >
            <ProfileHeader name={vm.name} />
            <div className="flex flex-row flex-wrap justify-evenly gap-8">
              <div className="bg-cerosWhite/60 flex w-full flex-col gap-2 rounded-[10px] p-8 md:w-[490px]">
                <span className="whitespace-nowrap pb-2 text-base font-medium">
                  Adjust your profile settings
                </span>
                <LabeledTextInput
                  name="name"
                  label="Your name:"
                  value={vm.formState?.name ?? ''}
                  onChange={vm.fieldChange}
                />
                <LabeledTextInput
                  name="job_title"
                  label="Your title:"
                  value={vm.formState?.job_title ?? ''}
                  onChange={vm.fieldChange}
                />
                <Select
                  name="roles[]"
                  label="Main responsibility:"
                  options={vm.availableRoles}
                  value={vm.formState?.roles[0] ?? ''}
                  // TODO: need big refactor for FORM components
                  onChange={vm.rolesFiledChange}
                />
                <LabeledTextInput
                  name="organization"
                  label="Who do you work for:"
                  value={vm.formState?.organization ?? ''}
                  onChange={vm.fieldChange}
                />
                <TextAreaProfile
                  className="mt-6"
                  name="additional_info"
                  label="What should Gemma know about you for tailored responses?"
                  tooltip={`e.g.
                  - What subjects can you talk about for hours
                  - What are your hobbies and interests
                  - What do you do for work
                  - What are some goals you have
                  - How formal or casual should Gemma be
                  - How long or short should responses generally be`}
                  value={vm.formState?.additional_info ?? ''}
                  onChange={vm.fieldChange}
                />
              </div>
              <div className="flex flex-col justify-between gap-4 md:items-center lg:items-start">
                <div className="bg-cerosWhite/60 flex w-full flex-col items-start gap-4 rounded-[10px] p-8 pb-12 md:w-[490px]">
                  <span className="flex h-[32px] w-full items-start justify-between text-base font-medium">
                    Where can Gemma be particularly helpful?
                    <Tooltip
                      className="text-cerosGrey400 max-w-[300px] px-4 py-[6px] text-xs"
                      text="Gemma will help in all these areas. But picking some key priorities will help Gemma to create more tailored responses and recommendations."
                      placement="top-end"
                    >
                      <InfoIcon className="stroke-cerosGrey500 m-[5px] mr-2 h-4 w-4" />
                    </Tooltip>
                  </span>
                  <fieldset className="flex flex-wrap items-center justify-start gap-2">
                    {vm.availableInterests.map((choice) => (
                      <Choice
                        intent="alternate"
                        key={`${choice.name}-key`}
                        name="interests[]"
                        label={`${choice.name}`}
                        value={choice.name}
                        checked={vm.formState?.interests.includes(choice.name)}
                        emoji={choice.emoji}
                        multi={true}
                        onChange={vm.fieldChange}
                      />
                    ))}
                  </fieldset>
                </div>
                <Button disabled={!vm.isDirty} size="large">
                  Save changes
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
})
