import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import LogoIcon from '../../assets/icons/logo.svg?react'
import cerosLogo from '../../assets/images/ceros-black.svg'
import splash from '../../assets/images/splash.png'
import { BreathingAnimation } from '../chat/breathing-animation'

export const LoginLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathsWithSignupButton = ['/login', '/forgot-password']
  const pathsWithSigninButton = ['/signup', '/login/unassigned']

  return (
    <main className="h-full overflow-auto">
      <div className="grid min-h-screen grid-cols-1 grid-rows-1 md:grid-cols-2">
        <BreathingAnimation className="md:-ml-[80%] lg:-ml-[70%] xl:-ml-[60%] 2xl:-ml-[50%]" />
        <div className="relative grid grid-cols-1 grid-rows-[min-content_auto_min-content] flex-col p-2 py-4 md:p-14">
          <div className="flex h-fit w-full justify-between">
            <div className="mx-auto grid w-full max-w-[300px] grid-cols-[min-content_min-content] items-center md:m-0">
              <LogoIcon className="h-9 w-9 -translate-x-2" />
              <span className="text-cerosBlack text-l font-medium">Gemma</span>
            </div>
            <button
              hidden={
                !(
                  pathsWithSignupButton.includes(location.pathname) ||
                  pathsWithSigninButton.includes(location.pathname)
                )
              }
              className="mx-0  w-24 rounded-md border "
              onClick={() =>
                navigate(
                  pathsWithSignupButton.includes(location.pathname)
                    ? '/signup'
                    : '/login',
                )
              }
            >
              {pathsWithSignupButton.includes(location.pathname)
                ? 'Sign up'
                : 'Sign in'}
            </button>
          </div>
          <div className="flex grow items-center justify-center">
            <Outlet />
          </div>
          <div className="flex w-full grow items-center justify-center">
            <div className="grid auto-rows-min text-center">
              <div className="mb-12 w-full">
                <a
                  href="https://www.ceros.com"
                  target="_blank"
                  className="inline-block"
                  rel="noreferrer"
                >
                  <img
                    src={cerosLogo}
                    alt="Ceros Logo"
                    title="Made with love by Ceros"
                    className="w-[80px]"
                  />
                </a>
              </div>
              <div className="text-cerosGrey500 grid grid-cols-2 gap-8 text-sm">
                <a
                  href="https://www.ceros.com/gemma-terms-conditions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms
                </a>
                <a
                  href="https://www.ceros.com/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden h-screen items-center justify-center p-8 md:flex">
          <div className="bg-cerosViolet z-30 flex h-full flex-1 items-center justify-center overflow-hidden rounded-3xl p-16">
            <img
              src={splash}
              alt="Gemma Splash"
              title="Hey, I'm Gemma, how can I help?"
              className="w-[600px] min-w-[450px]"
            />
          </div>
        </div>
      </div>
    </main>
  )
}

LoginLayout.displayName = 'LoginLayout'
