import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { errorResponseSchema } from './error.js';
const c = initContract();
export const generativeAIResponse = z.object({
    poll_url: z.string(),
});
const generativeAITextRequestSchema = z.object({
    prompt: z.string(),
});
const generativeAIImageRequestSchema = z.object({
    prompt: z.string(),
    aspect_ratio: z.enum(['square', 'portrait', 'landscape']),
});
export const getGeneratedTextResponse = z.object({
    status: z.enum(['pending', 'completed']),
    object: z.object({
        text: z.string(),
    }),
});
export const generatedImage = z.object({
    url: z.string(),
    seed: z.number(),
});
export const getGeneratedImagesResponse = z.object({
    status: z.enum(['pending', 'completed']),
    object: z.object({
        images: z.array(generatedImage),
    }),
});
export const contract = c.router({
    generateText: {
        method: 'POST',
        path: '/generative/text',
        responses: {
            202: generativeAIResponse,
            422: errorResponseSchema,
        },
        summary: 'Generate text',
        body: generativeAITextRequestSchema,
    },
    getGeneratedText: {
        method: 'GET',
        path: '/generative/text/:workflow_id',
        responses: {
            200: getGeneratedTextResponse,
            422: errorResponseSchema,
        },
        summary: 'Get generated text from workflow',
    },
    generateImage: {
        method: 'POST',
        path: '/generative/images',
        responses: {
            202: generativeAIResponse,
            422: errorResponseSchema,
        },
        summary: 'Generate images',
        body: generativeAIImageRequestSchema,
    },
    getGeneratedImages: {
        method: 'GET',
        path: '/generative/images/:workflow_id',
        responses: {
            200: getGeneratedImagesResponse,
            422: errorResponseSchema,
        },
        summary: 'Get generated images from workflow',
    },
});
