import ImageIcon from '@/assets/icons/image.svg?react'

export interface IImageResult {
  children: React.ReactNode
  description?: string
}

export const ImageResult = ({ children, description }: IImageResult) => {
  return (
    <div
      data-automation="image-result"
      className="-mx-5 -ml-20 mt-5 flex overflow-auto px-5 md:mx-0 md:ml-0 md:mt-0 md:px-0"
    >
      <div className="bg-cerosWhite shadow-imagesBlock w-auto min-w-[200px] rounded-lg p-4 sm:w-[448px] ">
        {children}

        {description && (
          <div className="mt-4 flex w-full items-center justify-between">
            <div className="flex items-baseline">
              <ImageIcon className="mr-2 h-4 w-4" />
              <span className="text-base font-medium">{description}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
