import type { Blocks as BlocksSchema } from '@ceros/gemma-api-spec'
import { captureException, ErrorBoundary } from '@sentry/react'

import type { ActionHandler } from '@/models/blocks'

import { BlockIterator } from './blocks/block-iterator'
import { Button } from './blocks/button'
import { Choices } from './blocks/choices'
import { Form } from './blocks/form'

const blockComponents = {
  // blocks: Blocks, // to avoid circular dependency we would need a nested blocks component
  button: Button,
  choices: Choices,
  form: Form,
}

export function blockToComponent(
  blockDefinition: BlocksSchema,
  componentProps: any,
) {
  const { t, ...rest } = blockDefinition

  if (!blockComponents[t]) {
    throw new Error(`Unknown block type ${t}`)
  }

  const Component = blockComponents[t]
  return <Component {...componentProps} {...rest} />
}

interface ComponentProps {
  blocks: BlocksSchema
  onAction?: ActionHandler
}

export const BlocksContent = ({ blocks, onAction }: ComponentProps) => {
  // NOTE: we want an array of blocks in future, plus we can use the iterator this way
  const blocksArray = [blocks]

  return (
    <div
      data-automation="blocks-response"
      className="mt-4 flex w-full flex-col gap-4"
    >
      <ErrorBoundary
        fallback={
          <p className="text-cerosDanger text-sm">Unable to render block</p>
        }
        onError={(error) => {
          console.error('Blocks error', error, blocks)
          captureException(error, { extra: { blocks } })
        }}
      >
        <BlockIterator blocks={blocksArray} onAction={onAction} />
      </ErrorBoundary>
    </div>
  )
}
