import { useState } from 'react'

import { DI_TYPE } from '@/di.types'
import { type IMenuGroup } from '@/services/sidebar'
import { useIsMobile } from '@/utils/use-is-mobile'
import { cls } from '@/utils/utils'
import { useViewModel } from '@/utils/view'

import { ProBadge } from './pro-badge'
import { ProfileMenu } from './profile-menu'
import { ProfileMenuMobile } from './profile-menu-mobile'

interface IProfileButton {
  className?: string
  name: string
  initials: string
  title?: string
  imageUrl?: string
  actions: IMenuGroup[]
}

export function ProfileButton({
  className,
  name,
  initials,
  title,
  imageUrl,
  actions,
}: IProfileButton) {
  const vm = useViewModel(DI_TYPE.SubscriptionPlanViewModel)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const isMobile = useIsMobile()

  const ProfileIcon = ({ className }: { className?: string }) => (
    <>
      {imageUrl ? (
        <div
          className={cls(
            'bg-cerosGrey100 h-10 w-10 flex-shrink-0 rounded-full bg-cover bg-center bg-no-repeat',
            className,
          )}
          style={{ backgroundImage: `url("${imageUrl}")` }}
        />
      ) : (
        <div
          className={cls(
            'flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-[#F88A8A]',
            className,
          )}
        >
          <span
            className={cls(
              'text-cerosWhite text-[14px] uppercase leading-[14px]',
            )}
          >
            {initials}
          </span>
        </div>
      )}
    </>
  )

  const Username = () => (
    <div
      className={cls(
        'flex h-full flex-1 flex-col overflow-hidden',
        !title && 'justify-center',
      )}
    >
      <span className="text-cerosGrey600 w-full truncate text-ellipsis text-start text-sm font-medium">
        {name}
      </span>
      {title && (
        <span className="text-cerosGrey400 truncate text-start text-xs font-medium">
          {title}
        </span>
      )}
    </div>
  )

  const hasProPlan = vm.currentPlan?.planType === 'pro' && vm.hasBillingEnabled

  const profileSection = (
    <div className="flex max-w-full flex-1 items-center justify-between">
      <div className="flex max-w-full flex-1 items-center justify-start gap-2">
        <ProfileIcon />
        <Username />
        {hasProPlan && <ProBadge />}
      </div>
    </div>
  )

  return isMobile ? (
    <>
      <button
        onClick={() => setIsMenuOpen(true)}
        className="bg-cerosWhite hover:bg-cerosGrey100 active:bg-cerosGrey200 mx-2 mt-2 flex h-[56px] gap-2 rounded-xl px-3.5 py-2"
      >
        {profileSection}
      </button>
      <ProfileMenuMobile
        actions={actions}
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        profileSection={<ProfileIcon className="flex-shrink-0" />}
        username={name}
        title={title}
        isPro={hasProPlan}
      />
    </>
  ) : (
    <ProfileMenu
      className={className}
      actions={actions}
      isOpen={isMenuOpen}
      setIsOpen={setIsMenuOpen}
      profileSection={profileSection}
    />
  )
}
