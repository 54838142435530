import type { RefObject } from 'react'
import { useEffect, useMemo } from 'react'

export function useOnScreen(
  ref: RefObject<HTMLElement>,
  callback: (value: boolean) => void,
) {
  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => callback(entry.isIntersecting)),
    [callback],
  )

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => observer.disconnect()
  }, [observer, ref])
}
