import { generateState } from '@okta/okta-auth-js'
import { injected } from 'brandi'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { toast } from 'react-hot-toast'

import { DI_TYPE } from '@/di.types.js'
import type { ILoginData } from '@/models/login.js'
import {
  LOGIN_ACTION_QUERY_PARAM,
  LOGIN_TYPE_QUERY_PARAM,
  LoginAction,
  LoginType,
  UTM_SOURCE_QUERY_PARAM,
} from '@/models/login.js'
import type { AuthService } from '@/services/auth.js'
import type { Auth0Service } from '@/services/auth0.js'
import type { SaveLoginData } from '@/services/login.js'
import { signInUri } from '@/services/login.js'
import type { MixpanelService } from '@/services/mixpanel.js'
import type { RouterService } from '@/services/router.js'
import { getAppOrigin } from '@/utils/utils.js'

import { BaseViewModel } from './base-view-model.js'

export class LoginViewModel extends BaseViewModel {
  @observable isInitialized = false
  @observable isBusy = false
  @observable parsingForAutoLoginAccessToken = false
  @observable redirectingForAutoLoginAccessToken = false
  @observable errorWithAutoLogin = false

  constructor(
    private authService: AuthService,
    private auth0Service: Auth0Service,
    private routerService: RouterService,
    private mixpanelService: MixpanelService,
    private saveLoginData: SaveLoginData,
  ) {
    super()
    makeObservable(this)
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onInit(): void {}

  onDispose(): void {}

  @computed
  get isAuthenticated() {
    return this.authService.isAuthenticated
  }

  @action.bound
  getLoginDataFromSearchParams(searchParams: URLSearchParams): ILoginData {
    const state = searchParams.get('state') || generateState()
    const loginType = LoginType.parse(
      searchParams.get(LOGIN_TYPE_QUERY_PARAM) || this.detectLoginType(),
    )
    const loginAction = LoginAction.parse(
      searchParams.get(LOGIN_ACTION_QUERY_PARAM) || this.detectLoginAction(),
    )
    const utmSource = searchParams.get(UTM_SOURCE_QUERY_PARAM) || undefined

    return { state, loginType, loginAction, utmSource }
  }

  detectLoginType() {
    return getAppOrigin() === 'web'
      ? LoginType.enum.okta
      : LoginType.enum.embedded
  }

  detectLoginAction() {
    return getAppOrigin() === 'web'
      ? LoginAction.enum.exchange
      : LoginAction.enum.store
  }

  @action.bound
  async login(loginData: ILoginData) {
    this.isBusy = true

    // save the login data to local storage so we can retrieve it after the auth flow
    await this.saveLoginData(loginData)

    switch (loginData.loginType) {
      case 'embedded':
        // we need read and write keys to login, kick off the auth0 flow
        await this.startAuth0()
        break
      case 'okta':
        break
      default:
        throw new Error(`Invalid login type: ${loginData.loginType}`)
    }

    runInAction(() => {
      this.isInitialized = true
      this.isBusy = false
    })
  }

  @action.bound
  private async startAuth0() {
    const success = await this.auth0Service.start()
    if (!success) {
      toast.error('Failed to start auth0')
    }
  }

  @action.bound
  continueAuth0() {
    if (!this.auth0Service.startData) {
      toast.error('Auth0 start data not found')
      return
    }

    window.open(signInUri(this.auth0Service.startData.write_key), '_blank')

    // by redirecting to the callback page, we avoid a race condition with the /login route for authenticated users and push all authentication flows down the same path
    this.routerService.goTo({
      pathname: '/callback',
      search: `?fromAuth0=true&state=${this.auth0Service.startData.write_key}`,
    })
  }

  @action.bound
  setRedirectingForAutoLoginAccessToken(redirecting: boolean) {
    runInAction(() => {
      this.redirectingForAutoLoginAccessToken = redirecting
    })
  }

  trackLoginByEmailStarted() {
    this.mixpanelService.trackLoginByEmailStarted()
  }
}

injected(
  LoginViewModel,
  DI_TYPE.AuthService,
  DI_TYPE.Auth0Service,
  DI_TYPE.RouterService,
  DI_TYPE.MixpanelService,
  DI_TYPE.SaveLoginData,
)
