import type {
  ConversationMessageContent,
  ConversationMessageStreamContent,
} from '@ceros/gemma-api-spec'
import { Fragment } from 'react'

import { DocumentThumbnail } from '@/components/chat/document-thumbnail'
import { EmbeddedContent } from '@/components/chat/embedded-content'
import { ErrorContent } from '@/components/chat/error-content'
import { GeneratedImagesGrid } from '@/components/chat/generated-images-grid'
import { ImageAttachment } from '@/components/chat/image-attachment'
import { ImageResult } from '@/components/chat/image-result'
import { Loader } from '@/components/chat/loader'
import { NounProjectAttachment } from '@/components/chat/noun-project-attachment'
import NounProjectResultLink from '@/components/chat/noun-project-result-link'
import { PresentationContent } from '@/components/chat/presentation-content'
import { TextContent } from '@/components/chat/text-content'
import UnsplashSearchLink from '@/components/chat/unsplash-search-link'
import type {
  ImageAttachmentObject,
  RenderableConversationMessage,
} from '@/models/conversation'
import { resolveImageToolName, resolveImageToolType } from '@/utils/tool'
import { cls } from '@/utils/utils'
import type { ConversationReadonlyViewModel } from '@/view-models/conversation-readonly'

interface IMessageContent {
  message: RenderableConversationMessage
  vm: ConversationReadonlyViewModel
}

function assertNeverMissingContentType(content: never) {
  return (
    <ErrorContent
      dataAutomation="error-response"
      error={`Unknown message content type '${(content as any).type}'`}
    />
  )
}

const mapContentTypeToComponent = (
  params: IMessageContent,
  item: ConversationMessageContent | ConversationMessageStreamContent,
) => {
  const content = item.content
  switch (content.type) {
    case 'text':
      return <TextContent dataAutomation="text-response" text={content.text} />
    case 'inline_document':
      return (
        <TextContent
          isDocument={true}
          dataAutomation="text-response"
          text={content.text}
        />
      )
    case 'images':
      const toolType = content.tool
        ? resolveImageToolType(content.tool)
        : undefined

      const isOnlyOneResult = content.results.length === 1

      return (
        <GeneratedImagesGrid
          toolType={toolType}
          toolName={
            content.tool ? resolveImageToolName(content.tool) : undefined
          }
          moreLink={
            toolType === 'search' && content.search_url
              ? content.search_url
              : undefined
          }
        >
          {content.results.map((result) => {
            const image: ImageAttachmentObject = {
              id: result.id,
              url: result.url,
              width: result.width,
              height: result.height,
              thumbnail_url: result.thumbnail_url || undefined,
              alt: result.description || undefined,
            }
            return (
              <ImageAttachment
                key={image.id}
                className={cls('sm:w-auto', isOnlyOneResult && 'w-auto')}
                editBlocked={true}
                dataAutomation="image-response"
                image={image}
                onTask={() => {}}
                openEdit={() => {}}
                includeImageData={false}
                onDownload={() =>
                  params.vm.downloadImage(params.message, image)
                }
              />
            )
          })}
        </GeneratedImagesGrid>
      )
    case 'image_pin':
      return (
        <ImageResult description="Uploaded image">
          <ImageAttachment
            className="w-auto"
            dataAutomation="image-response"
            image={{ id: item.id, url: content.url }}
            editBlocked={true}
            includeImageData={false}
            onDownload={() => {
              params.vm.downloadImage(params.message, {
                id: item.id,
                url: content.url,
              })
            }}
          />
        </ImageResult>
      )
    case 'pdf_pin':
    case 'upload_pin':
      return (
        <DocumentThumbnail
          title={content.filename}
          status={content.status}
          mimeType={content.mime_type}
        />
      )
    case 'unsplash_search':
      // FUTURE: deprecate me when old conversations aren't going to be useful anymore
      return (
        <UnsplashSearchLink
          query={content.query}
          messageId={params.message.id}
        />
      )
    case 'noun_project_search':
      // FUTURE: deprecate me when old conversations aren't going to be useful anymore
      return (
        <NounProjectResultLink
          query={content.query}
          messageId={params.message.id}
        />
      )
    case 'noun_project_pin':
      return (
        <NounProjectAttachment
          icon={{
            id: item.id,
            icon_id: content.icon.icon_id,
            url: content.icon.url,
            type: 'noun_project',
          }}
        />
      )
    case 'image_edit_session_pin':
      return (
        <ImageResult description="Edited image">
          <ImageAttachment
            className="w-auto"
            dataAutomation="image-response"
            image={{
              id: content.session.result.id,
              url: content.session.result.url,
            }}
            includeImageData={false}
            onDownload={() =>
              params.vm.downloadImage(params.message, {
                id: content.session.result.id,
                url: content.session.result.url,
              })
            }
          />
        </ImageResult>
      )
    case 'blocks_json':
      return null
    case 'embedded_video':
      return (
        <EmbeddedContent source={content.source} html={content.embed_code} />
      )
    case 'images_placeholder':
      // FUTURE: implement once being sent to the client
      return null
    case 'loader':
      return (
        <Loader
          percentage={content.percentage}
          text={content.text}
          is_failed={content.is_failed}
        />
      )
    case 'ai_presentation':
      return <PresentationContent url={content.url} />
    case 'error':
      return (
        <ErrorContent dataAutomation="error-response" error={content.text} />
      )
    default:
      return assertNeverMissingContentType(content)
  }
}

export const MessageContentReadonly = (params: IMessageContent) => {
  return (
    <div data-automation="content-blocks">
      {params.message.items.map((item) => {
        return (
          <Fragment key={item.id}>
            {mapContentTypeToComponent(params, item)}
          </Fragment>
        )
      })}
    </div>
  )
}
