import { Container } from 'brandi'

import { DI_TYPE } from './di.types.js'
import { AuthRepository } from './repositories/auth.js'
import { BrandfetchRepository } from './repositories/brandfetch.js'
import { EditImageRepository } from './repositories/edit-image.js'
import { FileUploadRepository } from './repositories/file-upload.js'
import { NounProjectRepository } from './repositories/noun-project.js'
import { SpeechRepository } from './repositories/speech.js'
import { TasksRepository } from './repositories/tasks.js'
import { UnsplashRepository } from './repositories/unsplash.js'
import { UserRepository } from './repositories/user.js'
import { createApi } from './services/api.js'
import { ApiClient } from './services/api-client.js'
import { AuthService } from './services/auth.js'
import { Auth0Service } from './services/auth0.js'
import { ConversationStreamService } from './services/conversation-stream.js'
import { ConversationsService } from './services/conversations.js'
import { EventBusService } from './services/event-bus.js'
import { FeatureFlagService } from './services/feature-flag.js'
import { FileProcessService } from './services/file-process.js'
import { GemsService } from './services/gems.js'
import { InitService } from './services/init.js'
import {
  getAndRemoveLoginReturnFactory,
  getLoginDataForStateFactory,
  saveLoginDataFactory,
  saveLoginReturnFactory,
} from './services/login.js'
import { LogRocketService } from './services/logrocket.js'
import { MixpanelService } from './services/mixpanel.js'
import { ModalService } from './services/modal.js'
import { oktaAuthFactory } from './services/okta-auth.js'
import { OktaIdpService } from './services/okta-idp.js'
import { RouterService } from './services/router.js'
import { SentryService } from './services/sentry.js'
import { SidebarService } from './services/sidebar.js'
import { StorageService } from './services/storage.js'
import { SupportService } from './services/support.js'
import { UserService } from './services/user.js'
import { VoiceInputService } from './services/voice-input.js'
import { Prompt } from './stores/prompt.js'
import { ActiveGemViewModel } from './view-models/active-gem.js'
import { AuthCallbackViewModel } from './view-models/auth-callback.js'
import { BrandfetchViewModel } from './view-models/brandfetch.js'
import { ConversationViewModel } from './view-models/conversation.js'
import { ConversationCloneViewModel } from './view-models/conversation-clone.js'
import { ConversationLatestViewModel } from './view-models/conversation-latest.js'
import { ConversationNewViewModel } from './view-models/conversation-new.js'
import { ConversationReadonlyViewModel } from './view-models/conversation-readonly.js'
import { EditImageViewModel } from './view-models/edit-image.js'
import { EmbedStudioNewViewModel } from './view-models/embed-studio-new.js'
import { FeatureFlagViewModel } from './view-models/feature-flag.js'
import { FileUploadViewModel } from './view-models/file-upload.js'
import { GemViewModel } from './view-models/gem.js'
import { GemModalViewModel } from './view-models/gem-modal.js'
import { GemPromptInputViewModel } from './view-models/gem-prompt-input.js'
import { HomePageViewModel } from './view-models/home-page.js'
import { LoginViewModel } from './view-models/login.js'
import { NounProjectViewModel } from './view-models/noun-project.js'
import { OnboardingViewModel } from './view-models/onboarding.js'
import { PasswordResetViewModel } from './view-models/password-reset.js'
import { ProfileViewModel } from './view-models/profile.js'
import { PromptInputViewModel } from './view-models/prompt-input.js'
import { PromptsViewModel } from './view-models/prompts.js'
import { RequiredUserChecks } from './view-models/required-user-checks.js'
import { SessionViewModel } from './view-models/session.js'
import { SignUpViewModel } from './view-models/sign-up.js'
import { SocialLoginViewModel } from './view-models/social-login.js'
import { SubscriptionPlanViewModel } from './view-models/subscription-plan.js'
import { TermsViewModel } from './view-models/terms.js'
import { UnsplashViewModel } from './view-models/unsplash.js'
import { WithUserProperties } from './view-models/with-user-properties.js'

export const diContainer = new Container()

// Services
diContainer.bind(DI_TYPE.InitService).toInstance(InitService).inSingletonScope()
diContainer
  .bind(DI_TYPE.StorageService)
  .toInstance(StorageService)
  .inSingletonScope()
diContainer.bind(DI_TYPE.AuthService).toInstance(AuthService).inSingletonScope()
diContainer
  .bind(DI_TYPE.Auth0Service)
  .toInstance(Auth0Service)
  .inSingletonScope()
diContainer.bind(DI_TYPE.ApiClient).toInstance(ApiClient).inSingletonScope()
diContainer.bind(DI_TYPE.Api).toInstance(createApi).inSingletonScope()
diContainer
  .bind(DI_TYPE.MixpanelService)
  .toInstance(MixpanelService)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.ModalService)
  .toInstance(ModalService)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.OktaAuth)
  .toInstance(oktaAuthFactory)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.OktaIdpService)
  .toInstance(OktaIdpService)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.RouterService)
  .toInstance(RouterService)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.LogRocketService)
  .toInstance(LogRocketService)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.SentryService)
  .toInstance(SentryService)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.EventBusService)
  .toInstance(EventBusService)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.SidebarService)
  .toInstance(SidebarService)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.ConversationsService)
  .toInstance(ConversationsService)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.FeatureFlagService)
  .toInstance(FeatureFlagService)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.VoiceInputService)
  .toInstance(VoiceInputService)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.FileProcessService)
  .toInstance(FileProcessService)
  .inSingletonScope()
diContainer.bind(DI_TYPE.UserService).toInstance(UserService).inSingletonScope()
diContainer.bind(DI_TYPE.GemsService).toInstance(GemsService).inSingletonScope()
diContainer
  .bind(DI_TYPE.SupportService)
  .toInstance(SupportService)
  .inSingletonScope()

// Repositories
diContainer
  .bind(DI_TYPE.AuthRepository)
  .toInstance(AuthRepository)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.ConversationStreamService)
  .toInstance(ConversationStreamService)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.UnsplashRepository)
  .toInstance(UnsplashRepository)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.NounProjectRepository)
  .toInstance(NounProjectRepository)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.EditImageRepository)
  .toInstance(EditImageRepository)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.BrandfetchRepository)
  .toInstance(BrandfetchRepository)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.UserRepository)
  .toInstance(UserRepository)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.TasksRepository)
  .toInstance(TasksRepository)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.FileUploadRepository)
  .toInstance(FileUploadRepository)
  .inSingletonScope()
diContainer
  .bind(DI_TYPE.SpeechRepository)
  .toInstance(SpeechRepository)
  .inSingletonScope()

// ViewModels
diContainer
  .bind(DI_TYPE.HomePageViewModel)
  .toInstance(HomePageViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.LoginViewModel)
  .toInstance(LoginViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.TermsViewModel)
  .toInstance(TermsViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.AuthCallbackViewModel)
  .toInstance(AuthCallbackViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.UnsplashViewModel)
  .toInstance(UnsplashViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.NounProjectViewModel)
  .toFactory(NounProjectViewModel, (instance, messageId, query) =>
    instance.setFactoryData(messageId, query),
  )
diContainer
  .bind(DI_TYPE.EditImageViewModel)
  .toInstance(EditImageViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.BrandfetchViewModel)
  .toFactory(BrandfetchViewModel, (instance, messageId) =>
    instance.setFactoryData(messageId),
  )
diContainer
  .bind(DI_TYPE.OnboardingViewModel)
  .toInstance(OnboardingViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.ConversationViewModel)
  .toFactory(ConversationViewModel, (instance, id) =>
    instance.setFactoryData(id),
  )
diContainer
  .bind(DI_TYPE.SessionViewModel)
  .toFactory(SessionViewModel, (instance, id) => instance.setFactoryData(id))
diContainer
  .bind(DI_TYPE.ConversationNewViewModel)
  .toInstance(ConversationNewViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.ConversationLatestViewModel)
  .toInstance(ConversationLatestViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.ConversationReadonlyViewModel)
  .toFactory(ConversationReadonlyViewModel, (instance, id) =>
    instance.setFactoryData(id),
  )
diContainer
  .bind(DI_TYPE.ConversationCloneViewModel)
  .toFactory(ConversationCloneViewModel, (instance, id) =>
    instance.setFactoryData(id),
  )
diContainer
  .bind(DI_TYPE.GemViewModel)
  .toFactory(GemViewModel, (instance, id) => instance.setFactoryData(id))
diContainer
  .bind(DI_TYPE.ActiveGemViewModel)
  .toInstance(ActiveGemViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.SocialLoginViewModel)
  .toInstance(SocialLoginViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.SignUpViewModel)
  .toInstance(SignUpViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.PasswordResetViewModel)
  .toInstance(PasswordResetViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.FeatureFlagViewModel)
  .toInstance(FeatureFlagViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.EmbedStudioNewViewModel)
  .toInstance(EmbedStudioNewViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.WithUserProperties)
  .toInstance(WithUserProperties)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.RequiredUserChecks)
  .toInstance(RequiredUserChecks)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.FileUploadViewModel)
  .toFactory(FileUploadViewModel, (instance, successCallback, failCallback) =>
    instance.setFactoryData(successCallback, failCallback),
  )
diContainer
  .bind(DI_TYPE.GemModalViewModel)
  .toFactory(GemModalViewModel, (instance, gemToUpdate) =>
    instance.setFactoryData(gemToUpdate),
  )
diContainer
  .bind(DI_TYPE.ProfileViewModel)
  .toInstance(ProfileViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.PromptInputViewModel)
  .toInstance(PromptInputViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.GemPromptInputViewModel)
  .toInstance(GemPromptInputViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.PromptViewModel)
  .toInstance(PromptsViewModel)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.SubscriptionPlanViewModel)
  .toInstance(SubscriptionPlanViewModel)
  .inSingletonScope()

// Stores
diContainer.bind(DI_TYPE.Prompt).toInstance(Prompt).inSingletonScope()

// Functions
diContainer
  .bind(DI_TYPE.SaveLoginData)
  .toInstance(saveLoginDataFactory)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.GetLoginDataForState)
  .toInstance(getLoginDataForStateFactory)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.SaveLoginReturn)
  .toInstance(saveLoginReturnFactory)
  .inTransientScope()
diContainer
  .bind(DI_TYPE.GetAndRemoveLoginReturn)
  .toInstance(getAndRemoveLoginReturnFactory)
  .inTransientScope()
