import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { errorResponseSchema, unprocessableContentResponseSchema, } from './error.js';
import { conversationMessageContentSchema, conversationMessageStreamContentSchema, } from './message_contents.js';
const c = initContract();
export const conversationUserSchema = z.object({
    id: z.string().uuid(),
    email: z.string().email(),
    name: z.string().optional(),
    // display_name: z.string(),
});
export const conversationMessageSchema = z.object({
    id: z.string().uuid(),
    author: z.discriminatedUnion('type', [
        z.object({ type: z.literal('user'), user: conversationUserSchema }),
        z.object({ type: z.literal('bot'), name: z.literal('Gemma') }),
    ]),
    conversation_id: z.string().uuid(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    responding_to_id: z.string().uuid().optional(),
    // this is array to handle cases like unsplash link under image
    items: z.array(conversationMessageContentSchema),
    is_cloned: z.boolean(),
    is_liked: z.boolean().optional(),
});
export const conversationMessageStreamSchema = z.object({
    id: z.string().uuid(),
    author: z.discriminatedUnion('type', [
        z.object({ type: z.literal('user'), user: conversationUserSchema }),
        z.object({ type: z.literal('bot'), name: z.literal('Gemma') }),
    ]),
    conversation_id: z.string().uuid(),
    created_at: z.string().datetime().optional(),
    updated_at: z.string().datetime(),
    responding_to_id: z.string().uuid().optional(),
    items: z.array(conversationMessageStreamContentSchema),
    is_cloned: z.boolean(),
    is_liked: z.boolean().optional(),
});
export const messageGeneratingEventSchema = z.object({
    event: z.literal('message_generating'),
    data: conversationMessageStreamSchema,
});
export const messageFinishedEventSchema = z.object({
    event: z.literal('message_finished'),
    data: conversationMessageSchema,
});
const taskPromptTypeEnum = z.enum([
    'task_brainstorm_ideas',
    'task_create_images',
    'task_copy_content',
    'task_ai_presentations',
    'task_creative_strategy',
    'task_magic_insights',
    'task_fun_with_gemma',
    'task_blank_chat',
]);
const conversationSourceEnum = z.enum(['markup-api', 'gemma']);
export const conversationSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    emoji: z.string(),
    source: conversationSourceEnum,
    studio_target_project_id: z.number().nullable(),
    task_prompt_type: taskPromptTypeEnum.nullable(),
    gem_id: z.string().uuid().nullable(),
    is_publicly_shared: z.boolean(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
});
export const conversationWithUserAccess = z.object({
    conversation: conversationSchema,
    user_access: z.object({
        can_write: z.boolean(),
    }),
});
const conversationListItem = conversationSchema.extend({
    last_message: z.object({
        id: z.string().uuid(),
        has_attachment: z.boolean(),
        last_answer_message: z.string().nullable(),
    }),
});
const conversationListSchema = z.object({
    count: z.number(),
    limit: z.number(),
    result: z.array(conversationListItem),
    until: z.string().datetime().nullable(),
});
const conversationCreateSchema = z.object({
    name: z.string(),
    emoji: z.string().optional(),
    source: conversationSourceEnum.optional(),
    studio_target_project_id: z.number().optional(),
    task_prompt_type: taskPromptTypeEnum.optional(),
    gem_id: z.string().uuid().optional(),
});
const conversationUpdateSchema = conversationCreateSchema.merge(z.object({
    is_publicly_shared: z.boolean().optional(),
    name: z.string().optional(),
}));
const conversationTitleSuggestionSchema = z.object({
    emoji: z.string(),
    name: z.string(),
});
const getDownloadParamsSVGSchema = z.object({
    filetype: z.literal('svg'),
    color: z.string().default('A3A3A3'),
});
const getDownloadParamsPNGSchema = z.object({
    filetype: z.literal('png'),
    color: z.string().default('A3A3A3'),
    size: z.string().default('25'),
});
const getDownloadParamsSchema = z.union([
    getDownloadParamsPNGSchema,
    getDownloadParamsSVGSchema,
]);
const nounProjectPinSchema = z.object({
    icon_id: z.number(),
    params: getDownloadParamsSchema,
    type: z.literal('noun_project_pin'),
});
const imageEditSessionPinSchema = z.object({
    session_id: z.string(),
    type: z.literal('image_edit_session_pin'),
});
const imagePinSchema = z.object({
    url: z.string(),
    type: z.literal('image_pin'),
});
const pdfPinSchema = z.object({
    url: z.string(),
    type: z.literal('pdf_pin'),
});
const uploadPinSchema = z.object({
    url: z.string(),
    type: z.literal('upload_pin'),
});
const attachmentPinSchema = z.union([
    nounProjectPinSchema,
    imageEditSessionPinSchema,
    imagePinSchema,
    pdfPinSchema,
    uploadPinSchema,
]);
const pinAttachmentRequestSchema = z.object({
    pin: attachmentPinSchema,
});
const invokeToolTypeSchema = z.discriminatedUnion('type', [
    z.object({
        type: z.literal('upscale'),
        scale: z.union([z.literal('2'), z.literal('4'), z.literal('8')]),
        image: z.object({ url: z.string() }),
    }),
    z.object({
        type: z.literal('remove_bg'),
        image: z.object({ url: z.string() }),
    }),
]);
const invokeToolRequestSchema = z.object({ tool: invokeToolTypeSchema });
const enableChatTaskRequestSchema = z.object({
    task_prompt_type: taskPromptTypeEnum,
});
const enableGemRequestSchema = z.object({
    gem_id: z.string().uuid(),
});
export const updateMessageRequestSchema = z.object({
    content: z.string().optional(),
});
export const updateMessageResponse = z.object({
    message_id: z.string(),
});
export const postFeedbackRequestSchema = z.object({
    // true for like, false for dislike
    like: z.boolean(),
});
export const contract = c.router({
    createConversation: {
        method: 'POST',
        path: '/conversations',
        responses: {
            201: conversationSchema,
            401: errorResponseSchema,
        },
        body: conversationCreateSchema,
    },
    getConversations: {
        method: 'GET',
        path: '/conversations',
        responses: {
            200: conversationListSchema,
            401: errorResponseSchema,
        },
        query: z.object({
            until: z.string().datetime().optional(),
            limit: z.number().optional(),
        }),
    },
    getConversation: {
        method: 'GET',
        path: '/conversations/:conversation_id',
        responses: {
            200: conversationWithUserAccess,
            403: errorResponseSchema,
            404: errorResponseSchema,
        },
    },
    deleteConversation: {
        method: 'DELETE',
        path: '/conversations/:conversation_id',
        responses: {
            200: conversationSchema,
            403: errorResponseSchema,
            404: errorResponseSchema,
        },
        body: z.never(),
    },
    updateConversation: {
        method: 'PATCH',
        path: '/conversations/:conversation_id',
        responses: {
            200: conversationSchema,
            403: errorResponseSchema,
            404: errorResponseSchema,
        },
        body: conversationUpdateSchema,
    },
    getAiTitleSuggestion: {
        method: 'POST',
        path: '/conversations/:conversation_id/ai_title_suggestion',
        responses: {
            200: conversationTitleSuggestionSchema,
            403: errorResponseSchema,
            404: errorResponseSchema,
        },
        body: z.object({}),
    },
    postTextEditMessage: {
        method: 'POST',
        path: '/v2/conversations/:conversation_id/publish-text-edit-message/:message_id/:item_id',
        responses: {
            201: z.object({ message_id: z.string().uuid() }),
        },
        body: z.object({
            occurence_number: z.number(),
            fragment: z.string(),
        }),
        summary: 'Publish a rewrite message request with given text to given conversation',
    },
    postTextMessage: {
        method: 'POST',
        path: '/v2/conversations/:conversation_id/publish-text-message',
        responses: {
            201: z.object({ message_id: z.string().uuid() }),
        },
        body: z.object({
            content: z.string(),
            context: z
                .object({
                text_editor: z
                    .object({ selection: z.string() })
                    .or(z.object({ draft: z.string() })),
            })
                .optional(),
        }),
        summary: 'Publish a new message with given text to given conversation',
    },
    enableGem: {
        method: 'POST',
        path: '/v2/conversations/:conversation_id/enable-gem',
        responses: {
            200: conversationSchema,
            401: errorResponseSchema,
            403: errorResponseSchema,
            422: unprocessableContentResponseSchema,
        },
        body: enableGemRequestSchema,
        summary: 'Enable a gem for the given conversation',
    },
    enableChatTask: {
        method: 'POST',
        path: '/v2/conversations/:conversation_id/enable-chat-task',
        responses: {
            200: conversationSchema,
            401: errorResponseSchema,
            403: errorResponseSchema,
            422: unprocessableContentResponseSchema,
        },
        body: enableChatTaskRequestSchema,
        summary: 'Enable a chat task for the given conversation',
    },
    getMessageHistory: {
        method: 'GET',
        path: '/v2/conversations/:conversation_id/past-messages',
        query: z.object({
            size: z.number().optional(),
            page: z.number().optional(),
        }),
        responses: {
            200: z.object({
                items: z.array(conversationMessageSchema),
            }),
        },
        summary: 'List of historical messages in given conversation',
    },
    pinAttachment: {
        method: 'POST',
        path: '/v2/conversations/:conversation_id/pin-attachment',
        responses: {
            201: z.object({
                message_id: z.string().uuid(),
                handler_id: z.string().uuid(),
            }),
            403: errorResponseSchema,
            404: errorResponseSchema,
        },
        body: pinAttachmentRequestSchema,
        summary: 'Pin a user attachment to given conversation',
    },
    invokeTool: {
        method: 'POST',
        path: '/v2/conversations/:conversation_id/invoke-tool',
        responses: {
            201: z.object({
                message_id: z.string().uuid(),
                handler_id: z.string().uuid(),
            }),
        },
        body: invokeToolRequestSchema,
        summary: 'Directly invoke a tool on an image',
    },
    getUpdates: {
        method: 'GET',
        path: '/v2/conversations/:conversation_id/updates',
        responses: {
            200: c.otherResponse({
                contentType: 'text/event-stream',
                body: z.never(),
            }),
            // NOTE: this is sent via SSE - don't use ts-rest HTTP adapter, instead use https://github.com/Azure/fetch-event-source
            100: c.otherResponse({
                contentType: 'text/event-stream',
                body: z.array(z.discriminatedUnion('event', [
                    messageGeneratingEventSchema,
                    messageFinishedEventSchema,
                ])),
            }),
        },
        summary: 'Get updates for given conversation',
    },
    stopGenerating: {
        method: 'POST',
        path: '/v2/conversations/:conversation_id/stop-generating',
        body: z.object({
            responding_to: z.string().uuid(),
        }),
        responses: {
            200: z.object({}),
        },
        summary: 'Stop generating any in-flight responses for a message',
    },
    clone: {
        method: 'POST',
        path: '/v2/conversations/:conversation_id/clone',
        responses: {
            200: z.object({ conversation: conversationSchema }),
            404: errorResponseSchema,
        },
        body: z.object({}),
        summary: 'Clone a conversation',
    },
    updateTextMessage: {
        method: 'POST',
        path: '/v2/conversations/:id/update-text-message/:itemId',
        responses: {
            200: updateMessageResponse,
        },
        body: updateMessageRequestSchema,
        summary: 'Update text message',
    },
    postFeedback: {
        method: 'POST',
        path: '/v2/conversations/:message_id/feedback',
        responses: {
            204: null,
            400: errorResponseSchema,
            404: errorResponseSchema,
        },
        body: postFeedbackRequestSchema,
        summary: 'Likes or dislikes a message',
    },
});
