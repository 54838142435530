import { z } from 'zod'

export const envSchema = z
  .object({
    VITE_API_ENDPOINT: z.string(),
    VITE_SUPPORT_API_ENDPOINT: z.string(),
    VITE_API_KEY: z.string(),
    VITE_APP_MIXPANEL_PROJECT_ID: z.string(),
    VITE_OKTA_ISSUER: z.string(),
    VITE_OKTA_CLIENT_ID: z.string(),
    VITE_GOOGLE_IDP_ID: z.string(),
    VITE_LOGROCKET_APP_ID: z.string(),
    VITE_SENTRY_DSN: z.string(),
    VITE_APP_ENVIRONMENT: z.union([
      z.literal('Test'),
      z.literal('Development'),
      z.literal('Production'),
    ]),
    VITE_UNSPLASH_APP_NAME: z.string(),
    VITE_ENABLE_LANGTAIL_PLAYGROUNDS: z.string(),
    VITE_REFRESH_TOKEN_RELEASE_DATE: z.string(),
    VITE_GTM_ID: z.string(),
  })
  .strict()
export type Env = z.infer<typeof envSchema>
