import { observable, runInAction } from 'mobx'

export abstract class BaseViewModel {
  @observable isActive = false
  protected abortController?: AbortController = undefined

  protected abstract onInit(): void

  protected abstract onDispose(): void

  _start() {
    if (this.isActive) {
      this._stop()
    }
    this.abortController = new AbortController()
    this.onInit()

    runInAction(() => {
      this.isActive = true
    })
  }

  _stop() {
    if (this.isActive) {
      this.onDispose()
      this.abortController = undefined
    }

    runInAction(() => {
      this.isActive = false
    })
  }
}
