import { observer } from 'mobx-react-lite'

import { OfflineIndicator } from '@/components/offline-indicator/offline-indicator.js'

interface IPublic {
  children: JSX.Element
}

export const Public = observer(({ children }: IPublic) => {
  return <OfflineIndicator>{children}</OfflineIndicator>
})

Public.displayName = 'Public'
