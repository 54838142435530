import type { FormikErrors, FormikHelpers, FormikProps } from 'formik'
import { useEffect, useRef } from 'react'

import TickIcon from '@/assets/icons/tick-circle.svg?react'
import { cls } from '@/utils/utils'

export interface StepContentProps<Values> {
  formik: FormikProps<Values>
  isLastStep: boolean
}

export interface IFormStep<Values> {
  key: keyof Values & string
  label: string
  content: (props: StepContentProps<Values>) => JSX.Element
  validate?: (values: Values) => void | object | Promise<FormikErrors<Values>>
  onSubmit?: (values: Values, bag: FormikHelpers<Values>) => void
}

export interface IFormStepProps {
  step: IFormStep<any>
  children?: React.ReactNode // NOTE: children will always render, so you must handle conditional rendering yourself higher up
  isOpen: boolean
  isComplete: boolean
  onOpenStep?: () => void
  formik: FormikProps<any>
}

export const FormStep = ({
  isOpen,
  isComplete,
  step,
  onOpenStep,
  children,
  formik,
}: IFormStepProps) => {
  const isStyledValue = step.key === 'style' || step.key === 'presentation_type'
  const value = formik.values[step.key]

  const stepRef = useRef<HTMLDivElement>(null)

  // autofocus on first element with an autofocus attribute in the step as it opens, fallback to first form element
  useEffect(() => {
    if (isOpen && stepRef.current) {
      const firstAutoSelectableElement =
        stepRef.current.querySelector('[autofocus]') ||
        stepRef.current.querySelector('input,textarea, select, button')

      if (firstAutoSelectableElement instanceof HTMLElement) {
        firstAutoSelectableElement.focus()
      }
    }
  }, [isOpen, stepRef])

  return (
    <div
      ref={stepRef}
      key={step.key}
      className={cls(
        'border-cerosGrey300 grid grid-cols-1 border-b border-solid bg-[#F6F9FC] px-4 pt-4',
        isOpen && 'bg-cerosWhite',
        !isOpen && onOpenStep && 'cursor-pointer',
      )}
      aria-expanded={isOpen}
      tabIndex={isOpen ? undefined : 0}
      onClick={onOpenStep && isOpen ? undefined : onOpenStep}
      onKeyDown={
        onOpenStep && isOpen
          ? undefined
          : (event) =>
              (event.code === 'Enter' || event.code === 'Space') &&
              onOpenStep?.()
      }
    >
      <div className="mb-4 grid grid-cols-[1fr_max-content]">
        <div className="overflow-hidden md:flex">
          <label
            className={cls(
              'inline-block shrink-0 py-1',
              'text-md font-medium ',
              (isOpen || isComplete) && 'text-cerosBlack',
              !isOpen && !isComplete && 'text-cerosGrey400',
              !isOpen && onOpenStep && 'cursor-pointer',
            )}
          >
            {step.label}
          </label>
          {value && !isOpen && (
            <span
              className={cls(
                'text-cerosGrey500 block py-1 text-base md:mx-4 md:inline md:truncate',
                isStyledValue &&
                  'bg-cerosGrey300 ml-4 inline-block rounded-md px-3 py-1',
                !isStyledValue && 'grow',
              )}
            >
              {value}
            </span>
          )}
        </div>
        <span className="text-lg">
          <TickIcon
            width="24"
            height="24"
            className={cls(!isComplete && 'fill-cerosGrey300', 'mt-[4px]')}
          />
        </span>
      </div>
      {children}
    </div>
  )
}
