import { Drawer, DrawerItem, DrawerTitle } from '../drawer/drawer'

type TaskButtonMobileMenuProps = {
  title: string
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  onEdit?: () => void
  onDelete?: () => void
  onShare?: () => void
}

export function TaskButtonMobileMenu({
  title,
  isOpen,
  setIsOpen,
  onEdit,
  onDelete,
  onShare,
}: TaskButtonMobileMenuProps) {
  return (
    <Drawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      header={<DrawerTitle>{title}</DrawerTitle>}
    >
      {onShare && (
        <DrawerItem
          onClick={() => {
            setIsOpen(false)
            onShare()
          }}
        >
          Share
        </DrawerItem>
      )}
      {onEdit && (
        <DrawerItem
          onClick={() => {
            setIsOpen(false)
            onEdit()
          }}
        >
          Edit
        </DrawerItem>
      )}
      {onDelete && (
        <DrawerItem
          onClick={() => {
            setIsOpen(false)
            onDelete()
          }}
          className="text-cerosDanger"
        >
          Delete
        </DrawerItem>
      )}
    </Drawer>
  )
}
