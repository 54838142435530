import { ErrorMessage } from 'formik'

import { cls } from '@/utils/utils'

export interface IFieldErrorProps {
  name: string
  className?: string
}

export const FieldError = ({ name, className }: IFieldErrorProps) => {
  return (
    <ErrorMessage
      className={cls('text-cerosDanger text-md mt-2', className)}
      component="div"
      name={name}
    />
  )
}
