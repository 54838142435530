import { useEffect, useRef, useState } from 'react'
import type { AuthCodeRef } from 'react-auth-code-input'
import AuthCode from 'react-auth-code-input'
import { Link } from 'react-router-dom'

import { cls } from '@/utils/utils'

import { BackButton } from '../button/back-button'
import { Button } from '../button/button'

export interface PasswordResetCodeProps {
  email: string
  errorMessage: string | null
  isBusy: boolean
  onSubmit: (code: string) => Promise<boolean>
  resendCode: () => void
}

const CODE_LENGTH = 6
const RESEND_INTERVAL = 60

export const PasswordResetCode = ({
  email,
  errorMessage,
  isBusy,
  onSubmit,
  resendCode,
}: PasswordResetCodeProps): JSX.Element => {
  const AuthInputRef = useRef<AuthCodeRef>(null)
  const [code, setCode] = useState('')
  const [seconds, setSeconds] = useState(RESEND_INTERVAL)

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) setSeconds(seconds - 1)
    }, 1000)
    return () => clearInterval(timer)
  }, [seconds])

  const resendText =
    seconds > 0 ? `Resend code (${seconds} sec.)` : 'Resend code'

  return (
    <div className="flex max-w-[420px] flex-col items-start">
      <h1 className="text-cerosBlack mb-2 text-3xl font-bold md:mt-14">
        Check your inbox
      </h1>
      <div
        className={cls(
          'text-cerosBlack px mb-14 text-sm font-medium',
          errorMessage && 'text-cerosDanger',
        )}
      >
        {errorMessage
          ? errorMessage
          : `If the email address exists, we’ve sent a 6-digit verification code to ${email} to complete resetting your password.`}
      </div>
      <div className="w-fit max-w-full">
        <div className="mb-4 flex justify-between">
          <Link to="/login">
            <BackButton />
          </Link>
          <button
            className="text-cerosPrimary hover:text-cerosPrimaryHover disabled:text-cerosGrey500 text-sm hover:underline disabled:hover:no-underline"
            disabled={seconds > 0}
            onClick={() => {
              resendCode()
              setSeconds(RESEND_INTERVAL)
            }}
          >
            {resendText}
          </button>
        </div>
        <div
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              if (code.length !== CODE_LENGTH || isBusy) return
              const shouldResetCode = await onSubmit(code)
              if (shouldResetCode) {
                setCode('')
                AuthInputRef.current?.clear()
              }
            }
          }}
        >
          <AuthCode
            onChange={(str) => setCode(str)}
            length={CODE_LENGTH}
            autoFocus={true}
            ref={AuthInputRef}
            inputClassName="w-11 h-11 pl-4 py-6 bg-white rounded-lg border border-cerosGrey1 justify-start items-center inline-flex"
            containerClassName="h-11 justify-start items-start gap-2 inline-flex"
          />
        </div>
        <Button
          className="mt-8 inline-flex h-11 w-[120px]"
          disabled={code.length !== CODE_LENGTH || isBusy}
          onClick={async () => {
            const shouldResetCode = await onSubmit(code)
            if (shouldResetCode) {
              setCode('')
              AuthInputRef.current?.clear()
            }
          }}
        >
          Continue
        </Button>
      </div>
      <div className="mt-14 flex w-full justify-center">
        <div className="text-cerosGrey400 w-80 text-center text-sm">
          <div className="text-cerosBlack mb-1">Why do we need a code?</div>
          <div>
            Your security is really important to us. We need a code to verify
            it's actually you.
          </div>
        </div>
      </div>
    </div>
  )
}
