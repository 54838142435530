import { observer } from 'mobx-react-lite'
import { Outlet, useSearchParams } from 'react-router-dom'

import { LoadingDots } from '@/components/loading-dots/loading-dots'
import { DI_TYPE } from '@/di.types'
import { useViewModel } from '@/utils/view'

import gemmaLogo from '../assets/icons/gemma-logo.svg'

export const WithUserProperties = observer(() => {
  const vm = useViewModel(DI_TYPE.WithUserProperties)
  const [searchParams] = useSearchParams()

  if (vm.isFetchingUserProperties) {
    return (
      <div className="flex h-full min-h-screen w-full items-center justify-center">
        <LoadingDots />
      </div>
    )
  }

  const oktaUserId = searchParams.get('oktaUserId')
  if (!vm.oktaUserIdMatch(oktaUserId)) {
    return (
      <div className="flex h-full min-h-screen w-full items-center justify-center">
        <div className="flex w-[630px] flex-col items-center gap-[64px]">
          <div
            className="h-[56px] w-[56px] bg-contain bg-center bg-no-repeat"
            style={{
              backgroundImage: `url("${gemmaLogo}")`,
            }}
          ></div>
          <div
            className="text-cerosGrey500 text-center text-[40px] font-normal leading-[55px] tracking-[-1.6px;]"
            style={{
              fontFamily: 'Object Sans',
            }}
          >
            You are logged in to Gemma with a different user to Studio, please
            log out of Studio and try again.
          </div>
        </div>
      </div>
    )
  }

  return <Outlet />
})

WithUserProperties.displayName = 'WithUserProperties'
