import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './swiper.css'

import { observer } from 'mobx-react-lite'
import { Keyboard, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { EditDrawingTool } from '@/pages/modals/edit-image/edit-drawing-tool.js'
import { cls } from '@/utils/utils.js'
import type { EditImageViewModel } from '@/view-models/edit-image.js'

interface IEditImagePreview {
  vm: EditImageViewModel
}

export const EditImagePreview = observer(({ vm }: IEditImagePreview) => {
  return (
    <div
      className={cls(
        'relative flex h-full w-full flex-1 flex-col items-center justify-center p-10',
        vm.enabled && 'w-[calc(100%-292px)]',
      )}
    >
      {!vm.enabled && vm.inputUrls.length > 1 ? (
        <Swiper
          modules={[Navigation, Pagination, Keyboard]}
          spaceBetween={10}
          slidesPerView={1}
          navigation
          initialSlide={vm.index}
          keyboard
          loop
          pagination={{ clickable: true }}
          onSlideChangeTransitionStart={(swiper) =>
            vm.setIndex(swiper.realIndex)
          }
          className="h-full w-full"
        >
          {vm.inputUrls.map((_image, index) => (
            <SwiperSlide key={index}>
              <EditDrawingTool vm={vm} index={index} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <EditDrawingTool vm={vm} />
      )}
    </div>
  )
})

EditImagePreview.displayName = 'EditImagePreview'
