import type { Conversation } from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import type { IReactionDisposer } from 'mobx'
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'

import { DI_TYPE } from '@/di.types'
import type { ConversationsService } from '@/services/conversations'
import type { MixpanelService } from '@/services/mixpanel'
import type { RouterService } from '@/services/router'

import { BaseViewModel } from './base-view-model'

export class ConversationCloneViewModel extends BaseViewModel {
  @observable id: Conversation['id'] | null = null
  @observable error: unknown = null
  @observable reactionDisposers: IReactionDisposer[] = []

  constructor(
    private conversationsService: ConversationsService,
    private routerService: RouterService,
    private mixpanelService: MixpanelService,
  ) {
    super()
    makeObservable(this)

    const disposer = reaction(
      () => [this.isActive, this.conversationsService.initialized, !!this.id],
      async ([isActive, conversationsServiceInitialized]) => {
        if (isActive && conversationsServiceInitialized && this.id != null) {
          try {
            const result = await this.cloneConversation(this.id)
            mixpanelService.trackChatCloned(result.id, this.id)

            this.routerService.replace(`/conversations/${result.id}`)
          } catch (err) {
            runInAction(() => (this.error = err))
          }
        }
      },
    )

    this.reactionDisposers.push(disposer)
  }

  onInit(): void {}

  onDispose(): void {
    this.reactionDisposers.forEach((disposer) => disposer())
  }

  @action.bound
  setFactoryData(id: string | undefined) {
    if (!id) {
      throw new Error('conversation id is required')
    }

    this.id = id
  }

  @action
  private cloneConversation(id: Conversation['id']) {
    return this.conversationsService.cloneConversation(id)
  }

  @computed
  get hasError() {
    return this.error != null
  }
}

injected(
  ConversationCloneViewModel,
  DI_TYPE.ConversationsService,
  DI_TYPE.RouterService,
  DI_TYPE.MixpanelService,
)
