import { forwardRef } from 'react'

import InfoIcon from '@/assets/icons/info.svg?react'
import { cls } from '@/utils/utils.js'

import { Tooltip } from '../tooltip/tooltip'

interface ITextAreaProfile
  extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  tooltip?: string
}

export const TextAreaProfile = forwardRef<
  HTMLTextAreaElement,
  ITextAreaProfile
>(({ className, label, tooltip, style, ...rest }, ref) => {
  return (
    <>
      <div className={cls('flex flex-col gap-2', className)}>
        {label && (
          <div className="flex items-start justify-between">
            <span className="text-cerosBlack max-w-[75%] text-base font-medium">
              {label}
            </span>
            {tooltip && (
              <Tooltip
                className="text-cerosGrey400 max-w-[310px] whitespace-pre-line px-4 py-[6px] text-xs"
                text={tooltip}
                placement="top-end"
              >
                <InfoIcon className="stroke-cerosGrey500 m-[5px] mr-2 h-4 w-4" />
              </Tooltip>
            )}
          </div>
        )}
        <textarea
          ref={ref}
          className={cls(
            'h-[82px] w-full resize-none border py-2.5 pl-4 pr-2 text-sm',
            'focus-within:border-cerosPrimary',
            'border-cerosGrey200 bg-cerosWhite text-cerosGrey400 rounded-lg border font-medium outline-none',
            'disabled:bg-cerosGrey100 disabled:text-cerosGrey400 disabled:hover:border-cerosGrey400 disabled:focus:border-cerosGrey400 disabled:active:border-cerosGrey400 disabled:cursor-not-allowed',
            'hover:bg-cerosGrey100 focus:bg-cerosWhite focus:text-cerosGrey600',
          )}
          style={{
            ...style,
          }}
          {...rest}
        />
      </div>
    </>
  )
})
