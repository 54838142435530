import { z } from 'zod'

export const nounProjectIconSchema = z.object({
  attribution: z.string(),
  id: z.number(),
  license_description: z.string(),
  thumbnail_url: z.string().url(),
})

export type NounProjectIcon = z.infer<typeof nounProjectIconSchema>

export const nounProjectIconV1Schema = z.object({
  attribution: z.string(),
  id: z.number(),
  license_description: z.string(),
  preview_url: z.string().url(),
})

export type NounProjectIconV1 = z.infer<typeof nounProjectIconV1Schema>

export const nounProjectUsageLimitSchema = z.object({
  limit: z.number(),
  usage: z.number(),
})

export type NounProjectUsageLimit = z.infer<typeof nounProjectUsageLimitSchema>

export const nounProjectUsageLimitsSchema = z.object({
  monthly: nounProjectUsageLimitSchema,
})

export type NounProjectUsageLimits = z.infer<
  typeof nounProjectUsageLimitsSchema
>

export const nounProjectSearchResponseSchema = z.object({
  generated_at: z.string(),
  icons: z.array(nounProjectIconSchema),
  total: z.number(),
  next_page: z.string().nullable(),
  prev_page: z.string().nullable(),
  usage_limits: nounProjectUsageLimitsSchema,
})

export type NounProjectSearchResponse = z.infer<
  typeof nounProjectSearchResponseSchema
>

export const nounProjectRecentResponseSchema = z.object({
  generated_at: z.string(),
  recent_uploads: z.array(nounProjectIconV1Schema),
})

export type NounProjectRecentResponse = z.infer<
  typeof nounProjectRecentResponseSchema
>
