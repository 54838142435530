import type { TokenParams } from '@okta/okta-auth-js'
import type { OktaAuth } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { ProcessScreen } from '@/components/process-screen/process-screen.js'
import { diContainer } from '@/di.container'
import { DI_TYPE } from '@/di.types.js'
import { useViewModel } from '@/utils/view.js'

interface IEmbedAuth {
  children?: JSX.Element
}

const redirectToSelfToGetToken = async (oktaAuth: OktaAuth) => {
  const tokenParams: TokenParams = {
    scopes: ['openid', 'email'],
  }
  // this will send the code to the redirectUri as a query param
  // It will automatically find the sessionToken from the cookie via the ceros okta login
  // If we aren't embedded in ceros we should not try to auto login this way
  await oktaAuth.token.getWithRedirect(tokenParams)
}
export const AuthEmbedPage = observer(({ children }: IEmbedAuth) => {
  const vm = useViewModel(DI_TYPE.LoginViewModel)

  if (!vm.isAuthenticated) {
    diContainer.get(DI_TYPE.SaveLoginReturn)({
      uri: diContainer.get(DI_TYPE.RouterService).currentUri,
      createdAt: Date.now(),
    })

    const { oktaAuth } = useOktaAuth()
    useEffect(() => {
      // putting this here so the warning in this link doesn't keep popping up
      // https://github.com/okta/okta-react/issues/227#issuecomment-1167375982
      oktaAuth.options.restoreOriginalUri = undefined
      if (!vm.isAuthenticated && !vm.isBusy) {
        if (!vm.redirectingForAutoLoginAccessToken) {
          vm.setRedirectingForAutoLoginAccessToken(true)
          redirectToSelfToGetToken(oktaAuth).then(() => {
            vm.setRedirectingForAutoLoginAccessToken(false)
          })
        }
      }
    }, [vm, oktaAuth, vm.isAuthenticated])

    return <ProcessScreen text="Logging In" />
  }

  return children
})

AuthEmbedPage.displayName = 'AuthEmbedPage'
