import { cls } from '@/utils/utils.js'

import { ModalBackdrop } from './modal-backdrop.js'

interface IModal {
  children: React.ReactNode
  kind?: 'full' | 'normal'
  backdrop?: boolean
  closeOnBackdropClick?: boolean
  zIndex?: number
  className?: string
  onClose?: () => void
}
export const Modal = ({
  children,
  kind = 'normal',
  backdrop,
  className,
  zIndex = 50,
  closeOnBackdropClick = true,
  onClose,
}: IModal) => {
  return (
    <ModalBackdrop
      zIndex={zIndex}
      backdrop={backdrop}
      closeOnClick={closeOnBackdropClick}
      kind={kind}
      onClose={onClose}
    >
      <div
        className={cls(
          'bg-cerosWhite inset-6 flex w-full flex-col overflow-auto rounded-[10px]',
          kind === 'normal' && 'max-w-[558px]',
          kind === 'full' && 'h-full w-full rounded-none',
          !backdrop && 'shadow-md',
          className,
        )}
      >
        {children}
      </div>
    </ModalBackdrop>
  )
}
