import { useCallback } from 'react'
import toast from 'react-hot-toast'

import CloseIcon from '@/assets/icons/close.svg?react'
import GlobeImage from '@/assets/icons/globe.svg?react'
import InfoIcon from '@/assets/icons/info.svg?react'
import TickIcon from '@/assets/icons/tick-circle.svg?react'
import { Button } from '@/components/button/button'
import { IconButton } from '@/components/icon-button/icon-button'
import { ModalBackdrop } from '@/components/modal/modal-backdrop'
import { Switch, useSwitch } from '@/components/switch/switch'
import { copyToClipboard } from '@/utils/utils'
import { useModalService } from '@/utils/view'

export type ShareModalType = 'Conversation' | 'Gem'

interface IShareModal {
  type: ShareModalType
  isShared: boolean
  shareUrl: string
  onToggle: (enabled: boolean) => void
  onCopyUrl?: () => void
}

export const ShareModal = ({
  type,
  onToggle,
  isShared,
  shareUrl,
  onCopyUrl,
}: IShareModal) => {
  const modalService = useModalService()

  const close = useCallback(() => {
    modalService.close()
  }, [modalService])

  const copyUrl = useCallback(() => {
    copyToClipboard(shareUrl)
    toast.success('Link copied to clipboard', {
      icon: <TickIcon />,
    })
    onCopyUrl?.()
  }, [shareUrl, onCopyUrl])

  const { isEnabled, toggle } = useSwitch({
    initialValue: isShared,
    onToggle: onToggle,
  })

  const title = type === 'Gem' ? 'Share This Gem' : 'Share project'

  return (
    <ModalBackdrop zIndex={50} backdrop>
      <div
        className="bg-cerosWhite shadow-normal-elevation relative flex w-[540px] max-w-sm flex-col rounded-2xl px-8 py-6 sm:max-w-full"
        data-automation="share-modal"
      >
        <IconButton
          className="absolute right-8 top-6 h-6 w-6"
          icon={<CloseIcon />}
          onClick={close}
        />
        <label className="mb-2 text-left text-xl font-medium">{title}</label>
        <div className="border-cerosGrey200 mt-4 flex items-center justify-between rounded-lg border-[1px] border-solid p-4">
          <div className="flex flex-shrink-0 items-center gap-3">
            <GlobeImage />
            <p className="text-cerosBlack whitespace-nowrap text-left text-sm font-normal">
              {isEnabled ? 'Sharing enabled' : 'Sharing disabled'}
            </p>
          </div>
          <Switch
            enabled={isEnabled}
            onToggle={toggle}
            data-automation="sharing-toggle"
            id="toggle"
          />
        </div>
        <div className="mt-2 flex flex-shrink-0 items-center gap-2 p-1">
          <InfoIcon className="[&>*]:stroke-cerosPrimary flex-shrink-0" />
          <p className="text-cerosGrey400 text-left text-sm font-normal">
            {isEnabled
              ? `Anyone with the link will be able to access the shared ${
                  type === 'Gem' ? 'gem' : 'chat'
                }.`
              : 'Enable sharing to activate sharable link'}
          </p>
        </div>
        <div className="bg-cerosGrey100 border-cerosGrey200 mt-5 flex items-center justify-between gap-4 rounded-lg border-[1px] border-solid p-4">
          <p
            data-automation="share-url"
            className="text-cerosBlack flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-left text-sm font-normal"
          >
            {shareUrl}
          </p>
          <Button
            variant="primary"
            onClick={copyUrl}
            disabled={!isEnabled}
            data-automation="copy-share-button"
          >
            Copy
          </Button>
        </div>
      </div>
    </ModalBackdrop>
  )
}
