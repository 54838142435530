import { injected } from 'brandi'
import type { Location, To, Update } from 'history'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import type React from 'react'

import { DI_TYPE } from '@/di.types'
import { getAppOrigin } from '@/utils/utils.js'

import { BaseService } from './base-service'
import { type EventBusService, RegisteredEvents } from './event-bus'

export interface Route {
  path: string
  component: React.ComponentType<any>
  exact?: boolean
  routes?: Route[]
}

export class RouterService extends BaseService {
  @observable currentPath: string = window.location.pathname
  @observable currentUri: string =
    window.location.pathname + window.location.search
  @observable.ref currentLocation: Location | null = null

  history =
    getAppOrigin() === 'web' ? createBrowserHistory() : createMemoryHistory()

  constructor(private eventBusService: EventBusService) {
    super()
    makeObservable(this)

    this.onInit()
  }

  @action
  onInit() {
    this.currentLocation = window.location as any
    this.currentPath = window.location.pathname
    this.history.listen(this.handleLocationChange)
    this.initialized = true
  }

  @action
  private handleLocationChange = (update: Update) => {
    const { location } = update

    let defaultBehavior = true
    const syntheticLocationChangeEvent = {
      update,
      preventDefault: () => {
        defaultBehavior = false
      },
    }

    // TODO: Check if this is needed for feature
    this.eventBusService.publish(
      RegisteredEvents.LocationChange,
      syntheticLocationChangeEvent,
    )

    if (!defaultBehavior) return

    this.currentLocation = location
    this.currentPath = location.pathname
    this.currentUri = location.pathname + location.search
  }

  applyUpdate(update: Update) {
    const { location } = update
    runInAction(() => {
      this.currentLocation = location
      this.currentPath = location.pathname
    })
  }

  @computed
  get searchParams() {
    return new URLSearchParams(this.history.location.search)
  }

  @action
  hasSearchParam(param: string): boolean {
    return this.searchParams.has(param)
  }

  @action
  getSearchParam(param: string): string | null {
    return this.searchParams.get(param)
  }

  @action
  setSearchParam(param: string, value: string = '1') {
    const params = this.searchParams
    params.set(param, value)
    const queryString = new URLSearchParams(params).toString()
    this.history.push({ search: queryString })
  }

  @action
  deleteSearchParam(param: string) {
    const params = this.searchParams
    params.delete(param)
    const queryString = new URLSearchParams(params).toString()
    this.history.push({ search: queryString })
  }

  @action
  public goTo(path: To, state?: any) {
    this.history.push(path, state)
  }

  @action
  public replace(path: To, state?: any) {
    this.history.replace(path, state)
  }

  @action
  public goBack() {
    this.history.back()
  }
}

injected(RouterService, DI_TYPE.EventBusService)
