import { useEffect, useRef, useState } from 'react'
import type { AuthCodeRef } from 'react-auth-code-input'
import AuthCode from 'react-auth-code-input'

import { cls } from '@/utils/utils'

import { Button } from '../button/button'

export interface SignUpCodeProps {
  email: string
  errorMessage: string | null
  isBusy: boolean
  onSubmit: (code: string) => Promise<boolean>
  resendCode: () => void
}

const CODE_LENGTH = 6
const RESEND_INTERVAL = 60

export const SignUpCode = ({
  email,
  errorMessage,
  isBusy,
  onSubmit,
  resendCode,
}: SignUpCodeProps): JSX.Element => {
  const AuthInputRef = useRef<AuthCodeRef>(null)
  const [code, setCode] = useState('')
  const [seconds, setSeconds] = useState(RESEND_INTERVAL)

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) setSeconds(seconds - 1)
    }, 1000)
    return () => clearInterval(timer)
  }, [seconds])

  const resendText =
    seconds > 0 ? `Resend code (${seconds} sec.)` : 'Resend code'

  return (
    <div className="flex w-full flex-col">
      <h1 className="text-cerosBlack text-3xl font-bold md:mt-14">
        Verify email
      </h1>
      <div
        className={cls(
          'text-cerosBlack px my-8 text-sm font-medium',
          errorMessage && 'text-cerosDanger',
        )}
      >
        {errorMessage ? errorMessage : `Enter code sent to ${email}`}
      </div>
      <div className="mb-2 text-sm font-medium leading-tight text-neutral-400">
        Verification code
      </div>
      <div
        onKeyDown={async (e) => {
          if (e.key === 'Enter') {
            if (code.length !== CODE_LENGTH || isBusy) return
            const shouldResetCode = await onSubmit(code)
            if (shouldResetCode) {
              setCode('')
              AuthInputRef.current?.clear()
            }
          }
        }}
      >
        <AuthCode
          onChange={(str) => setCode(str)}
          length={CODE_LENGTH}
          autoFocus={true}
          ref={AuthInputRef}
          inputClassName="w-11 h-11 pl-4 py-6 rounded-lg border border-cerosGrey1 justify-start items-center inline-flex"
          containerClassName="w-[400px] h-11 justify-start items-start gap-2 inline-flex"
        />
      </div>
      <Button
        className="mt-8 inline-flex h-11 w-[120px]"
        disabled={code.length !== CODE_LENGTH || isBusy}
        onClick={async () => {
          const shouldResetCode = await onSubmit(code)
          if (shouldResetCode) {
            setCode('')
            AuthInputRef.current?.clear()
          }
        }}
      >
        Continue
      </Button>
      <div className="mt-14 w-full text-center font-medium">
        <button
          className="text-cerosPrimary hover:text-cerosPrimaryHover disabled:text-cerosGrey500 text-sm hover:underline disabled:hover:no-underline"
          disabled={seconds > 0}
          onClick={() => {
            resendCode()
            setSeconds(RESEND_INTERVAL)
          }}
        >
          {resendText}
        </button>
      </div>
    </div>
  )
}
