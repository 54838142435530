import { makeAutoObservable, runInAction } from 'mobx'

import type { MessageOrigin } from '@/services/mixpanel'

export class Prompt {
  text: string = ''
  origin?: MessageOrigin

  constructor() {
    makeAutoObservable(this)
  }

  reset() {
    runInAction(() => {
      this.text = ''
      this.origin = undefined
    })
  }
}
