import { useCurrentEditor } from '@tiptap/react'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import toast from 'react-hot-toast'

import ClipboardIcon from '@/assets/icons/clipboard.svg?react'
import MicrophoneIcon from '@/assets/icons/microphone.svg?react'
import MicrophoneStopIcon from '@/assets/icons/microphone-stop.svg?react'
import RetryIcon from '@/assets/icons/refresh.svg?react'

import { ConversationContext } from '../../pages/conversation/conversation-page'
import {
  getHTMLOfSelection,
  tryToGetSelectionRange,
} from '../../utils/document'
import { copyRichTextToClipboard } from '../../utils/utils'
import type { ConversationViewModel } from '../../view-models/conversation'
import { IconButton } from '../icon-button/icon-button'
import { Tooltip } from '../tooltip/tooltip'

export const BubbleMenuActions: React.FC<{ vm: ConversationViewModel }> =
  observer(({ vm }) => {
    const { editor } = useCurrentEditor()
    const context = useContext(ConversationContext)

    if (!editor || !context) {
      return null
    }

    const { promptInputVM } = context

    const fixSelectedFragment = () => {
      const { msg, index } = vm.currentDocument
      const itemId = vm.documentViewModel.currentItemId
      const { from, to } = editor.state.selection
      const selectionText = editor.state.doc.textBetween(from, to, '\n\n')
      const text = editor.getText()
      const splitText = text.split(selectionText)
      // count which occurrence of selected text we are fixing
      // we pass it to LLM to know which fragment to update
      let occurrence = 0
      let currentIndex = 0
      for (let i = 0; i < splitText.length; i++) {
        currentIndex += splitText[i].length
        if (currentIndex >= from) {
          occurrence = i
          break
        }
        currentIndex += selectionText.length
      }
      if (msg && itemId && from && selectionText.length) {
        const { node } = editor.view.domAtPos(from)
        const selectionRect = tryToGetSelectionRange(0)?.getBoundingClientRect()
        let rectToUse = node.parentElement?.getBoundingClientRect()
        // only use selection rect if it's big enough
        if (!rectToUse || (selectionRect && selectionRect.height > 60)) {
          rectToUse = selectionRect
        }
        if (rectToUse) {
          editor.chain().focus('start', { scrollIntoView: false }).run()
          editor.setEditable(false, false)
          vm.documentViewModel
            .sendEditTextMsg(
              {
                occurrence,
                text: selectionText,
                msgId: msg.id,
                itemId,
                messageIndex: index,
              },
              rectToUse,
            )
            .catch(() => {
              toast.error('Failed to update the document')
              editor.setEditable(true, false)
            })
        }
      }
    }
    const onMicrophoneClick = () => {
      if (!promptInputVM.isRecording) {
        vm.documentViewModel.highlightSelection()
        promptInputVM.voiceInputStart()
      } else {
        promptInputVM.voiceInputStopAndSubmit((prompt) =>
          vm.sendMessageText(prompt.text, 'VOICE_INPUT'),
        )
      }
    }
    const copyCurrentSelection = () => {
      const html = getHTMLOfSelection(true)
      copyRichTextToClipboard(html)
        .then(() => {
          const index = vm.currentDocument.index
          if (index !== -1) {
            vm.documentViewModel.onCopyDocument(
              vm.currentDocument.index,
              'selection',
            )
          }
          toast.success('Copied selection to clipboard')
        })
        .catch(() => {
          toast.error('Failed to copy to clipboard')
        })
    }
    return (
      <>
        <Tooltip text="Talk to Gemma" placement="top">
          <IconButton
            icon={
              promptInputVM.isRecording ? (
                <MicrophoneStopIcon />
              ) : (
                <MicrophoneIcon className="stroke-black" />
              )
            }
            onClick={onMicrophoneClick}
          />
        </Tooltip>
        <Tooltip text="Rewrite" placement="top">
          <IconButton icon={<RetryIcon />} onClick={fixSelectedFragment} />
        </Tooltip>
        <Tooltip text="Copy" placement="top">
          <IconButton icon={<ClipboardIcon />} onClick={copyCurrentSelection} />
        </Tooltip>
      </>
    )
  })
