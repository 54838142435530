import {
  autoPlacement,
  offset,
  useClick,
  useFloating,
  useInteractions,
} from '@floating-ui/react'
import { MenuButton } from '@szhsin/react-menu'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'

import MoreIcon from '@/assets/icons/more.svg?react'
import { EmojiPicker } from '@/components/emoji-picker/emoji-picker'
import { RenameConversationModal } from '@/pages/modals/rename-conversation'
import type { IMenuItem } from '@/services/sidebar'
import { useIsMobile } from '@/utils/use-is-mobile'
import { useLongPress } from '@/utils/use-long-press'
import { cls } from '@/utils/utils'
import { useModalService } from '@/utils/view'

import { SharedBadge } from './shared-badge'
import { SidebarItemIcon } from './sidebar-item-icon'
import { SidebarItemMenu, useMenuDisclosure } from './sidebar-item-menu'
import { SidebarItemMobileMenu } from './sidebar-item-mobile-menu'

interface ISidebarItem {
  dataAutomation?: string
  item: IMenuItem
  selected: boolean
}

export const SidebarItem = observer(
  ({ item, selected, dataAutomation }: ISidebarItem) => {
    const modalService = useModalService()
    const isMobile = useIsMobile()

    const itemRef = useRef<HTMLDivElement | HTMLButtonElement>(null)
    const nameBackupRef = useRef<HTMLSpanElement>(null)
    const emojiRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const [isRenaming, setIsRenaming] = useState(false)
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false)
    const [isMenuOpen, setIsMenuOpen] = useMenuDisclosure()
    const [isMenuButtonFocused, setIsMenuButtonFocused] = useState(false)

    const iconValue = item.icon.value
    const title = item.title
    const onRename = item.onRename

    const { y, strategy, refs, context } = useFloating({
      middleware: [
        offset(16),
        autoPlacement({
          alignment: 'start',
        }),
      ],
      open: isRenaming && isEmojiPickerOpen,
      onOpenChange: (value) => setIsEmojiPickerOpen(isRenaming && value),
    })

    const click = useClick(context)
    const { getReferenceProps, getFloatingProps } = useInteractions([click])

    const onLongPress = useCallback(() => {
      if (!isMobile) return
      setIsMenuOpen(true)
    }, [setIsMenuOpen, isMobile])

    const longPressProps = useLongPress(onLongPress, {
      shouldPreventDefault: false,
    })

    const closeRenamingAndSave = useCallback(() => {
      setIsRenaming(false)
      setIsEmojiPickerOpen(false)

      if (emojiRef.current && inputRef.current && nameBackupRef.current) {
        if (inputRef.current.value.trim() === '') {
          inputRef.current.value = nameBackupRef.current.innerHTML
        } else {
          nameBackupRef.current.innerHTML = inputRef.current.value
        }

        if (
          (iconValue !== emojiRef.current!.innerHTML ||
            title !== inputRef.current!.value) &&
          onRename
        ) {
          onRename(emojiRef.current!.innerHTML, inputRef.current!.value)
        }
      }
    }, [iconValue, title, onRename])

    const closeRenamingAndReset = useCallback(() => {
      setIsRenaming(false)
      setIsEmojiPickerOpen(false)

      if (!inputRef.current || !nameBackupRef.current) {
        return
      }
    }, [])

    const handleOnClick = (e: any) => {
      e.stopPropagation()
      if (!isRenaming) {
        item.onClick()
      }
    }

    const handleOnRenameClick = () => {
      setIsRenaming(true)
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.select()
        }
      }, 100)
    }

    const handleOnMobileRenameClick = () => {
      modalService.show(
        <RenameConversationModal item={item} />,
        'Rename this project',
      )
    }

    const handleOnEmojiClick = (value: string) => {
      if (emojiRef.current) {
        emojiRef.current.innerHTML = value
      }

      closeRenamingAndSave()
    }

    const handleOnKeyDown = (e: any) => {
      if (e.key === 'Enter') {
        closeRenamingAndSave()
      }
    }

    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (itemRef.current && !itemRef.current.contains(event.target)) {
          event.stopPropagation()
          closeRenamingAndSave()
        }
      }

      const handleEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          event.stopPropagation()
          closeRenamingAndReset()
        }
      }

      const handlePageLeave = (event: any) => {
        if (event.target instanceof Window) {
          event.stopPropagation()
          closeRenamingAndSave()
        }
      }

      if (isRenaming) {
        document.addEventListener('click', handleClickOutside, true)
        document.addEventListener('keydown', handleEscape, true)
        window.addEventListener('blur', handlePageLeave, true)
      }

      return () => {
        document.removeEventListener('click', handleClickOutside, true)
        document.removeEventListener('keydown', handleEscape, true)
        window.removeEventListener('blur', handlePageLeave, true)
      }
    }, [closeRenamingAndSave, closeRenamingAndReset, isRenaming])

    useEffect(() => {
      if (inputRef.current && inputRef.current.value !== title) {
        inputRef.current.value = title
      }
    }, [title])

    const Item = isRenaming ? 'div' : 'button'
    const InnerItem = isRenaming ? 'button' : 'div'

    return (
      <li
        data-automation={dataAutomation}
        className={cls(
          'group/menuItem hover:bg-cerosGrey100 active:bg-cerosGrey200 relative flex w-full items-center justify-between rounded-xl pr-2',
          isMenuOpen && 'bg-cerosGrey100',
          selected && 'bg-cerosGrey100 hover:bg-cerosGrey100',
        )}
        onContextMenu={(e) => {
          if (!isMobile) return

          e.preventDefault()
          e.stopPropagation()
          return false
        }}
      >
        <Item
          ref={itemRef as any}
          className="relative flex min-w-0 flex-1 justify-between text-left"
          onClick={isRenaming ? undefined : handleOnClick}
          {...longPressProps}
        >
          <InnerItem
            disabled={!isRenaming}
            ref={refs.setReference}
            {...getReferenceProps()}
            onContextMenu={(e) => {
              if (!isMobile) return
              e.preventDefault()
              e.stopPropagation()
            }}
            className="hover:cursor-pointer"
          >
            <SidebarItemIcon
              ref={emojiRef}
              icon={item.icon}
              className={cls(
                'absolute left-3 top-1/2 -translate-y-1/2',
                isRenaming &&
                  (isEmojiPickerOpen
                    ? 'border-cerosPrimary'
                    : 'border-cerosGrey400'),
              )}
            />
          </InnerItem>

          {isRenaming && isEmojiPickerOpen && (
            <div
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                zIndex: 30,
              }}
              {...getFloatingProps()}
            >
              <EmojiPicker onEmojiSelect={handleOnEmojiClick} />
            </div>
          )}

          <input
            ref={inputRef}
            className={cls(
              'text-cerosGrey500 w-full flex-1 truncate bg-transparent py-[10px] pl-[52px] pr-2 text-sm font-medium outline-none hover:cursor-pointer',
              isRenaming && 'hover:cursor-text',
              !isRenaming && 'hidden',
            )}
            data-automation={`input-${item.id}`}
            defaultValue={title}
            disabled={!isRenaming}
            onKeyDown={handleOnKeyDown}
          />

          <span
            ref={nameBackupRef}
            className={cls(
              'text-cerosGrey500 w-full flex-1 truncate bg-transparent py-[10px] pl-[52px] pr-2 text-sm font-medium outline-none hover:cursor-pointer',
              isRenaming && 'hidden',
            )}
            data-automation={item.id}
          >
            {title}
          </span>
        </Item>
        <>
          {item.isPubliclyShared && (
            <SharedBadge
              className={cls(
                '-mr:3 flex group-hover/menuItem:hidden sm:-mr-5',
                isMenuOpen && 'hidden',
                isMenuButtonFocused && 'hidden',
              )}
            />
          )}
          {(item.actions || []).length > 0 &&
            (isMobile ? (
              <SidebarItemMobileMenu
                item={item}
                isOpen={isMenuOpen}
                setIsOpen={setIsMenuOpen}
                onRename={handleOnMobileRenameClick}
              />
            ) : (
              <SidebarItemMenu
                item={item}
                setIsOpen={setIsMenuOpen}
                onRename={handleOnRenameClick}
              >
                <MenuButton
                  className={cls(
                    'peer flex h-6 w-6 flex-shrink-0 items-center justify-center p-1',
                    !isMenuOpen &&
                      'opacity-0 focus:opacity-100 group-hover/menuItem:opacity-100',
                  )}
                  data-automation={`item-actions-${item.id}`}
                  onClick={(e) => e.stopPropagation()}
                  onFocus={() => setIsMenuButtonFocused(true)}
                  onBlur={() => setIsMenuButtonFocused(false)}
                >
                  <MoreIcon />
                </MenuButton>
              </SidebarItemMenu>
            ))}
        </>
      </li>
    )
  },
)
