import { Button } from '@/components/button/button.js'
import { useModalService } from '@/utils/view.js'

interface IDeleteModal {
  message: string
  onDelete: () => void
  onCancel?: () => void
}

export function DeleteModal({ message, onCancel, onDelete }: IDeleteModal) {
  const modalService = useModalService()

  const handleCancel = () => {
    onCancel && onCancel()
    modalService.close()
  }

  const handleDelete = () => {
    onDelete()
    modalService.close()
  }

  return (
    <>
      <div className="mt-4 flex items-center justify-center">
        <p className=" text-cerosGrey400 w-3/4 text-center text-sm font-medium ">
          {message}
        </p>
      </div>
      <div className="mt-10 flex items-center justify-center gap-4">
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          tabIndex={1}
          data-automation="delete-gem-confirmation-button"
        >
          Delete
        </Button>
      </div>
    </>
  )
}
