import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef } from 'react'
import toast from 'react-hot-toast'

import MicrophoneIcon from '@/assets/icons/microphone.svg?react'
import { AnimatedBorder } from '@/components/animated-border/animated-border'
import { TextArea } from '@/components/text-area/text-area'
import { DI_TYPE } from '@/di.types'
import { cls } from '@/utils/utils'
import { useViewModel } from '@/utils/view'
import { MAX_PROMPT_LENGTH } from '@/view-models/gem-prompt-input'

interface IGemPromptInput {
  prompt?: string
  onPromptChange: (prompt: string) => void
}

const PLACEHOLDER =
  'The instructions here should describe a task you want Gemma to help you with more than once. That might be something like "Change this text to always read like this example [insert example content]" or "Help me create my next marketing campaign, we can use the following channels: [insert marketing channels]."'

export const GemPromptInput = observer(
  ({ prompt, onPromptChange }: IGemPromptInput) => {
    const vm = useViewModel(DI_TYPE.GemPromptInputViewModel)

    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const scrollToBottom = () => {
      if (!textAreaRef.current) return
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight
    }

    const moveCursorToEnd = () => {
      if (!textAreaRef.current) return
      const length = textAreaRef.current.value.length
      textAreaRef.current.setSelectionRange(length, length)
    }

    const focusTextArea = () => {
      if (!textAreaRef.current) return
      textAreaRef.current.focus()
    }

    useEffect(() => {
      vm.onPromptChange(prompt)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // run only once

    const stopIfLimit = useCallback(() => {
      if (vm.promptText.length < MAX_PROMPT_LENGTH) return
      if (vm.isRecording) vm.voiceInputStop()
      toast.error('Max character limit reached', {
        position: 'bottom-center',
      })
    }, [vm])

    useEffect(() => {
      onPromptChange(vm.promptText)
      scrollToBottom()
      stopIfLimit()
    }, [onPromptChange, stopIfLimit, vm.promptText]) // run every time promptText changes

    const onMicrophoneClick = async () => {
      if (!vm.isRecording) {
        await vm.voiceInputStart()
        scrollToBottom()
      } else {
        await vm.voiceInputStop()

        focusTextArea()
        moveCursorToEnd()
        scrollToBottom()
      }
    }

    const textArea = (
      <TextArea
        id="gemPrompt"
        ref={textAreaRef}
        className="h-28 w-full rounded-xl text-xs tracking-tight"
        name="gemPrompt"
        data-automation="custom-gem-field"
        onChange={(e) => vm.onPromptChange(e.target.value)}
        value={vm.promptText}
        placeholder={PLACEHOLDER}
        maxLength={MAX_PROMPT_LENGTH}
      />
    )

    return (
      <div className="mt-4 flex gap-2">
        <fieldset className="flex w-full flex-wrap items-center justify-start gap-2">
          <label htmlFor="gemPrompt" className="flex text-sm leading-tight">
            Instructions
          </label>
          <div className="w-full">
            <AnimatedBorder
              borderWidth={2}
              className={cls(!vm.isRecording && 'hidden')}
            >
              {textArea}
            </AnimatedBorder>
          </div>
        </fieldset>
        <button
          className={cls(
            'mt-6 inline-flex h-10 w-11 items-center justify-center rounded-xl',
            vm.isRecording
              ? 'bg-cerosPrimary hover:bg-cerosPrimaryHover active:bg-white'
              : 'active:bg-cerosPrimary bg-white hover:bg-neutral-100',
          )}
          onClick={onMicrophoneClick}
        >
          <MicrophoneIcon
            className={cls(
              'h-5 w-5',
              vm.isRecording
                ? 'stroke-white active:stroke-black'
                : 'stroke-black active:stroke-white',
            )}
          />
        </button>
      </div>
    )
  },
)
