import './editor.css'

import Color from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'
import { BubbleMenu, EditorProvider } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { observer } from 'mobx-react-lite'

import { getDocumentContent, HighlightMatch } from '../../utils/document'
import type { ConversationViewModel } from '../../view-models/conversation'
import { BubbleMenuActions } from './bubble-menu'
import { useTrackingEventListener } from './event-listener'
import { Placeholder } from './placeholder'
import { Toolbar } from './toolbar'
import { Updater } from './updater'

// define your extension array
const extensions = [
  StarterKit.configure({
    heading: { levels: [1, 2, 3, 6] },
  }),
  TextStyle,
  Underline,
  Highlight.configure({ multicolor: true }),
  HighlightMatch,
  Color,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
    defaultAlignment: 'justify',
  }),
  Typography,
]

export const TiptapEditor: React.FC<{
  vm: ConversationViewModel
}> = observer(({ vm }) => {
  useTrackingEventListener(vm)
  const { msg } = vm.currentDocument
  return (
    <div className="relative z-20 h-full min-h-0">
      <div
        className="gemma-editor bg-cerosWhite h-full max-h-full rounded-md p-4 pt-[40px]"
        data-automation="copy-and-content-text-content"
      >
        <EditorProvider
          onUpdate={({ editor, transaction }) => {
            const newHTML = editor.getHTML().replace(/<mark.*?>|<\/mark>/g, '') // remove highlights
            if (msg?.id) {
              const isEmpty = editor.isEmpty
              const isChanged =
                newHTML !== getDocumentContent(msg) && transaction.docChanged
              const skipUpdate =
                isEmpty || !isChanged || vm.documentViewModel.showDiff
              if (skipUpdate) return
              vm.onEditorTextChange(
                newHTML,
                msg.id,
                vm.documentViewModel.currentItemId,
              )
            } else {
              // draft
              vm.onEditorTextChange(newHTML)
            }
          }}
          onFocus={() => {
            // hide diff when editor is focused
            vm.documentViewModel.showDiff = false
            vm.documentViewModel.isNew = false
          }}
          extensions={extensions}
          slotBefore={<Toolbar vm={vm} />}
        >
          {msg && ( // FUTURE: bubble actions for draft, allowing to ask Gemma to update draft fragment as new AI version
            <BubbleMenu className="bg-cerosWhite border-cerosGrey300 flex gap-2 rounded-md border px-2 py-2 shadow-md">
              <BubbleMenuActions vm={vm} />
            </BubbleMenu>
          )}
          <Updater vm={vm} />
          <Placeholder vm={vm} />
        </EditorProvider>
      </div>
    </div>
  )
})
