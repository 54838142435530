import { z } from 'zod'

export const searchBrandSchema = z.object({
  name: z.string().nullable(),
  domain: z.string(),
  claimed: z.boolean(),
  icon: z.string().nullable(),
  brandId: z.string(),
})

export type SearchBrand = z.infer<typeof searchBrandSchema>

export const searchBrandResultSchema = z.array(searchBrandSchema)

export type SearchBrandResult = z.infer<typeof searchBrandResultSchema>
