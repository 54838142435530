import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import AddIcon from '@/assets/icons/add.svg?react'
import SettingsIcon from '@/assets/icons/manage.svg?react'
import { AnimatedBorder } from '@/components/animated-border/animated-border'
import { MainLayout as MainLayoutComponent } from '@/components/layouts/main-layout'
import { ProfileButton } from '@/components/profile-button/profile-button'
import { Sidebar } from '@/components/sidebar/sidebar'
import { SidebarItem } from '@/components/sidebar/sidebar-item'
import { SidebarSection } from '@/components/sidebar/sidebar-section'
import { Tooltip } from '@/components/tooltip/tooltip'
import { UpgradeButton } from '@/components/upgrade-button/upgrade-button'
import { DI_TYPE } from '@/di.types'
import { cls } from '@/utils/utils'
import { useSidebarService, useViewModel } from '@/utils/view'

export const MainLayout = observer(() => {
  const sidebarService = useSidebarService()
  const vm = useViewModel(DI_TYPE.SubscriptionPlanViewModel)

  const actions = sidebarService.userActions
  const planType = vm.planType
  const hasBillingEnabled = vm.hasBillingEnabled
  const openPlanModal = vm.openPlanModal

  // FIXME: hack - this should not be happening in a component
  // ideally it would happen wherever the actions are defined
  // but for now we don't have access to the openPlanModal method in sidebarService
  useEffect(() => {
    if (planType !== 'pro' || !hasBillingEnabled) {
      return
    }

    actions[0].data.splice(1, 0, {
      title: 'My Plan',
      icon: () => <SettingsIcon className="h-4 w-4" />,
      id: 'my_plan',
      onClick: () => openPlanModal({ source: 'plan-settings' }),
    })
  }, [actions, planType, openPlanModal, hasBillingEnabled])

  const startNewChatButton = (
    <div className="z-20">
      <Tooltip text="Start new chat" placement="bottom-end">
        <button
          className="shadow-navigationButton mr-1 flex h-9 w-9 items-center justify-center rounded-lg"
          onClick={sidebarService.startBlankChat}
          data-automation="create-conversation-btn"
        >
          <AddIcon />
        </button>
      </Tooltip>
    </div>
  )

  return (
    <MainLayoutComponent
      isSidebarOpen={sidebarService.isOpen}
      onSidebarToggle={sidebarService.toggle}
      isHomePage={sidebarService.isHomePage}
      onHomePageClick={sidebarService.goToHomePage}
      sidebar={
        <Sidebar
          className={cls(
            'absolute z-40 transition duration-300 ease-in-out lg:static',
            !sidebarService.isOpen && 'translate-x-[-300px]',
          )}
        >
          <SidebarSection title="Chats" button={startNewChatButton}>
            <>
              {sidebarService.initialized &&
                sidebarService.conversations.length === 0 && (
                  <div className="text-cerosGrey400 mt-20 flex flex-col items-center text-center text-sm">
                    <div className="bg-cerosGrey1 h-[75px] w-[75px] rounded-full p-[20px] text-3xl">
                      💬
                    </div>
                    <div className="mt-6 max-w-[150px] leading-6">
                      Start creating with Gemma by clicking on the
                      <button
                        className="shadow-navigationButton text-cerosGrey600 mx-1 inline-flex h-5 w-5 translate-y-[3px] items-center justify-center rounded-lg"
                        onClick={sidebarService.startBlankChat}
                        data-automation="create-conversation-btn"
                      >
                        <AddIcon />
                      </button>
                      button above
                    </div>
                  </div>
                )}
              {sidebarService.conversations.map((item) => (
                <SidebarItem
                  key={item.path}
                  item={item}
                  selected={item.id === sidebarService.currentConversationId}
                />
              ))}
            </>
          </SidebarSection>

          {vm.canUpgrade && (
            <div className="flex w-full pl-2">
              <AnimatedBorder className="hidden group-hover/animated-border:block group-focus/animated-border:block">
                <UpgradeButton
                  className="w-[264px] pl-[18px]"
                  onClick={() => vm.openPlanModal({ source: 'upgrade-banner' })}
                />
              </AnimatedBorder>
            </div>
          )}
          <ProfileButton
            initials={sidebarService.userInitials}
            name={sidebarService.userData.name}
            title={sidebarService.userData.jobTitle}
            actions={actions}
          />
        </Sidebar>
      }
      content={<Outlet />}
    />
  )
})
