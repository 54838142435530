import { useEffect } from 'react'

import type { ConversationViewModel } from '../../view-models/conversation'

export const useTrackingEventListener = (vm: ConversationViewModel) => {
  const { index } = vm.currentDocument
  const { onCopyDocument, editor } = vm.documentViewModel
  const isEditorActive = editor?.isActive

  useEffect(() => {
    if (index !== -1) {
      const listenForCopy = () => {
        if (isEditorActive) {
          onCopyDocument(index, 'keyboard')
        }
      }
      window.addEventListener('copy', listenForCopy)
      return () => {
        window.removeEventListener('copy', listenForCopy)
      }
    }
  }, [index, isEditorActive, onCopyDocument])
}
