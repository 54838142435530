import './okta-login-form.css'

import { useOktaAuth } from '@okta/okta-react'
import OktaSignIn from '@okta/okta-signin-widget'
import { useEffect, useRef } from 'react'
import { toast } from 'react-hot-toast'

import { DI_TYPE } from '@/di.types'
import { getOktaConfig } from '@/services/okta-auth'
import { useViewModel } from '@/utils/view'

interface IOktaLoginForm {
  authState: string
}

const oktaConfig = getOktaConfig()

export const OktaLoginForm = ({ authState }: IOktaLoginForm) => {
  const vm = useViewModel(DI_TYPE.LoginViewModel)
  const { oktaAuth } = useOktaAuth()
  const widgetRef = useRef(null)
  const redirectUri = `${window.location.protocol}//${window.location.host}/callback`

  useEffect(() => {
    if (!widgetRef.current) return

    const widget = new OktaSignIn({
      useClassicEngine: true, // Set to false when our Okta org is OIE enabled
      baseUrl: oktaConfig.issuer?.split('/oauth2')[0],
      clientId: oktaConfig.clientId,
      redirectUri,
      state: authState, // This can be the write_key for an auth0 flow from desktop app or plugin
      authParams: {
        issuer: oktaConfig.issuer,
        scopes: ['openid', 'email', 'profile', 'offline_access'],
        pkce: true,
        responseMode: 'query',
        responseType: 'code',
        tokenManager: {
          storage: 'sessionStorage',
        },
      },
      features: {
        autoFocus: true,
        idpDiscovery: true,
        rememberMe: false,
        showIdentifier: true,
        showPasswordToggleOnSignInPage: true,
      },
      idpDiscovery: {
        requestContext: `${redirectUri}${
          authState ? `?state=${authState}` : ''
        }`,
      },
      helpLinks: {
        help: 'https://educate.ceros.com/',
        forgotPassword: 'https://admin.ceros.com/forgot-password', // Use admin.ceros.com until we support password reset
      },
      i18n: {
        en: {
          'primaryauth.title': 'Sign In',
          'primaryauth.username.placeholder': 'Email',
          'primaryauth.username.tooltip': 'Email',
          'primaryauth.submit': 'Sign in',
          'password.forgot.email.or.username.placeholder': 'Email',
          'password.forgot.email.or.username.tooltip': 'Email',
          'password.expired.title.generic': 'Password Expired',
          'error.username.required': 'Please enter an email',
          'errors.E0000004': 'The email or password you entered is incorrect.',
          'oform.errorbanner.title': ' ', // don't display default error message
          'oform.next': 'Continue',
        },
      },
      transformUsername: (identity, operation) => {
        if (operation === 'PRIMARY_AUTH') {
          vm.trackLoginByEmailStarted()
        }
        return identity
      },
    })

    // FUTURE: considering doing auth flow in the background, avoiding a redirect -- needs more research and decent error messaging
    widget.showSignInAndRedirect({ el: widgetRef.current })

    // Note: Can't distinguish CORS error from other network errors
    const isCorsError = (err) => err.name === 'AuthApiError' && !err.statusCode

    widget.on('afterError', (_context, error) => {
      if (isCorsError(error)) {
        toast.error('Okta CORS error', {
          id: 'okta-cors-error',
          duration: Infinity,
        })
        throw new Error('Okta CORS error')
      }
    })

    return () => widget.remove()
  }, [vm, oktaAuth, authState, redirectUri])

  return <div ref={widgetRef} />
}
