import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'

import LogoIcon from '@/assets/icons/logo.svg?react'
import { Message } from '@/components/chat/message.js'
import { MessageContext } from '@/components/chat/message-context.js'
import type { ConversationReadonlyViewModel } from '@/view-models/conversation-readonly.js'

import { MessageContentReadonly } from './message-content-readonly.js'

interface IMessagesView {
  vm: ConversationReadonlyViewModel
}

export const MessagesReadonlyView = observer(({ vm }: IMessagesView) => {
  const totalMessages = vm.messages.length
  return (
    <>
      {vm.messages.map((msg, index) => {
        const firstMessage = index === 0
        const lastMessage = index === totalMessages - 1

        const authorDisplayName =
          msg.author.type === 'bot'
            ? 'Gemma'
            : msg.author.user.name
              ? msg.author.user.name
              : msg.author.user.email

        const authorInitials = authorDisplayName.slice(0, 2).toUpperCase()

        return (
          <Fragment key={msg.id}>
            <Message
              from={authorDisplayName}
              initials={authorInitials}
              time={msg.renderMetadata.date}
              avatar={
                msg.author.type === 'user' ? undefined : (
                  <LogoIcon className="h-[22.5px] w-[22.5px]" />
                )
              }
              onReport={undefined}
              disabled
            >
              <MessageContext.Provider
                value={{
                  isFirstMessage: firstMessage,
                  isLastMessage: lastMessage,
                }}
              >
                <MessageContentReadonly message={msg} vm={vm} />
              </MessageContext.Provider>
            </Message>
          </Fragment>
        )
      })}
    </>
  )
})

MessagesReadonlyView.displayName = 'MessagesReadonlyView'
