import { cls } from '@/utils/utils'

interface IEmbeddedContent {
  className?: string
  html: string
  source: string
  title?: string
}

export const EmbeddedContent = ({
  className,
  html,
  source,
  title,
}: IEmbeddedContent) => {
  // NOTE: this overrides user agent's body default margin: 8px
  const styleOverride = `
    <style>
      body {
        margin: 0;
      }
    </style>
  `

  return (
    <div
      className="bg-cerosWhite shadow-imagesBlock max-w-[602px] rounded-lg p-4"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto auto',
        gridTemplateAreas: `
                    "embed"
                    "credits"
                  `,
      }}
    >
      <div style={{ gridArea: 'embed' }}>
        <iframe
          className={cls('aspect-video w-full', className)}
          sandbox="allow-scripts allow-same-origin"
          src={`data:text/html;base64,${btoa(html + styleOverride)}`} // NOTE: since we don't have url for iframe and srcDoc is not supported on iOS Safari and Android, the url is provided in data schema
          title={title ?? 'embedded content'}
        />
      </div>
      <div
        style={{ gridArea: 'credits' }}
        className="mt-4 flex min-h-[40px] w-full items-center justify-between"
      >
        <div className="flex items-baseline text-base">
          Sent by Gemma
          <span className="text-cerosGrey400 ml-2 text-sm font-normal">
            on {source}
          </span>
        </div>
      </div>
    </div>
  )
}
