import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom'

import { AudioContextProvider } from './components/audio-context-provider.js'
import { OktaAuthWrapper } from './components/auth/okta-auth-wrapper.js'
import { AutoUpdateProvider } from './components/auto-update/auto-update.js'
import { AppLayout } from './components/layouts/app-layout.js'
import { LoginLayout } from './components/layouts/login-layout.js'
import { Toaster } from './components/toaster/toaster.js'
import { AuthCallbackPage } from './pages/auth/auth-callback-page.js'
import { AuthEmbedPage } from './pages/auth/auth-embed-page.js'
import { AutoLoginPage } from './pages/auth/auto-login-page.js'
import { LoginPage } from './pages/auth/login-page.js'
import { LoginSuccessPage } from './pages/auth/login-success.js'
import { LoginUnassignedPage } from './pages/auth/login-unassigned.js'
import { PasswordResetPage } from './pages/auth/password-reset.js'
import { SignUpPage } from './pages/auth/sign-up.js'
import { Conversation } from './pages/conversation/conversation.js'
import { ConversationClonePage } from './pages/conversation/conversation-clone-page.js'
import { ConversationLatestPage } from './pages/conversation/conversation-latest-page.js'
import { ConversationNewPage } from './pages/conversation/conversation-new-page.js'
import { ConversationPage } from './pages/conversation/conversation-page.js'
import { ConversationReadonlyPage } from './pages/conversation/readonly/conversation-readonly-page.js'
import { EmbedStudioPage } from './pages/embed-studio-new-page.js'
import { GemPage } from './pages/gem/gem-page.js'
import { HomePage } from './pages/home-page/home-page.js'
import { EmbedLayout } from './pages/layouts/embed/embed-layout.js'
import { MainLayout } from './pages/layouts/main/main-layout.js'
import { ModalServiceView } from './pages/modal-service.js'
import { NotFound } from './pages/not-found-page.js'
import { OnboardingPage } from './pages/onboarding/onboarding-page.js'
import { ProfilePage } from './pages/profile-page.js'
import { PromptsPage } from './pages/prompts-page.js'
import { Public } from './pages/public.js'
import { RequiredUserChecks } from './pages/required-user-checks.js'
import { StripeCallbackPage } from './pages/stripe/callback/stripe-callback-page.js'
import { TermsPage } from './pages/terms.js'
import { WithUserProperties } from './pages/with-user-properties.js'
import { initGoogleTagManager } from './utils/gtm.js'
import { useAuthService, useRouterService } from './utils/view.js'

initGoogleTagManager()

const AppRouter = observer(() => {
  const authService = useAuthService()
  const routerService = useRouterService()

  const isAuthenticated = authService.isAuthenticated

  return (
    <HistoryRouter history={routerService.history as any}>
      <Routes location={routerService.currentLocation as any}>
        <Route path="/callback" element={<AuthCallbackPage />} />
        <Route path="/stripe/callback" element={<StripeCallbackPage />} />

        {isAuthenticated && (
          <Route element={<EmbedLayout />}>
            <Route element={<WithUserProperties />}>
              <Route path="/embed">
                <Route path="studio" element={<EmbedStudioPage />} />
                <Route
                  path="studio/:conversationId"
                  element={<ConversationPage />}
                />
              </Route>
            </Route>
            <Route element={<AppLayout />}>
              <Route element={<WithUserProperties />}>
                <Route element={<RequiredUserChecks />}>
                  <Route path="/" element={<MainLayout />}>
                    <Route index element={<HomePage />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="conversations">
                      <Route path="new" element={<ConversationNewPage />} />
                      <Route
                        path="latest"
                        element={<ConversationLatestPage />}
                      />
                      <Route
                        path=":conversationId/clone"
                        element={<ConversationClonePage />}
                      />
                      <Route
                        path=":conversationId"
                        element={<Conversation />}
                      />
                      <Route
                        path="not-found"
                        element={<NotFound type={'Conversation'} />}
                      />
                    </Route>
                    <Route path="gems">
                      <Route path=":gemId" element={<GemPage />} />
                      <Route
                        path="not-found"
                        element={<NotFound type={'Gem'} />}
                      />
                    </Route>
                  </Route>
                  <Route path="/prompts" element={<PromptsPage />} />
                </Route>
                <Route path="/onboarding" element={<OnboardingPage />} />
                <Route element={<LoginLayout />}>
                  <Route path="/terms" element={<TermsPage />} />
                </Route>
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Route>
        )}
        {!isAuthenticated && (
          <Fragment>
            <Route
              element={
                <Public>
                  <OktaAuthWrapper>
                    <Outlet />
                  </OktaAuthWrapper>
                </Public>
              }
            >
              <Route element={<LoginLayout />}>
                <Route
                  path="/forgot-password"
                  element={<PasswordResetPage />}
                />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/login/success" element={<LoginSuccessPage />} />
                <Route
                  path="/login/unassigned"
                  element={<LoginUnassignedPage />}
                />
                <Route path="/signup" element={<SignUpPage />} />
              </Route>
              <Route path="/autologin" element={<AutoLoginPage />} />
              <Route path="/embed/*" element={<AuthEmbedPage />} />
            </Route>
            <Route path="conversations" element={<AppLayout />}>
              <Route
                path=":conversationId"
                element={<ConversationReadonlyPage />}
              />
              <Route
                path="not-found"
                element={<NotFound type={'Conversation'} />}
              />
            </Route>
            <Route path="gems" element={<AppLayout />}>
              <Route path=":gemId" element={<GemPage />} />
              <Route path="not-found" element={<NotFound type={'Gem'} />} />
            </Route>
            <Route path="*" element={<Navigate to="/login" />} />
          </Fragment>
        )}
      </Routes>
    </HistoryRouter>
  )
})

AppRouter.displayName = 'AppRouter'

export function App() {
  return (
    <AutoUpdateProvider>
      <ModalServiceView />
      <Toaster />
      <AudioContextProvider>
        <AppRouter />
      </AudioContextProvider>
    </AutoUpdateProvider>
  )
}
