import type { FeatureFlag } from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import { action, computed, makeObservable } from 'mobx'

import { DI_TYPE } from '@/di.types.js'
import type { FeatureFlagService } from '@/services/feature-flag.js'
import type { MixpanelService } from '@/services/mixpanel.js'

import { BaseViewModel } from './base-view-model.js'

export class FeatureFlagViewModel extends BaseViewModel {
  constructor(
    private featureFlagService: FeatureFlagService,
    private mixpanelService: MixpanelService,
  ) {
    super()
    makeObservable(this)
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onInit(): void {}

  onDispose(): void {}

  @computed
  get items() {
    return this.featureFlagService.items
  }

  @computed
  get isEditable() {
    return this.featureFlagService.isEditable
  }

  @action.bound
  async resetAll() {
    this.featureFlagService.resetAll()
    this.mixpanelService.trackFeatureUpdate('Reset All', true)
  }

  @action.bound
  async toggle({ id: flag_id, value, label }: FeatureFlag) {
    const newValue = !value
    const result = await this.featureFlagService.set(flag_id, newValue)
    if (result) this.mixpanelService.trackFeatureUpdate(label, newValue)
  }
}

injected(
  FeatureFlagViewModel,
  DI_TYPE.FeatureFlagService,
  DI_TYPE.MixpanelService,
)
