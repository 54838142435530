import { captureException } from '@sentry/react'
import { injected } from 'brandi'

import { DI_TYPE } from '@/di.types'
import type { Auth0StartModel } from '@/models/auth'
import {
  type ILoginData,
  LOGIN_ACTION_QUERY_PARAM,
  LoginAction,
  type LoginReturn,
  loginReturnSchema,
} from '@/models/login'
import { getAppOrigin } from '@/utils/utils'

import type { StorageService } from './storage'

export const LOGIN_DATA_STORE_KEY = 'login-data'
export const LOGIN_RETURN_STORE_KEY = 'login-return'

export type SaveLoginData = (loginData: ILoginData) => Promise<boolean>

export function saveLoginDataFactory(
  storageService: StorageService,
): SaveLoginData {
  return async (loginData: ILoginData) => {
    return storageService.save(LOGIN_DATA_STORE_KEY, JSON.stringify(loginData))
  }
}

export type GetLoginDataForState = (state: string) => ILoginData | undefined

injected(saveLoginDataFactory, DI_TYPE.StorageService)

export function getLoginDataForStateFactory(
  storageService: StorageService,
): GetLoginDataForState {
  return (state: string): ILoginData | undefined => {
    const loginDataRaw = storageService.load(LOGIN_DATA_STORE_KEY)
    const loginData = loginDataRaw ? JSON.parse(loginDataRaw) : undefined

    if (loginData && loginData.state === state) {
      return loginData
    }
  }
}

injected(getLoginDataForStateFactory, DI_TYPE.StorageService)

export function signInUri(write_key: Auth0StartModel['write_key']) {
  const url = new URL(
    `${window.location.protocol}//${window.location.host}/login`,
  )

  const origin = getAppOrigin()
  url.searchParams.set('state', write_key)
  url.searchParams.set('utm_source', origin)
  url.searchParams.set(LOGIN_ACTION_QUERY_PARAM, LoginAction.enum.store)

  return url.toString()
}

export type SaveLoginReturn = (loginReturnData: LoginReturn) => boolean

export function saveLoginReturnFactory(
  storageService: StorageService,
): SaveLoginReturn {
  return (loginReturnData: LoginReturn) => {
    return storageService.save(
      LOGIN_RETURN_STORE_KEY,
      JSON.stringify(loginReturnData),
    )
  }
}

injected(saveLoginReturnFactory, DI_TYPE.StorageService)

export type GetAndRemoveLoginReturn = () => LoginReturn | undefined

export function getAndRemoveLoginReturnFactory(
  storageService: StorageService,
): GetAndRemoveLoginReturn {
  return (): LoginReturn | undefined => {
    let data: LoginReturn | undefined = undefined
    const dataRaw = storageService.load(LOGIN_RETURN_STORE_KEY)

    if (dataRaw) {
      storageService.remove(LOGIN_RETURN_STORE_KEY)

      try {
        data = loginReturnSchema.parse(JSON.parse(dataRaw))
      } catch (e) {
        captureException(e)
        console.error(e)
      }
    }

    return data
  }
}

injected(getAndRemoveLoginReturnFactory, DI_TYPE.StorageService)
