import type { Editor, Range } from '@tiptap/react'
import { Extension } from '@tiptap/react'
import type { Change } from 'diff'
import { diffLines, diffWords } from 'diff'

import type { RenderableConversationMessage } from '../models/conversation'

export const getDocumentContent = (
  msg: RenderableConversationMessage | undefined,
) => {
  if (!msg) return ''
  const item = msg.items.find((it) => it.content.type === 'inline_document')
  const content =
    item?.content.type === 'inline_document' ? item?.content.text : ''
  return content
}

export function getHTMLOfSelection(removeMarkTags?: boolean): string {
  let range
  if (
    'selection' in document &&
    document.selection &&
    (document.selection as any).createRange
  ) {
    range = (document.selection as any).createRange()
    const html = range.htmlText
    if (removeMarkTags) {
      return html.replace(/<mark.*?>|<\/mark>/g, '')
    }
    return html
  } else if (window.getSelection) {
    const selection = window.getSelection()
    if (selection && selection.rangeCount > 0) {
      range = tryToGetSelectionRange(0)
      const clonedSelection = range.cloneContents()
      const div = document.createElement('div')
      div.appendChild(clonedSelection)
      const html = div.innerHTML
      if (removeMarkTags) {
        return html.replace(/<mark.*?>|<\/mark>/g, '')
      }
      return html
    } else {
      return ''
    }
  } else {
    return ''
  }
}

export function tryToGetSelectionRange(
  idx: number,
): globalThis.Range | undefined {
  const selection = window.getSelection()
  if (selection && selection.rangeCount > 0) {
    return selection.getRangeAt(idx)
  }
  return undefined
}
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    highlightMatch: {
      searchAndHighlight: (
        searchTerm: string,
        startingIndex?: number,
      ) => ReturnType
    }
  }
}
interface TextNodesWithPosition {
  text: string
  pos: number
}
export const HighlightMatch = Extension.create({
  name: 'highlightMatch',
  addCommands() {
    return {
      searchAndHighlight:
        (searchTerm: string, startingIndex: number = 0) =>
        ({ editor, commands }) => {
          const results: Range[] = []
          let textNodesWithPosition: TextNodesWithPosition[] = []
          let index = 0
          editor.state.doc.descendants((node, pos) => {
            if (node.isText) {
              if (textNodesWithPosition[index]) {
                textNodesWithPosition[index] = {
                  text: textNodesWithPosition[index].text + node.text,
                  pos: textNodesWithPosition[index].pos,
                }
              } else {
                textNodesWithPosition[index] = {
                  text: `${node.text}`,
                  pos,
                }
              }
            } else {
              index += 1
            }
          })

          textNodesWithPosition = textNodesWithPosition.filter(Boolean)
          const safeSearchTerm = searchTerm.replace(
            /[.*+?^${}()|[\]\\]/g,
            '\\$&',
          )
          const regex = RegExp(safeSearchTerm, 'gi')
          let found = false
          for (const element of textNodesWithPosition) {
            const { text, pos } = element
            const matches = Array.from(text.matchAll(regex)).filter(
              ([matchText]) => matchText.trim().length > 0,
            )
            for (const m of matches) {
              if (m[0] === '') break
              if (
                m.index !== undefined &&
                pos + m.index + m[0].length >= startingIndex
              ) {
                const addDot = text[m[0].length + m.index] === '.' ? 1 : 0
                results.push({
                  from: pos + m.index,
                  to: pos + m.index + m[0].length + addDot,
                })
                // if we found the match we were looking for, we stop the search
                found = true
                break
              }
            }
            if (found) break
          }
          // set highlight to all matches
          for (let i = 0; i < results.length; i += 1) {
            const r = results[i]
            commands.setTextSelection({ from: r.from, to: r.to })
            commands.unsetMark('highlight')
            commands.setMark('highlight', {
              color: 'var(--gemma-highlight-color)',
            })
            commands.setTextSelection({ from: r.to, to: r.to })
          }
          return true
        },
    }
  },
})

export const highlightDifferences = (
  prevMsg: RenderableConversationMessage,
  editor: Editor,
) => {
  const prevCleanedContent = getDocumentContent(prevMsg)
  const endSentenceRegex = /([.!?])/g

  const container = document.createElement('div')
  const prevHtml = prevCleanedContent
  container.innerHTML = prevHtml
  const innerText: string[] = []
  container.childNodes.forEach((node) => {
    if (node.textContent) {
      const sentences = new RegExp(endSentenceRegex).test(node.textContent)
        ? node.textContent.split(endSentenceRegex)
        : [node.textContent]
      innerText.push(...sentences)
    }
  })
  // we change all sentences to be separated by a new line
  // in order for diffLines to perform best
  const sentencesToLines = (s: string[]) => {
    return s
      .filter((s) => s.trim().length > 0)
      .map((s) => s.trim())
      .join('\n')
      .replaceAll(endSentenceRegex, '\n')
      .replaceAll(/\n{2,}/g, '\n')
  }
  const prevSentences = sentencesToLines(innerText)
  const newSentences = sentencesToLines(
    editor.getText({ blockSeparator: '.' }).split(endSentenceRegex),
  )
  if (prevSentences.length > 0 && newSentences.length > 0) {
    const diffL = diffLines(prevSentences, newSentences, {
      ignoreWhitespace: false,
      newlineIsToken: true,
    })
    const MAX_WORD_DIFF_PER_LINE = 3
    // decide if we should diff lines or words
    // if line has at least 3 words diff, diff line
    // otherwise, diff words
    const diff: Change[] = []
    diffL.forEach((d, i) => {
      if (d.added) {
        const prev = diffL[i - 1]
        if (prev && prev.value.length > 0 && !prev.added) {
          const wordDiff = diffWords(prev.value, d.value)
          const wordDifference = wordDiff.reduce(
            (acc, d) => (d.added ? acc + (d.count ?? 0) : acc),
            0,
          )
          if (wordDiff.length > 0 && wordDifference <= MAX_WORD_DIFF_PER_LINE) {
            diff.push(...wordDiff)
          } else {
            diff.push(d)
          }
        } else {
          diff.push(d)
        }
      }
    })
    let currentPos = 0
    diff.forEach((d) => {
      if (d.added && d.value.length > 0) {
        const searchTerms = d.value
          .trim()
          .split('\n')
          .map((s) => s.trim())
        searchTerms.forEach((searchTerm) => {
          editor.chain().searchAndHighlight(searchTerm, currentPos).run()
        })
      }
      if (d.value && !d.removed) {
        currentPos += d.value.length
      }
    })
  }
}
