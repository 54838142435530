interface IUnsplashSearchLink {
  query: string
  messageId?: string
}
export default function UnsplashSearchLink({ query }: IUnsplashSearchLink) {
  return (
    <span className="text-cerosGrey400 mt-2 text-sm">
      Not what you are looking for? Browse{' '}
      <a
        href={`https://unsplash.com/s/photos/${query}`}
        className="text-cerosPrimary hover:text-cerosPrimaryHover hover:cursor-pointer"
        target="_blank"
        rel="noreferrer"
      >
        Unsplash
      </a>
    </span>
  )
}
