import { captureException } from '@sentry/react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { z } from 'zod'

import { useEventCallback } from '@/utils/use-event-callback'
import { APP_VERSION } from '@/utils/utils'

import gemmaLogo from '../../assets/icons/gemma-logo.svg'

const context = createContext<{
  apply: () => void
  available: boolean
} | null>(null)

export function AutoUpdateProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [webAvailable, setWebAvailable] = useState(false)
  useEffect(() => {
    if (import.meta.env.DEV) return // <- do not check for update in dev server
    const controller = new AbortController()
    watchForWebUpdate(controller.signal, () => setWebAvailable(true))
    return () => controller.abort()
  }, [])

  const apply = useEventCallback(() => {
    if (webAvailable) window.location.reload()
  })
  const value = useMemo(
    () => ({
      apply,
      available: webAvailable,
    }),
    [apply, webAvailable],
  )

  return <context.Provider value={value}>{children}</context.Provider>
}

const UPDATE_CHECK_INTERVAL = 60 * 1000 // <- 1 minute
async function watchForWebUpdate(signal: AbortSignal, onUpdate: () => void) {
  let errorSleep = 50
  while (!signal.aborted) {
    try {
      let res: Response
      try {
        // this changes once every UPDATE_CHECK_INTERVAL
        const cacheBust = Math.floor(Date.now() / UPDATE_CHECK_INTERVAL)
        res = await fetch('/detect-update.json?v=' + cacheBust)
      } catch (e) {
        // probably offline, try again later
        await sleep()
        continue
      }
      if (res.status !== 200) {
        // detect-update.json not found, try again later
        await sleep()
        continue
      }
      const { version } = z
        .object({ version: z.string() })
        .parse(await res.json())
      if (version !== APP_VERSION) {
        if (!signal.aborted) onUpdate()
        break
      }
      errorSleep = 50
      await new Promise((resolve) => setTimeout(resolve, UPDATE_CHECK_INTERVAL))
    } catch (e) {
      // unexpected error, report it, but continue checking for the update
      console.error(e)
      captureException(e)
      await sleep()
    }
  }

  function sleep() {
    errorSleep *= 2 // <- we do not want to DDOS our own server
    if (errorSleep > 15 * 60 * 1000) errorSleep = 15 * 60 * 1000 // <- max 15 minutes
    return new Promise((resolve) => setTimeout(resolve, errorSleep))
  }
}

export function AutoUpdate() {
  const ctx = useContext(context)
  if (!ctx) throw new Error('AutoUpdate context not found')

  if (!ctx.available) return null

  return <UpdateButton onClick={() => void ctx.apply()} />
}

export function UpdateButton({ onClick }: { onClick?: () => void }) {
  return (
    <div className="w-full px-3">
      <button
        type="button"
        onClick={onClick}
        className="border-cerosWhite bg-cerosWhite hover:border-cerosGrey500 active:border-cerosGrey600
          relative
          w-full rounded-lg border py-4"
      >
        <div className="text-cerosGrey600 text-sm font-medium leading-none">
          Gemma is ready to update!
        </div>
        <div className="text-cerosGrey400 mt-3 text-xs font-medium leading-none">
          Click to reload
        </div>
        <div
          className="absolute bottom-0 left-0 right-0 top-0 bg-no-repeat opacity-5"
          style={{
            backgroundImage: `url("${gemmaLogo}")`,
            backgroundPosition: 'center -5.75rem',
            backgroundSize: '12rem',
            margin: '-1px', // overlap the border
          }}
        ></div>
      </button>
    </div>
  )
}
