import { observer } from 'mobx-react-lite'
import React from 'react'

import { useMatchMedia } from '../../utils/use-match-media'
import type { ConversationViewModel } from '../../view-models/conversation'
import { CopyAndContentTool } from './copy-and-content'

export const SidePreview: React.FC<{ vm: ConversationViewModel }> = observer(
  ({ vm }) => {
    const overlayChat = !useMatchMedia('(min-width: 1024px)')
    const sidepanelView = vm.sidepanelContentType

    switch (sidepanelView) {
      case 'copy_and_content_tool':
        if (overlayChat && vm.documentViewModel.isEditorOpen) {
          return (
            <div className="absolute left-0 top-0 z-50 h-svh w-svw">
              <CopyAndContentTool vm={vm} />
            </div>
          )
        }
        return <CopyAndContentTool vm={vm} />
      default:
        return null
    }
  },
)
