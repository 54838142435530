import type { Factory } from 'brandi'
import { injected } from 'brandi'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import toast from 'react-hot-toast'

import { DI_TYPE } from '@/di.types'
import type { FileUploadRepository } from '@/repositories/file-upload'
import type { ModalService } from '@/services/modal'
import { bound, debounce } from '@/utils/utils'

import { BaseViewModel } from './base-view-model'

enum FileUploadError {
  FILE_TOO_LARGE = 'FILE_TOO_LARGE',
  FILE_TYPE_NOT_SUPPORTED = 'FILE_TYPE_NOT_SUPPORTED',
  ONLY_ONE_FILE_ALLOWED = 'ONLY_ONE_FILE_ALLOWED',
}

export const MAX_IMAGE_SIZE = 25 * 1024 * 1024 // 25MB
export const MAX_DOCUMENT_SIZE = 100 * 1024 * 1024 // 100MB

type SuccessCallback = (file: File) => Promise<void>
type FailCallback = (file: File | undefined, reason?: string) => void
type DraggingType = 'chat' | 'gem' | null

export type FileUploadViewModelFactory = Factory<
  FileUploadViewModel,
  [
    successCallback: (file: File) => Promise<void>,
    failCallback: (file: File, reason?: string) => void,
  ]
>
export class FileUploadViewModel extends BaseViewModel {
  onDropSuccess: SuccessCallback | undefined = undefined
  onDropFail: FailCallback | undefined = undefined
  finishDragging: () => void
  @observable allowImages = true
  @observable fileUploadErrorType: FileUploadError | undefined = undefined
  @observable fileUploading = false
  @observable showUploadErrorChatMessage = false
  @observable draggingType: DraggingType = null

  constructor(
    private fileUploadRepository: FileUploadRepository,
    private modalService: ModalService,
  ) {
    super()
    makeObservable(this)

    this.finishDragging = debounce(() => {
      this.draggingType = null
    }, 100)
  }

  @action.bound
  setFactoryData(successCallback, failCallback) {
    this.onDropSuccess = successCallback
    this.onDropFail = failCallback
  }

  onInit(): void {
    this.subscribeDNDEvents()
  }

  onDispose(): void {
    this.unsubscribeDNDEvents()
  }

  @bound
  private subscribeDNDEvents() {
    document.addEventListener('dragenter', this.documentOnDragEnter)
    document.addEventListener('drop', this.onDrop)
    document.addEventListener('dragover', this.onDragover)
    document.addEventListener('keydown', this.onKeyDown)
  }

  @bound
  private unsubscribeDNDEvents() {
    document.removeEventListener('dragenter', this.documentOnDragEnter)
    document.removeEventListener('drop', this.onDrop)
    document.removeEventListener('dragover', this.onDragover)
    document.removeEventListener('keydown', this.onKeyDown)
  }

  @computed
  private get isGemModalOpen(): boolean {
    return Boolean(this.modalService.findModalById('gem-upsert'))
  }

  @action.bound
  documentOnDragEnter() {
    this.draggingType = this.isGemModalOpen ? 'gem' : 'chat'
  }

  @action.bound
  async uploadFile(file: File): Promise<string | undefined> {
    runInAction(() => {
      this.fileUploading = true
    })
    this.closeDropArea()
    try {
      const { data, status } = await this.fileUploadRepository.uploadFile(file)

      if (status >= 200 && status < 400 && data) {
        return data.read_url
      } else {
        toast.error('Failed to upload file')
      }
    } catch (e) {
      toast.error('Failed to upload file')
    } finally {
      runInAction(() => {
        this.fileUploading = false
      })
    }
  }

  @action.bound
  onDrop(event: DragEvent) {
    if (this.isGemModalOpen) return

    event.preventDefault()
    event.stopPropagation()

    if (!this.hasFiles(event)) {
      return
    }

    if (!this.isValidFileInput(event.dataTransfer?.files)) {
      this.onDropFail?.(event.dataTransfer?.files[0], this.fileUploadErrorType)
      return
    }

    this.closeDropArea()
    this.onDropSuccess?.(event.dataTransfer!.files[0])
  }

  @action.bound
  validateFiles(files: File[]): boolean {
    if (!this.isValidFileInput(files)) {
      this.showUploadErrorChatMessage = true
      return false
    }
    return true
  }

  @action.bound
  onDragover(event: DragEvent) {
    this.finishDragging()
    event.preventDefault()
    event.stopPropagation()
    this.resetUploadError()
  }

  @action.bound
  closeDropArea() {
    this.resetUploadError()
  }

  @action.bound
  openDropArea() {
    this.resetUploadError()
  }

  @action.bound
  onKeyDown(e: KeyboardEvent) {
    if (this.isGemModalOpen) return

    if (e.key === 'Escape') {
      this.closeDropArea()
    }
  }

  @action.bound
  private isFileValid(file: File): boolean {
    if (!file) return false

    if (!this.isAllowedFileType(file)) {
      this.fileUploadErrorType = FileUploadError.FILE_TYPE_NOT_SUPPORTED
      return false
    }

    if (!this.isAllowedFileSize(file)) {
      this.fileUploadErrorType = FileUploadError.FILE_TOO_LARGE
      return false
    }

    return true
  }

  @action.bound
  isValidFileInput(files?: any): files is FileList {
    if (!files?.length) return false
    if ([...files].some((file) => !this.isFileValid(file))) return false
    this.resetUploadError()
    return true
  }

  @computed
  get isUploadingError() {
    return this.fileUploadErrorType !== undefined
  }

  @computed
  get uploadingErrorTitle() {
    switch (this.fileUploadErrorType) {
      case FileUploadError.ONLY_ONE_FILE_ALLOWED:
        return 'Please upload only one item at a time!'
      case FileUploadError.FILE_TOO_LARGE:
        return 'Whoa there! That file was rather large!'
      case FileUploadError.FILE_TYPE_NOT_SUPPORTED:
        return 'Oops! That file type is not supported!'
      default:
        return ''
    }
  }

  @computed
  get uploadingErrorDescription() {
    switch (this.fileUploadErrorType) {
      case FileUploadError.ONLY_ONE_FILE_ALLOWED:
        return 'Try again with only one file selected'
      case FileUploadError.FILE_TOO_LARGE:
        return 'Try a file with a max of 10MB'
      case FileUploadError.FILE_TYPE_NOT_SUPPORTED:
        return 'Try again with the correct file type!'
      default:
        return ''
    }
  }

  @computed
  get uploadingErrorChatMessage() {
    switch (this.fileUploadErrorType) {
      case FileUploadError.ONLY_ONE_FILE_ALLOWED:
        return 'Please upload only one item at a time!'
      case FileUploadError.FILE_TOO_LARGE:
        return 'Woah there! That file was rather large - please upload files with a max size of 10MB. Thanks! 🙏'
      case FileUploadError.FILE_TYPE_NOT_SUPPORTED:
        return 'Oops! That file was not a .png or .jpg'
      default:
        return ''
    }
  }

  @action.bound
  private resetUploadError() {
    this.fileUploadErrorType = undefined
    this.showUploadErrorChatMessage = false
  }

  @action.bound
  private isAllowedFileType(file: File): boolean {
    if (file.type.startsWith('image/')) {
      if (!this.allowImages) return false
      if (!['image/png', 'image/jpeg'].includes(file.type)) return false
    }
    return true
  }

  @action.bound
  private isAllowedFileSize(file: File): boolean {
    if (file.type.startsWith('image/')) return file.size <= MAX_IMAGE_SIZE
    return file.size <= MAX_DOCUMENT_SIZE
  }

  @action.bound
  private hasFiles(e: DragEvent) {
    return e.dataTransfer && e.dataTransfer.files.length > 0
  }

  buildAttachmentContentForFile(
    file: File,
    url: string,
  ): { type: 'upload_pin' | 'image_pin'; url: string } {
    const attachmentContentType = file.type.startsWith('image/')
      ? 'image_pin'
      : 'upload_pin'

    return {
      type: attachmentContentType,
      url,
    }
  }
}

injected(
  FileUploadViewModel,
  DI_TYPE.FileUploadRepository,
  DI_TYPE.ModalService,
)
