import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'

import { AudioContext } from '@/components/audio-context-provider'
import { OnboardingMediaScene } from '@/components/onboarding/onboarding-media-scene'
import { OnboardingStartScene } from '@/components/onboarding/onboarding-start-scene'
import { QuestionaryComplete } from '@/components/onboarding/questionary-complete'
import { QuestionaryControls } from '@/components/onboarding/questionary-controls'
import { DI_TYPE } from '@/di.types'
import { useViewModel } from '@/utils/view'
import type { OnboardingViewModel } from '@/view-models/onboarding'

import { OnboardingInterestsForm } from './interests-form'
import { OnboardingNameForm } from './name-form'
import { OnboardingWorkForm } from './work-form'

export const OnboardingPage = observer(() => {
  const vm = useViewModel<OnboardingViewModel>(DI_TYPE.OnboardingViewModel)

  // NOTE: we need the user to interact with the app before we can autoplay audio, if they happen to hit the onboarding page first, show an enter message
  // otherwise we should be able to autoplay
  const audioContext = useContext(AudioContext)
  const [audioEnabled, setAudioEnabled] = useState(audioContext.canAutoplay)
  const [canBeMuted, setCanBeMuted] = useState(false)
  const [isQuestionaryVisible, setQuestionaryVisible] = useState(false)

  const handleAudioClick = () => {
    setAudioEnabled(true)
  }

  useEffect(() => {
    audioEnabled && vm.startOnboarding()
  }, [audioEnabled, vm])

  if (!audioEnabled) {
    return (
      <>
        <OnboardingStartScene onStart={handleAudioClick} />
      </>
    )
  }

  return (
    <>
      {vm.initialized && (
        <OnboardingMediaScene
          onIntroComplete={() => setQuestionaryVisible(true)}
          onIntroSoundComplete={() => setCanBeMuted(true)}
        />
      )}

      {vm.initialized && isQuestionaryVisible && (
        <div className="absolute z-10 flex h-full w-full items-center justify-center">
          <AnimatePresence mode="wait">
            <motion.div
              id="onboarding-page"
              key={!vm.isComplete ? vm.currentStep.key : 'complete'}
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 100 }}
              transition={{ duration: 1 }}
            >
              {!vm.isComplete ? (
                <>
                  {vm.currentStep.key === 'name' && (
                    <OnboardingNameForm
                      data={vm.currentStep}
                      onSubmit={vm.submitAnswer}
                    />
                  )}

                  {vm.currentStep.key === 'work' && (
                    <OnboardingWorkForm
                      username={vm.username}
                      data={vm.currentStep}
                      onSubmit={vm.submitAnswer}
                    />
                  )}

                  {vm.currentStep.key === 'interests' && (
                    <OnboardingInterestsForm
                      username={vm.username}
                      data={vm.currentStep}
                      onSubmit={vm.submitAnswer}
                    />
                  )}
                </>
              ) : (
                <QuestionaryComplete onDone={vm.complete} />
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      )}

      {vm.initialized && isQuestionaryVisible && !vm.isComplete && (
        <QuestionaryControls
          className="absolute bottom-[60px] z-20 px-8 lg:px-[112px]"
          steps={vm.steps}
          currentStep={vm.currentStep}
          currentStepIndex={vm.currentStepIndex}
          onStepChange={vm.changeStep}
          onSkip={vm.skip}
          canBeMuted={canBeMuted}
        />
      )}
    </>
  )
})
