interface ISidebarSection {
  title: string
  button?: React.ReactNode
  children?: React.ReactNode
}

export const SidebarSection = ({
  title,
  button,
  children,
}: ISidebarSection) => {
  return (
    <section
      id="sidebar-section"
      className="mt-[64px] flex flex-grow flex-col overflow-hidden"
    >
      <div
        id="sidebar-section__header"
        className="mb-4 flex items-center justify-between px-2"
      >
        <label id="sidebar-section__label" className="text-sm font-medium">
          {title}
        </label>
        {button && (
          <div id="sidebar-section__button" className="flex">
            {button}
          </div>
        )}
      </div>
      <div
        id="sidebar-section__items-container"
        className="sidebar-custom-scrollbar box-border flex flex-grow flex-col gap-1 overflow-auto pl-2"
      >
        {children}
      </div>
    </section>
  )
}
