import { injected } from 'brandi'

import { DI_TYPE } from '@/di.types'
import type { ApiClient } from '@/services/api-client'

interface SpeechProps {
  token: string
  region: string
}

export class SpeechRepository {
  constructor(private apiClient: ApiClient) {}

  public async generateSpeechToken(): Promise<{
    data?: SpeechProps
    status: number
  }> {
    try {
      const { data, status } =
        await this.apiClient.get<SpeechProps>('/speech/token')
      return { data, status }
    } catch (error: any) {
      return { status: 500 }
    }
  }
}

injected(SpeechRepository, DI_TYPE.ApiClient)
