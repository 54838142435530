import type {
  MenuItemProps,
  MenuProps,
  MenuState,
  SubMenuProps,
} from '@szhsin/react-menu'
import {
  Menu as MenuInner,
  MenuDivider as MenuDividerInner,
  MenuItem as MenuItemInner,
  SubMenu as SubMenuInner,
} from '@szhsin/react-menu'

import ChevronRightIcon from '@/assets/icons/chevron-right.svg?react'
import { cls } from '@/utils/utils.js'

const menuClassName = (state: MenuState, minSize?: string) =>
  cls(
    'pointer-events-auto',
    'group-[.message-image]:sm:!bottom-auto group-[.message-image]:sm:!left-auto group-[.message-image]:sm:!right-auto group-[.message-image]:sm:p-[2px]',
    'group-[.message-image]:!fixed group-[.message-image]:!bottom-0 group-[.message-image]:!left-0 group-[.message-image]:!right-0 group-[.message-image]:!top-auto group-[.message-image]:sm:!absolute',
    'z-50 text-sm font-medium bg-cerosWhite border border-cerosGrey300 rounded-xl shadow-cerosLow select-none focus:outline-none',
    state === 'opening' && 'animate-fadeIn',
    state === 'closing' && 'animate-fadeOut',
    minSize && minSize,
  )

const menuItemClassName = ({ hover, disabled, submenu, other }: any) =>
  cls(
    'pointer-events-auto',
    'my-[2px] px-2 py-1 text-base mb-auto px-auto first:mt-auto',
    'flex justify-between items-center rounded-md focus:outline-none',
    hover && 'bg-cerosGrey100 cursor-pointer',
    disabled && 'text-cerosGrey400',
    submenu && 'flex items-center',
    other,
  )

interface IMenu extends MenuProps {
  minSize?: string
}

export const Menu = ({ minSize, ...rest }: IMenu) => (
  <MenuInner
    {...rest}
    menuClassName={({ state }) => menuClassName(state, minSize)}
  />
)

export const MenuItem = ({ className, ...rest }: MenuItemProps) => (
  <MenuItemInner
    {...rest}
    className={(values) => menuItemClassName({ ...values, other: className })}
  />
)

export const SubMenu = (props: SubMenuProps) => (
  <SubMenuInner
    {...props}
    label={
      <>
        {props.label}
        <ChevronRightIcon />
      </>
    }
    shift={-3}
    gap={8}
    className="relative"
    menuClassName={({ state }) => menuClassName(state)}
    itemProps={{ className: menuItemClassName }}
  />
)

interface IMenuDivider {
  className?: string
}

export const MenuDivider = ({ className }: IMenuDivider) => (
  <MenuDividerInner className={cls('bg-cerosGrey300 h-[1px]', className)} />
)
