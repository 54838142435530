import { injected } from 'brandi'

import { DI_TYPE } from '@/di.types.js'
import type { SearchBrand } from '@/models/brandfetch'
import type { ApiClient } from '@/services/api-client.js'

export class BrandfetchRepository {
  constructor(private apiClient: ApiClient) {}

  public async searchBrand(query: string): Promise<{
    data: SearchBrand[] | undefined
    status: number
  }> {
    try {
      const { data, status } = await this.apiClient.get<SearchBrand[]>(
        `https://api.brandfetch.io/v2/search/${query}`,
        {
          headers: { Authorization: null },
        },
      )
      return { data, status }
    } catch (error: any) {
      return { data: undefined, status: 500 }
    }
  }
}

injected(BrandfetchRepository, DI_TYPE.ApiClient)
