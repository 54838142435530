interface IUnsplashResultLink {
  query: string
  messageId?: string
}

export default function NounProjectResultLink({ query }: IUnsplashResultLink) {
  return (
    <span className="text-cerosBlack mt-2 text-sm">
      Here are some icons from{' '}
      <a
        className="text-cerosPrimary hover:text-cerosPrimaryHover hover:cursor-pointer"
        href={`https://thenounproject.com/search/icons/?q=${query}`}
        target="_blank"
        rel="noreferrer"
      >
        Noun Project
      </a>
    </span>
  )
}
