import { observer } from 'mobx-react-lite'

import SubmitIcon from '@/assets/icons/arrow-up.svg?react'
import MicrophoneIcon from '@/assets/icons/microphone.svg?react'
import MicrophoneStopIcon from '@/assets/icons/microphone-stop.svg?react'
import { Spinner } from '@/components/spinner/spinner.js'
import { Tooltip } from '@/components/tooltip/tooltip'
import { cls } from '@/utils/utils'

type ContextButtonProps = {
  microphoneLoading: boolean
  isRecording: boolean
  handleMicrophoneClick: () => void
  handleSubmit: () => void
  promptValue: string
  disabled?: boolean
  hasError?: boolean
  className?: string
}

export const ContextButton = observer(
  ({
    microphoneLoading,
    disabled,
    handleMicrophoneClick,
    handleSubmit,
    isRecording,
    promptValue,
    hasError,
    className,
  }: ContextButtonProps) => {
    const isPromptEmpty = !promptValue || promptValue.length === 0

    const onClick = () => {
      if (!isPromptEmpty && !isRecording) {
        return handleSubmit()
      }

      return handleMicrophoneClick()
    }

    return (
      <button
        disabled={microphoneLoading || disabled}
        type="button"
        onClick={onClick}
        className={cls(
          'relative flex h-12 w-12 shrink-0 items-center justify-center',
          'text-cerosGrey500 rounded-lg',
          'bg-cerosWhite font-medium outline-none',
          'hover:bg-cerosGrey100 active:bg-cerosGrey200 focus:ring-cerosPrimary focus:ring-1 disabled:opacity-50',
          isRecording &&
            'bg-cerosPrimary hover:bg-cerosPrimary active:bg-cerosPrimary hover:opacity-75 active:bg-opacity-50',
          isRecording && hasError && 'bg-cerosSecondaryPink',
          className,
        )}
      >
        {!isRecording && !isPromptEmpty && (
          <Tooltip
            text="Submit"
            placement="right"
            className="-translate-x-4 -translate-y-4 whitespace-nowrap"
          >
            <div className="absolute inset-0 flex items-center justify-center">
              <SubmitIcon className="h-6 w-6" />
            </div>
          </Tooltip>
        )}
        {microphoneLoading && <Spinner size="small" />}
        {!microphoneLoading && isRecording && (
          <Tooltip
            text="Stop recording"
            placement="right"
            className="-translate-y-4 whitespace-nowrap"
          >
            <div className="absolute inset-0 flex items-center justify-center">
              <MicrophoneStopIcon className="fill-cerosWhite h-3 w-3" />
            </div>
          </Tooltip>
        )}
        {!microphoneLoading && !isRecording && isPromptEmpty && (
          <Tooltip
            text="Click to talk to Gemma"
            placement="right"
            className="-translate-y-4 whitespace-nowrap"
          >
            <div className="absolute inset-0 flex items-center justify-center">
              <MicrophoneIcon className="h-6 w-6 stroke-black" />
            </div>
          </Tooltip>
        )}
      </button>
    )
  },
)
