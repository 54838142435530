import { injected } from 'brandi'
import { action, makeObservable } from 'mobx'
import { toast } from 'react-hot-toast'

import { DI_TYPE } from '@/di.types'
import type { RenderableConversationMessage } from '@/models/conversation'
import type { ReportType } from '@/services/mixpanel.js'
import { getMessageContentTypes } from '@/utils/message'
import { APP_VERSION, getAppOrigin, readableAppOrigin } from '@/utils/utils'

import type { Api } from './api'
import { BaseService } from './base-service'
import type { MixpanelService } from './mixpanel'

export interface CreateFeedbackForm {
  plugin_version: string
  type: 'Feature Request' | 'Bug Report' | 'Comment' | 'Question'
  origin: string
  message: string
}

export interface CreateReportForm {
  isNotSafe: boolean
  isNotAccurate: boolean
  isNotHelpful: boolean
  list?: ReportType[]
  message: string
}

export type SendFeedbackParams = {
  type: CreateFeedbackForm['type']
  message: string
  conversationId?: string
}

export class SupportService extends BaseService {
  constructor(
    private api: Api,
    private mixpanelService: MixpanelService,
  ) {
    super()
    makeObservable(this)
  }

  @action.bound
  async sendFeedback({ conversationId, type, message }: SendFeedbackParams) {
    const { status } = await this.api.support.feedback({
      body: {
        feedback: {
          plugin_version: APP_VERSION,
          message,
          type,
          origin: readableAppOrigin(getAppOrigin()),
        },
        conversation_id: conversationId,
      },
    })

    if (status >= 200 && status < 400) {
      this.mixpanelService.trackFeedbackSent(
        conversationId ?? '',
        type,
        message,
      )
      toast.success('Feedback sent')
    } else {
      toast.error('Failed to send feedback')
    }
  }

  @action.bound
  async sendReport(
    conversationId: string,
    report: CreateReportForm,
    message: RenderableConversationMessage,
  ) {
    const { status } = await this.api.support.report({
      body: {
        report,
        message_id: message.id,
      },
    })

    if (status >= 200 && status < 400) {
      toast.success('Report sent')
      this.mixpanelService.trackResultReported({
        chatId: conversationId,
        messageId: message.id,
        messageContentTypes: getMessageContentTypes(message),
        respondingToId: message.responding_to_id,
        reportMessage: report.message,
        reportType: report.list,
      })
    } else {
      toast.error('Failed to send report')
    }
  }

  @action.bound
  async sendReportGem(
    conversationId: string,
    gemId: string,
    report: CreateReportForm,
  ) {
    const { status } = await this.api.support.report({
      body: {
        report,
        conversation_id: conversationId,
        gem_id: gemId,
      },
    })

    if (status >= 200 && status < 400) {
      toast.success('Report sent')
      this.mixpanelService.trackResultReported({
        chatId: conversationId,
        gemId: gemId,
        reportMessage: report.message,
        reportType: report.list,
      })
    } else {
      toast.error('Failed to send report')
    }
  }
}

injected(SupportService, DI_TYPE.Api, DI_TYPE.MixpanelService)
