import { observer } from 'mobx-react-lite'

import { Button } from '@/components/button/button'

export const LoginSuccessPage = observer(() => {
  return (
    <div className="mx-auto flex max-w-[400px] flex-col">
      <h1 className="text-cerosBlack	mb-8 mt-16 text-3xl font-bold">Success</h1>
      <p className="mb-8 text-sm">
        You’re now signed into Gemma. You can close this window.
      </p>
      <div>
        {window.opener && (
          <Button variant="primary" size="large" onClick={() => window.close()}>
            Close window
          </Button>
        )}
      </div>
    </div>
  )
})

LoginSuccessPage.displayName = 'LoginSuccessPage'
