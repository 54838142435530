import { injected } from 'brandi'
import { action, computed, makeObservable, observable, reaction } from 'mobx'

import { DI_TYPE } from '@/di.types'
import type { Prompt } from '@/stores/prompt'

import type { VoiceInputService } from '../services/voice-input'
import { BaseViewModel } from './base-view-model'

export const MAX_PROMPT_LENGTH = 30_000

export class PromptInputViewModel extends BaseViewModel {
  @observable pendingPrompt?: string

  constructor(
    private prompt: Prompt,
    private readonly voiceInputService: VoiceInputService,
  ) {
    super()
    makeObservable(this)
  }

  protected onInit(): void {
    this.voiceInputService._start()

    reaction(
      () => this.voiceInputService.recognizingText,
      this.onSpeechRecognizing,
    )

    reaction(
      () => this.voiceInputService.recognizedText,
      this.onSpeechRecognized,
    )
  }

  protected onDispose(): void {
    this.voiceInputService._stop()
    this.prompt.reset()
  }

  @action.bound
  onPromptChange(value?: string) {
    this.prompt.text = value ?? ''
  }

  @action.bound
  onPromptSubmit(
    callback: (prompt: Prompt) => void,
    options?: { isVoiceInput: boolean },
  ) {
    const origin = options?.isVoiceInput ? 'VOICE_INPUT' : 'TEXT'

    if (this.prompt.text == null) {
      return
    }

    this.prompt.origin = origin

    callback(this.prompt)
    this.prompt.reset()
  }

  @action.bound
  onSpeechRecognized(text?: string) {
    if (!text) return
    const isEndOfSentence =
      this.prompt.text?.endsWith('.') ||
      this.prompt.text?.endsWith('?') ||
      this.prompt.text?.endsWith('!')

    if (this.prompt.text) {
      if (isEndOfSentence && !text?.startsWith(' ')) {
        text = ' ' + text
      }
      this.prompt.text += text
    } else {
      this.prompt.text = text
    }

    this.pendingPrompt = undefined
  }

  @action.bound
  onSpeechRecognizing(text?: string) {
    if (!text) return
    this.pendingPrompt = text
  }

  @action.bound
  async voiceInputStart() {
    this.voiceInputService.voiceInputStart()
  }

  @action.bound
  async voiceInputStopAndSubmit(callback: (prompt: Prompt) => void) {
    await this.voiceInputService.voiceInputStop()
    this.onPromptSubmit(callback, { isVoiceInput: true })
  }

  @computed
  get promptText() {
    return (this.prompt.text ?? '') + (this.pendingPrompt ?? '')
  }

  @computed
  get speechInitializing() {
    return this.voiceInputService.speechInitializing
  }

  @computed
  get isRecording() {
    return this.voiceInputService.speechRecording
  }

  @computed
  get recognizingText() {
    return this.voiceInputService.recognizingText
  }

  @computed
  get recognizedText() {
    return this.voiceInputService.recognizedText
  }

  @computed
  get speechRecordingStarted() {
    return this.voiceInputService.speechRecordingStarted
  }

  @computed
  get speechRecordingEnded() {
    return this.voiceInputService.speechRecordingEnded
  }
}

injected(PromptInputViewModel, DI_TYPE.Prompt, DI_TYPE.VoiceInputService)
