import { z } from 'zod';
const ErrorDetail = z.object({
    loc: z.array(z.string()),
    msg: z.string(),
    type: z.string(),
});
export const errorResponseSchema = z.object({
    code: z.string().optional(),
    detail: z.string(),
});
export const unprocessableContentResponseSchema = z.object({
    detail: z.array(ErrorDetail),
});
