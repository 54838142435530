import { forwardRef } from 'react'

import { cls } from '@/utils/utils.js'

export interface ILabeledTextInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'type'> {
  label?: string
}

export const LabeledTextInput = forwardRef<
  HTMLInputElement,
  ILabeledTextInputProps
>(
  (
    { className, placeholder, name, defaultValue, value, label, ...rest },
    ref,
  ) => {
    return (
      <label className="bg-cerosWhite focus-within:border-cerosPrimary hover:bg-cerosGrey100 focus-within:bg-cerosWhite focus-within:hover:bg-cerosWhite  border-cerosGrey200  flex items-baseline gap-2 rounded-lg border py-[10px] pl-[16px] pr-[8px]">
        {label && (
          <span className="text-cerosGrey400 whitespace-nowrap bg-transparent text-sm font-medium leading-[28px]">
            {label}
          </span>
        )}
        <input
          autoComplete="off"
          className={cls(
            'h-[28px] w-[100%] rounded-md bg-transparent text-sm font-medium outline-none',
            'disabled:bg-cerosGrey100 disabled:text-cerosGrey400 disabled:hover:border-cerosGrey400 disabled:focus:border-cerosGrey400 disabled:active:border-cerosGrey400 autofill:bg-transparent disabled:cursor-not-allowed',
            className,
          )}
          ref={ref}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          name={name}
          {...rest}
        />
      </label>
    )
  },
)
