import { useGlobalAudioPlayer } from 'react-use-audio-player'

import { cls } from '@/utils/utils'
import type { StepData } from '@/view-models/onboarding'

import SoundOffIcon from '../../assets/icons/sound-off.svg?react'
import SoundOnIcon from '../../assets/icons/sound-on.svg?react'
import { IconButton } from '../icon-button/icon-button'

interface IQuestionaryControls {
  className?: string
  steps: StepData[]
  currentStep: StepData
  currentStepIndex: number
  onStepChange: (index: number) => void
  onSkip: () => void
  canBeMuted?: boolean
}

export function QuestionaryControls({
  className,
  steps,
  currentStep,
  currentStepIndex,
  onStepChange,
  onSkip,
  canBeMuted,
}: IQuestionaryControls) {
  const { muted, mute, fade, stop } = useGlobalAudioPlayer()

  const handleMute = () => mute(!muted)

  const handleSkip = () => {
    onSkip()
    fade(100, 0, 500)
    setTimeout(() => {
      stop()
    }, 500)
  }

  return (
    <div className={cls('flex w-full items-center justify-between', className)}>
      <IconButton
        className={cls(
          'invisible h-7 w-7 p-[6px] hover:bg-[#e9ebec80] hover:backdrop-blur-md active:bg-[#dcdfe1cc] active:backdrop-blur-md',
          canBeMuted && 'visible',
        )}
        icon={muted ? <SoundOffIcon /> : <SoundOnIcon />}
        onClick={handleMute}
        disabled={!canBeMuted}
      />

      <div className="flex gap-4">
        {steps.map((step, index) => (
          <button
            key={step.key}
            disabled={!step.form.isSubmitted}
            onClick={() => onStepChange(index)}
          >
            <span
              className={cls(
                'bg-cerosGrey300 my-2 block h-1 w-8 rounded',
                step.form.isSubmitted &&
                  currentStep.form.isValid &&
                  'hover:bg-cerosGrey400',
                currentStepIndex >= index &&
                  'bg-cerosPrimary hover:bg-cerosPrimary',
              )}
            />
          </button>
        ))}
      </div>
      <button
        className="flex h-7 items-center justify-center rounded-md p-[6px] text-sm hover:bg-[#e9ebec80] hover:backdrop-blur-md active:bg-[#dcdfe1cc] active:backdrop-blur-md"
        onClick={handleSkip}
        data-automation="onboarding-skip-btn"
      >
        Skip
      </button>
    </div>
  )
}
