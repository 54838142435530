import type { BillingUserSettings } from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import { captureException } from 'logrocket'
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { toast } from 'react-hot-toast'

import { DI_TYPE } from '@/di.types'
import type { Interests, Roles, UserProperties } from '@/models/user'
import type { UserRepository } from '@/repositories/user'

import type { AuthService } from './auth'
import { BaseService } from './base-service'
import type { MixpanelService } from './mixpanel'

export class UserService extends BaseService {
  @observable properties: UserProperties | undefined = undefined
  @observable availableRoles: Roles | undefined = undefined
  @observable availableInterests: Interests | undefined = undefined
  @observable subscription: BillingUserSettings | undefined = undefined

  constructor(
    private authService: AuthService,
    private userRepository: UserRepository,
    private mixpanelService: MixpanelService,
  ) {
    super()
    makeObservable(this)
    this.init()
  }

  async init() {
    reaction(
      () => this.authService.isAuthenticated,
      (isAuthenticated) => {
        if (isAuthenticated) {
          this.updateAuthenticatedUserProperties()
          this.getAvailableRoles()
          this.getAvailableInterests()
          this.getSubscriptionData()
        } else {
          runInAction(() => {
            this.properties = undefined
          })
        }
      },
      {
        fireImmediately: true,
      },
    )

    runInAction(() => {
      /** @TODO move roles/interests to profile vm */
      this.initialized = true
    })
  }

  @computed
  private get username() {
    if (this.properties) {
      const { name, email } = this.properties

      return name || (email || '').split('@')[0]
    }

    return 'User'
  }

  @computed
  private get firstname() {
    if (!this.properties) return undefined
    const { name, email } = this.properties

    if (name) {
      return name.split(' ')[0]
    }

    if (email) {
      return email.split('@')[0].split('.')[0]
    }

    return undefined
  }

  @computed
  private get initials() {
    return this.username.slice(0, 2).toUpperCase()
  }

  @computed
  private get jobTitle() {
    return this.properties?.job_title
  }

  @computed
  get userData() {
    return {
      id: this.authService.auth?.data.user.id, // TODO: user id can be undefined in this implementation. We should fix it
      name: this.username,
      firstname: this.firstname,
      initials: this.initials,
      jobTitle: this.jobTitle,
    }
  }

  @computed
  get shouldForceOnboarding() {
    return (
      !this.properties?.onboarding_skipped &&
      this.properties?.onboarding_started_at === null
    )
  }

  @computed
  get isFetchingUserProperties() {
    return !!this.authService.auth && !this.properties
  }

  @computed
  get shouldForceTermsPage() {
    return (
      !!this.properties &&
      (!this.properties.tac_accepted_at ||
        !this.properties.privacy_policy_accepted_at)
    )
  }

  private applyUserPropertiesToMixpanel(
    properties: UserProperties | undefined,
  ) {
    if (properties && this.userData.id !== undefined) {
      this.mixpanelService.applyUserProperties(this.userData.id, properties)
    }
  }

  async updateAuthenticatedUserProperties() {
    const { data, status } = await this.userRepository.getUserProperties()

    if (status === 200) {
      runInAction(() => {
        // this.initializedProperties.resolve()
        toast.dismiss('user-property-fetch-error')
        this.applyUserPropertiesToMixpanel(data)
        this.properties = data
      })
    } else {
      captureException(new Error('Failed to fetch user properties'))
      toast.error('Failed to fetch user properties', {
        id: 'user-property-fetch-error',
        duration: Infinity,
      })
    }
  }

  async startOnboarding() {
    const { status } = await this.userRepository.onboardingStarted()
    if (status >= 200 && status < 300) {
      await this.updateAuthenticatedUserProperties()
    }
  }

  async skipOnboarding() {
    const { status } = await this.userRepository.onboardingSkipped()
    if (status >= 200 && status < 300) {
      if (this.properties) {
        this.properties.onboarding_skipped = true
        this.applyUserPropertiesToMixpanel(this.properties)
      }
    }
  }

  async updateProperties(props: FormData) {
    const { data, status } =
      await this.userRepository.updateUserProperties(props)
    if (status === 200 && data) {
      this.applyUserPropertiesToMixpanel(data)
      this.setProperties(data)
      return data
    }
    return undefined
  }

  @action.bound
  async getProperties() {
    const { data, status } = await this.userRepository.getUserProperties()
    if (status === 200 && data) {
      this.setProperties(data)
    }
  }

  @action.bound
  async getAvailableRoles() {
    const { data, status } = await this.userRepository.getUserAvailableRoles()
    if (status === 200 && data) {
      this.setRoles(data)
    }
  }

  @action.bound
  async getAvailableInterests() {
    const { data, status } =
      await this.userRepository.getUserAvailableInterests()
    if (status === 200 && data) {
      this.setInterests(data)
    }
  }

  @action.bound
  async getSubscriptionData() {
    const subscription = await this.userRepository.getUserSubscriptionData()

    if (!subscription) {
      toast.error('Could not fetch subscription data')
      return
    }

    this.mixpanelService.applyBillingProperties(subscription)

    runInAction(() => {
      this.subscription = subscription
    })
  }

  getSubscriptionPlanUpgradeSession() {
    return this.userRepository.getStripeCheckoutSession()
  }

  completePlanUpgrade(sessionId: string) {
    return this.userRepository.completeStripeCheckout(sessionId)
  }

  getManagementPortalSessionUrl() {
    return this.userRepository.getStripePortalSession()
  }

  private setProperties(props: UserProperties) {
    runInAction(() => {
      this.properties = props
    })
  }

  private setRoles(roles: Roles) {
    runInAction(() => {
      this.availableRoles = roles
    })
  }

  private setInterests(interests: Interests) {
    runInAction(() => {
      this.availableInterests = interests
    })
  }
}

injected(
  UserService,
  DI_TYPE.AuthService,
  DI_TYPE.UserRepository,
  DI_TYPE.MixpanelService,
)
