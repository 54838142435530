import { AppFooter } from '@/components/app-footer/app-footer.js'
import { Spinner } from '@/components/spinner/spinner.js'

interface IProcessScreen {
  text?: string
}

export function ProcessScreen({ text }: IProcessScreen) {
  return (
    <main className="flex h-screen w-full flex-col">
      <section className="flex flex-auto flex-col items-center justify-center">
        <Spinner size="large" />
        {text && (
          <p className="text-cerosGrey400 mt-2 text-xs font-medium">{text}</p>
        )}
      </section>

      <AppFooter />
    </main>
  )
}
