import { observer } from 'mobx-react-lite'

import SparklesIcon from '@/assets/icons/sparkles.svg?react'
import { cls } from '@/utils/utils'

type UpgradeButtonProps = {
  onClick: () => void
  className?: string
}

export const UpgradeButton = observer(
  ({ onClick, className }: UpgradeButtonProps) => {
    return (
      <button
        onClick={onClick}
        className={cls(
          'flex h-12 w-full items-center justify-start gap-2 overflow-hidden rounded-lg bg-gradient-to-r from-stone-200 to-indigo-200 pl-[10px] text-sm font-medium',
          className,
        )}
      >
        <div className="bg-cerosWhite flex h-7 w-7 items-center justify-center rounded-full shadow-[0_0_4px_2px_#FFFFFF40]">
          <SparklesIcon />
        </div>
        Upgrade to Gemma Pro
      </button>
    )
  },
)

UpgradeButton.displayName = 'UpgradeButton'
