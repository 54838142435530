import type { Gem } from '@ceros/gemma-api-spec'
import type OktaAuth from '@okta/okta-auth-js'
import type { Factory } from 'brandi'
import { token } from 'brandi'

import type { AuthRepository } from './repositories/auth.js'
import type { BrandfetchRepository } from './repositories/brandfetch.js'
import type { EditImageRepository } from './repositories/edit-image.js'
import type { FileUploadRepository } from './repositories/file-upload.js'
import type { NounProjectRepository } from './repositories/noun-project.js'
import type { SpeechRepository } from './repositories/speech.js'
import type { TasksRepository } from './repositories/tasks.js'
import type { UnsplashRepository } from './repositories/unsplash.js'
import type { UserRepository } from './repositories/user.js'
import type { Api } from './services/api.js'
import type { ApiClient } from './services/api-client.js'
import type { AuthService } from './services/auth.js'
import type { Auth0Service } from './services/auth0.js'
import type { ConversationStreamService } from './services/conversation-stream.js'
import type { ConversationsService } from './services/conversations.js'
import type { EventBusService } from './services/event-bus.js'
import type { FeatureFlagService } from './services/feature-flag.js'
import type { FileProcessService } from './services/file-process.js'
import type { GemsService } from './services/gems.js'
import type { InitService } from './services/init.js'
import {
  type GetAndRemoveLoginReturn,
  type GetLoginDataForState,
  type SaveLoginData,
  type SaveLoginReturn,
} from './services/login.js'
import type { LogRocketService } from './services/logrocket.js'
import type { MixpanelService } from './services/mixpanel.js'
import type { ModalService } from './services/modal.js'
import type { OktaIdpService } from './services/okta-idp.js'
import type { RouterService } from './services/router.js'
import type { SentryService } from './services/sentry.js'
import type { SidebarService } from './services/sidebar.js'
import type { StorageService } from './services/storage.js'
import type { SupportService } from './services/support.js'
import type { UserService } from './services/user.js'
import type { VoiceInputService } from './services/voice-input.js'
import type { Prompt } from './stores/prompt.js'
import type { ActiveGemViewModel } from './view-models/active-gem.js'
import type { AuthCallbackViewModel } from './view-models/auth-callback.js'
import type { BrandfetchViewModel } from './view-models/brandfetch.js'
import type { ConversationViewModel } from './view-models/conversation.js'
import type { ConversationCloneViewModel } from './view-models/conversation-clone.js'
import type { ConversationLatestViewModel } from './view-models/conversation-latest.js'
import type { ConversationNewViewModel } from './view-models/conversation-new.js'
import type { ConversationReadonlyViewModel } from './view-models/conversation-readonly.js'
import type { EditImageViewModel } from './view-models/edit-image.js'
import type { EmbedStudioNewViewModel } from './view-models/embed-studio-new.js'
import type { FeatureFlagViewModel } from './view-models/feature-flag.js'
import type { FileUploadViewModel } from './view-models/file-upload.js'
import type { GemViewModel } from './view-models/gem.js'
import type { GemModalViewModel } from './view-models/gem-modal.js'
import type { GemPromptInputViewModel } from './view-models/gem-prompt-input.js'
import type { HomePageViewModel } from './view-models/home-page.js'
import type { LoginViewModel } from './view-models/login.js'
import type { NounProjectViewModel } from './view-models/noun-project.js'
import type { OnboardingViewModel } from './view-models/onboarding.js'
import type { PasswordResetViewModel } from './view-models/password-reset.js'
import type { ProfileViewModel } from './view-models/profile.js'
import type { PromptInputViewModel } from './view-models/prompt-input.js'
import type { PromptsViewModel } from './view-models/prompts.js'
import type { RequiredUserChecks } from './view-models/required-user-checks.js'
import type { SessionViewModel } from './view-models/session.js'
import type { SignUpViewModel } from './view-models/sign-up.js'
import type { SocialLoginViewModel } from './view-models/social-login.js'
import type { SubscriptionPlanViewModel } from './view-models/subscription-plan.js'
import type { TermsViewModel } from './view-models/terms.js'
import type { UnsplashViewModel } from './view-models/unsplash.js'
import type { WithUserProperties } from './view-models/with-user-properties.js'

export const DI_TYPE = {
  // Services
  InitService: token<InitService>('InitService'),
  StorageService: token<StorageService>('StorageService'),
  AuthService: token<AuthService>('AuthService'),
  Auth0Service: token<Auth0Service>('Auth0Service'),
  ApiClient: token<ApiClient>('ApiClient'),
  Api: token<Api>('Api'),
  GemsService: token<GemsService>('GemsService'),
  SupportService: token<SupportService>('SupportService'),
  MixpanelService: token<MixpanelService>('MixpanelService'),
  ModalService: token<ModalService>('ModalService'),
  RouterService: token<RouterService>('RouterService'),
  LogRocketService: token<LogRocketService>('LogRocketService'),
  SentryService: token<SentryService>('SentryService'),
  EventBusService: token<EventBusService>('EventBusService'),
  OktaAuth: token<OktaAuth>('OktaAuth'),
  OktaIdpService: token<OktaIdpService>('OktaIdpService'),
  SidebarService: token<SidebarService>('SidebarService'),
  ConversationsService: token<ConversationsService>('ConversationsService'),
  FeatureFlagService: token<FeatureFlagService>('FeatureFlagService'),
  FileProcessService: token<FileProcessService>('FileProcessService'),
  UserService: token<UserService>('UserService'),
  VoiceInputService: token<VoiceInputService>('VoiceInputService'),
  ConversationStreamService: token<ConversationStreamService>(
    'ConversationStreamService',
  ),

  // Repositories
  AuthRepository: token<AuthRepository>('AuthRepository'),
  UnsplashRepository: token<UnsplashRepository>('UnsplashRepository'),
  NounProjectRepository: token<NounProjectRepository>('NounProjectRepository'),
  EditImageRepository: token<EditImageRepository>('EditImageRepository'),
  BrandfetchRepository: token<BrandfetchRepository>('BrandfetchRepository'),
  UserRepository: token<UserRepository>('UserRepository'),
  FileUploadRepository: token<FileUploadRepository>('FileUploadRepository'),
  TasksRepository: token<TasksRepository>('TasksRepository'),
  SpeechRepository: token<SpeechRepository>('SpeechRepository'),

  // ViewModels
  LoginViewModel: token<LoginViewModel>('LoginViewModel'),
  SignUpViewModel: token<SignUpViewModel>('SignUpViewModel'),
  PasswordResetViewModel: token<PasswordResetViewModel>(
    'PasswordResetViewModel',
  ),
  FeatureFlagViewModel: token<FeatureFlagViewModel>('FeatureFlagViewModel'),
  TermsViewModel: token<TermsViewModel>('TermsViewModel'),
  AuthCallbackViewModel: token<AuthCallbackViewModel>('AuthCallbackViewModel'),
  UnsplashViewModel: token<UnsplashViewModel>('UnsplashViewModel'),
  NounProjectViewModel: token<
    Factory<
      NounProjectViewModel,
      [messageId: string | undefined, query: string | undefined]
    >
  >('Factory<NounProjectViewModel>'),
  EditImageViewModel: token<EditImageViewModel>('EditImageViewModel'),
  BrandfetchViewModel: token<
    Factory<
      BrandfetchViewModel,
      [messageId: string | undefined, query: string | undefined]
    >
  >('Factory<BrandfetchViewModel>'),
  OnboardingViewModel: token<OnboardingViewModel>('OnboardingViewModel'),
  SocialLoginViewModel: token<SocialLoginViewModel>('SocialLoginViewModel'),
  ProfileViewModel: token<ProfileViewModel>('ProfileViewModel'),
  GemModalViewModel: token<Factory<GemModalViewModel, [gemToUpdate?: Gem]>>(
    'Factory<GemModalViewModel>',
  ),
  ConversationViewModel: token<
    Factory<ConversationViewModel, [id: string | undefined]>
  >('Factory<ConversationViewModel>'),
  ConversationNewViewModel: token<ConversationNewViewModel>(
    'ConversationNewViewModel',
  ),
  ConversationLatestViewModel: token<ConversationLatestViewModel>(
    'ConversationLatestViewModel',
  ),
  ConversationReadonlyViewModel: token<
    Factory<ConversationReadonlyViewModel, [id: string | undefined]>
  >('Factory<ConversationReadonlyViewModel>'),
  ConversationCloneViewModel: token<
    Factory<ConversationCloneViewModel, [id: string]>
  >('Factory<ConversationCloneViewModel>'),
  GemViewModel: token<Factory<GemViewModel, [id: string | undefined]>>(
    'Factory<GemViewModel>',
  ),
  ActiveGemViewModel: token<ActiveGemViewModel>('ActiveGemViewModel'),
  FileUploadViewModel: token<
    Factory<
      FileUploadViewModel,
      [
        successCallback: (file: File) => Promise<void>,
        failCallback: (file: File, reason?: string) => void,
      ]
    >
  >('Factory<FileUploadViewModel>'),
  PromptViewModel: token<PromptsViewModel>('PromptViewModel'),
  PromptInputViewModel: token<PromptInputViewModel>('PromptInputViewModel'),
  GemPromptInputViewModel: token<GemPromptInputViewModel>(
    'GemPromptInputViewModel',
  ),
  EmbedStudioNewViewModel: token<EmbedStudioNewViewModel>(
    'EmbedStudioNewViewModel',
  ),
  HomePageViewModel: token<HomePageViewModel>('HomePageViewModel'),
  WithUserProperties: token<WithUserProperties>('WithUserProperties'),
  RequiredUserChecks: token<RequiredUserChecks>('RequiredUserChecks'),
  SessionViewModel: token<Factory<SessionViewModel, [id: string]>>(
    'Factory<SessionViewModel>',
  ),
  SubscriptionPlanViewModel: token<SubscriptionPlanViewModel>(
    'SubscriptionPlanViewModel',
  ),

  // Stores
  Prompt: token<Prompt>('PromptStore'),

  // Functions
  SaveLoginData: token<SaveLoginData>('SaveLoginData'),
  GetLoginDataForState: token<GetLoginDataForState>('GetLoginDataForState'),
  SaveLoginReturn: token<SaveLoginReturn>('SaveLoginReturn'),
  GetAndRemoveLoginReturn: token<GetAndRemoveLoginReturn>(
    'GetAndRemoveLoginReturn',
  ),
}
