import type { InvokeToolType } from '@ceros/gemma-api-spec'
import { MenuButton } from '@szhsin/react-menu'
import { useEffect, useRef, useState } from 'react'

import MoreIcon from '@/assets/icons/more.svg?react'
import type { ImageAttachmentObject } from '@/models/conversation.js'
import { useIsMobile } from '@/utils/use-is-mobile.js'
import { cls, createFileFromImageUrl } from '@/utils/utils.js'
import type { DrugEventValue } from '@/utils/view.js'

import { Menu, MenuItem, SubMenu } from '../menu/menu.js'

const upscaleRatios: Array<'2' | '4' | '8'> = ['2', '4', '8']

interface IImageAttachment {
  image: ImageAttachmentObject
  className?: string
  onTask?: (tool: InvokeToolType) => void
  onDrag?: (event: DrugEventValue) => void
  onDragEnd?: (event: DrugEventValue) => void
  onDragStart?: (event: DrugEventValue) => void
  openEdit?: () => void
  onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void
  editBlocked?: boolean
  dataAutomation?: string
  includeImageData: boolean
  onCopyUrl?: () => void
  onDownload?: () => void
  hideMenu?: boolean
}

export const ImageAttachment = ({
  image,
  className,
  editBlocked,
  onTask,
  onDrag,
  onDragStart,
  onDragEnd,
  openEdit,
  onClick,
  dataAutomation,
  includeImageData,
  onCopyUrl,
  onDownload,
  hideMenu,
}: IImageAttachment) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const file = useDataFromUrl(includeImageData ? image.url : null)
  const isMobile = useIsMobile()

  const handleDrag = (e: React.DragEvent<unknown>) => {
    onDrag?.({
      clientX: e.clientX,
      clientY: e.clientY,
      data: file,
      native: e.nativeEvent,
    })
  }

  const handleDragStart = (e: React.DragEvent<unknown>) => {
    onDragStart?.({
      clientX: e.clientX,
      clientY: e.clientY,
      data: file,
      native: e.nativeEvent,
    })
  }

  const handleDragEnd = (e: React.DragEvent<unknown>) => {
    onDragEnd?.({
      clientX: e.clientX,
      clientY: e.clientY,
      data: file,
      native: e.nativeEvent,
    })
  }

  return (
    <div
      data-automation={dataAutomation}
      className={cls(
        'group/messageImage hover/messageImage:bg-cerosBlack relative h-[200px] w-[276px] shrink-0 rounded-lg',
        !editBlocked && 'cursor-pointer',
        isMenuOpen && 'bg-cerosBlack',
        'message-image group',
        className,
      )}
    >
      <img
        className={cls(
          'bg-cerosGrey100 h-full w-full rounded-lg object-contain group-hover/messageImage:opacity-60',
          isMenuOpen && 'opacity-60',
        )}
        src={image.url}
        alt={image.alt}
        draggable={true}
        onClick={onClick}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      />

      <div
        data-automation="image-actions"
        className={cls(
          'invisible absolute right-1 top-1 group-hover/messageImage:visible',
          isMenuOpen && 'visible',
        )}
      >
        {hideMenu === false && (
          <Menu
            align="start"
            direction="left"
            minSize={'min-w-[150px]'}
            arrow={true}
            portal={!isMobile}
            arrowProps={{ className: 'w-2 h-2' }}
            viewScroll="initial"
            overflow="hidden"
            menuButton={
              <MenuButton className="bg-cerosGrey100 active:bg-cerosGrey200 h-6 w-6 rounded-[4px] p-1">
                <MoreIcon />
              </MenuButton>
            }
            onMenuChange={({ open }) => setIsMenuOpen(open)}
          >
            {!editBlocked && (
              <>
                <MenuItem onClick={openEdit}>Edit</MenuItem>

                <MenuItem
                  onClick={() =>
                    onTask?.({ type: 'remove_bg', image: { url: image.url } })
                  }
                >
                  Remove BG
                </MenuItem>

                <SubMenu label="Upscale" direction="right">
                  {upscaleRatios.map((v) => (
                    <MenuItem
                      key={v}
                      onClick={() =>
                        onTask?.({
                          type: 'upscale',
                          scale: v,
                          image: { url: image.url },
                        })
                      }
                    >
                      {v}x
                    </MenuItem>
                  ))}
                </SubMenu>
              </>
            )}

            <MenuItem onClick={onDownload}>Download</MenuItem>
            <MenuItem onClick={onCopyUrl}>Copy URL</MenuItem>
          </Menu>
        )}
      </div>
    </div>
  )
}

function useDataFromUrl(url: string | null) {
  const lastUrl = useRef<string | null>()
  const [fileState, setFileState] = useState<{
    file: { data: ArrayBuffer; filename: string }
    url: string
  } | null>()
  useEffect(() => {
    lastUrl.current = url
    if (!url) return
    ;(async () => {
      const file = await createFileFromImageUrl(url)
      if (lastUrl.current !== url) return
      const buffer = await file.arrayBuffer()
      if (lastUrl.current !== url) return
      setFileState({ file: { data: buffer, filename: file.name }, url })
    })()
  }, [url])
  return fileState?.url === url ? fileState?.file : null
}
