// This is the page shown to users that don't have access to Gemma via Okta assignment yet
export const LoginUnassignedPage = () => {
  return (
    <div className="grid-cols-auto grid h-[540px] grid-rows-[1fr_min-content]">
      <div className="mx-auto mb-14 flex w-full max-w-[400px]">
        <div className="flex w-full flex-col">
          <h1 className="text-cerosBlack mb-8 text-3xl font-bold md:mt-14">
            Early access only
          </h1>
          <div className="text-md text-cerosGrey600">
            <p>
              Currently, Gemma is in early access and is only available to a
              select group of users. If you think you received this error by
              mistake, please reach out to your Ceros account executive.
            </p>
            <br />
            Interested in learning more?{' '}
            <a
              href="https://www.ceros.com/demo-request/"
              target="_blank"
              className="text-cerosPrimary"
              rel="noreferrer"
            >
              Request a demo
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

LoginUnassignedPage.displayName = 'LoginUnassignedPage'
