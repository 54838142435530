import { type ReactNode, useEffect, useRef, useState } from 'react'

import { cls } from '@/utils/utils'

type AnimatedBorderProps = {
  borderWidth?: number
  children: ReactNode
  className?: string
}

const SCALING_COEFFICIENT = 1.6

export const AnimatedBorder = ({
  borderWidth = 1,
  children,
  className,
}: AnimatedBorderProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const [borderRadius, setBorderRadius] = useState(0)

  useEffect(() => {
    if (!ref.current?.firstChild) return

    const childStyles = ref.current
      ? window.getComputedStyle(ref.current.firstChild as Element)
      : ({} as Partial<CSSStyleDeclaration>)

    const childHeight = Number(childStyles.height?.split('px')[0]) ?? 0
    const childWidth = Number(childStyles.width?.split('px')[0]) ?? 0
    const borderRadius = Number(childStyles.borderRadius?.split('px')[0]) ?? 0

    setHeight(childHeight)
    setWidth(childWidth)

    setBorderRadius(borderRadius)
  }, [])

  const longEdgeLength = Math.max(height, width)
  const size = longEdgeLength * SCALING_COEFFICIENT

  const yOffset = (size - height) / -2
  const xOffset = (size - width) / -2

  return (
    <div
      className="group/animated-border relative left-0 top-0 overflow-hidden"
      style={{
        borderRadius: borderRadius + borderWidth, // border radius of the child + borderWidth to adjust for padding
        padding: borderWidth,
      }}
    >
      <div
        className={cls(
          `border-animation bg-brand-grad absolute`,
          'animate-spin',
          className,
        )}
        style={{
          width: size,
          height: size,
          top: yOffset,
          left: xOffset,
        }}
      />
      <div ref={ref} className="relative" style={{ height }}>
        {children}
      </div>
    </div>
  )
}
