import { injected } from 'brandi'

export type EventBusPayload<T> = {
  detail: T
}

type EventBusCallback<T> = (event: EventBusPayload<T>) => void | Promise<void>

export enum RegisteredEvents {
  LogRocketGetSessionURL = 'logrocket-get-session-url',
  ConversationsLoaded = 'conversations-loaded',
  ConversationLoaded = 'conversation-loaded',
  ConversationRenamed = 'conversation-renamed',
  ConversationCreated = 'conversation-created',
  ConversationUpdated = 'conversation-updated',
  ConversationDeleted = 'conversation-deleted',
  SelectTask = 'select-task',
  LocationChange = 'location-change',
}

export class EventBusService {
  private eventTarget: EventTarget
  private eventBuilder = {
    [RegisteredEvents.LogRocketGetSessionURL]: (payload: any) =>
      new CustomEvent(RegisteredEvents.LogRocketGetSessionURL, {
        detail: { ...payload },
      }),
    [RegisteredEvents.ConversationsLoaded]: (payload: any) =>
      new CustomEvent(RegisteredEvents.ConversationsLoaded, {
        detail: { ...payload },
      }),
    [RegisteredEvents.ConversationLoaded]: (payload: any) =>
      new CustomEvent(RegisteredEvents.ConversationLoaded, {
        detail: { ...payload },
      }),
    [RegisteredEvents.ConversationRenamed]: (payload: any) =>
      new CustomEvent(RegisteredEvents.ConversationRenamed, {
        detail: { ...payload },
      }),
    [RegisteredEvents.ConversationCreated]: (payload: any) =>
      new CustomEvent(RegisteredEvents.ConversationCreated, {
        detail: { ...payload },
      }),
    [RegisteredEvents.ConversationDeleted]: (payload: any) =>
      new CustomEvent(RegisteredEvents.ConversationDeleted, {
        detail: { ...payload },
      }),
    [RegisteredEvents.ConversationUpdated]: (payload: any) =>
      new CustomEvent(RegisteredEvents.ConversationUpdated, {
        detail: { ...payload },
      }),
    [RegisteredEvents.LocationChange]: (payload: any) =>
      new CustomEvent(RegisteredEvents.LocationChange, {
        detail: { ...payload },
      }),
  }

  constructor() {
    this.eventTarget = new EventTarget()
  }

  public subscribe<T>(name: RegisteredEvents, callback: EventBusCallback<T>) {
    this.eventTarget.addEventListener(name, callback as any)
  }

  public unsubscribe<T>(name: RegisteredEvents, callback: EventBusCallback<T>) {
    this.eventTarget.removeEventListener(name, callback as any)
  }

  public publish<T>(name: RegisteredEvents, payload: T = {} as T): void {
    this.eventTarget.dispatchEvent(this.eventBuilder[name](payload))
  }
}

injected(EventBusService)
