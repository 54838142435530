import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { Button } from '@/components/button/button'
import { DI_TYPE } from '@/di.types'
import { useViewModel } from '@/utils/view'

export const TermsPage = observer(() => {
  const vm = useViewModel(DI_TYPE.TermsViewModel)

  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState(false)

  return (
    <div id="terms-page" className="mx-auto flex max-w-[360px] flex-col">
      <h1 className="text-cerosBlack text-3xl font-bold">Terms & conditions</h1>
      <div className="text-cerosGrey600 my-8 text-sm">
        <p>
          Before continuing please agree to Terms & conditions and Privacy
          policy
        </p>
        <div className="mt-8">
          <fieldset className="mb-2">
            <label className="flex items-center gap-2">
              <input
                name="termsAndConditions"
                type="checkbox"
                value="1"
                onChange={() => setTermsAndConditions(!termsAndConditions)}
                checked={termsAndConditions}
              />
              <span>
                I have read and agreed to the{' '}
                <a
                  href="https://www.ceros.com/gemma-terms-conditions/"
                  target="_blank"
                  className="text-cerosPrimary"
                  rel="noreferrer"
                >
                  Terms & conditions
                </a>
              </span>
            </label>
          </fieldset>
          <fieldset>
            <label className="flex items-center gap-2">
              <input
                name="privacyPolicy"
                type="checkbox"
                value="1"
                onChange={() => setPrivacyPolicy(!privacyPolicy)}
                checked={privacyPolicy}
              />
              <span>
                I have read and agreed to the{' '}
                <a
                  href="https://www.ceros.com/privacy-policy/"
                  target="_blank"
                  className="text-cerosPrimary"
                  rel="noreferrer"
                >
                  Privacy policy
                </a>
              </span>
            </label>
          </fieldset>
        </div>
      </div>
      <div>
        <Button
          variant="primary"
          size="large"
          disabled={!termsAndConditions || !privacyPolicy}
          onClick={() => vm.onContinue(termsAndConditions, privacyPolicy)}
          data-automation="terms-continue-btn"
        >
          Continue
        </Button>
      </div>
    </div>
  )
})
