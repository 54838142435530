import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { errorResponseSchema } from './error.js';
import { uploadStatusEnum } from './message_contents.js';
const c = initContract();
export const gemAttachment = z.object({
    id: z.string().uuid(),
    conversation_message_id: z.string().uuid(),
    url: z.string(),
    mime_type: z.string(),
    filename: z.string(),
    status: uploadStatusEnum.optional(),
});
export const gemSchema = z.object({
    id: z.string().uuid(),
    emoji: z.string().length(1),
    title: z.string().length(50),
    background_color: z.string(),
    is_disabled: z.boolean(),
    is_draft: z.boolean(),
    is_new: z.boolean(),
    prompt: z.string(),
    is_publicly_shared: z.boolean(),
    user_id: z.string().uuid(),
    conversation_template_id: z.string().uuid().optional(),
    attachments: z.array(gemAttachment),
});
export const attachmentResponse = z.object({
    message_id: z.string().uuid(),
    handler_id: z.string().uuid(),
    conversation_template_id: z.string().uuid(),
});
export const createGemRequestSchema = z.object({
    emoji: z.string().length(1),
    title: z.string().length(50),
    background_color: z.string(),
    is_disabled: z.boolean(),
    is_draft: z.boolean(),
    prompt: z.string(),
    is_publicly_shared: z.boolean(),
    conversation_template_id: z.string().uuid().optional(),
});
export const gemsSchema = z.object({
    items: z.array(gemSchema),
});
const gemPinAttachmentRequestSchema = z.object({
    pin: z.object({
        url: z.string(),
        type: z.literal('upload_pin'),
    }),
});
export const gemWithUserAccess = z.object({
    gem: gemSchema,
    user_access: z.object({
        can_write: z.boolean(),
    }),
});
export const contract = c.router({
    getAllGems: {
        method: 'GET',
        path: '/gems',
        responses: {
            200: gemsSchema,
            401: errorResponseSchema,
            403: errorResponseSchema,
            404: errorResponseSchema,
        },
        summary: 'Returns all the gems for a user',
    },
    getGem: {
        method: 'GET',
        path: '/gems/:gem_id',
        responses: {
            200: gemWithUserAccess,
            401: errorResponseSchema,
            403: errorResponseSchema,
            404: errorResponseSchema,
        },
    },
    createGem: {
        method: 'POST',
        path: '/gems',
        responses: {
            201: gemSchema,
            401: errorResponseSchema,
            403: errorResponseSchema,
            404: errorResponseSchema,
        },
        body: createGemRequestSchema,
        summary: 'Create a gem',
    },
    updateGem: {
        method: 'PATCH',
        path: '/gems/:gem_id',
        responses: {
            200: gemSchema,
            401: errorResponseSchema,
            403: errorResponseSchema,
            404: errorResponseSchema,
        },
        body: createGemRequestSchema,
        summary: 'Updates a gem',
    },
    deleteGem: {
        method: 'DELETE',
        path: '/gems/:gem_id',
        responses: {
            200: gemSchema,
            401: errorResponseSchema,
            403: errorResponseSchema,
            404: errorResponseSchema,
        },
        body: z.never(),
        summary: 'Deletes a gem',
    },
    pinAttachment: {
        method: 'POST',
        path: '/gems/:gem_id/pin-attachment',
        responses: {
            201: attachmentResponse,
            401: errorResponseSchema,
            403: errorResponseSchema,
            404: errorResponseSchema,
        },
        body: gemPinAttachmentRequestSchema,
        summary: 'Pins a user attachment to given gem',
    },
    unpinAttachment: {
        method: 'POST',
        path: '/gems/:gem_id/unpin-attachment/:attachment_id',
        responses: {
            200: gemsSchema,
            401: errorResponseSchema,
            403: errorResponseSchema,
            404: errorResponseSchema,
        },
        body: z.object({}),
        summary: 'Unpins an attachment from the given gem',
    },
});
