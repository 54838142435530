import { Popover } from '@headlessui/react'
import { useCurrentEditor } from '@tiptap/react'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect } from 'react'

import DiffIcon from '@/assets/icons/diff.svg?react'
import AlignCenterIcon from '@/assets/icons/text-align-center.svg?react'
import AlignLeftIcon from '@/assets/icons/text-align-left.svg?react'
import AlignRightIcon from '@/assets/icons/text-align-right.svg?react'
import BoldIcon from '@/assets/icons/text-bold.svg?react'
import BulletListIcon from '@/assets/icons/text-bullets.svg?react'
import ItalicIcon from '@/assets/icons/text-italic.svg?react'
import AlignJustifyIcon from '@/assets/icons/text-justify.svg?react'
import NumberedListIcon from '@/assets/icons/text-list.svg?react'
import UnderlineIcon from '@/assets/icons/text-underline.svg?react'

import { highlightDifferences } from '../../utils/document'
import { cls } from '../../utils/utils'
import type { ConversationViewModel } from '../../view-models/conversation'
import { IconButton } from '../icon-button/icon-button'
import type { Changes } from '../select/select'
import { Select } from '../select/select'
import { Tooltip } from '../tooltip/tooltip'

export const Toolbar: React.FC<{ vm: ConversationViewModel }> = observer(
  ({ vm }) => {
    const { editor } = useCurrentEditor()
    const { index } = vm.currentDocument
    const prevDoc = vm.documents[index - 1]
    const setIsNew = useCallback((newValue: boolean) => {
      vm.documentViewModel.isNew = newValue
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const toggleDiff = useCallback((newValue?: boolean) => {
      vm.documentViewModel.showDiff =
        newValue !== undefined ? newValue : !vm.documentViewModel.showDiff
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
      if (editor && vm.documentViewModel.showDiff && prevDoc) {
        // sometimes this gets trigger while editor still has old content
        window.requestAnimationFrame(() => {
          editor.setEditable(false, false)
          highlightDifferences(prevDoc, editor)
        })
      } else if (editor && !vm.documentViewModel.showDiff) {
        editor.chain().selectAll().unsetHighlight().blur().run()
        editor.setEditable(true, false)
        setIsNew(false)
      }
    }, [vm.documentViewModel.showDiff, setIsNew, prevDoc, editor])

    if (!editor) {
      return null
    }
    const getHeadingValue = () => {
      const heading = editor.getAttributes('heading').level ?? 0
      if (heading > 0 && heading <= 3) {
        return `Heading ${heading}`
      }
      if (heading === 6) {
        return 'Caption'
      }
      return 'Paragraph'
    }

    const updateHeading = (c: Changes<string>) => {
      if (c.multiple) {
        return
      }
      const value = c.value
      if (value === 'Paragraph') {
        const currentLevel = editor.getAttributes('heading').level
        editor.chain().focus().toggleHeading({ level: currentLevel }).run()
      } else if (value === 'Caption') {
        editor.chain().focus().toggleHeading({ level: 6 }).run()
      } else {
        const level = parseInt(value.split(' ')[1])
        if (level < 1 || level > 3) return
        editor
          .chain()
          .focus()
          .toggleHeading({ level: level as 1 | 2 | 3 })
          .run()
      }
    }

    return (
      <div
        role="toolbar"
        className="bg-cerosGrey200/100 absolute left-0 right-0 top-0 z-20 flex h-[40px] items-center justify-start gap-1 rounded-t-md p-2"
      >
        <IconButton
          className={editor.isActive('bold') ? 'bg-cerosGrey300' : ''}
          icon={<BoldIcon />}
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
        />
        <IconButton
          className={editor.isActive('italic') ? 'bg-cerosGrey300' : ''}
          icon={<ItalicIcon />}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
        />
        <IconButton
          className={editor.isActive('underline') ? 'bg-cerosGrey300' : ''}
          icon={<UnderlineIcon />}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
        />
        <Select
          selectClassName="pl-3 pr-2 py-0"
          name="heading"
          value={getHeadingValue()}
          options={[
            ...new Array(3).fill(null).map((_, i) => `Heading ${i + 1}`),
            'Paragraph',
            'Caption',
          ]}
          multiple={false}
          onChange={updateHeading}
        />
        <span className="bg-cerosGrey300 mx-1 h-full w-[2px]" />
        <Popover>
          <Popover.Button>
            <IconButton icon={<AlignLeftIcon />} />
          </Popover.Button>
          <Popover.Panel className="absolute z-10 -translate-x-1/2 translate-y-2">
            <div className="bg-cerosGrey200 rounded-md p-2 shadow-lg">
              <div className="flex flex-col gap-1">
                {['justify', 'left', 'center', 'right'].map((align) => (
                  <IconButton
                    key={`text-align-${align}`}
                    className={cls(
                      `bg-cerosGrey200 hover:bg-cerosGrey300 cursor-pointer rounded-md p-1 text-sm`,
                      editor.isActive({ textAlign: align }) &&
                        'border-cerosGrey500 bg-cerosGrey300 border',
                    )}
                    disabled={
                      !editor.can().chain().focus().setTextAlign(align).run()
                    }
                    onClick={() => {
                      const isActive = editor.isActive({ textAlign: align })
                      if (isActive) {
                        editor.chain().focus().unsetTextAlign().run()
                      } else {
                        editor.chain().focus().setTextAlign(align).run()
                      }
                    }}
                    icon={
                      {
                        justify: <AlignJustifyIcon />,
                        left: <AlignLeftIcon />,
                        center: <AlignCenterIcon />,
                        right: <AlignRightIcon />,
                      }[align]
                    }
                  />
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Popover>
        <IconButton
          className={editor.isActive('bulletList') ? 'bg-cerosGrey300' : ''}
          icon={<BulletListIcon />}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          disabled={!editor.can().chain().focus().toggleBulletList().run()}
        />
        <IconButton
          className={editor.isActive('orderedList') ? 'bg-cerosGrey300' : ''}
          icon={<NumberedListIcon />}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          disabled={!editor.can().chain().focus().toggleOrderedList().run()}
        />
        <Tooltip
          containerClassName="ml-auto"
          className="max-w-[200px] text-center"
          text={
            !prevDoc
              ? 'No previous version available'
              : vm.documentViewModel.isNew
                ? 'Click here at any time to preview changes with previous version'
                : 'Preview changes'
          }
          placement="top-end"
          autoOpen={
            vm.documentViewModel.isNew
              ? index === 1
                ? true
                : undefined
              : undefined
          } // auto open for first change
        >
          <IconButton
            className={
              vm.documentViewModel.showDiff
                ? 'bg-cerosGrey300'
                : ' disabled:opacity-50'
            }
            disabled={!prevDoc}
            icon={<DiffIcon />}
            onClick={() => toggleDiff()}
          />
        </Tooltip>
      </div>
    )
  },
)
