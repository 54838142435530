import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'

import { LoadingDots } from '@/components/loading-dots/loading-dots'
import { DI_TYPE } from '@/di.types'
import { useViewModel } from '@/utils/view'

export const RequiredUserChecks = observer(() => {
  const vm = useViewModel(DI_TYPE.RequiredUserChecks)
  if (vm.isBusy) {
    return (
      <div className="flex h-full min-h-screen w-full items-center justify-center">
        <LoadingDots />
      </div>
    )
  }

  return <Outlet />
})

RequiredUserChecks.displayName = 'RequiredUserChecks'
