import { observer } from 'mobx-react-lite'

import { LoadingDots } from '@/components/loading-dots/loading-dots'
import { DI_TYPE } from '@/di.types'
import { useViewModel } from '@/utils/view'

export const EmbedStudioPage = observer(() => {
  useViewModel(DI_TYPE.EmbedStudioNewViewModel)
  return (
    <div className="flex h-full min-h-screen w-full items-center justify-center">
      <LoadingDots />
    </div>
  )
})

EmbedStudioPage.displayName = 'EmbedStudioPage'
