import { injected } from 'brandi'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { toast } from 'react-hot-toast'
import type { SetURLSearchParams } from 'react-router-dom'

import { DI_TYPE } from '@/di.types.js'
import type { AuthModel } from '@/models/auth.js'
import { passwordSchema } from '@/models/password.js'
import type { Api } from '@/services/api.js'
import type { AuthService } from '@/services/auth.js'
import type { MixpanelService } from '@/services/mixpanel.js'
import type { OktaIdpService } from '@/services/okta-idp.js'
import type { RouterService } from '@/services/router.js'

import { BaseViewModel } from './base-view-model.js'

const SEARCH_PARAM_EMAIL = 'email'
const SEARCH_PARAM_CODE = 'code'

type SignUpState = 'email' | 'code' | 'password'

export class SignUpViewModel extends BaseViewModel {
  @observable email: string | null = null
  @observable code: string | null = null
  @observable errorMessage: string | null = null
  @observable searchParams: URLSearchParams | null = null
  @observable setSearchParams: SetURLSearchParams | null = null
  @observable isBusy = false

  constructor(
    private api: Api,
    private authService: AuthService,
    private mixpanelService: MixpanelService,
    private oktaIdpService: OktaIdpService,
    private routerService: RouterService,
  ) {
    super()
    makeObservable(this)
  }

  // FIX ME - make sure init and cleanup happens here correctly
  onInit(): void {}

  onDispose(): void {}

  @computed
  get state(): SignUpState {
    if (this.email && this.code) return 'password'
    if (this.email) return 'code'
    return 'email'
  }

  @action.bound
  setEmail(email: string | null) {
    this.email = email
    if (email) this.errorMessage = null
    this.setSearchParam(SEARCH_PARAM_EMAIL, email)
  }

  @action.bound
  setCode(code: string | null) {
    this.code = code
    if (code) this.errorMessage = null
    this.setSearchParam(SEARCH_PARAM_CODE, code)
  }

  @action.bound
  loadSignUpDataFromSearchParams(
    searchParams: URLSearchParams,
    setSearchParams: SetURLSearchParams,
  ): void {
    const email = searchParams.get(SEARCH_PARAM_EMAIL)
    const code = searchParams.get(SEARCH_PARAM_CODE)
    this.searchParams = searchParams
    this.setSearchParams = setSearchParams
    this.setEmail(email)
    this.setCode(code)
  }

  setSearchParam(name: string, value: string | null) {
    if (this.searchParams && this.setSearchParams) {
      if (value) {
        this.searchParams.set(name, value)
      } else {
        this.searchParams.delete(name)
      }
      this.setSearchParams(this.searchParams)
    }
  }

  handleErrorResponse(status: number, body: any) {
    if (status === 422 && body.detail?.length) {
      this.errorMessage = body.detail[0].msg
    } else {
      toast.error('Something went wrong')
    }
  }

  @action.bound
  async startSignUp(email: string) {
    this.isBusy = true
    const idpProps = await this.oktaIdpService.webfinger(email)
    if (idpProps) {
      this.oktaIdpService.goToIdp(idpProps)
      this.isBusy = false
      return
    }

    const { status, body } = await this.api.authentication.startSignUp({
      body: { email },
    })
    this.mixpanelService.track('SignUp Started', { email, status })
    runInAction(() => {
      this.isBusy = false
    })
    if (status === 204) {
      this.setEmail(email)
      this.setCode(null)
    } else if (status === 400 && body.code === 'EMAIL_NOT_WHITELISTED') {
      this.mixpanelService.track('Signup Access Requested', { email })
      this.routerService.goTo({ pathname: '/login/unassigned' })
    } else {
      this.handleErrorResponse(status, body)
    }
  }

  @action.bound
  async resendCode() {
    const email = this.email ?? ''
    const { status, body } = await this.api.authentication.startSignUp({
      body: { email },
    })
    this.mixpanelService.track('SignUp Resend Code', { email, status })
    if (status === 204) {
      toast.success('Activation code has been sent')
      this.errorMessage = null
    } else {
      this.handleErrorResponse(status, body)
    }
  }

  @action.bound
  async checkActivationCode(code: string): Promise<boolean> {
    this.isBusy = true
    const email = this.email ?? ''
    const { status, body } = await this.api.authentication.checkActivationCode({
      body: { email, code },
    })
    this.mixpanelService.track('SignUp Check Activation Code', {
      email,
      status,
    })
    runInAction(() => {
      this.isBusy = false
    })
    if (status === 200 && body?.valid) {
      this.setCode(code)
      return false
    } else if (status === 200 && !body?.valid) {
      this.errorMessage = 'Activation code is not valid'
      this.setCode(null)
      return true
    } else {
      this.handleErrorResponse(status, body)
      return false
    }
  }

  @action.bound
  async finishSignUp(password: string) {
    const validationResult = passwordSchema.safeParse(password)
    if (!validationResult.success) {
      const issue = validationResult.error.issues[0]
      toast.error(issue.message)
      return
    }

    const email = this.email ?? ''
    const code = this.code ?? ''
    this.isBusy = true
    const { status, body } = await this.api.authentication.finishSignUp({
      body: { email, code, password },
    })
    this.mixpanelService.track('SignUp Finish', { email, status })
    if (status === 200 && body?.user.id && body?.access_token) {
      this.setEmail(null)
      this.setCode(null)
      const token: AuthModel = {
        type: 'auth0',
        data: {
          access_token: body.access_token,
          refresh_token: body.refresh_token,
          expires_at: new Date(body.expires_at),
          user: { id: body.user.id, email },
        },
      }
      await this.authService.applyToken(token)
      this.routerService.goTo({ pathname: '/conversations' })
      this.isBusy = false
    } else {
      this.isBusy = false
      this.handleErrorResponse(status, body)
    }
  }
}

injected(
  SignUpViewModel,
  DI_TYPE.Api,
  DI_TYPE.AuthService,
  DI_TYPE.MixpanelService,
  DI_TYPE.OktaIdpService,
  DI_TYPE.RouterService,
)
