import { cls } from '@/utils/utils'

export const ProBadge = ({ className }: { className?: string }) => {
  return (
    <span
      className={cls(
        'flex h-6 w-[42px] flex-shrink-0 items-center justify-center rounded-lg bg-gradient-to-r from-stone-200 to-indigo-200 text-sm shadow-[0px_0px_4px_2px_#FFFFFF40_inset]',
        className,
      )}
    >
      Pro
    </span>
  )
}
