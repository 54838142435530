import EffectsIcon from '@/assets/icons/effects.svg?react'
import PresentationThumb from '@/assets/images/ai-presentations/thumb.svg?react'

import { Button } from '../button/button'

interface IPresentationContent {
  url: string
}

export const PresentationContent = ({ url }: IPresentationContent) => {
  return (
    <div className="shadow-accordion bg-cerosWhite border-cerosGrey300 -ml-[64px] -mr-[7px] flex max-w-[376px] flex-col overflow-hidden rounded-2xl border-t sm:ml-0 sm:mr-auto">
      <PresentationThumb className="h-auto w-full" />
      <div className="border-cerosGrey300 mb-1 flex items-center justify-between border-t p-2">
        <div className="flex p-1">
          <EffectsIcon className="m-2 h-6 w-6 stroke-[0.75]" />
          <div className="mx-3 text-base font-medium">
            Created by Gemma
            <div className="text-cerosGrey400 text-xs">on Ceros</div>
          </div>
        </div>
        <Button
          type="button"
          className="h-auto px-4 py-2"
          onClick={() => window.open(url, '_blank')}
        >
          Open in Editor
        </Button>
      </div>
    </div>
  )
}
