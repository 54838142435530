import type { ReactNode } from 'react'

import { cls } from '@/utils/utils.js'

interface IEditItem {
  title: string
  icon: ReactNode
  onClick: () => void
  disabled?: boolean
}

export const EditItem = ({ title, icon, onClick, disabled }: IEditItem) => {
  const handleClick = () => {
    if (!disabled) {
      onClick()
    }
  }

  return (
    <button
      disabled={disabled}
      className={cls(
        'bg-cerosGrey100 hover:text-cerosGrey500 border-cerosGrey300 relative box-border flex w-full cursor-default items-center justify-between border-b px-6 py-4 text-sm',
        disabled && 'text-cerosGrey400 hover:text-cerosGrey40',
      )}
      onClick={handleClick}
    >
      <div className="flex items-center gap-2">
        {icon}
        {title}
      </div>

      {disabled && (
        <div className="bg-cerosWhite absolute inset-0 cursor-not-allowed opacity-50" />
      )}
    </button>
  )
}
