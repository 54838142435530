import { injected } from 'brandi'
import type { IReactionDisposer } from 'mobx'
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { createElement, type FunctionComponent } from 'react'

import EditIcon from '@/assets/icons/edit.svg?react'
import EmojiIcon from '@/assets/icons/emoji.svg?react'
import LogOutIcon from '@/assets/icons/log-out.svg?react'
import logoSvg from '@/assets/icons/logo.svg'
import PluginIcon from '@/assets/icons/plugin.svg?react'
import ProfileIcon from '@/assets/icons/profile.svg?react'
import ShareIcon from '@/assets/icons/share.svg?react'
import TrashIcon from '@/assets/icons/trash.svg?react'
import { DI_TYPE } from '@/di.types'
import { env } from '@/env'
import { DeleteChatModal } from '@/pages/modals/delete-chat'
import { FeatureFlagsModal } from '@/pages/modals/feature-flags'
import { FeedbackModal } from '@/pages/modals/feedback'
import { ShareModal } from '@/pages/modals/share'
import { bound } from '@/utils/utils'

import type { AuthService } from './auth'
import { BaseService } from './base-service'
import {
  type ConversationItem,
  ConversationsService,
  RENAME_SOURCE,
} from './conversations'
import type { MixpanelService } from './mixpanel'
import type { ModalService } from './modal'
import type { RouterService } from './router'
import type { StorageService } from './storage'
import type { CreateFeedbackForm, SupportService } from './support'
import type { UserService } from './user'

export interface IMenuItem {
  id?: string
  title: string
  path: string
  icon: {
    type: 'icon' | 'emoji' | 'url'
    value: string
  }
  isPubliclyShared: boolean
  onClick: () => void
  onRename?: (emoji: string, name: string) => void
  actions: IMenuGroup[]
}

export interface IMenuAction {
  id: string
  icon: FunctionComponent
  title: string
  className?: string
  tooltipText?: string
  onClick: () => void
}

export interface IMenuGroup {
  id: string
  data: IMenuAction[]
}

export enum SidebarState {
  OPEN = 'open',
  CLOSED = 'closed',
}

const LOCAL_STORAGE_KEY = 'sidebarOpenState'

export class SidebarService extends BaseService {
  @observable isOpen = false

  // FIX ME: this is a super hacky way of setting the current conversation id on the sidebar, ideally all services could grab this from the router params, but we don't have a way to share that yet (will address post hotfix)
  @observable currentConversationId: string | undefined = undefined

  private reactionDisposers: IReactionDisposer[] = []

  constructor(
    private conversationsService: ConversationsService,
    private routerService: RouterService,
    private mixpanelService: MixpanelService,
    private modalService: ModalService,
    private authService: AuthService,
    private userService: UserService,
    private storageService: StorageService,
    private supportService: SupportService,
  ) {
    super()
    makeObservable(this)

    this.onInit()
  }

  async onInit() {
    const sidebarIsOpen = await this.loadSidebarPreference()
    runInAction(() => {
      this.isOpen = sidebarIsOpen === SidebarState.OPEN
    })

    const initReactionDisposer = reaction(
      () => this.conversationsService.initialized,
      async (initialized) => {
        if (initialized) {
          runInAction(() => {
            this.initialized = true
          })
        }
      },
      {
        fireImmediately: true,
      },
    )

    const routerReactionDisposer = reaction(
      () => [this.routerService.currentPath, this.isMobile()],
      ([, isMobile]) => {
        if (!isMobile) return

        this.setIsOpen(false)
      },
    )

    this.reactionDisposers.push(initReactionDisposer, routerReactionDisposer)
  }

  onDispose() {
    this.reactionDisposers.forEach((disposer) => disposer())
  }

  isMobile() {
    const media = window.matchMedia('(max-width: 640px)')
    return media.matches
  }

  @computed
  get userData() {
    return this.userService.userData
  }

  @computed
  get conversations(): IMenuItem[] {
    return this.conversationsService.items.map((item) => ({
      id: item.id,
      title: item.name,
      path: `/conversations/${item.id}`,
      icon: {
        type: item.emoji ? 'emoji' : 'url',
        value: item.emoji ? item.emoji : logoSvg,
      },
      isPubliclyShared: item.is_publicly_shared,
      onClick: () => this.openPage(`/conversations/${item.id}`),
      onRename: (emoji: string, name: string) =>
        this.conversationsService.renameConversation(
          item.id,
          emoji,
          name,
          RENAME_SOURCE.USER,
        ),

      actions: this.getConversationActions(item),
    }))
  }

  @computed
  get userInitials() {
    return this.userService.userData.initials
  }

  private getConversationActions(item: ConversationItem): IMenuGroup[] {
    const onToggleSharing = () => {
      this.conversationsService.updateConversation(item.id, {
        is_publicly_shared: !item.is_publicly_shared,
      })
      this.mixpanelService.trackChatSharingToggled({
        chatId: item.id,
        linkOrigin: 'Sidebar',
        shared: !item.is_publicly_shared,
      })
    }

    const onCopySharingUrl = () => {
      this.mixpanelService.trackChatSharingLinkCopied({
        chatId: item.id,
        linkOrigin: 'Sidebar',
      })
    }

    const groups = [
      {
        id: 'first',
        data: [
          {
            id: 'share',
            icon: ShareIcon,
            title: 'Share',
            tooltipText: 'Share this project',
            onClick: () => {
              this.modalService.showCustom(
                'share-project',
                createElement(ShareModal, {
                  type: 'Conversation',
                  onToggle: onToggleSharing,
                  onCopyUrl: onCopySharingUrl,
                  isShared: item.is_publicly_shared,
                  shareUrl: `${window.location.origin}/${ConversationsService.CONVERSATIONS_SLUG}/${item.id}`,
                }),
              )

              this.mixpanelService.trackChatSharingOpened({
                chatId: item.id,
                linkOrigin: 'Sidebar',
              })
            },
          },
          {
            id: 'rename',
            icon: EditIcon,
            title: 'Rename',
            onClick: () => {}, // implemented in sidebar-item.tsx
          },
        ],
      },
      {
        id: 'second',
        data: [
          {
            id: 'delete',
            icon: TrashIcon,
            title: 'Delete',
            className: 'text-cerosDanger',
            onClick: () => this.deleteConversation(item.id),
          },
        ],
      },
    ]

    return groups
  }

  @computed
  get userActions(): IMenuGroup[] {
    const groups = [
      {
        id: 'first',
        data: [
          {
            id: 'profile',
            icon: ProfileIcon,
            title: 'Profile',
            onClick: () => this.openPage('/profile'),
          },
          {
            id: 'feedback',
            icon: EmojiIcon,
            title: 'Feedback',
            onClick: () =>
              this.modalService.show(
                createElement(FeedbackModal, {
                  onSubmit: this.sendFeedback,
                }),
                'Feedback',
              ),
          },
          {
            id: 'beta_features',
            icon: PluginIcon,
            title: 'Beta features',
            onClick: () =>
              this.modalService.showCustom(
                'feature-flags',
                createElement(FeatureFlagsModal),
              ),
          },
        ].filter((action) => {
          if (action.id === 'beta_features') {
            return env().VITE_APP_ENVIRONMENT === 'Development'
          }
          return true
        }),
      },
      {
        id: 'second',
        data: [
          {
            id: 'sign_out',
            icon: LogOutIcon,
            title: 'Sign out',
            onClick: this.signOut,
          },
        ],
      },
    ]
    return groups
  }

  @computed
  get isHomePage() {
    return this.routerService.currentPath === '/'
  }

  @bound
  async goToHomePage() {
    this.openPage('/')
  }

  @action.bound
  async startBlankChat() {
    const conversation = await this.conversationsService.createConversation(
      {
        task_prompt_type: 'task_blank_chat',
      },
      { createSource: 'Sidebar button' },
    )

    if (!conversation) return

    this.openPage(`/conversations/${conversation.id}`, {
      fromExisting: false,
      expectGemmaResponse: true,
    })
  }

  @action.bound
  toggle() {
    this.setIsOpen(!this.isOpen)
  }

  @action.bound
  setIsOpen(value: boolean) {
    this.isOpen = value
    this.setSidebarPreference(value ? SidebarState.OPEN : SidebarState.CLOSED)
  }

  async loadSidebarPreference(): Promise<SidebarState> {
    const preference = await this.storageService.load(LOCAL_STORAGE_KEY)
    if (
      preference === SidebarState.OPEN ||
      preference === SidebarState.CLOSED
    ) {
      return preference
    }

    // NOTE: default to closed when sidebar would sit over the homepage content on a
    return window.screen.width < 1024 ? SidebarState.CLOSED : SidebarState.OPEN
  }

  setSidebarPreference(value: SidebarState) {
    this.storageService.save(LOCAL_STORAGE_KEY, value)
  }

  @action.bound
  private openPage(path: string, state?: any) {
    this.routerService.goTo(path, state)
  }

  @action.bound
  private async sendFeedback(
    type: CreateFeedbackForm['type'],
    message: string,
  ) {
    this.supportService.sendFeedback({
      conversationId: this.currentConversationId,
      type,
      message,
    })
  }

  @action.bound
  private deleteConversation(itemId: string) {
    this.modalService.show(
      createElement(DeleteChatModal, {
        onDelete: () => this.conversationsService.deleteConversation(itemId),
      }),
      'Delete chat',
    )
  }

  @action.bound
  private signOut() {
    this.authService.applyToken(undefined)
    this.mixpanelService.trackAppSessionEnded()
    this.modalService.close()
    this.routerService.goTo('/login')
  }
}

injected(
  SidebarService,
  DI_TYPE.ConversationsService,
  DI_TYPE.RouterService,
  DI_TYPE.MixpanelService,
  DI_TYPE.ModalService,
  DI_TYPE.AuthService,
  DI_TYPE.UserService,
  DI_TYPE.StorageService,
  DI_TYPE.SupportService,
)
