import type { FeatureFlag } from '@ceros/gemma-api-spec'
import { injected } from 'brandi'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import toast from 'react-hot-toast'

import { DI_TYPE } from '@/di.types.js'

import type { Api } from './api.js'
import type { AuthService } from './auth.js'
import { BaseService } from './base-service.js'

export enum FEATURE_FLAGS {
  MIXPANEL_LOGGING = 'enable-mixpanel-logging',
}

export class FeatureFlagService extends BaseService {
  @observable isEditable = false
  @observable items: FeatureFlag[] = []

  constructor(
    private authService: AuthService,
    private api: Api,
  ) {
    super()
    makeObservable(this)
    this.onInit()
  }

  @action.bound
  async set(flag_id: FeatureFlag['id'], value: FeatureFlag['value']) {
    if (this.isEditable) {
      const { status, body } = await this.api.featureFlags.setFeatureFlag({
        params: {
          flag_id,
        },
        body: {
          value,
        },
      })

      if (status === 200 && body) {
        toast.success(
          `Feature "${body.label}" has been ${
            body.value ? 'enabled' : 'disabled'
          }`,
        )

        runInAction(() => {
          this.items = this.items.map((flag) =>
            flag.id === flag_id ? body : flag,
          )
        })

        return true
      } else {
        toast.error('Something went wrong')
      }
    }
    return false
  }

  @action.bound
  async reset(flag_id: FeatureFlag['id']) {
    if (this.isEditable) {
      const { status, body } = await this.api.featureFlags.resetFeatureFlag({
        params: {
          flag_id,
        },
      })

      if (status === 200 && body) {
        toast.success(`Feature ${body.label} has been reset`)

        runInAction(() => {
          this.items = this.items.map((flag) => {
            if (flag.id === flag_id) {
              return body
            }

            return flag
          })
        })
      } else {
        toast.error('Something went wrong')
      }
    }
  }

  @action.bound
  async resetAll() {
    if (this.isEditable) {
      const { status, body } =
        await this.api.featureFlags.resetAllFeatureFlags()

      if (status === 200 && body.items.length > 0) {
        toast.success('All beta features have been reset')

        runInAction(() => {
          this.items = body.items
        })
      }
    } else {
      toast.error('Something went wrong')
    }
  }

  private async loadGlobalItems() {
    const { status, body } = await this.api.featureFlags.getGlobalFeatureFlags()

    if (status === 200 && body.items.length > 0) {
      runInAction(() => {
        this.items = body.items
        this.isEditable = false
      })
    }
  }

  private async loadUserItems() {
    const { status, body } = await this.api.featureFlags.getUserFeatureFlags()

    if (status === 200 && body.items.length > 0) {
      runInAction(() => {
        this.items = body.items
        this.isEditable = body.isEditable
      })
    }
  }

  private async loadItems(isAuthenticated: boolean) {
    if (isAuthenticated) {
      await this.loadUserItems()
    } else {
      await this.loadGlobalItems()
    }
  }

  async onInit() {
    await this.loadItems(this.authService.isAuthenticated)

    runInAction(() => {
      this.initialized = true
    })

    reaction(
      () => this.authService.isAuthenticated,
      (isAuthenticated) => this.loadItems(isAuthenticated),
      {
        fireImmediately: true,
      },
    )
  }

  /**
   * Check if feature flag is enabled
   * For using outside of components/pages
   * If you need check feature flag inside component/page, use `useFeatureFlag` hook
   */
  @action.bound
  isEnabled(value: FEATURE_FLAGS) {
    const flag = this.items.find((flag) => flag.slug === value)

    // Check feature flag value, if not found, default to true
    return flag ? flag.value : true
  }
}

injected(FeatureFlagService, DI_TYPE.AuthService, DI_TYPE.Api)
