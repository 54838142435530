import type { AttachmentPin } from '@ceros/gemma-api-spec'
import { captureException } from '@sentry/react'
import { injected } from 'brandi'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { match } from 'path-to-regexp'
import { toast } from 'react-hot-toast'

import { DI_TYPE } from '@/di.types.js'
import type { NounProjectIcon } from '@/models/noun-project.js'
import {
  nounProjectRecentResponseSchema,
  nounProjectSearchResponseSchema,
} from '@/models/noun-project.js'
import type {
  NounProjectRepository,
  RecentSearchBody,
  SearchBody,
} from '@/repositories/noun-project.js'
import type { Api } from '@/services/api.js'
import { type EventBusService } from '@/services/event-bus.js'
import type { MixpanelService, ResultUsedType } from '@/services/mixpanel.js'
import type { RouterService } from '@/services/router.js'
import { bound, createFileFromImageUrl, downloadFile } from '@/utils/utils.js'

import { BaseViewModel } from './base-view-model.js'

const THUMBNAIL_SIZE = 200

export interface NounProjectLocationState {
  messageId?: string
  query?: string
}

export class NounProjectViewModel extends BaseViewModel {
  @observable messageId: string | undefined = undefined
  @observable icons: NounProjectIcon[] = []
  @observable isLoading: boolean = false
  @observable query: string = ''
  // string - has next page, null - has no next page, undefined - not loaded yet
  @observable nextPageCursor: string | null | undefined = undefined
  @observable total: number = 0
  perPage: number = 30

  constructor(
    private api: Api,
    private nounProjectRepository: NounProjectRepository,
    private eventBusService: EventBusService,
    private routerService: RouterService,
    private mixpanelService: MixpanelService,
  ) {
    super()
    makeObservable(this)
  }

  onInit() {
    this.loadData()
  }

  @action.bound
  setFactoryData(messageId: string | undefined, query: string | undefined) {
    this.messageId = messageId

    this.trackIntegrationOpened()

    if (query) {
      this.setQuery(query)
    }
  }

  onDispose() {
    this.abortController?.abort()
  }

  @computed
  get launchedFrom() {
    return this.messageId ? 'Chat' : 'Sidebar'
  }

  @computed
  get conversationId() {
    const fn = match('/conversations/:conversationId{/:integration}?', {
      decode: decodeURIComponent,
    })

    const result = fn(this.routerService.currentPath)

    if (result) {
      const { conversationId } = result.params as { conversationId: string }

      return conversationId
    }

    return undefined
  }

  @action.bound
  async loadData() {
    if (this.isLoading || this.nextPageCursor === null) {
      return
    }

    this.isLoading = true

    const commonParams: RecentSearchBody = {
      thumbnail_size: THUMBNAIL_SIZE,
      limit_to_public_domain: 1,
    }

    if (this.query === '') {
      await this.loadRecentIcons(commonParams)
    } else {
      await this.loadSearchIcons({
        query: this.query,
        next_page: this.nextPageCursor,
        ...commonParams,
      })
      this.trackIntegrationSearch(this.query)
    }

    runInAction(() => {
      this.isLoading = false
    })
  }

  async loadRecentIcons(params: RecentSearchBody) {
    try {
      const { data, status } = await this.nounProjectRepository.getRecentIcons(
        params,
        this.abortController,
      )

      if (status === 200 && data) {
        const result = nounProjectRecentResponseSchema.parse(data)

        const icons = result.recent_uploads.map((icon) => ({
          ...icon,
          thumbnail_url: icon.preview_url,
        }))

        runInAction(() => {
          this.icons.push(...icons)
          this.nextPageCursor = null
          this.total = 0
        })
      }
    } catch (e) {
      toast.error('Failed to load icons')
    }
  }

  async loadSearchIcons(params: SearchBody) {
    try {
      const { data, status } = await this.nounProjectRepository.searchIcons(
        params,
        this.abortController,
      )

      if (status === 200 && data) {
        const result = nounProjectSearchResponseSchema.parse(data)
        runInAction(() => {
          this.icons.push(...result.icons)
          this.nextPageCursor = result.next_page
          this.total = result.total
        })
      }
    } catch (e) {
      toast.error('Failed to load icons')
    }
  }

  private trackIntegrationOpened() {
    this.mixpanelService.trackIntegrationOpened(
      'NounProject',
      this.launchedFrom,
    )
  }

  private trackIntegrationSearch(query: string) {
    this.mixpanelService.trackIntegrationSearch(
      query,
      this.launchedFrom,
      'NounProject',
    )
  }

  private trackResultUsed(id: string, type: ResultUsedType) {
    this.mixpanelService.trackResultUsed({
      chatId: this.conversationId,
      integrationOrigin: this.launchedFrom,
      messageId: this.messageId,
      messageContentTypes: ['image'],
      queryValue: this.query,
      resultOrigin: 'NounProject',
      resultUsedType: type,
    })
  }

  @bound
  async onDownload(icon: NounProjectIcon): Promise<undefined> {
    const { data, status } = await this.nounProjectRepository.trackDownload(
      icon.id,
    )

    this.trackResultUsed(icon.id.toString(), 'download')

    if (status === 500) {
      toast.error('Failed to track download')
      return
    }

    const url = `data:image/svg+xml;base64,${data.base64_encoded_file}`
    const file = await createFileFromImageUrl(url)
    downloadFile(file)
  }

  @bound
  async onSave(icon: NounProjectIcon) {
    if (this.conversationId) {
      const pin: AttachmentPin = {
        type: 'noun_project_pin',
        icon_id: icon.id,
        params: { filetype: 'svg', color: '000000' },
      }

      const result = await this.api.conversations.pinAttachment({
        params: { conversation_id: this.conversationId },
        body: {
          pin,
        },
      })

      if (result.status === 201) {
        this.trackResultUsed(icon.id.toString(), 'User Attachment')
      } else {
        captureException(new Error('Failed to send attachment'))
        toast.error('Failed to send attachment')
      }
    }
  }

  @action.bound
  setQuery(query: string) {
    this.query = query
    // Reset pagination
    this.nextPageCursor = undefined
    this.total = 0
    this.icons = []

    this.loadData()
  }
}

injected(
  NounProjectViewModel,
  DI_TYPE.Api,
  DI_TYPE.NounProjectRepository,
  DI_TYPE.EventBusService,
  DI_TYPE.RouterService,
  DI_TYPE.MixpanelService,
)
