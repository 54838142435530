import React from 'react'

import RemoveBGIcon from '@/assets/icons/background-remove.svg?react'
import EffectsIcon from '@/assets/icons/effects.svg?react'
import SearchIcon from '@/assets/icons/search.svg?react'
import UpscaleIcon from '@/assets/icons/upscale.svg?react'
import { cls } from '@/utils/utils'

import { Button } from '../button/button'

export type ImageToolType = 'generative' | 'search' | 'upscaler' | 'remove_bg'
export interface IGeneratedImagesGrid {
  children: React.ReactNode
  toolType?: ImageToolType
  toolName?: string
  moreLink?: string
}

export const GeneratedImagesGrid = ({
  children,
  toolType,
  toolName,
  moreLink,
}: IGeneratedImagesGrid) => {
  const onlyOneChild = React.Children.count(children) === 1

  let title = ''
  switch (toolType) {
    case 'generative':
      title = 'Gemma Generated'
      break
    case 'search':
      title = 'Found by Gemma'
      break
    case 'upscaler':
      title = 'Upscaled image'
      break
    case 'remove_bg':
      title = 'Removed background'
      break
  }
  return (
    <div
      data-automation="images"
      className="-mx-5 -ml-20 mb-2 mt-5 overflow-auto px-5 md:mx-0 md:ml-0 md:mt-0 md:px-0"
    >
      <div
        className={cls(
          'bg-cerosWhite shadow-imagesBlock rounded-lg p-4',
          'w-max sm:w-auto sm:max-w-[448px]',
          onlyOneChild && 'min-w-[200px] sm:w-[448px]',
        )}
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: 'auto auto',
          gridTemplateAreas: `
                        "photos"
                        "credits"
                      `,
        }}
      >
        <div
          className={cls(
            !onlyOneChild &&
              'grid grid-cols-[repeat(4,auto)] gap-4 sm:grid-cols-[repeat(2,auto)]',
          )}
          style={{ gridArea: 'photos' }}
        >
          {children}
        </div>

        {toolName && toolType && (
          <div
            style={{ gridArea: 'credits' }}
            className="mt-4 flex min-h-[40px] w-full items-center justify-between"
          >
            <div className="flex items-center text-xs sm:items-baseline sm:text-base">
              {toolType === 'search' && <SearchIcon className="mr-1 h-4 w-4" />}
              {toolType === 'generative' && (
                <EffectsIcon className="mr-1 h-4 w-4" />
              )}
              {toolType === 'upscaler' && (
                <UpscaleIcon className="mr-1 h-4 w-4" />
              )}
              {toolType === 'remove_bg' && (
                <RemoveBGIcon className="mr-1 h-4 w-4" />
              )}
              <span className="ml-1 text-xs font-medium sm:text-base">
                {title}
              </span>
              &nbsp;
              {toolName && !['remove_bg', 'upscaler'].includes(toolType) && (
                <span className="text-cerosGrey400 font-normal sm:text-sm">
                  {toolType === 'search' ? 'on' : 'by'} {toolName}
                </span>
              )}
            </div>
            {moreLink && (
              <Button
                variant="secondary"
                className="h-8 min-w-fit px-2 text-xs hover:shadow-none sm:h-10 sm:px-4 sm:text-base"
                onClick={() => window.open(moreLink)}
              >
                Find more
              </Button>
            )}
            {/* FIXME: Uncomment this button when functionality is ready */}
            {/* <Button className="bg-cerosWhite text-cerosBlack border-cerosGrey300 shadow-button hover:bg-cerosWhite active:border-cerosPrimary focus:border-cerosPrimary h-10 border py-2 text-base hover:shadow-none">
                          <span className="mr-1">🎲</span> Regenerate
                        </Button> */}
          </div>
        )}
      </div>
    </div>
  )
}
