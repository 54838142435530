import { observer } from 'mobx-react-lite'

import { Button } from '@/components/button/button'
import { Modal } from '@/components/modal/modal'
import { ModalHeader } from '@/components/modal/modal-header'
import { DI_TYPE } from '@/di.types'
import { useModalService, useViewModel } from '@/utils/view'

export const FeatureFlagsModal = observer(() => {
  const vm = useViewModel(DI_TYPE.FeatureFlagViewModel)
  const modalService = useModalService()

  return (
    <Modal backdrop={true} onClose={modalService.close}>
      <ModalHeader title="Beta features" onClose={modalService.close} />
      <div className="flex flex-col justify-between px-4 py-5">
        <ul className="custom-scrollbar flex max-h-[300px] flex-col overflow-auto">
          {vm.items.map((featureFlag) => (
            <li key={featureFlag.id}>
              <label
                className="border-b-cerosGrey100 hover:bg-cerosGrey100 active:bg-cerosGrey200 flex items-center justify-between gap-2 gap-x-2 rounded-lg border-b px-4 py-2 last:border-b-0
              hover:cursor-pointer"
              >
                <div>
                  {featureFlag.label}
                  <p className="text-cerosGrey400 text-xs">
                    {featureFlag.description}
                  </p>
                </div>

                <input
                  type="checkbox"
                  checked={featureFlag.value}
                  onChange={() => vm.toggle(featureFlag)}
                  disabled={!vm.isEditable}
                />
              </label>
            </li>
          ))}
        </ul>
        <div className="mt-5 flex items-center justify-center gap-4">
          <Button
            type="button"
            size="small"
            variant="danger"
            onClick={() => vm.resetAll()}
            disabled={!vm.isEditable}
          >
            Reset All
          </Button>
        </div>
      </div>
    </Modal>
  )
})
