import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { createElement, Fragment } from 'react'

import { DI_TYPE } from '@/di.types'
import type { IMenuGroup } from '@/services/sidebar'
import { useViewModel } from '@/utils/view'

import { Drawer, DrawerItem, DrawerTitle } from '../drawer/drawer'
import { UpgradeButton } from '../upgrade-button/upgrade-button'
import { ProBadge } from './pro-badge'

export const ProfileMenuMobile = ({
  actions,
  isOpen,
  setIsOpen,
  profileSection,
  username,
  title,
  isPro,
}: {
  actions: IMenuGroup[]
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  profileSection: ReactNode
  username: string
  title?: string
  isPro?: boolean
}) => {
  const vm = useViewModel(DI_TYPE.SubscriptionPlanViewModel)

  return (
    <Drawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      header={
        <div className="flex w-full flex-col items-center justify-center p-6">
          <div className="relative">
            {profileSection}
            {isPro && <ProBadge className="absolute -bottom-3 left-0" />}
          </div>
          <DrawerTitle className="line-clamp-2 pb-0 pt-3 text-base font-medium leading-5">
            {username}
          </DrawerTitle>
          {title != null && (
            <span className="text-cerosGrey400 truncate pt-1 text-start text-xs leading-5">
              {title}
            </span>
          )}
        </div>
      }
    >
      {vm.canUpgrade && (
        <div className="flex items-center justify-center px-2">
          <UpgradeButton
            onClick={() => {
              setIsOpen(false)
              vm.openPlanModal({ source: 'upgrade-button' })
            }}
          />
        </div>
      )}
      {actions.map((group) => (
        <Fragment key={group.id}>
          {group.data.map((action) => {
            const menuItem = (
              <DrawerItem
                key={action.id}
                onClick={() => {
                  setIsOpen(false)
                  action.onClick()
                }}
                icon={createElement(action.icon)}
              >
                {action.title}
              </DrawerItem>
            )

            return menuItem
          })}
        </Fragment>
      ))}
    </Drawer>
  )
}
