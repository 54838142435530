import { injected } from 'brandi'
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { match } from 'path-to-regexp'

import { DI_TYPE } from '@/di.types'
import type { AuthService } from '@/services/auth'
import type {
  GetAndRemoveLoginReturn,
  SaveLoginReturn,
} from '@/services/login.js'
import type { RouterService } from '@/services/router'
import type { UserService } from '@/services/user'
import { someAsync } from '@/utils/utils'

import { BaseViewModel } from './base-view-model'

const SKIP_ONBOARDING_PATH = 'conversations/new'

export class RequiredUserChecks extends BaseViewModel {
  @observable isBusy = true

  constructor(
    private authService: AuthService,
    private routerService: RouterService,
    private userService: UserService,
    private saveLoginReturn: SaveLoginReturn,
    private getAndRemoveLoginReturn: GetAndRemoveLoginReturn,
  ) {
    super()
    makeObservable(this)
    this.initChecks()
  }

  onInit(): void {}

  onDispose(): void {}

  private initChecks() {
    reaction(
      () => {
        return {
          userPropertiesLoaded: this.userService.properties,
          isAuthenticated: this.authService.isAuthenticated,
          path: this.routerService.currentPath,
        }
      },
      ({ userPropertiesLoaded, isAuthenticated }) => {
        if (userPropertiesLoaded && isAuthenticated) this.forceChecks()
      },
      { fireImmediately: true },
    )
  }

  private async forceChecks() {
    const pathChecks: Array<() => Promise<boolean>> = [
      this.checkTerms,
      this.checkOnboarding,
    ]
    await someAsync(pathChecks, (check) => check())
    runInAction(() => {
      this.isBusy = false
    })
  }

  @action.bound
  private async checkTerms() {
    if (
      this.authService.isAuthenticated &&
      this.userService.shouldForceTermsPage &&
      !this.embedded
    ) {
      if (this.routerService.currentUri.includes(SKIP_ONBOARDING_PATH)) {
        this.saveLoginReturn({
          uri: this.routerService.currentUri,
          createdAt: Date.now(),
        })
      }
      this.routerService.goTo('/terms')
      return true
    }
    return false
  }

  @action.bound
  private async checkOnboarding() {
    const maybeOnboard =
      this.authService.isAuthenticated &&
      !this.embedded &&
      !this.userService.shouldForceTermsPage &&
      this.userService.shouldForceOnboarding
    if (maybeOnboard) {
      const loginReturn = this.getAndRemoveLoginReturn()
      if (
        loginReturn?.uri.includes(SKIP_ONBOARDING_PATH) &&
        Date.now() < loginReturn.createdAt + 5 * 60 * 1000
      ) {
        await this.userService.skipOnboarding()
        this.routerService.goTo(loginReturn.uri)
      } else {
        this.routerService.goTo('/onboarding')
      }
      return true
    }
    return false
  }

  @computed
  get embedded() {
    return Boolean(
      match('/embed/(.*)', { decode: decodeURIComponent })(
        this.routerService.currentPath,
      ),
    )
  }
}

injected(
  RequiredUserChecks,
  DI_TYPE.AuthService,
  DI_TYPE.RouterService,
  DI_TYPE.UserService,
  DI_TYPE.SaveLoginReturn,
  DI_TYPE.GetAndRemoveLoginReturn,
)
