/// <reference types="vite-plugin-svgr/client" />
import './di.container.js'
import './di.types.js'
import './index.css'

import { captureException } from '@sentry/react'

import { diContainer } from './di.container.js'
import { runApp } from './utils/application.js'
;(async () => {
  runApp(diContainer)
})().catch((err) => captureException(err))
