import type { FieldAttributes } from 'formik'
import { Field } from 'formik'

import { cls } from '@/utils/utils'

export type ITextareaField = {
  name: string
  placeholder?: string
  type?: string
  inputClassName?: string
} & Omit<FieldAttributes<any>, 'component'>

export const TextareaField = ({
  name,
  inputClassName,
  ...rest
}: ITextareaField) => {
  return (
    <Field
      component="textarea"
      name={name}
      {...rest}
      className={cls(
        'text-cerosBlack p-4',
        'border-cerosGrey300 w-full rounded-lg border',
        'rounded-md border text-base font-normal outline-none',
        'hover:border-cerosGrey300 active:border-cerosGrey300',
        'focus:outline-cerosPrimary -outline-offset-2',
        'bg-cerosWhite hover:bg-cerosWhite active:bg-cerosWhite focus:bg-cerosWhite',
        inputClassName,
      )}
    />
  )
}
